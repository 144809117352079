import { Component, OnInit } from '@angular/core';
import { Feature } from '../models/feature.model';
import { ActivatedRoute } from '@angular/router';
import { FeaturesService } from '../services/features.service';
import { NotificationsService } from 'angular2-notifications';
import { not_options } from '../../../shared/models/notification-options';

@Component({
  selector: 'features',
  templateUrl: './features.component.html'
})

export class FeaturesComponent implements OnInit {

  public features: Feature[];
  public titlePop = 'Are you sure?';
  public messagePop = 'This option is going to be updated';

  public value: number;

  constructor(private route: ActivatedRoute,
              private featuresService: FeaturesService,
              private notificationsService: NotificationsService) {
  }

  ngOnInit() {
    this.route.data.forEach((data: { features: Feature[] }) => {
      this.features = data.features;
    });
  }

  public onConfirm(feature: Feature, checkboxValue: boolean) {
    this.featuresService.updateFeature(feature, checkboxValue).subscribe(
        () => {
          let enDisString: string;
          if (checkboxValue) { enDisString = 'enabled'; } else { enDisString = 'disabled'; }
          this.notificationsService.success('Feature ' + feature.key + ' successfully updated',
            'The new value is ' + enDisString, not_options);
          this.refreshFeature(feature);
        },
        (error) => {
          this.notificationsService.error('Feature' + feature.key +  ' has not been updated',
            'An error occured during interaction with the server', not_options);
        }
      );
  }

  private refreshFeature(feature: Feature) {
    this.featuresService.getFeatureById(feature.id).subscribe(
      (data: any) => {
        const index: number = this.features.indexOf(feature);
        if (index > -1) {
          const newFeature: Feature = data;
          this.features.splice(index, 1, newFeature);
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  public onCancel() {

  }

  /*public collapsed(event:any):void {
    console.log("collapsed: " + event.value);
  }

  public expanded(event:any):void {
    console.log("expanded: " + event.value);
  }*/
}
