import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login.component';
import { LogoutComponent } from './components/logout.component';
import { NotFoundComponent } from './components/not-found.component';
import { LocationsComponent } from './components/locations.component';
import { LocationsResolve } from './services/locations-resolve.service';
import { AuthGuardService } from '../../shared/services/auth-guard.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'app',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGuardService] },
  {
    path: 'locations',
    component: LocationsComponent,
    canActivate: [AuthGuardService],
    resolve: { locations: LocationsResolve },
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: 'integration-testing',
    loadChildren: '../integration-testing/integration-testing.module#IntegrationTestingModule',
    canActivateChild: [AuthGuardService],
  },
  {
    path: '**',
    redirectTo: '/not-found',
  },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
