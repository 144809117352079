import { Roles } from './../models/roles.model';

export const ROLES: Roles[] = [
    {'id': 2, 'value': 'centrale'},
    {'id': 3, 'value': 'palleggiatore'},
    {'id': 4, 'value': 'schiacciatore'},
    {'id': 5, 'value': 'opposto'},
    {'id': 6, 'value': 'libero'},
    {'id': 7, 'value': 'universale'},
    /*{'id': 8, 'value': 'non specificato'},*/
];
