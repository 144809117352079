import { NgModuleRef } from '@angular/core';
import { hmrModule, createNewHosts, createInputTransfer, removeNgStyles } from '@angularclass/hmr';

export const hmrBootstrap = (module: any, bootstrap: () => Promise<NgModuleRef<any>>) => {
    return bootstrap().then((ngModuleRef: any) => {
        return hmrModule(ngModuleRef, module);
      });
};

export const hmrOnInit = (store, appRef) => {
    if (!store) { return; }

    if ('restoreInputValues' in store) {
      store.restoreInputValues();
    }
    // change detection
    appRef.tick();
    delete store.restoreInputValues;
};

export const hmrOnDestroy = (store, appRef) => {
    const cmpLocation = appRef.components.map(cmp => cmp.location.nativeElement);
    // recreate elements
    store.disposeOldHosts = createNewHosts(cmpLocation);
    // save input values
    store.restoreInputValues  = createInputTransfer();
    // remove styles
    removeNgStyles();
};

export const hmrAfterOnDestroy = store => {
    // display new elements
    store.disposeOldHosts();
    delete store.disposeOldHosts;
};
