import { LOCALE_ID_VALUE } from './../../config/i18n';
import { NgModule, LOCALE_ID, ApplicationRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { CustomFormsModule } from 'ng2-validation';
import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { SimpleNotificationsModule } from 'angular2-notifications';
import { AgmCoreModule } from '@agm/core';
import { StatsModule } from '../stats/stats.module';
import { NumberDisplayModule } from '../../shared/modules/number-display/number-display.module';
import { TabModule } from '../../shared/modules/tab/tab.module';
import { FaqsModule } from '../faqs/faqs.module';
import { FeaturesModule } from '../features/features.module';
import { NewslettersModule } from '../newsletters/newsletters.module';
import { UsersModule } from '../users/users.module';
import { AnnouncementsApprovalModule } from '../announcements-approval/announcements-approval.module';
import { CandidaciesApprovalModule } from '../candidacies-approval/candidacies-approval.module';

import { AppComponent } from './components/app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar.component';
import { SidebarLinksComponent } from './components/sidebar-links.component';
import { LoginComponent } from './components/login.component';
import { LogoutComponent } from './components/logout.component';
import { NotFoundComponent } from './components/not-found.component';
import { LocationsComponent } from './components/locations.component';
import { AuthGuardService } from '../../shared/services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { LocationsResolve } from './services/locations-resolve.service';
import { LocationsService } from './services/locations.service';
import { NotificationsService } from 'angular2-notifications';

import { routing } from './app.routing';

import { environment } from '../../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { hmrOnInit, hmrOnDestroy, hmrAfterOnDestroy } from '../../../hmr';

export function tokenGetter() {
  return sessionStorage.getItem('id_token');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CustomFormsModule,
    CommonModule,
    HttpClientModule,
    SimpleNotificationsModule.forRoot(),
    routing,
    AgmCoreModule.forRoot({
      apiKey: environment.mapsApiKey,
    }),
    StatsModule,
    NumberDisplayModule,
    TabModule,
    FaqsModule,
    NewslettersModule,
    UsersModule,
    FeaturesModule,
    AnnouncementsApprovalModule,
    CandidaciesApprovalModule,
    AngularFireModule.initializeApp(environment.firebase, 'backoffice'),
    AngularFirestoreModule,
    AngularFireAuthModule,
  ],
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    SidebarLinksComponent,
    LoginComponent,
    LogoutComponent,
    LocationsComponent,
    NotFoundComponent,
  ],
  providers: [
    environment.hmr ? { provide: LOCALE_ID, useValue: LOCALE_ID_VALUE } : [],
    AuthGuardService,
    LocationsResolve,
    LocationsService,
    NotificationsService,
    { provide: FirestoreSettingsToken, useValue: {} }, // https://github.com/angular/angularfire2/issues/1993#issuecomment-456481677
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(public appRef: ApplicationRef) {}

  hmrOnInit = store => hmrOnInit(store, this.appRef);
  hmrOnDestroy = store => hmrOnDestroy(store, this.appRef);
  hmrAfterDestroy = store => hmrAfterOnDestroy(store);
}
