import { Component } from '@angular/core';
import { Tab } from '../../../shared/modules/tab/models/tab.model';

@Component({
  selector: 'users',
  template: `
    <div class="col-xs-9 col-xs-offset-3 main">
      <h1 class="page-header">Users</h1>
      <tabs [tabs]="tabs"></tabs>
      <router-outlet></router-outlet>
    </div>
  `
})
export class UsersComponent {

    public tabs: Tab[];
    public tabSearch: Tab;
    public tabSubscriptions: Tab;
    public tabInstantSearch: Tab;

    constructor() {
      this.tabs = [];
      this.tabSearch = new Tab();
      this.tabSubscriptions = new Tab();
      this.tabInstantSearch = new Tab();
      this.tabSearch.path = '/users/search';
      this.tabSubscriptions.path = '/users/subscriptions';
      this.tabInstantSearch.path = '/users/instantsearch';
      this.tabSearch.name = 'Search';
      this.tabSubscriptions.name = 'Subscriptions';
      this.tabInstantSearch.name = 'Instant search';

      this.tabs.push(this.tabSearch);
      this.tabs.push(this.tabSubscriptions);
      this.tabs.push(this.tabInstantSearch);
    }

}
