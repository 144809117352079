import { Municipality } from '../models/municipality';

export const MUNICIPALITIES: Municipality[] = [
{id: 1192, value: 're', cap: '28856', provinceId: 8},
{id: 3697, value: 'vo', cap: '35030', provinceId: 28},
{id: 948, value: 'lu', cap: '15040', provinceId: 6},
{id: 4469, value: 'ro', cap: '44030', provinceId: 43},
{id: 4143, value: 'ne', cap: '16040', provinceId: 36},
{id: 3021, value: 'dro', cap: '38074', provinceId: 22},
{id: 5606, value: 'opi', cap: '67030', provinceId: 68},
{id: 1254, value: 'nus', cap: '11020', provinceId: 9},
{id: 7801, value: 'uri', cap: '07040', provinceId: 100},
{id: 158, value: 'bra', cap: '12042', provinceId: 4},
{id: 8064, value: 'pau', cap: '09090', provinceId: 103},
{id: 2889, value: 'ora', cap: '39040', provinceId: 21},
{id: 3451, value: 'vas', cap: '32030', provinceId: 25},
{id: 1802, value: 'rho', cap: '20017', provinceId: 13},
{id: 8005, value: 'uta', cap: '09010', provinceId: 102},
{id: 3421, value: 'mel', cap: '32026', provinceId: 25},
{id: 1141, value: 'bee', cap: '28813', provinceId: 8},
{id: 615, value: 'viu', cap: '10070', provinceId: 1},
{id: 3018, value: 'don', cap: '38011', provinceId: 22},
{id: 7852, value: 'lei', cap: '08010', provinceId: 101},
{id: 5749, value: 'ari', cap: '66010', provinceId: 71},
{id: 3142, value: 'ton', cap: '38010', provinceId: 22},
{id: 2422, value: 'rea', cap: '27040', provinceId: 16},
{id: 2946, value: 'ala', cap: '38061', provinceId: 22},
{id: 3002, value: 'cis', cap: '38020', provinceId: 22},
{id: 2220, value: 'ome', cap: '25050', provinceId: 15},
{id: 7443, value: 'ali', cap: '98020', provinceId: 93},
{id: 6675, value: 'sava', cap: '74028', provinceId: 81},
{id: 2970, value: 'brez', cap: '38021', provinceId: 22},
{id: 5512, value: 'ripi', cap: '03027', provinceId: 67},
{id: 4061, value: 'dego', cap: '17058', provinceId: 35},
{id: 6725, value: 'diso', cap: '73030', provinceId: 83},
{id: 3563, value: 'dolo', cap: '30031', provinceId: 27},
{id: 2181, value: 'irma', cap: '25061', provinceId: 15},
{id: 3918, value: 'arba', cap: '33090', provinceId: 33},
{id: 1349, value: 'duno', cap: '21030', provinceId: 10},
{id: 1488, value: 'erba', cap: '22036', provinceId: 11},
{id: 3629, value: 'este', cap: '35042', provinceId: 28},
{id: 96, value: 'momo', cap: '28015', provinceId: 3},
{id: 2712, value: 'erve', cap: '23805', provinceId: 19},
{id: 7172, value: 'bova', cap: '89033', provinceId: 88},
{id: 4062, value: 'erli', cap: '17030', provinceId: 35},
{id: 3200, value: 'erbe', cap: '37060', provinceId: 23},
{id: 2951, value: 'arco', cap: '38062', provinceId: 22},
{id: 2858, value: 'egna', cap: '39044', provinceId: 21},
{id: 7623, value: 'enna', cap: '94100', provinceId: 96},
{id: 1099, value: 'pray', cap: '13867', provinceId: 7},
{id: 212, value: 'elva', cap: '12020', provinceId: 4},
{id: 5462, value: 'arce', cap: '03032', provinceId: 67},
{id: 2711, value: 'ello', cap: '23848', provinceId: 19},
{id: 7292, value: 'dasa', cap: '89832', provinceId: 90},
{id: 7358, value: 'vita', cap: '91010', provinceId: 91},
{id: 2102, value: 'anfo', cap: '25070', provinceId: 15},
{id: 5379, value: 'poli', cap: '00010', provinceId: 65},
{id: 2214, value: 'nave', cap: '25075', provinceId: 15},
{id: 3070, value: 'nomi', cap: '38060', provinceId: 22},
{id: 4076, value: 'noli', cap: '17026', provinceId: 35},
{id: 468, value: 'nole', cap: '10076', provinceId: 1},
{id: 6218, value: 'nola', cap: '80035', provinceId: 76},
{id: 6632, value: 'noci', cap: '70015', provinceId: 80},
{id: 1858, value: 'alme', cap: '24011', provinceId: 14},
{id: 7893, value: 'seui', cap: '08037', provinceId: 105},
{id: 1609, value: 'civo', cap: '23010', provinceId: 12},
{id: 3004, value: 'cles', cap: '38023', provinceId: 22},
{id: 5207, value: 'nepi', cap: '01036', provinceId: 63},
{id: 4683, value: 'buti', cap: '56032', provinceId: 52},
{id: 5371, value: 'nemi', cap: '00040', provinceId: 65},
{id: 3005, value: 'cloz', cap: '38020', provinceId: 22},
{id: 4218, value: 'coli', cap: '29020', provinceId: 38},
{id: 134, value: 'alto', cap: '12070', provinceId: 4},
{id: 3015, value: 'dare', cap: '38080', provinceId: 22},
{id: 5505, value: 'pico', cap: '03020', provinceId: 67},
{id: 5509, value: 'pofi', cap: '03026', provinceId: 67},
{id: 4707, value: 'pisa', cap: '', provinceId: 52},
{id: 7, value: 'pila', cap: '13020', provinceId: 2},
{id: 1938, value: 'covo', cap: '24050', provinceId: 14},
{id: 3065, value: 'mori', cap: '38065', provinceId: 22},
{id: 823, value: 'piea', cap: '14020', provinceId: 5},
{id: 5427, value: 'cori', cap: '04010', provinceId: 66},
{id: 7501, value: 'naso', cap: '98074', provinceId: 93},
{id: 3761, value: 'buja', cap: '33030', provinceId: 30},
{id: 2212, value: 'mura', cap: '25070', provinceId: 15},
{id: 3561, value: 'cona', cap: '30010', provinceId: 27},
{id: 1477, value: 'como', cap: '22100', provinceId: 11},
{id: 9440, value: 'nals', cap: '39010', provinceId: 21},
{id: 7575, value: 'naro', cap: '92028', provinceId: 94},
{id: 4935, value: 'fano', cap: '61032', provinceId: 59},
{id: 7231, value: 'samo', cap: '89030', provinceId: 88},
{id: 7828, value: 'bosa', cap: '08013', provinceId: 103},
{id: 3321, value: 'malo', cap: '36034', provinceId: 24},
{id: 5010, value: 'jesi', cap: '60035', provinceId: 60},
{id: 3094, value: 'revo', cap: '38028', provinceId: 22},
{id: 5431, value: 'itri', cap: '04020', provinceId: 66},
{id: 1593, value: 'bema', cap: '23010', provinceId: 12},
{id: 1972, value: 'isso', cap: '24040', provinceId: 14},
{id: 3052, value: 'male', cap: '38027', provinceId: 22},
{id: 9431, value: 'mals', cap: '39024', provinceId: 21},
{id: 6988, value: 'lago', cap: '87035', provinceId: 86},
{id: 2182, value: 'iseo', cap: '25049', provinceId: 15},
{id: 7542, value: 'tusa', cap: '98079', provinceId: 93},
{id: 4332, value: 'rolo', cap: '42047', provinceId: 40},
{id: 7763, value: 'mara', cap: '07010', provinceId: 100},
{id: 5392, value: 'roma', cap: '', provinceId: 65},
{id: 3039, value: 'imer', cap: '38050', provinceId: 22},
{id: 2179, value: 'idro', cap: '25074', provinceId: 15},
{id: 9427, value: 'laas', cap: '39023', provinceId: 21},
{id: 2870, value: 'lana', cap: '39011', provinceId: 21},
{id: 528, value: 'rora', cap: '10060', provinceId: 1},
{id: 1218, value: 'bard', cap: '11020', provinceId: 9},
{id: 5028, value: 'ripe', cap: '60010', provinceId: 60},
{id: 3048, value: 'livo', cap: '38020', provinceId: 22},
{id: 6607, value: 'bari', cap: '', provinceId: 80},
{id: 1514, value: 'livo', cap: '22010', provinceId: 11},
{id: 7855, value: 'lode', cap: '08020', provinceId: 101},
{id: 2799, value: 'lodi', cap: '26900', provinceId: 20},
{id: 18, value: 'rive', cap: '13030', provinceId: 2},
{id: 9426, value: 'lana', cap: '39011', provinceId: 21},
{id: 88, value: 'lesa', cap: '28040', provinceId: 3},
{id: 2185, value: 'leno', cap: '25024', provinceId: 15},
{id: 7478, value: 'leni', cap: '98050', provinceId: 93},
{id: 4487, value: 'lugo', cap: '48022', provinceId: 44},
{id: 7857, value: 'lula', cap: '08020', provinceId: 101},
{id: 2871, value: 'lasa', cap: '39023', provinceId: 21},
{id: 4698, value: 'lari', cap: '56035', provinceId: 52},
{id: 1243, value: 'hone', cap: '11020', provinceId: 9},
{id: 3353, value: 'rosa', cap: '36027', provinceId: 24},
{id: 3971, value: 'armo', cap: '18026', provinceId: 34},
{id: 4255, value: 'bore', cap: '43030', provinceId: 39},
{id: 1010, value: 'sale', cap: '15045', provinceId: 6},
{id: 9419, value: 'gais', cap: '39030', provinceId: 21},
{id: 2863, value: 'gais', cap: '39030', provinceId: 21},
{id: 7737, value: 'bono', cap: '07011', provinceId: 100},
{id: 2267, value: 'salo', cap: '25087', provinceId: 15},
{id: 1238, value: 'gaby', cap: '11020', provinceId: 9},
{id: 7488, value: 'meri', cap: '98040', provinceId: 93},
{id: 4136, value: 'mele', cap: '16010', provinceId: 36},
{id: 1630, value: 'mese', cap: '23020', provinceId: 12},
{id: 6214, value: 'meta', cap: '80062', provinceId: 76},
{id: 3307, value: 'foza', cap: '36010', provinceId: 24},
{id: 3600, value: 'arre', cap: '35020', provinceId: 28},
{id: 7660, value: 'milo', cap: '95010', provinceId: 97},
{id: 3574, value: 'mira', cap: '30034', provinceId: 27},
{id: 7965, value: 'pula', cap: '09010', provinceId: 102},
{id: 940, value: 'gavi', cap: '15066', provinceId: 6},
{id: 1434, value: 'asso', cap: '22033', provinceId: 11},
{id: 7032, value: 'rose', cap: '87040', provinceId: 86},
{id: 3105, value: 'rumo', cap: '38020', provinceId: 22},
{id: 1216, value: 'ayas', cap: '11020', provinceId: 9},
{id: 2952, value: 'avio', cap: '38063', provinceId: 22},
{id: 4474, value: 'goro', cap: '44020', provinceId: 43},
{id: 7942, value: 'goni', cap: '09040', provinceId: 102},
{id: 3641, value: 'masi', cap: '35040', provinceId: 28},
{id: 3846, value: 'ruda', cap: '33050', provinceId: 30},
{id: 9445, value: 'auer', cap: '39040', provinceId: 21},
{id: 744, value: 'asti', cap: '14100', provinceId: 5},
{id: 6436, value: 'gioi', cap: '84056', provinceId: 78},
{id: 49, value: 'boca', cap: '28010', provinceId: 3},
{id: 7941, value: 'giba', cap: '09010', provinceId: 107},
{id: 5657, value: 'atri', cap: '64032', provinceId: 69},
{id: 1766, value: 'meda', cap: '20821', provinceId: 108},
{id: 2391, value: 'mede', cap: '27035', provinceId: 16},
{id: 7599, value: 'gela', cap: '93012', provinceId: 95},
{id: 470, value: 'none', cap: '10060', provinceId: 1},
{id: 6799, value: 'anzi', cap: '85010', provinceId: 84},
{id: 3169, value: 'affi', cap: '37010', provinceId: 23},
{id: 3590, value: 'stra', cap: '30039', provinceId: 27},
{id: 477, value: 'oulx', cap: '10056', provinceId: 1},
{id: 7902, value: 'teti', cap: '08030', provinceId: 101},
{id: 3384, value: 'zane', cap: '36010', provinceId: 24},
{id: 2148, value: 'cevo', cap: '25040', provinceId: 15},
{id: 3596, value: 'agna', cap: '35021', provinceId: 28},
{id: 4163, value: 'sori', cap: '16031', provinceId: 36},
{id: 195, value: 'ceva', cap: '12073', provinceId: 4},
{id: 2147, value: 'ceto', cap: '25040', provinceId: 15},
{id: 132, value: 'alba', cap: '12051', provinceId: 4},
{id: 6758, value: 'patu', cap: '73053', provinceId: 83},
{id: 6524, value: 'stio', cap: '84075', provinceId: 78},
{id: 4560, value: 'zeri', cap: '54029', provinceId: 47},
{id: 6015, value: 'cesa', cap: '81030', provinceId: 74},
{id: 4097, value: 'urbe', cap: '17048', provinceId: 35},
{id: 1791, value: 'pero', cap: '20016', provinceId: 13},
{id: 5604, value: 'ocre', cap: '67040', provinceId: 68},
{id: 7899, value: 'suni', cap: '08010', provinceId: 103},
{id: 3148, value: 'tres', cap: '38010', provinceId: 22},
{id: 6884, value: 'tito', cap: '85050', provinceId: 84},
{id: 120, value: 'suno', cap: '28019', provinceId: 3},
{id: 7082, value: 'albi', cap: '88055', provinceId: 87},
{id: 2099, value: 'adro', cap: '25030', provinceId: 15},
{id: 572, value: 'susa', cap: '10059', provinceId: 1},
{id: 2302, value: 'zone', cap: '25050', provinceId: 15},
{id: 3251, value: 'sona', cap: '37060', provinceId: 23},
{id: 5528, value: 'sora', cap: '03039', provinceId: 67},
{id: 5210, value: 'orte', cap: '01028', provinceId: 63},
{id: 8093, value: 'uras', cap: '09099', provinceId: 103},
{id: 4882, value: 'todi', cap: '06059', provinceId: 57},
{id: 3128, value: 'taio', cap: '38012', provinceId: 22},
{id: 2489, value: 'zeme', cap: '27030', provinceId: 16},
{id: 7776, value: 'ossi', cap: '07045', provinceId: 100},
{id: 3867, value: 'teor', cap: '33050', provinceId: 30},
{id: 7800, value: 'tula', cap: '07010', provinceId: 100},
{id: 6929, value: 'acri', cap: '87041', provinceId: 86},
{id: 1541, value: 'pare', cap: '22020', provinceId: 11},
{id: 8084, value: 'sini', cap: '09090', provinceId: 103},
{id: 165, value: 'camo', cap: '12050', provinceId: 4},
{id: 5327, value: 'cave', cap: '00033', provinceId: 65},
{id: 7265, value: 'ciro', cap: '88813', provinceId: 89},
{id: 4756, value: 'stia', cap: '52017', provinceId: 53},
{id: 1921, value: 'cene', cap: '24020', provinceId: 14},
{id: 2281, value: 'temu', cap: '25050', provinceId: 15},
{id: 3078, value: 'peio', cap: '38024', provinceId: 22},
{id: 7717, value: 'noto', cap: '96017', provinceId: 99},
{id: 6372, value: 'tufo', cap: '83010', provinceId: 77},
{id: 7770, value: 'nule', cap: '07010', provinceId: 100},
{id: 1284, value: 'agra', cap: '21010', provinceId: 10},
{id: 5930, value: 'toro', cap: '86018', provinceId: 72},
{id: 1608, value: 'cino', cap: '23010', provinceId: 12},
{id: 4077, value: 'onzo', cap: '17037', provinceId: 35},
{id: 7360, value: 'alia', cap: '90021', provinceId: 92},
{id: 2010, value: 'peia', cap: '24020', provinceId: 14},
{id: 6648, value: 'turi', cap: '70010', provinceId: 80},
{id: 3224, value: 'palu', cap: '37050', provinceId: 23},
{id: 8028, value: 'ales', cap: '09091', provinceId: 103},
{id: 585, value: 'vaie', cap: '10050', provinceId: 1},
{id: 6689, value: 'oria', cap: '72024', provinceId: 82},
{id: 3339, value: 'nove', cap: '36055', provinceId: 24},
{id: 21, value: 'rossa', cap: '13020', provinceId: 2},
{id: 5346, value: 'gorga', cap: '00030', provinceId: 65},
{id: 1968, value: 'gromo', cap: '24020', provinceId: 14},
{id: 1965, value: 'gorle', cap: '24020', provinceId: 14},
{id: 3894, value: 'grado', cap: '34073', provinceId: 31},
{id: 1966, value: 'gorno', cap: '24020', provinceId: 14},
{id: 3708, value: 'canda', cap: '45020', provinceId: 29},
{id: 2116, value: 'bione', cap: '25070', provinceId: 15},
{id: 529, value: 'roure', cap: '10060', provinceId: 1},
{id: 795, value: 'grana', cap: '14031', provinceId: 5},
{id: 6297, value: 'greci', cap: '83030', provinceId: 77},
{id: 530, value: 'rosta', cap: '10090', provinceId: 1},
{id: 2958, value: 'bieno', cap: '38050', provinceId: 22},
{id: 3355, value: 'rotzo', cap: '36010', provinceId: 24},
{id: 4167, value: 'uscio', cap: '16036', provinceId: 36},
{id: 2973, value: 'cagno', cap: '38028', provinceId: 22},
{id: 6866, value: 'ruoti', cap: '85056', provinceId: 84},
{id: 2634, value: 'goito', cap: '46044', provinceId: 18},
{id: 6099, value: 'bonea', cap: '82013', provinceId: 75},
{id: 3633, value: 'gazzo', cap: '35010', provinceId: 28},
{id: 1031, value: 'terzo', cap: '15010', provinceId: 6},
{id: 7842, value: 'gavoi', cap: '08020', provinceId: 101},
{id: 5844, value: 'vacri', cap: '66010', provinceId: 71},
{id: 3204, value: 'garda', cap: '37016', provinceId: 23},
{id: 2963, value: 'bondo', cap: '38081', provinceId: 22},
{id: 7395, value: 'gangi', cap: '90024', provinceId: 92},
{id: 5736, value: 'salle', cap: '65020', provinceId: 70},
{id: 5752, value: 'bomba', cap: '66042', provinceId: 71},
{id: 7840, value: 'gairo', cap: '08040', provinceId: 105},
{id: 4921, value: 'terni', cap: '05100', provinceId: 58},
{id: 7811, value: 'tergu', cap: '07030', provinceId: 100},
{id: 3721, value: 'gaiba', cap: '45030', provinceId: 29},
{id: 7470, value: 'gaggi', cap: '98030', provinceId: 93},
{id: 5430, value: 'gaeta', cap: '04024', provinceId: 66},
{id: 6367, value: 'teora', cap: '83056', provinceId: 77},
{id: 3156, value: 'vervo', cap: '38010', provinceId: 22},
{id: 7903, value: 'tiana', cap: '08020', provinceId: 101},
{id: 7824, value: 'bitti', cap: '08021', provinceId: 101},
{id: 7802, value: 'usini', cap: '07049', provinceId: 100},
{id: 5787, value: 'gissi', cap: '66052', provinceId: 71},
{id: 3034, value: 'giovo', cap: '38030', provinceId: 22},
{id: 4903, value: 'giove', cap: '05024', provinceId: 58},
{id: 5175, value: 'blera', cap: '01010', provinceId: 63},
{id: 7441, value: 'blufi', cap: '90020', provinceId: 92},
{id: 2435, value: 'ruino', cap: '27040', provinceId: 16},
{id: 4338, value: 'toano', cap: '42010', provinceId: 40},
{id: 7797, value: 'tissi', cap: '07040', provinceId: 100},
{id: 5009, value: 'genga', cap: '60040', provinceId: 60},
{id: 5406, value: 'tolfa', cap: '00059', provinceId: 65},
{id: 4491, value: 'russi', cap: '48026', provinceId: 44},
{id: 9484, value: 'tirol', cap: '39019', provinceId: 21},
{id: 2927, value: 'tires', cap: '39050', provinceId: 21},
{id: 7755, value: 'giave', cap: '07010', provinceId: 100},
{id: 6492, value: 'sacco', cap: '84070', provinceId: 78},
{id: 9483, value: 'tiers', cap: '39050', provinceId: 21},
{id: 2175, value: 'ghedi', cap: '25016', provinceId: 15},
{id: 1969, value: 'grone', cap: '24060', provinceId: 14},
{id: 6950, value: 'canna', cap: '87070', provinceId: 86},
{id: 5836, value: 'tollo', cap: '66010', provinceId: 71},
{id: 3795, value: 'lauco', cap: '33029', provinceId: 30},
{id: 1974, value: 'leffe', cap: '24026', provinceId: 14},
{id: 9698, value: 'ledro', cap: '38067', provinceId: 22},
{id: 2720, value: 'lecco', cap: '23900', provinceId: 19},
{id: 6595, value: 'troia', cap: '71029', provinceId: 79},
{id: 6733, value: 'lecce', cap: '73100', provinceId: 83},
{id: 3045, value: 'lavis', cap: '38015', provinceId: 22},
{id: 6303, value: 'lauro', cap: '83023', provinceId: 77},
{id: 171, value: 'carde', cap: '12030', provinceId: 4},
{id: 432, value: 'leini', cap: '10040', provinceId: 1},
{id: 1454, value: 'canzo', cap: '22035', provinceId: 11},
{id: 1453, value: 'cantu', cap: '22063', provinceId: 11},
{id: 6302, value: 'lapio', cap: '83030', provinceId: 77},
{id: 4347, value: 'carpi', cap: '41012', provinceId: 41},
{id: 7541, value: 'tripi', cap: '98060', provinceId: 93},
{id: 3413, value: 'lamon', cap: '32033', provinceId: 25},
{id: 6807, value: 'bella', cap: '85051', provinceId: 84},
{id: 9424, value: 'lajen', cap: '39040', provinceId: 21},
{id: 2468, value: 'trovo', cap: '27020', provinceId: 16},
{id: 4132, value: 'leivi', cap: '16040', provinceId: 36},
{id: 2868, value: 'laion', cap: '39040', provinceId: 21},
{id: 3877, value: 'udine', cap: '33100', provinceId: 30},
{id: 8002, value: 'turri', cap: '09020', provinceId: 106},
{id: 6182, value: 'capri', cap: '80073', provinceId: 76},
{id: 8001, value: 'tuili', cap: '09029', provinceId: 106},
{id: 240, value: 'lisio', cap: '12070', provinceId: 4},
{id: 7543, value: 'ucria', cap: '98060', provinceId: 93},
{id: 2385, value: 'lirio', cap: '27040', provinceId: 16},
{id: 1435, value: 'barni', cap: '22030', provinceId: 11},
{id: 6304, value: 'lioni', cap: '83047', provinceId: 77},
{id: 1756, value: 'lesmo', cap: '20855', provinceId: 108},
{id: 433, value: 'lemie', cap: '10070', provinceId: 1},
{id: 947, value: 'lerma', cap: '15070', provinceId: 6},
{id: 6001, value: 'capua', cap: '81043', provinceId: 74},
{id: 3351, value: 'roana', cap: '36010', provinceId: 24},
{id: 657, value: 'lenta', cap: '13035', provinceId: 2},
{id: 9487, value: 'ulten', cap: '39016', provinceId: 21},
{id: 1510, value: 'lenno', cap: '22016', provinceId: 11},
{id: 1975, value: 'lenna', cap: '24010', provinceId: 14},
{id: 2930, value: 'tubre', cap: '39020', provinceId: 21},
{id: 3546, value: 'vidor', cap: '31020', provinceId: 26},
{id: 1506, value: 'laino', cap: '22020', provinceId: 11},
{id: 6885, value: 'tolve', cap: '85017', provinceId: 84},
{id: 578, value: 'trana', cap: '10090', provinceId: 1},
{id: 5092, value: 'treia', cap: '62010', provinceId: 61},
{id: 7848, value: 'isili', cap: '08033', provinceId: 102},
{id: 3040, value: 'isera', cap: '38060', provinceId: 22},
{id: 4245, value: 'travo', cap: '29020', provinceId: 38},
{id: 2030, value: 'rogno', cap: '24060', provinceId: 14},
{id: 6646, value: 'trani', cap: '76125', provinceId: 110},
{id: 4451, value: 'berra', cap: '44033', provinceId: 43},
{id: 7906, value: 'torpe', cap: '08020', provinceId: 101},
{id: 683, value: 'roddi', cap: '12060', provinceId: 4},
{id: 4339, value: 'vetto', cap: '42020', provinceId: 40},
{id: 4421, value: 'imola', cap: '40026', provinceId: 42},
{id: 1573, value: 'torno', cap: '22020', provinceId: 11},
{id: 848, value: 'tonco', cap: '14039', provinceId: 5},
{id: 1168, value: 'gurro', cap: '28828', provinceId: 8},
{id: 3231, value: 'ronca', cap: '37030', provinceId: 23},
{id: 5933, value: 'ururi', cap: '86049', provinceId: 72},
{id: 9492, value: 'gsies', cap: '39030', provinceId: 21},
{id: 853, value: 'viale', cap: '14010', provinceId: 5},
{id: 4341, value: 'viano', cap: '42030', provinceId: 40},
{id: 34, value: 'trino', cap: '13039', provinceId: 2},
{id: 5349, value: 'jenne', cap: '00020', provinceId: 65},
{id: 3315, value: 'laghi', cap: '36010', provinceId: 24},
{id: 2866, value: 'laces', cap: '39021', provinceId: 21},
{id: 5260, value: 'labro', cap: '02010', provinceId: 64},
{id: 3290, value: 'carre', cap: '36010', provinceId: 24},
{id: 9399, value: 'kuens', cap: '39010', provinceId: 21},
{id: 9406, value: 'kiens', cap: '39030', provinceId: 21},
{id: 4179, value: 'carro', cap: '19012', provinceId: 37},
{id: 7849, value: 'jerzu', cap: '08044', provinceId: 105},
{id: 5880, value: 'jelsi', cap: '86015', provinceId: 72},
{id: 1052, value: 'benna', cap: '13871', provinceId: 7},
{id: 172, value: 'carru', cap: '12061', provinceId: 4},
{id: 2547, value: 'izano', cap: '26010', provinceId: 17},
{id: 427, value: 'ivrea', cap: '10015', provinceId: 1},
{id: 7822, value: 'belvi', cap: '08030', provinceId: 101},
{id: 7908, value: 'triei', cap: '08040', provinceId: 105},
{id: 7477, value: 'itala', cap: '98025', provinceId: 93},
{id: 4884, value: 'trevi', cap: '06039', provinceId: 57},
{id: 1367, value: 'ispra', cap: '21027', provinceId: 10},
{id: 9494, value: 'vahrn', cap: '39040', provinceId: 21},
{id: 7838, value: 'fonni', cap: '08023', provinceId: 101},
{id: 5784, value: 'furci', cap: '66050', provinceId: 71},
{id: 29, value: 'scopa', cap: '13027', provinceId: 2},
{id: 3366, value: 'schio', cap: '36015', provinceId: 24},
{id: 749, value: 'bruno', cap: '14046', provinceId: 5},
{id: 6902, value: 'craco', cap: '75010', provinceId: 85},
{id: 2362, value: 'cozzo', cap: '27030', provinceId: 16},
{id: 3706, value: 'calto', cap: '45030', provinceId: 29},
{id: 4031, value: 'vasia', cap: '18020', provinceId: 34},
{id: 7245, value: 'scido', cap: '89010', provinceId: 88},
{id: 6772, value: 'secli', cap: '73050', provinceId: 83},
{id: 2915, value: 'scena', cap: '39017', provinceId: 21},
{id: 2454, value: 'sommo', cap: '27048', provinceId: 16},
{id: 7097, value: 'cerva', cap: '88050', provinceId: 87},
{id: 5088, value: 'sefro', cap: '62025', provinceId: 61},
{id: 2097, value: 'solza', cap: '24030', provinceId: 14},
{id: 5403, value: 'segni', cap: '00037', provinceId: 65},
{id: 5293, value: 'selci', cap: '02040', provinceId: 64},
{id: 396, value: 'corio', cap: '10070', provinceId: 1},
{id: 1203, value: 'varzo', cap: '28868', provinceId: 8},
{id: 2528, value: 'crema', cap: '26013', provinceId: 17},
{id: 3252, value: 'sorga', cap: '37060', provinceId: 23},
{id: 2474, value: 'varzi', cap: '27057', provinceId: 16},
{id: 6018, value: 'curti', cap: '81040', provinceId: 74},
{id: 6102, value: 'calvi', cap: '82018', provinceId: 75},
{id: 7387, value: 'cerda', cap: '90010', provinceId: 92},
{id: 3193, value: 'cerea', cap: '37053', provinceId: 23},
{id: 3014, value: 'daone', cap: '38080', provinceId: 22},
{id: 1346, value: 'cuvio', cap: '21030', provinceId: 10},
{id: 1941, value: 'cusio', cap: '24010', provinceId: 14},
{id: 6517, value: 'scala', cap: '84010', provinceId: 78},
{id: 6723, value: 'cursi', cap: '73020', provinceId: 83},
{id: 374, value: 'ceres', cap: '10070', provinceId: 1},
{id: 7448, value: 'brolo', cap: '98061', provinceId: 93},
{id: 1940, value: 'curno', cap: '24035', provinceId: 14},
{id: 2327, value: 'broni', cap: '27043', provinceId: 16},
{id: 207, value: 'cuneo', cap: '12100', provinceId: 4},
{id: 5845, value: 'vasto', cap: '66054', provinceId: 71},
{id: 647, value: 'crova', cap: '13040', provinceId: 2},
{id: 1071, value: 'crosa', cap: '13853', provinceId: 7},
{id: 1158, value: 'crodo', cap: '28862', provinceId: 8},
{id: 3984, value: 'cervo', cap: '18010', provinceId: 34},
{id: 7744, value: 'bulzi', cap: '07030', provinceId: 100},
{id: 5739, value: 'scafa', cap: '65027', provinceId: 70},
{id: 4209, value: 'cadeo', cap: '29010', provinceId: 38},
{id: 6534, value: 'valva', cap: '84020', provinceId: 78},
{id: 3931, value: 'claut', cap: '33080', provinceId: 33},
{id: 7991, value: 'setzu', cap: '09029', provinceId: 106},
{id: 709, value: 'sinio', cap: '12050', provinceId: 4},
{id: 7894, value: 'seulo', cap: '08030', provinceId: 102},
{id: 5449, value: 'sezze', cap: '04018', provinceId: 66},
{id: 3115, value: 'sfruz', cap: '38010', provinceId: 22},
{id: 6521, value: 'siano', cap: '84088', provinceId: 78},
{id: 8085, value: 'siris', cap: '09090', provinceId: 103},
{id: 7992, value: 'siddi', cap: '09020', provinceId: 106},
{id: 2128, value: 'caino', cap: '25070', provinceId: 15},
{id: 4791, value: 'siena', cap: '53100', provinceId: 54},
{id: 388, value: 'cirie', cap: '10073', provinceId: 1},
{id: 4657, value: 'signa', cap: '50058', provinceId: 50},
{id: 3537, value: 'silea', cap: '31057', provinceId: 26},
{id: 4929, value: 'cagli', cap: '61043', provinceId: 59},
{id: 5693, value: 'silvi', cap: '64028', provinceId: 69},
{id: 6968, value: 'cleto', cap: '87030', provinceId: 86},
{id: 3116, value: 'siror', cap: '38054', provinceId: 22},
{id: 4296, value: 'varsi', cap: '43049', provinceId: 39},
{id: 6519, value: 'serre', cap: '84028', provinceId: 78},
{id: 2938, value: 'varna', cap: '39040', provinceId: 21},
{id: 3878, value: 'varmo', cap: '33030', provinceId: 30},
{id: 3299, value: 'conco', cap: '36062', provinceId: 24},
{id: 8078, value: 'senis', cap: '09080', provinceId: 103},
{id: 3985, value: 'cesio', cap: '18022', provinceId: 34},
{id: 2275, value: 'serle', cap: '25080', provinceId: 15},
{id: 163, value: 'busca', cap: '12022', provinceId: 4},
{id: 7892, value: 'serri', cap: '08030', provinceId: 102},
{id: 4284, value: 'sissa', cap: '43018', provinceId: 39},
{id: 2919, value: 'sesto', cap: '39030', provinceId: 21},
{id: 7989, value: 'sestu', cap: '09028', provinceId: 102},
{id: 8102, value: 'soddi', cap: '09080', provinceId: 103},
{id: 5855, value: 'busso', cap: '86010', provinceId: 72},
{id: 2690, value: 'calco', cap: '23885', provinceId: 19},
{id: 3249, value: 'soave', cap: '37038', provinceId: 23},
{id: 4684, value: 'calci', cap: '56011', provinceId: 52},
{id: 1230, value: 'cogne', cap: '11012', provinceId: 9},
{id: 1612, value: 'dazio', cap: '23010', provinceId: 12},
{id: 4453, value: 'cento', cap: '44042', provinceId: 43},
{id: 2862, value: 'funes', cap: '39040', provinceId: 21},
{id: 3024, value: 'faver', cap: '38030', provinceId: 22},
{id: 3025, value: 'fiave', cap: '38075', provinceId: 22},
{id: 406, value: 'fiano', cap: '10070', provinceId: 1},
{id: 1351, value: 'ferno', cap: '21010', provinceId: 10},
{id: 5114, value: 'fermo', cap: '63900', provinceId: 109},
{id: 7712, value: 'ferla', cap: '96010', provinceId: 99},
{id: 1236, value: 'fenis', cap: '11020', provinceId: 9},
{id: 2119, value: 'borno', cap: '25042', provinceId: 15},
{id: 216, value: 'faule', cap: '12030', provinceId: 4},
{id: 6980, value: 'firmo', cap: '87010', provinceId: 86},
{id: 6512, value: 'sanza', cap: '84030', provinceId: 78},
{id: 6513, value: 'sapri', cap: '84073', provinceId: 78},
{id: 6882, value: 'teana', cap: '85032', provinceId: 84},
{id: 3540, value: 'tarzo', cap: '31020', provinceId: 26},
{id: 3150, value: 'valda', cap: '38030', provinceId: 22},
{id: 3936, value: 'fanna', cap: '33092', provinceId: 33},
{id: 5850, value: 'fallo', cap: '66040', provinceId: 71},
{id: 6560, value: 'faeto', cap: '71020', provinceId: 79},
{id: 6077, value: 'teano', cap: '81057', provinceId: 74},
{id: 2169, value: 'flero', cap: '25020', provinceId: 15},
{id: 881, value: 'bosio', cap: '15060', provinceId: 6},
{id: 5589, value: 'fossa', cap: '67020', provinceId: 68},
{id: 6764, value: 'salve', cap: '73050', provinceId: 83},
{id: 3681, value: 'teolo', cap: '35037', provinceId: 28},
{id: 411, value: 'front', cap: '10070', provinceId: 1},
{id: 5783, value: 'frisa', cap: '66030', provinceId: 71},
{id: 3133, value: 'tenno', cap: '38060', provinceId: 22},
{id: 3132, value: 'tenna', cap: '38050', provinceId: 22},
{id: 3568, value: 'fosso', cap: '30030', provinceId: 27},
{id: 4502, value: 'forli', cap: '', provinceId: 45},
{id: 7805, value: 'telti', cap: '07020', provinceId: 104},
{id: 6199, value: 'forio', cap: '80075', provinceId: 76},
{id: 5116, value: 'force', cap: '63086', provinceId: 62},
{id: 3485, value: 'fonte', cap: '31010', provinceId: 26},
{id: 3130, value: 'telve', cap: '38050', provinceId: 22},
{id: 1451, value: 'cagno', cap: '22070', provinceId: 11},
{id: 3030, value: 'fondo', cap: '38013', provinceId: 22},
{id: 5428, value: 'fondi', cap: '04022', provinceId: 66},
{id: 4183, value: 'follo', cap: '19020', provinceId: 37},
{id: 155, value: 'bosia', cap: '12050', provinceId: 4},
{id: 3022, value: 'faedo', cap: '38010', provinceId: 22},
{id: 7794, value: 'sorso', cap: '07037', provinceId: 100},
{id: 2141, value: 'casto', cap: '25070', provinceId: 15},
{id: 4414, value: 'dozza', cap: '40060', provinceId: 42},
{id: 1233, value: 'doues', cap: '11010', provinceId: 9},
{id: 630, value: 'breia', cap: '13020', provinceId: 2},
{id: 2364, value: 'dorno', cap: '27020', provinceId: 16},
{id: 2710, value: 'dorio', cap: '23824', provinceId: 19},
{id: 2325, value: 'breme', cap: '27020', provinceId: 16},
{id: 1485, value: 'dongo', cap: '22014', provinceId: 11},
{id: 2125, value: 'breno', cap: '25043', provinceId: 15},
{id: 6429, value: 'eboli', cap: '84025', provinceId: 78},
{id: 3120, value: 'spera', cap: '38059', provinceId: 22},
{id: 3199, value: 'dolce', cap: '37020', provinceId: 23},
{id: 3781, value: 'dogna', cap: '33010', provinceId: 30},
{id: 3119, value: 'sover', cap: '38048', provinceId: 22},
{id: 1740, value: 'desio', cap: '20832', provinceId: 108},
{id: 3016, value: 'denno', cap: '38010', provinceId: 22},
{id: 2163, value: 'dello', cap: '25020', provinceId: 15},
{id: 7598, value: 'delia', cap: '93010', provinceId: 95},
{id: 3020, value: 'drena', cap: '38074', provinceId: 22},
{id: 2165, value: 'edolo', cap: '25048', provinceId: 15},
{id: 4900, value: 'fabro', cap: '05015', provinceId: 58},
{id: 7343, value: 'erice', cap: '91016', provinceId: 91},
{id: 6514, value: 'sarno', cap: '84087', provinceId: 78},
{id: 2167, value: 'esine', cap: '25040', provinceId: 15},
{id: 4758, value: 'talla', cap: '52010', provinceId: 53},
{id: 7813, value: 'erula', cap: '07030', provinceId: 100},
{id: 1275, value: 'sarre', cap: '11010', provinceId: 9},
{id: 1408, value: 'taino', cap: '21020', provinceId: 10},
{id: 5217, value: 'sutri', cap: '01015', provinceId: 63},
{id: 157, value: 'boves', cap: '12012', provinceId: 4},
{id: 7253, value: 'stilo', cap: '89049', provinceId: 88},
{id: 6781, value: 'surbo', cap: '73010', provinceId: 83},
{id: 214, value: 'envie', cap: '12030', provinceId: 4},
{id: 3305, value: 'enego', cap: '36052', provinceId: 24},
{id: 9393, value: 'bozen', cap: '39100', provinceId: 21},
{id: 3125, value: 'storo', cap: '38089', provinceId: 22},
{id: 8023, value: 'elmas', cap: '09030', provinceId: 102},
{id: 7833, value: 'elini', cap: '08040', provinceId: 105},
{id: 5718, value: 'elice', cap: '65010', provinceId: 70},
{id: 141, value: 'barge', cap: '12032', provinceId: 4},
{id: 6924, value: 'tursi', cap: '75028', provinceId: 85},
{id: 4563, value: 'barga', cap: '55051', provinceId: 48},
{id: 1263, value: 'quart', cap: '11020', provinceId: 9},
{id: 4661, value: 'vinci', cap: '50059', provinceId: 50},
{id: 3391, value: 'arsie', cap: '32030', provinceId: 25},
{id: 7871, value: 'olzai', cap: '08020', provinceId: 101},
{id: 7872, value: 'onani', cap: '08020', provinceId: 101},
{id: 5208, value: 'onano', cap: '01010', provinceId: 63},
{id: 5013, value: 'mergo', cap: '60030', provinceId: 60},
{id: 3429, value: 'quero', cap: '32030', provinceId: 25},
{id: 9493, value: 'vintl', cap: '39030', provinceId: 21},
{id: 1997, value: 'oneta', cap: '24020', provinceId: 14},
{id: 1998, value: 'onore', cap: '24020', provinceId: 14},
{id: 9436, value: 'meran', cap: '39012', provinceId: 21},
{id: 6388, value: 'ascea', cap: '84046', provinceId: 78},
{id: 3573, value: 'meolo', cap: '30020', provinceId: 27},
{id: 7572, value: 'menfi', cap: '92013', provinceId: 94},
{id: 3498, value: 'miane', cap: '31050', provinceId: 26},
{id: 291, value: 'perlo', cap: '12070', provinceId: 4},
{id: 1628, value: 'mello', cap: '23010', provinceId: 12},
{id: 7492, value: 'mirto', cap: '98070', provinceId: 93},
{id: 6326, value: 'nusco', cap: '83051', provinceId: 77},
{id: 7958, value: 'nuxis', cap: '09010', provinceId: 107},
{id: 3060, value: 'moena', cap: '38035', provinceId: 22},
{id: 2218, value: 'odolo', cap: '25076', provinceId: 15},
{id: 5605, value: 'ofena', cap: '67025', provinceId: 68},
{id: 2299, value: 'vione', cap: '25050', provinceId: 15},
{id: 1213, value: 'arnad', cap: '11020', provinceId: 9},
{id: 738, value: 'viola', cap: '12070', provinceId: 4},
{id: 9489, value: 'olang', cap: '39030', provinceId: 21},
{id: 7772, value: 'olbia', cap: '07026', provinceId: 104},
{id: 1136, value: 'arola', cap: '28899', provinceId: 8},
{id: 45, value: 'arona', cap: '28041', provinceId: 3},
{id: 7661, value: 'mineo', cap: '95044', provinceId: 97},
{id: 8051, value: 'milis', cap: '09070', provinceId: 103},
{id: 1769, value: 'melzo', cap: '20066', provinceId: 13},
{id: 251, value: 'melle', cap: '12020', provinceId: 4},
{id: 7868, value: 'nurri', cap: '08035', provinceId: 102},
{id: 7706, value: 'avola', cap: '96012', provinceId: 99},
{id: 1399, value: 'ranco', cap: '21020', provinceId: 10},
{id: 3495, value: 'maser', cap: '31010', provinceId: 26},
{id: 6464, value: 'orria', cap: '84060', provinceId: 78},
{id: 7004, value: 'marzi', cap: '87050', provinceId: 86},
{id: 4015, value: 'ranzo', cap: '18020', provinceId: 34},
{id: 1215, value: 'avise', cap: '11010', provinceId: 9},
{id: 13, value: 'rassa', cap: '13020', provinceId: 2},
{id: 950, value: 'masio', cap: '15024', provinceId: 6},
{id: 5202, value: 'marta', cap: '01010', provinceId: 63},
{id: 104, value: 'pella', cap: '28010', provinceId: 3},
{id: 7881, value: 'orune', cap: '08020', provinceId: 101},
{id: 6931, value: 'aieta', cap: '87020', provinceId: 86},
{id: 3837, value: 'raveo', cap: '33029', provinceId: 30},
{id: 7775, value: 'osilo', cap: '07033', provinceId: 100},
{id: 284, value: 'ormea', cap: '12078', provinceId: 4},
{id: 4553, value: 'massa', cap: '54100', provinceId: 47},
{id: 6843, value: 'melfi', cap: '85025', provinceId: 84},
{id: 3896, value: 'medea', cap: '34076', provinceId: 31},
{id: 1783, value: 'opera', cap: '20090', provinceId: 13},
{id: 2610, value: 'asola', cap: '46041', provinceId: 18},
{id: 3459, value: 'asolo', cap: '31011', provinceId: 26},
{id: 43, value: 'ameno', cap: '28010', provinceId: 3},
{id: 8033, value: 'asuni', cap: '09080', provinceId: 103},
{id: 7875, value: 'orani', cap: '08026', provinceId: 101},
{id: 2892, value: 'perca', cap: '39030', provinceId: 21},
{id: 4544, value: 'aulla', cap: '54011', provinceId: 47},
{id: 5465, value: 'atina', cap: '03042', provinceId: 67},
{id: 3092, value: 'rabbi', cap: '38020', provinceId: 22},
{id: 4145, value: 'orero', cap: '16040', provinceId: 36},
{id: 450, value: 'mazze', cap: '10035', provinceId: 1},
{id: 1393, value: 'orino', cap: '21030', provinceId: 10},
{id: 5727, value: 'penne', cap: '65017', provinceId: 70},
{id: 448, value: 'mathi', cap: '10075', provinceId: 1},
{id: 7270, value: 'cutro', cap: '88842', provinceId: 89},
{id: 7865, value: 'nuoro', cap: '08100', provinceId: 101},
{id: 5023, value: 'osimo', cap: '60027', provinceId: 60},
{id: 9449, value: 'plaus', cap: '39025', provinceId: 21},
{id: 7312, value: 'pizzo', cap: '89812', provinceId: 90},
{id: 7221, value: 'plati', cap: '89039', provinceId: 88},
{id: 7164, value: 'anoia', cap: '89020', provinceId: 88},
{id: 6750, value: 'nardo', cap: '73048', provinceId: 83},
{id: 2893, value: 'plaus', cap: '39025', provinceId: 21},
{id: 4911, value: 'narni', cap: '05035', provinceId: 58},
{id: 107, value: 'pogno', cap: '28076', provinceId: 3},
{id: 3337, value: 'nanto', cap: '36024', provinceId: 24},
{id: 7314, value: 'polia', cap: '89813', provinceId: 90},
{id: 277, value: 'neive', cap: '12052', provinceId: 4},
{id: 6476, value: 'polla', cap: '84035', provinceId: 78},
{id: 5308, value: 'anzio', cap: '00042', provinceId: 65},
{id: 41, value: 'vocca', cap: '13020', provinceId: 2},
{id: 5095, value: 'visso', cap: '62039', provinceId: 61},
{id: 1637, value: 'piuro', cap: '23020', provinceId: 12},
{id: 6386, value: 'angri', cap: '84012', provinceId: 78},
{id: 4068, value: 'loano', cap: '17025', provinceId: 35},
{id: 3750, value: 'amaro', cap: '33020', provinceId: 30},
{id: 8104, value: 'mosso', cap: '13822', provinceId: 7},
{id: 3899, value: 'mossa', cap: '34070', provinceId: 31},
{id: 4778, value: 'murlo', cap: '53016', provinceId: 54},
{id: 7084, value: 'amato', cap: '88040', provinceId: 87},
{id: 7768, value: 'muros', cap: '07030', provinceId: 100},
{id: 7955, value: 'musei', cap: '09010', provinceId: 107},
{id: 7767, value: 'mores', cap: '07013', provinceId: 100},
{id: 3067, value: 'nanno', cap: '38010', provinceId: 22},
{id: 1534, value: 'musso', cap: '22010', provinceId: 11},
{id: 4010, value: 'pigna', cap: '18037', provinceId: 34},
{id: 1774, value: 'monza', cap: '20900', provinceId: 108},
{id: 1545, value: 'pigra', cap: '22020', provinceId: 11},
{id: 7729, value: 'anela', cap: '07010', provinceId: 100},
{id: 8, value: 'piode', cap: '13020', provinceId: 2},
{id: 7766, value: 'monti', cap: '07020', provinceId: 104},
{id: 1535, value: 'nesso', cap: '22020', provinceId: 11},
{id: 1212, value: 'aosta', cap: '11100', provinceId: 9},
{id: 1673, value: 'arese', cap: '20020', provinceId: 13},
{id: 5748, value: 'archi', cap: '66044', provinceId: 71},
{id: 9394, value: 'prags', cap: '39030', provinceId: 21},
{id: 504, value: 'prali', cap: '10060', provinceId: 1},
{id: 7916, value: 'arbus', cap: '09031', provinceId: 106},
{id: 2094, value: 'algua', cap: '24010', provinceId: 14},
{id: 3088, value: 'praso', cap: '38080', provinceId: 22},
{id: 4828, value: 'prato', cap: '59100', provinceId: 56},
{id: 4873, value: 'preci', cap: '06047', provinceId: 57},
{id: 5285, value: 'posta', cap: '02019', provinceId: 64},
{id: 262, value: 'monta', cap: '12046', provinceId: 4},
{id: 2207, value: 'monno', cap: '25040', provinceId: 15},
{id: 4014, value: 'prela', cap: '18020', provinceId: 34},
{id: 7771, value: 'nulvi', cap: '07032', provinceId: 100},
{id: 5418, value: 'ardea', cap: '00040', provinceId: 65},
{id: 7287, value: 'arena', cap: '89832', provinceId: 90},
{id: 5437, value: 'norma', cap: '04010', provinceId: 66},
{id: 6257, value: 'volla', cap: '80040', provinceId: 76},
{id: 8066, value: 'pompu', cap: '09093', provinceId: 103},
{id: 6143, value: 'ponte', cap: '82030', provinceId: 75},
{id: 1088, value: 'netro', cap: '13896', provinceId: 7},
{id: 6093, value: 'apice', cap: '82021', provinceId: 75},
{id: 5040, value: 'apiro', cap: '62021', provinceId: 61},
{id: 1548, value: 'ponna', cap: '22020', provinceId: 11},
{id: 3659, value: 'ponso', cap: '35040', provinceId: 28},
{id: 3883, value: 'visco', cap: '33040', provinceId: 30},
{id: 3813, value: 'nimis', cap: '33045', provinceId: 30},
{id: 5988, value: 'alife', cap: '81011', provinceId: 74},
{id: 3577, value: 'noale', cap: '30033', provinceId: 27},
{id: 993, value: 'ponti', cap: '15010', provinceId: 6},
{id: 5439, value: 'ponza', cap: '04027', provinceId: 66},
{id: 4751, value: 'poppi', cap: '52014', provinceId: 53},
{id: 8029, value: 'allai', cap: '09080', provinceId: 103},
{id: 1180, value: 'nonio', cap: '28891', provinceId: 8},
{id: 502, value: 'porte', cap: '10060', provinceId: 1},
{id: 4249, value: 'zerba', cap: '29020', provinceId: 38},
{id: 93, value: 'meina', cap: '28046', provinceId: 3},
{id: 7883, value: 'osini', cap: '08040', provinceId: 105},
{id: 4016, value: 'rezzo', cap: '18026', provinceId: 34},
{id: 7762, value: 'luras', cap: '07025', provinceId: 104},
{id: 321, value: 'balme', cap: '10070', provinceId: 1},
{id: 3727, value: 'lusia', cap: '45020', provinceId: 29},
{id: 2873, value: 'luson', cap: '39040', provinceId: 21},
{id: 484, value: 'pecco', cap: '10080', provinceId: 1},
{id: 6996, value: 'luzzi', cap: '87040', provinceId: 86},
{id: 5382, value: 'riano', cap: '00060', provinceId: 65},
{id: 1290, value: 'azzio', cap: '21030', provinceId: 10},
{id: 7225, value: 'riace', cap: '89040', provinceId: 88},
{id: 4300, value: 'baiso', cap: '42031', provinceId: 40},
{id: 317, value: 'bairo', cap: '10010', provinceId: 1},
{id: 241, value: 'macra', cap: '12020', provinceId: 4},
{id: 7118, value: 'maida', cap: '88025', provinceId: 87},
{id: 2092, value: 'zogno', cap: '24019', provinceId: 14},
{id: 3265, value: 'zevio', cap: '37059', provinceId: 23},
{id: 3840, value: 'resia', cap: '33010', provinceId: 30},
{id: 5456, value: 'acuto', cap: '03010', provinceId: 67},
{id: 1256, value: 'oyace', cap: '11010', provinceId: 9},
{id: 3815, value: 'ovaro', cap: '33025', provinceId: 30},
{id: 980, value: 'ovada', cap: '15076', provinceId: 6},
{id: 3699, value: 'adria', cap: '45011', provinceId: 29},
{id: 2803, value: 'maleo', cap: '26847', provinceId: 20},
{id: 7484, value: 'malfa', cap: '98050', provinceId: 93},
{id: 2901, value: 'renon', cap: '39054', provinceId: 21},
{id: 7028, value: 'rende', cap: '87036', provinceId: 86},
{id: 5024, value: 'ostra', cap: '60010', provinceId: 60},
{id: 4389, value: 'zocca', cap: '41059', provinceId: 41},
{id: 7815, value: 'padru', cap: '07020', provinceId: 104},
{id: 244, value: 'mango', cap: '12056', provinceId: 4},
{id: 7779, value: 'palau', cap: '07020', provinceId: 104},
{id: 2007, value: 'parre', cap: '24020', provinceId: 14},
{id: 7204, value: 'locri', cap: '89044', provinceId: 88},
{id: 4277, value: 'parma', cap: '', provinceId: 39},
{id: 4252, value: 'bardi', cap: '43032', provinceId: 39},
{id: 7017, value: 'paola', cap: '87027', provinceId: 86},
{id: 9391, value: 'abtei', cap: '39036', provinceId: 21},
{id: 7693, value: 'acate', cap: '97011', provinceId: 98},
{id: 4640, value: 'londa', cap: '50060', provinceId: 50},
{id: 6574, value: 'panni', cap: '71020', provinceId: 79},
{id: 7483, value: 'longi', cap: '98070', provinceId: 93},
{id: 7218, value: 'palmi', cap: '89015', provinceId: 88},
{id: 7507, value: 'patti', cap: '98066', provinceId: 93},
{id: 3726, value: 'loreo', cap: '45017', provinceId: 29},
{id: 3511, value: 'paese', cap: '31038', provinceId: 26},
{id: 3492, value: 'loria', cap: '31037', provinceId: 26},
{id: 2192, value: 'lozio', cap: '25040', provinceId: 15},
{id: 1374, value: 'lozza', cap: '21040', provinceId: 10},
{id: 4577, value: 'lucca', cap: '55100', provinceId: 48},
{id: 3168, value: 'zuclo', cap: '38079', provinceId: 22},
{id: 5287, value: 'rieti', cap: '02100', provinceId: 64},
{id: 287, value: 'pagno', cap: '12030', provinceId: 4},
{id: 7607, value: 'riesi', cap: '93016', provinceId: 95},
{id: 3603, value: 'baone', cap: '35030', provinceId: 28},
{id: 2413, value: 'pavia', cap: '27100', provinceId: 16},
{id: 6804, value: 'banzi', cap: '85010', provinceId: 84},
{id: 1375, value: 'luino', cap: '21016', provinceId: 10},
{id: 7615, value: 'agira', cap: '94011', provinceId: 96},
{id: 1992, value: 'mozzo', cap: '24030', provinceId: 14},
{id: 303, value: 'aglie', cap: '10011', provinceId: 1},
{id: 513, value: 'reano', cap: '10090', provinceId: 1},
{id: 4150, value: 'recco', cap: '16036', provinceId: 36},
{id: 2835, value: 'badia', cap: '39036', provinceId: 21},
{id: 245, value: 'manta', cap: '12030', provinceId: 4},
{id: 6146, value: 'reino', cap: '82020', provinceId: 75},
{id: 2491, value: 'zerbo', cap: '27017', provinceId: 16},
{id: 3136, value: 'terres', cap: '38010', provinceId: 22},
{id: 4026, value: 'taggia', cap: '18018', provinceId: 34},
{id: 467, value: 'noasca', cap: '10080', provinceId: 1},
{id: 7012, value: 'nocara', cap: '87070', provinceId: 86},
{id: 1542, value: 'peglio', cap: '22010', provinceId: 11},
{id: 4028, value: 'triora', cap: '18010', provinceId: 34},
{id: 4275, value: 'noceto', cap: '43015', provinceId: 39},
{id: 3221, value: 'nogara', cap: '37054', provinceId: 23},
{id: 2757, value: 'taceno', cap: '23837', provinceId: 19},
{id: 7879, value: 'orroli', cap: '08030', provinceId: 102},
{id: 6678, value: 'statte', cap: '74010', provinceId: 81},
{id: 3509, value: 'orsago', cap: '31010', provinceId: 26},
{id: 1790, value: 'paullo', cap: '20067', provinceId: 13},
{id: 6967, value: 'civita', cap: '87010', provinceId: 86},
{id: 9475, value: 'sexten', cap: '39030', provinceId: 21},
{id: 3080, value: 'pelugo', cap: '38079', provinceId: 22},
{id: 1824, value: 'seveso', cap: '20822', provinceId: 108},
{id: 2215, value: 'niardo', cap: '25050', provinceId: 15},
{id: 4968, value: 'piagge', cap: '61030', provinceId: 59},
{id: 3447, value: 'tambre', cap: '32010', provinceId: 25},
{id: 4963, value: 'peglio', cap: '61049', provinceId: 59},
{id: 1907, value: 'carona', cap: '24010', provinceId: 14},
{id: 7380, value: 'carini', cap: '90044', provinceId: 92},
{id: 4705, value: 'palaia', cap: '56036', provinceId: 52},
{id: 6279, value: 'carife', cap: '83040', provinceId: 77},
{id: 7900, value: 'talana', cap: '08040', provinceId: 105},
{id: 3860, value: 'sutrio', cap: '33020', provinceId: 30},
{id: 6467, value: 'pagani', cap: '84016', provinceId: 78},
{id: 7077, value: 'trenta', cap: '87050', provinceId: 86},
{id: 5530, value: 'supino', cap: '03019', provinceId: 67},
{id: 4607, value: 'pescia', cap: '51017', provinceId: 49},
{id: 5322, value: 'casape', cap: '00010', provinceId: 65},
{id: 6780, value: 'surano', cap: '73030', provinceId: 83},
{id: 6753, value: 'novoli', cap: '73051', provinceId: 83},
{id: 2000, value: 'ornica', cap: '24010', provinceId: 14},
{id: 581, value: 'traves', cap: '10070', provinceId: 1},
{id: 6473, value: 'petina', cap: '84020', provinceId: 78},
{id: 7896, value: 'sindia', cap: '08018', provinceId: 101},
{id: 7877, value: 'orosei', cap: '08028', provinceId: 101},
{id: 3652, value: 'padova', cap: '', provinceId: 28},
{id: 719, value: 'treiso', cap: '12050', provinceId: 4},
{id: 99, value: 'novara', cap: '28100', provinceId: 3},
{id: 2700, value: 'civate', cap: '23862', provinceId: 19},
{id: 3147, value: 'trento', cap: '', provinceId: 22},
{id: 7778, value: 'padria', cap: '07015', provinceId: 100},
{id: 1779, value: 'nosate', cap: '20020', provinceId: 13},
{id: 7612, value: 'sutera', cap: '93010', provinceId: 95},
{id: 6466, value: 'padula', cap: '84034', provinceId: 78},
{id: 1460, value: 'carugo', cap: '22060', provinceId: 11},
{id: 8082, value: 'simala', cap: '09090', provinceId: 103},
{id: 4865, value: 'norcia', cap: '06046', provinceId: 57},
{id: 7994, value: 'silius', cap: '09040', provinceId: 102},
{id: 7793, value: 'siligo', cap: '07040', provinceId: 100},
{id: 6135, value: 'paduli', cap: '82020', provinceId: 75},
{id: 3073, value: 'ossana', cap: '38026', provinceId: 22},
{id: 5372, value: 'nerola', cap: '00017', provinceId: 65},
{id: 5900, value: 'palata', cap: '86037', provinceId: 72},
{id: 5684, value: 'nereto', cap: '64015', provinceId: 69},
{id: 984, value: 'pareto', cap: '15010', provinceId: 6},
{id: 2047, value: 'serina', cap: '24017', provinceId: 14},
{id: 5694, value: 'teramo', cap: '64100', provinceId: 69},
{id: 4548, value: 'comano', cap: '54015', provinceId: 47},
{id: 7695, value: 'comiso', cap: '97013', provinceId: 98},
{id: 2884, value: 'nalles', cap: '39010', provinceId: 21},
{id: 3814, value: 'osoppo', cap: '33010', provinceId: 30},
{id: 1652, value: 'teglio', cap: '23036', provinceId: 12},
{id: 475, value: 'osasco', cap: '10060', provinceId: 1},
{id: 5925, value: 'sepino', cap: '86017', provinceId: 72},
{id: 6787, value: 'tuglie', cap: '73058', provinceId: 83},
{id: 6040, value: 'parete', cap: '81030', provinceId: 74},
{id: 6880, value: 'senise', cap: '85038', provinceId: 84},
{id: 476, value: 'osasio', cap: '10040', provinceId: 1},
{id: 6358, value: 'serino', cap: '83028', provinceId: 77},
{id: 2274, value: 'seniga', cap: '25020', provinceId: 15},
{id: 7882, value: 'osidda', cap: '08020', provinceId: 101},
{id: 1576, value: 'turate', cap: '22078', provinceId: 11},
{id: 2739, value: 'osnago', cap: '23875', provinceId: 19},
{id: 1777, value: 'muggio', cap: '20835', provinceId: 108},
{id: 2412, value: 'parona', cap: '27020', provinceId: 16},
{id: 7459, value: 'condro', cap: '98040', provinceId: 93},
{id: 3913, value: 'muggia', cap: '34015', provinceId: 32},
{id: 1819, value: 'senago', cap: '20030', provinceId: 13},
{id: 1394, value: 'osmate', cap: '21018', provinceId: 10},
{id: 5072, value: 'muccia', cap: '62034', provinceId: 61},
{id: 2357, value: 'corana', cap: '27050', provinceId: 16},
{id: 6621, value: 'corato', cap: '70033', provinceId: 80},
{id: 357, value: 'caprie', cap: '10040', provinceId: 1},
{id: 6253, value: 'tufino', cap: '80030', provinceId: 76},
{id: 7390, value: 'cinisi', cap: '90045', provinceId: 92},
{id: 7632, value: 'troina', cap: '94018', provinceId: 96},
{id: 7184, value: 'careri', cap: '89030', provinceId: 88},
{id: 1335, value: 'clivio', cap: '21050', provinceId: 10},
{id: 6849, value: 'nemoli', cap: '85040', provinceId: 84},
{id: 1993, value: 'nembro', cap: '24027', provinceId: 14},
{id: 359, value: 'carema', cap: '10010', provinceId: 1},
{id: 391, value: 'coazze', cap: '10050', provinceId: 1},
{id: 5295, value: 'tarano', cap: '02040', provinceId: 64},
{id: 7071, value: 'tarsia', cap: '87040', provinceId: 86},
{id: 1929, value: 'colere', cap: '24020', provinceId: 14},
{id: 3220, value: 'negrar', cap: '37024', provinceId: 23},
{id: 4075, value: 'nasino', cap: '17030', provinceId: 35},
{id: 2701, value: 'colico', cap: '23823', provinceId: 19},
{id: 7329, value: 'tropea', cap: '89861', provinceId: 90},
{id: 5932, value: 'tufara', cap: '86010', provinceId: 72},
{id: 5806, value: 'palena', cap: '66017', provinceId: 71},
{id: 2984, value: 'carano', cap: '38033', provinceId: 22},
{id: 9485, value: 'truden', cap: '39040', provinceId: 21},
{id: 5804, value: 'ortona', cap: '66026', provinceId: 71},
{id: 7956, value: 'narcao', cap: '09010', provinceId: 107},
{id: 7015, value: 'paludi', cap: '87060', provinceId: 86},
{id: 9480, value: 'terlan', cap: '39018', provinceId: 21},
{id: 6217, value: 'napoli', cap: '', provinceId: 76},
{id: 843, value: 'serole', cap: '14050', provinceId: 5},
{id: 1646, value: 'sernio', cap: '23030', provinceId: 12},
{id: 4646, value: 'pelago', cap: '50060', provinceId: 50},
{id: 2155, value: 'collio', cap: '25060', provinceId: 15},
{id: 3149, value: 'tuenno', cap: '38019', provinceId: 22},
{id: 5021, value: 'numana', cap: '60026', provinceId: 60},
{id: 3000, value: 'cimone', cap: '38060', provinceId: 22},
{id: 4837, value: 'cascia', cap: '06043', provinceId: 57},
{id: 118, value: 'soriso', cap: '28010', provinceId: 3},
{id: 4232, value: 'ottone', cap: '29026', provinceId: 38},
{id: 5296, value: 'toffia', cap: '02039', provinceId: 64},
{id: 1785, value: 'ossona', cap: '20010', provinceId: 13},
{id: 7796, value: 'thiesi', cap: '07047', provinceId: 100},
{id: 7873, value: 'onifai', cap: '08020', provinceId: 101},
{id: 4057, value: 'cengio', cap: '17056', provinceId: 35},
{id: 3371, value: 'thiene', cap: '36016', provinceId: 24},
{id: 7013, value: 'oriolo', cap: '87073', provinceId: 86},
{id: 7622, value: 'cerami', cap: '94010', provinceId: 96},
{id: 69, value: 'cerano', cap: '28065', provinceId: 3},
{id: 7885, value: 'ovodda', cap: '08020', provinceId: 101},
{id: 2225, value: 'ossimo', cap: '25050', provinceId: 15},
{id: 6419, value: 'ceraso', cap: '84052', provinceId: 78},
{id: 1197, value: 'toceno', cap: '28858', provinceId: 8},
{id: 283, value: 'oncino', cap: '12030', provinceId: 4},
{id: 1569, value: 'sorico', cap: '22010', provinceId: 11},
{id: 1196, value: 'stresa', cap: '28838', provinceId: 8},
{id: 2926, value: 'tesimo', cap: '39010', provinceId: 21},
{id: 1182, value: 'omegna', cap: '28887', provinceId: 8},
{id: 1027, value: 'strevi', cap: '15019', provinceId: 6},
{id: 2744, value: 'perego', cap: '23888', provinceId: 19},
{id: 1922, value: 'cerete', cap: '24020', provinceId: 14},
{id: 2823, value: 'sordio', cap: '26858', provinceId: 20},
{id: 2849, value: 'cermes', cap: '39010', provinceId: 21},
{id: 1114, value: 'strona', cap: '13823', provinceId: 7},
{id: 4821, value: 'sorano', cap: '58010', provinceId: 55},
{id: 7095, value: 'cenadi', cap: '88067', provinceId: 87},
{id: 6465, value: 'ottati', cap: '84020', provinceId: 78},
{id: 5612, value: 'pereto', cap: '67064', provinceId: 68},
{id: 4668, value: 'cecina', cap: '57023', provinceId: 51},
{id: 7251, value: 'staiti', cap: '89030', provinceId: 88},
{id: 285, value: 'ostana', cap: '12030', provinceId: 4},
{id: 1653, value: 'tirano', cap: '23037', provinceId: 12},
{id: 1916, value: 'castro', cap: '24063', provinceId: 14},
{id: 6794, value: 'castro', cap: '73030', provinceId: 83},
{id: 3589, value: 'spinea', cap: '30038', provinceId: 27},
{id: 2455, value: 'spessa', cap: '27010', provinceId: 16},
{id: 9448, value: 'percha', cap: '39030', provinceId: 21},
{id: 4880, value: 'spello', cap: '06038', provinceId: 57},
{id: 2928, value: 'tirolo', cap: '39019', provinceId: 21},
{id: 372, value: 'cavour', cap: '10061', provinceId: 1},
{id: 2345, value: 'cecima', cap: '27050', provinceId: 16},
{id: 7386, value: 'cefalu', cap: '90015', provinceId: 92},
{id: 7884, value: 'ottana', cap: '08020', provinceId: 101},
{id: 3558, value: 'ceggia', cap: '30022', provinceId: 27},
{id: 1826, value: 'sovico', cap: '20845', provinceId: 108},
{id: 9482, value: 'tisens', cap: '39010', provinceId: 21},
{id: 5577, value: 'celano', cap: '67043', provinceId: 68},
{id: 5811, value: 'perano', cap: '66040', provinceId: 71},
{id: 6960, value: 'celico', cap: '87053', provinceId: 86},
{id: 6600, value: 'ordona', cap: '71040', provinceId: 79},
{id: 9478, value: 'stilfs', cap: '39029', provinceId: 21},
{id: 2052, value: 'sovere', cap: '24060', provinceId: 14},
{id: 6690, value: 'ostuni', cap: '72017', provinceId: 82},
{id: 639, value: 'cellio', cap: '13024', provinceId: 2},
{id: 5405, value: 'tivoli', cap: '00019', provinceId: 65},
{id: 2997, value: 'cembra', cap: '38034', provinceId: 22},
{id: 7905, value: 'tonara', cap: '08039', provinceId: 101},
{id: 3118, value: 'soraga', cap: '38030', provinceId: 22},
{id: 7995, value: 'sinnai', cap: '09048', provinceId: 102},
{id: 2851, value: 'chiusa', cap: '39043', provinceId: 21},
{id: 846, value: 'soglio', cap: '14020', provinceId: 5},
{id: 2149, value: 'chiari', cap: '25032', provinceId: 15},
{id: 3507, value: 'oderzo', cap: '31046', provinceId: 26},
{id: 5965, value: 'pesche', cap: '86090', provinceId: 73},
{id: 380, value: 'chieri', cap: '10023', provinceId: 1},
{id: 3466, value: 'casier', cap: '31030', provinceId: 26},
{id: 5768, value: 'chieti', cap: '66100', provinceId: 71},
{id: 2522, value: 'chieve', cap: '26010', provinceId: 17},
{id: 3929, value: 'chions', cap: '33083', provinceId: 33},
{id: 7668, value: 'pedara', cap: '95030', provinceId: 97},
{id: 7021, value: 'pedace', cap: '87050', provinceId: 86},
{id: 1607, value: 'chiuro', cap: '23030', provinceId: 12},
{id: 8060, value: 'nureci', cap: '09080', provinceId: 103},
{id: 4310, value: 'casina', cap: '42034', provinceId: 40},
{id: 2753, value: 'sirone', cap: '23844', provinceId: 19},
{id: 5037, value: 'sirolo', cap: '60020', provinceId: 60},
{id: 1784, value: 'ornago', cap: '20876', provinceId: 108},
{id: 4770, value: 'chiusi', cap: '53043', provinceId: 54},
{id: 7099, value: 'cicala', cap: '88040', provinceId: 87},
{id: 198, value: 'ciglie', cap: '12060', provinceId: 4},
{id: 2150, value: 'cigole', cap: '25020', provinceId: 15},
{id: 1656, value: 'traona', cap: '23019', provinceId: 12},
{id: 2999, value: 'cimego', cap: '38082', provinceId: 22},
{id: 7187, value: 'cimina', cap: '89040', provinceId: 88},
{id: 3138, value: 'tesero', cap: '38038', provinceId: 22},
{id: 4092, value: 'stella', cap: '17044', provinceId: 35},
{id: 1825, value: 'solaro', cap: '20020', provinceId: 13},
{id: 2057, value: 'suisio', cap: '24040', provinceId: 14},
{id: 7773, value: 'olmedo', cap: '07040', provinceId: 100},
{id: 2457, value: 'suardi', cap: '27030', provinceId: 16},
{id: 478, value: 'ozegna', cap: '10080', provinceId: 1},
{id: 2278, value: 'sonico', cap: '25048', provinceId: 15},
{id: 574, value: 'torino', cap: '', provinceId: 1},
{id: 6471, value: 'perito', cap: '84060', provinceId: 78},
{id: 6363, value: 'sturno', cap: '83055', provinceId: 77},
{id: 7777, value: 'ozieri', cap: '07014', provinceId: 100},
{id: 710, value: 'somano', cap: '12060', provinceId: 4},
{id: 1257, value: 'perloz', cap: '11020', provinceId: 9},
{id: 7869, value: 'oliena', cap: '08025', provinceId: 101},
{id: 1786, value: 'ozzero', cap: '20080', provinceId: 13},
{id: 5152, value: 'pedaso', cap: '63827', provinceId: 109},
{id: 5763, value: 'casoli', cap: '66043', provinceId: 71},
{id: 4482, value: 'cervia', cap: '48015', provinceId: 44},
{id: 4767, value: 'cetona', cap: '53040', provinceId: 54},
{id: 7998, value: 'suelli', cap: '09040', provinceId: 102},
{id: 2756, value: 'suello', cap: '23867', provinceId: 19},
{id: 8118, value: 'casole', cap: '47890', provinceId: 111},
{id: 6774, value: 'soleto', cap: '73010', provinceId: 83},
{id: 1154, value: 'cesara', cap: '28891', provinceId: 8},
{id: 7458, value: 'cesaro', cap: '98033', provinceId: 93},
{id: 1723, value: 'cesate', cap: '20020', provinceId: 13},
{id: 4498, value: 'cesena', cap: '', provinceId: 45},
{id: 4966, value: 'pesaro', cap: '', provinceId: 59},
{id: 1022, value: 'solero', cap: '15029', provinceId: 6},
{id: 6420, value: 'cetara', cap: '84010', provinceId: 78},
{id: 5149, value: 'offida', cap: '63073', provinceId: 62},
{id: 7432, value: 'trabia', cap: '90019', provinceId: 92},
{id: 7348, value: 'paceco', cap: '91027', provinceId: 91},
{id: 648, value: 'desana', cap: '13034', provinceId: 2},
{id: 3009, value: 'coredo', cap: '38010', provinceId: 22},
{id: 3038, value: 'grumes', cap: '38030', provinceId: 22},
{id: 5790, value: 'guilmi', cap: '66050', provinceId: 71},
{id: 7786, value: 'romana', cap: '07010', provinceId: 100},
{id: 4854, value: 'gubbio', cap: '06024', provinceId: 57},
{id: 3097, value: 'romeno', cap: '38010', provinceId: 22},
{id: 1556, value: 'ronago', cap: '22027', provinceId: 11},
{id: 5059, value: 'gualdo', cap: '62020', provinceId: 61},
{id: 2725, value: 'margno', cap: '23832', provinceId: 19},
{id: 3569, value: 'gruaro', cap: '30020', provinceId: 27},
{id: 3571, value: 'marcon', cap: '30020', provinceId: 27},
{id: 5358, value: 'marino', cap: '00047', provinceId: 65},
{id: 7567, value: 'grotte', cap: '92020', provinceId: 94},
{id: 1808, value: 'rosate', cap: '20088', provinceId: 13},
{id: 421, value: 'grosso', cap: '10070', provinceId: 1},
{id: 1620, value: 'grosio', cap: '23033', provinceId: 12},
{id: 2203, value: 'marone', cap: '25054', provinceId: 15},
{id: 1642, value: 'rasura', cap: '23010', provinceId: 12},
{id: 246, value: 'marene', cap: '12030', provinceId: 4},
{id: 3570, value: 'iesolo', cap: '30016', provinceId: 27},
{id: 5029, value: 'rosora', cap: '60030', provinceId: 60},
{id: 3493, value: 'mansue', cap: '31040', provinceId: 26},
{id: 7951, value: 'mandas', cap: '09040', provinceId: 102},
{id: 1800, value: 'renate', cap: '20838', provinceId: 108},
{id: 6205, value: 'ischia', cap: '80077', provinceId: 76},
{id: 1643, value: 'rogolo', cap: '23010', provinceId: 12},
{id: 232, value: 'isasca', cap: '12020', provinceId: 4},
{id: 6908, value: 'irsina', cap: '75022', provinceId: 85},
{id: 7847, value: 'irgoli', cap: '08020', provinceId: 101},
{id: 1750, value: 'inzago', cap: '20065', provinceId: 13},
{id: 7846, value: 'ilbono', cap: '08040', provinceId: 105},
{id: 5391, value: 'roiate', cap: '00030', provinceId: 65},
{id: 1266, value: 'roisan', cap: '11010', provinceId: 9},
{id: 1244, value: 'introd', cap: '11010', provinceId: 9},
{id: 423, value: 'ingria', cap: '10080', provinceId: 1},
{id: 1365, value: 'inarzo', cap: '21020', provinceId: 10},
{id: 6053, value: 'recale', cap: '81020', provinceId: 74},
{id: 3207, value: 'illasi', cap: '37031', provinceId: 23},
{id: 3037, value: 'grigno', cap: '38055', provinceId: 22},
{id: 6860, value: 'rapone', cap: '85020', provinceId: 84},
{id: 3522, value: 'resana', cap: '31023', provinceId: 26},
{id: 3055, value: 'mazzin', cap: '38030', provinceId: 22},
{id: 4650, value: 'rufina', cap: '50068', provinceId: 50},
{id: 8068, value: 'ruinas', cap: '09085', provinceId: 103},
{id: 6909, value: 'matera', cap: '75100', provinceId: 85},
{id: 6740, value: 'matino', cap: '73046', provinceId: 83},
{id: 449, value: 'mattie', cap: '10050', provinceId: 1},
{id: 6656, value: 'ginosa', cap: '74013', provinceId: 81},
{id: 1239, value: 'gignod', cap: '11010', provinceId: 9},
{id: 6761, value: 'racale', cap: '73055', provinceId: 83},
{id: 687, value: 'ruffia', cap: '12030', provinceId: 4},
{id: 6491, value: 'rutino', cap: '84070', provinceId: 78},
{id: 7651, value: 'giarre', cap: '95014', provinceId: 97},
{id: 23, value: 'sabbia', cap: '13020', provinceId: 2},
{id: 1165, value: 'ghiffa', cap: '28823', provinceId: 8},
{id: 82, value: 'ghemme', cap: '28074', provinceId: 3},
{id: 3952, value: 'sacile', cap: '33077', provinceId: 33},
{id: 7939, value: 'gesico', cap: '09040', provinceId: 102},
{id: 3093, value: 'ragoli', cap: '38070', provinceId: 22},
{id: 7701, value: 'ragusa', cap: '97100', provinceId: 98},
{id: 3036, value: 'grauno', cap: '38030', provinceId: 22},
{id: 1765, value: 'masate', cap: '20060', provinceId: 13},
{id: 3635, value: 'granze', cap: '35040', provinceId: 28},
{id: 5817, value: 'rapino', cap: '66010', provinceId: 71},
{id: 7764, value: 'martis', cap: '07030', provinceId: 100},
{id: 2804, value: 'marudo', cap: '26866', provinceId: 20},
{id: 2263, value: 'rovato', cap: '25038', provinceId: 15},
{id: 228, value: 'govone', cap: '12040', provinceId: 4},
{id: 1382, value: 'marzio', cap: '21030', provinceId: 10},
{id: 2027, value: 'ranica', cap: '24020', provinceId: 14},
{id: 5622, value: 'raiano', cap: '67027', provinceId: 68},
{id: 3737, value: 'rovigo', cap: '45100', provinceId: 29},
{id: 7036, value: 'rovito', cap: '87050', provinceId: 86},
{id: 1174, value: 'masera', cap: '28855', provinceId: 8},
{id: 3792, value: 'gonars', cap: '33050', provinceId: 30},
{id: 9421, value: 'glurns', cap: '39020', provinceId: 21},
{id: 3664, value: 'rubano', cap: '35030', provinceId: 28},
{id: 4135, value: 'masone', cap: '16010', provinceId: 36},
{id: 2750, value: 'rogeno', cap: '23849', provinceId: 19},
{id: 2659, value: 'rodigo', cap: '46040', provinceId: 18},
{id: 7888, value: 'sadali', cap: '08030', provinceId: 102},
{id: 2191, value: 'losine', cap: '25050', provinceId: 15},
{id: 6030, value: 'letino', cap: '81010', provinceId: 74},
{id: 3318, value: 'lonigo', cap: '36045', provinceId: 24},
{id: 6564, value: 'lesina', cap: '71010', provinceId: 79},
{id: 4537, value: 'rimini', cap: '', provinceId: 46},
{id: 4186, value: 'lerici', cap: '19032', provinceId: 37},
{id: 521, value: 'rivoli', cap: '10098', provinceId: 1},
{id: 5011, value: 'loreto', cap: '60025', provinceId: 60},
{id: 5433, value: 'lenola', cap: '04025', provinceId: 66},
{id: 238, value: 'levice', cap: '12070', provinceId: 4},
{id: 1978, value: 'lovere', cap: '24065', provinceId: 14},
{id: 1625, value: 'lovero', cap: '23030', provinceId: 12},
{id: 6565, value: 'lucera', cap: '71036', provinceId: 79},
{id: 5883, value: 'lucito', cap: '86030', provinceId: 72},
{id: 5597, value: 'lucoli', cap: '67045', provinceId: 68},
{id: 9429, value: 'luesen', cap: '39040', provinceId: 21},
{id: 3211, value: 'lazise', cap: '37017', provinceId: 23},
{id: 1976, value: 'levate', cap: '24040', provinceId: 14},
{id: 435, value: 'levone', cap: '10070', provinceId: 1},
{id: 6837, value: 'lauria', cap: '85044', provinceId: 84},
{id: 1513, value: 'lipomo', cap: '22030', provinceId: 11},
{id: 436, value: 'locana', cap: '10080', provinceId: 1},
{id: 7853, value: 'loceri', cap: '08040', provinceId: 105},
{id: 7854, value: 'loculi', cap: '08020', provinceId: 101},
{id: 6208, value: 'liveri', cap: '80030', provinceId: 76},
{id: 9457, value: 'ritten', cap: '39054', provinceId: 21},
{id: 5795, value: 'liscia', cap: '66050', provinceId: 71},
{id: 7915, value: 'lodine', cap: '08020', provinceId: 101},
{id: 4423, value: 'loiano', cap: '40050', provinceId: 42},
{id: 6031, value: 'liberi', cap: '81040', provinceId: 74},
{id: 7482, value: 'lipari', cap: '98055', provinceId: 93},
{id: 518, value: 'rivara', cap: '10080', provinceId: 1},
{id: 7481, value: 'limina', cap: '98030', provinceId: 93},
{id: 3637, value: 'limena', cap: '35010', provinceId: 28},
{id: 3416, value: 'limana', cap: '32020', provinceId: 25},
{id: 2721, value: 'lierna', cap: '23827', provinceId: 19},
{id: 7570, value: 'licata', cap: '92027', provinceId: 94},
{id: 19, value: 'roasio', cap: '13060', provinceId: 2},
{id: 830, value: 'roatto', cap: '14018', provinceId: 5},
{id: 1555, value: 'rodero', cap: '22070', provinceId: 11},
{id: 6997, value: 'maiera', cap: '87020', provinceId: 86},
{id: 7302, value: 'jonadi', cap: '89851', provinceId: 90},
{id: 1981, value: 'madone', cap: '24040', provinceId: 14},
{id: 5434, value: 'maenza', cap: '04010', provinceId: 66},
{id: 2195, value: 'magasa', cap: '25080', provinceId: 15},
{id: 2657, value: 'revere', cap: '46036', provinceId: 18},
{id: 6737, value: 'maglie', cap: '73024', provinceId: 83},
{id: 7758, value: 'ittiri', cap: '07044', provinceId: 100},
{id: 4946, value: 'maiolo', cap: '47862', provinceId: 46},
{id: 4087, value: 'rialto', cap: '17020', provinceId: 35},
{id: 6445, value: 'maiori', cap: '84010', provinceId: 78},
{id: 1245, value: 'issime', cap: '11020', provinceId: 9},
{id: 3801, value: 'majano', cap: '33030', provinceId: 30},
{id: 7697, value: 'ispica', cap: '97014', provinceId: 98},
{id: 6438, value: 'ispani', cap: '84050', provinceId: 78},
{id: 1805, value: 'rodano', cap: '20090', provinceId: 13},
{id: 6998, value: 'malito', cap: '87030', provinceId: 86},
{id: 5350, value: 'labico', cap: '00030', provinceId: 65},
{id: 7850, value: 'laconi', cap: '08034', provinceId: 103},
{id: 4944, value: 'lunano', cap: '61026', provinceId: 59},
{id: 5881, value: 'larino', cap: '86035', provinceId: 72},
{id: 9422, value: 'latsch', cap: '39021', provinceId: 21},
{id: 5432, value: 'latina', cap: '04100', provinceId: 66},
{id: 5200, value: 'latera', cap: '01010', provinceId: 63},
{id: 6995, value: 'lungro', cap: '87010', provinceId: 86},
{id: 3043, value: 'lasino', cap: '38076', provinceId: 22},
{id: 5884, value: 'lupara', cap: '86030', provinceId: 72},
{id: 2426, value: 'robbio', cap: '27038', provinceId: 16},
{id: 5907, value: 'riccia', cap: '86016', provinceId: 72},
{id: 7759, value: 'laerru', cap: '07030', provinceId: 100},
{id: 1979, value: 'lurano', cap: '24050', provinceId: 14},
{id: 7315, value: 'ricadi', cap: '89866', provinceId: 90},
{id: 1973, value: 'lallio', cap: '24040', provinceId: 14},
{id: 2869, value: 'laives', cap: '39055', provinceId: 21},
{id: 6443, value: 'lustra', cap: '84050', provinceId: 78},
{id: 7582, value: 'ribera', cap: '92016', provinceId: 94},
{id: 6054, value: 'riardo', cap: '81053', provinceId: 74},
{id: 1505, value: 'laglio', cap: '22010', provinceId: 11},
{id: 1962, value: 'gerosa', cap: '24010', provinceId: 14},
{id: 7844, value: 'gergei', cap: '08030', provinceId: 102},
{id: 7144, value: 'sellia', cap: '88050', provinceId: 87},
{id: 1260, value: 'pontey', cap: '11024', provinceId: 9},
{id: 3947, value: 'porcia', cap: '33080', provinceId: 33},
{id: 2630, value: 'dosolo', cap: '46030', provinceId: 18},
{id: 4917, value: 'porano', cap: '05010', provinceId: 58},
{id: 5733, value: 'popoli', cap: '65026', provinceId: 70},
{id: 7935, value: 'donori', cap: '09040', provinceId: 102},
{id: 1232, value: 'donnas', cap: '11020', provinceId: 9},
{id: 1073, value: 'donato', cap: '13893', provinceId: 7},
{id: 1484, value: 'domaso', cap: '22013', provinceId: 11},
{id: 7294, value: 'drapia', cap: '89862', provinceId: 90},
{id: 8113, value: 'dogana', cap: '47891', provinceId: 111},
{id: 7293, value: 'dinami', cap: '89833', provinceId: 90},
{id: 3017, value: 'dimaro', cap: '38025', provinceId: 22},
{id: 3855, value: 'sauris', cap: '33020', provinceId: 30},
{id: 7830, value: 'desulo', cap: '08032', provinceId: 101},
{id: 2708, value: 'dervio', cap: '23824', provinceId: 19},
{id: 4847, value: 'deruta', cap: '06053', provinceId: 57},
{id: 2534, value: 'dovera', cap: '26010', provinceId: 17},
{id: 7887, value: 'posada', cap: '08020', provinceId: 101},
{id: 6226, value: 'pompei', cap: '80045', provinceId: 76},
{id: 663, value: 'prazzo', cap: '12028', provinceId: 4},
{id: 5266, value: 'mompeo', cap: '02040', provinceId: 64},
{id: 806, value: 'monale', cap: '14013', provinceId: 5},
{id: 3090, value: 'preore', cap: '38070', provinceId: 22},
{id: 3832, value: 'preone', cap: '33020', provinceId: 30},
{id: 1188, value: 'premia', cap: '28866', provinceId: 8},
{id: 6684, value: 'erchie', cap: '72020', provinceId: 82},
{id: 2897, value: 'predoi', cap: '39030', provinceId: 21},
{id: 4629, value: 'empoli', cap: '50053', provinceId: 50},
{id: 1487, value: 'drezzo', cap: '22020', provinceId: 11},
{id: 998, value: 'prasco', cap: '15010', provinceId: 6},
{id: 9438, value: 'montan', cap: '39040', provinceId: 21},
{id: 9, value: 'postua', cap: '13010', provinceId: 2},
{id: 2895, value: 'postal', cap: '39014', provinceId: 21},
{id: 7891, value: 'sarule', cap: '08020', provinceId: 101},
{id: 1614, value: 'dubino', cap: '23015', provinceId: 12},
{id: 3346, value: 'posina', cap: '36010', provinceId: 24},
{id: 924, value: 'denice', cap: '15010', provinceId: 6},
{id: 7534, value: 'savoca', cap: '98038', provinceId: 93},
{id: 3091, value: 'prezzo', cap: '38085', provinceId: 22},
{id: 5499, value: 'morolo', cap: '03017', provinceId: 67},
{id: 5507, value: 'piglio', cap: '03010', provinceId: 67},
{id: 3898, value: 'moraro', cap: '34070', provinceId: 31},
{id: 1253, value: 'morgex', cap: '11017', provinceId: 9},
{id: 7427, value: 'sciara', cap: '90020', provinceId: 92},
{id: 7703, value: 'scicli', cap: '97018', provinceId: 98},
{id: 5602, value: 'morino', cap: '67050', provinceId: 68},
{id: 7246, value: 'scilla', cap: '89058', provinceId: 88},
{id: 3588, value: 'scorze', cap: '30037', provinceId: 27},
{id: 5833, value: 'scerni', cap: '66020', provinceId: 71},
{id: 4780, value: 'pienza', cap: '53026', provinceId: 54},
{id: 3440, value: 'sedico', cap: '32036', provinceId: 25},
{id: 8076, value: 'sedilo', cap: '09076', provinceId: 103},
{id: 252, value: 'moiola', cap: '12010', provinceId: 4},
{id: 1092, value: 'piatto', cap: '13844', provinceId: 7},
{id: 295, value: 'piasco', cap: '12026', provinceId: 4},
{id: 2012, value: 'piario', cap: '24020', provinceId: 14},
{id: 1480, value: 'cremia', cap: '22010', provinceId: 11},
{id: 72, value: 'cressa', cap: '28012', provinceId: 3},
{id: 108, value: 'pombia', cap: '28050', provinceId: 3},
{id: 1482, value: 'cusino', cap: '22010', provinceId: 11},
{id: 4090, value: 'savona', cap: '17100', provinceId: 35},
{id: 4916, value: 'polino', cap: '05030', provinceId: 58},
{id: 7104, value: 'davoli', cap: '88060', provinceId: 87},
{id: 4084, value: 'plodio', cap: '17043', provinceId: 35},
{id: 3013, value: 'dambel', cap: '38010', provinceId: 22},
{id: 3012, value: 'daiano', cap: '38030', provinceId: 22},
{id: 1546, value: 'plesio', cap: '22010', provinceId: 11},
{id: 1737, value: 'cusago', cap: '20090', provinceId: 13},
{id: 5688, value: 'pineto', cap: '64025', provinceId: 69},
{id: 7064, value: 'scalea', cap: '87029', provinceId: 86},
{id: 106, value: 'pisano', cap: '28010', provinceId: 3},
{id: 1072, value: 'curino', cap: '13865', provinceId: 7},
{id: 790, value: 'cunico', cap: '14026', provinceId: 5},
{id: 3011, value: 'cunevo', cap: '38010', provinceId: 22},
{id: 298, value: 'piozzo', cap: '12060', provinceId: 4},
{id: 5638, value: 'scanno', cap: '67038', provinceId: 68},
{id: 6973, value: 'crosia', cap: '87060', provinceId: 86},
{id: 5621, value: 'prezza', cap: '67030', provinceId: 68},
{id: 3784, value: 'faedis', cap: '33040', provinceId: 30},
{id: 5345, value: 'gerano', cap: '00025', provinceId: 65},
{id: 5493, value: 'fumone', cap: '03010', provinceId: 67},
{id: 9461, value: 'salurn', cap: '39040', provinceId: 21},
{id: 6433, value: 'futani', cap: '84050', provinceId: 78},
{id: 1617, value: 'fusine', cap: '23010', provinceId: 12},
{id: 7937, value: 'furtei', cap: '09040', provinceId: 106},
{id: 6432, value: 'furore', cap: '84010', provinceId: 78},
{id: 1526, value: 'merone', cap: '22046', provinceId: 11},
{id: 1770, value: 'mesero', cap: '20010', provinceId: 13},
{id: 3203, value: 'fumane', cap: '37022', provinceId: 23},
{id: 6231, value: 'quarto', cap: '80010', provinceId: 76},
{id: 4463, value: 'mesola', cap: '44026', provinceId: 43},
{id: 935, value: 'fubine', cap: '15043', provinceId: 6},
{id: 5816, value: 'quadri', cap: '66040', provinceId: 71},
{id: 794, value: 'frinco', cap: '14030', provinceId: 5},
{id: 537, value: 'samone', cap: '10010', provinceId: 1},
{id: 3107, value: 'samone', cap: '38059', provinceId: 22},
{id: 5780, value: 'fraine', cap: '66050', provinceId: 71},
{id: 7839, value: 'gadoni', cap: '08030', provinceId: 101},
{id: 2726, value: 'merate', cap: '23807', provinceId: 19},
{id: 5429, value: 'formia', cap: '04023', provinceId: 66},
{id: 3941, value: 'meduno', cap: '33092', provinceId: 33},
{id: 7889, value: 'sagama', cap: '08010', provinceId: 103},
{id: 7197, value: 'gerace', cap: '89040', provinceId: 88},
{id: 7938, value: 'genuri', cap: '09020', provinceId: 106},
{id: 4128, value: 'genova', cap: '', provinceId: 36},
{id: 7843, value: 'genoni', cap: '08030', provinceId: 103},
{id: 225, value: 'genola', cap: '12040', provinceId: 4},
{id: 2642, value: 'medole', cap: '46046', provinceId: 18},
{id: 3728, value: 'melara', cap: '45037', provinceId: 29},
{id: 222, value: 'gaiola', cap: '12010', provinceId: 4},
{id: 3738, value: 'salara', cap: '45030', provinceId: 29},
{id: 4506, value: 'gatteo', cap: '47043', provinceId: 45},
{id: 2806, value: 'meleti', cap: '26843', provinceId: 20},
{id: 7353, value: 'salemi', cap: '91018', provinceId: 91},
{id: 3308, value: 'gallio', cap: '36032', provinceId: 24},
{id: 952, value: 'merana', cap: '15010', provinceId: 6},
{id: 2880, value: 'merano', cap: '39012', provinceId: 21},
{id: 1772, value: 'milano', cap: '', provinceId: 13},
{id: 7602, value: 'milena', cap: '93010', provinceId: 95},
{id: 664, value: 'priero', cap: '12070', provinceId: 4},
{id: 7419, value: 'prizzi', cap: '90038', provinceId: 92},
{id: 2728, value: 'moggio', cap: '23817', provinceId: 19},
{id: 4125, value: 'fascia', cap: '16020', provinceId: 36},
{id: 6685, value: 'fasano', cap: '72015', provinceId: 82},
{id: 2643, value: 'moglia', cap: '46024', provinceId: 18},
{id: 4221, value: 'farini', cap: '29023', provinceId: 38},
{id: 8053, value: 'mogoro', cap: '09095', provinceId: 103},
{id: 6130, value: 'moiano', cap: '82010', provinceId: 75},
{id: 4353, value: 'fanano', cap: '41021', provinceId: 41},
{id: 403, value: 'favria', cap: '10083', provinceId: 1},
{id: 2859, value: 'falzes', cap: '39030', provinceId: 21},
{id: 954, value: 'molare', cap: '15074', provinceId: 6},
{id: 3364, value: 'sarego', cap: '36040', provinceId: 24},
{id: 666, value: 'priola', cap: '12070', provinceId: 4},
{id: 4485, value: 'faenza', cap: '48018', provinceId: 44},
{id: 5889, value: 'molise', cap: '86020', provinceId: 72},
{id: 661, value: 'mollia', cap: '13020', provinceId: 2},
{id: 7566, value: 'favara', cap: '92026', provinceId: 94},
{id: 7862, value: 'modolo', cap: '08019', provinceId: 103},
{id: 6294, value: 'forino', cap: '83020', provinceId: 77},
{id: 5489, value: 'fiuggi', cap: '03014', provinceId: 67},
{id: 7306, value: 'mileto', cap: '89852', provinceId: 90},
{id: 5257, value: 'forano', cap: '02044', provinceId: 64},
{id: 2794, value: 'fombio', cap: '26861', provinceId: 20},
{id: 6447, value: 'minori', cap: '84010', provinceId: 78},
{id: 6561, value: 'foggia', cap: '', provinceId: 79},
{id: 3028, value: 'flavon', cap: '38010', provinceId: 22},
{id: 697, value: 'sanfre', cap: '12040', provinceId: 4},
{id: 3575, value: 'mirano', cap: '30035', provinceId: 27},
{id: 4263, value: 'felino', cap: '43035', provinceId: 39},
{id: 1948, value: 'filago', cap: '24040', provinceId: 14},
{id: 2168, value: 'fiesse', cap: '25020', provinceId: 15},
{id: 2536, value: 'fiesco', cap: '26010', provinceId: 17},
{id: 2898, value: 'proves', cap: '39040', provinceId: 21},
{id: 802, value: 'moasca', cap: '14050', provinceId: 5},
{id: 4365, value: 'modena', cap: '', provinceId: 41},
{id: 7698, value: 'modica', cap: '97015', provinceId: 98},
{id: 3408, value: 'feltre', cap: '32032', provinceId: 25},
{id: 7790, value: 'sedini', cap: '07035', provinceId: 100},
{id: 7181, value: 'canolo', cap: '89040', provinceId: 88},
{id: 1413, value: 'uboldo', cap: '21040', provinceId: 10},
{id: 1889, value: 'branzi', cap: '24010', provinceId: 14},
{id: 2949, value: 'amblar', cap: '38011', provinceId: 22},
{id: 1444, value: 'brenna', cap: '22040', provinceId: 11},
{id: 5661, value: 'campli', cap: '64012', provinceId: 69},
{id: 2685, value: 'barzio', cap: '23816', provinceId: 19},
{id: 4896, value: 'baschi', cap: '05023', provinceId: 58},
{id: 6385, value: 'amalfi', cap: '84011', provinceId: 78},
{id: 5458, value: 'alvito', cap: '03041', provinceId: 67},
{id: 2124, value: 'braone', cap: '25040', provinceId: 15},
{id: 5747, value: 'altino', cap: '66040', provinceId: 71},
{id: 5555, value: 'barrea', cap: '67030', provinceId: 68},
{id: 7447, value: 'basico', cap: '98060', provinceId: 93},
{id: 2838, value: 'braies', cap: '39030', provinceId: 21},
{id: 4169, value: 'vobbia', cap: '16010', provinceId: 36},
{id: 1888, value: 'bracca', cap: '24010', provinceId: 14},
{id: 9495, value: 'voeran', cap: '39010', provinceId: 21},
{id: 4039, value: 'altare', cap: '17041', provinceId: 35},
{id: 593, value: 'venaus', cap: '10050', provinceId: 1},
{id: 6544, value: 'bovino', cap: '71023', provinceId: 79},
{id: 4893, value: 'amelia', cap: '05022', provinceId: 58},
{id: 1302, value: 'brenta', cap: '21030', provinceId: 10},
{id: 3967, value: 'vajont', cap: '33080', provinceId: 33},
{id: 6790, value: 'veglie', cap: '73010', provinceId: 83},
{id: 2127, value: 'brione', cap: '25060', provinceId: 15},
{id: 56, value: 'briona', cap: '28072', provinceId: 3},
{id: 4040, value: 'andora', cap: '17051', provinceId: 35},
{id: 3922, value: 'barcis', cap: '33080', provinceId: 33},
{id: 601, value: 'vigone', cap: '10067', provinceId: 1},
{id: 5553, value: 'barete', cap: '67010', provinceId: 68},
{id: 3965, value: 'vivaro', cap: '33099', provinceId: 33},
{id: 2950, value: 'andalo', cap: '38010', provinceId: 22},
{id: 7085, value: 'andali', cap: '88050', provinceId: 87},
{id: 1582, value: 'veleso', cap: '22020', provinceId: 11},
{id: 4991, value: 'ancona', cap: '', provinceId: 60},
{id: 1691, value: 'bresso', cap: '20091', provinceId: 13},
{id: 1124, value: 'veglio', cap: '13824', provinceId: 7},
{id: 2109, value: 'barghe', cap: '25070', provinceId: 15},
{id: 5460, value: 'anagni', cap: '03012', provinceId: 67},
{id: 5224, value: 'vejano', cap: '01010', provinceId: 63},
{id: 6806, value: 'barile', cap: '85022', provinceId: 84},
{id: 142, value: 'barolo', cap: '12060', provinceId: 4},
{id: 2083, value: 'vigolo', cap: '24060', provinceId: 14},
{id: 4204, value: 'alseno', cap: '29010', provinceId: 38},
{id: 4829, value: 'vaiano', cap: '59021', provinceId: 56},
{id: 4926, value: 'barchi', cap: '61040', provinceId: 59},
{id: 7437, value: 'vicari', cap: '90020', provinceId: 92},
{id: 6383, value: 'alfano', cap: '84040', provinceId: 78},
{id: 6701, value: 'alezio', cap: '73011', provinceId: 83},
{id: 2830, value: 'aldino', cap: '39040', provinceId: 21},
{id: 2948, value: 'aldeno', cap: '38060', provinceId: 22},
{id: 9386, value: 'aldein', cap: '39040', provinceId: 21},
{id: 7336, value: 'alcamo', cap: '91011', provinceId: 91},
{id: 2939, value: 'verano', cap: '39010', provinceId: 21},
{id: 5745, value: 'vicoli', cap: '65010', provinceId: 70},
{id: 3925, value: 'caneva', cap: '33070', provinceId: 33},
{id: 6597, value: 'vieste', cap: '71019', provinceId: 79},
{id: 1857, value: 'albino', cap: '24021', provinceId: 14},
{id: 5180, value: 'canino', cap: '01011', provinceId: 63},
{id: 1294, value: 'besano', cap: '21050', provinceId: 10},
{id: 1685, value: 'besate', cap: '20080', provinceId: 13},
{id: 9384, value: 'wengen', cap: '39030', provinceId: 21},
{id: 4989, value: 'urbino', cap: '61029', provinceId: 59},
{id: 5457, value: 'alatri', cap: '03011', provinceId: 67},
{id: 2091, value: 'zanica', cap: '24050', provinceId: 14},
{id: 1596, value: 'bormio', cap: '23032', provinceId: 12},
{id: 7826, value: 'borore', cap: '08016', provinceId: 101},
{id: 4659, value: 'vaglia', cap: '50036', provinceId: 50},
{id: 7821, value: 'baunei', cap: '08040', provinceId: 105},
{id: 1422, value: 'viggiu', cap: '21059', provinceId: 10},
{id: 2932, value: 'vadena', cap: '39051', provinceId: 21},
{id: 3269, value: 'alonte', cap: '36045', provinceId: 24},
{id: 5300, value: 'vacone', cap: '02040', provinceId: 64},
{id: 308, value: 'almese', cap: '10040', provinceId: 1},
{id: 4616, value: 'uzzano', cap: '51010', provinceId: 49},
{id: 7434, value: 'ustica', cap: '90010', provinceId: 92},
{id: 5094, value: 'ussita', cap: '62039', provinceId: 61},
{id: 1210, value: 'allein', cap: '11010', provinceId: 9},
{id: 9423, value: 'algund', cap: '39022', provinceId: 21},
{id: 1140, value: 'baveno', cap: '28831', provinceId: 8},
{id: 3165, value: 'volano', cap: '38060', provinceId: 22},
{id: 8003, value: 'ussana', cap: '09020', provinceId: 102},
{id: 166, value: 'canale', cap: '12043', provinceId: 4},
{id: 3707, value: 'canaro', cap: '45034', provinceId: 29},
{id: 6897, value: 'aliano', cap: '75010', provinceId: 85},
{id: 6890, value: 'venosa', cap: '85029', provinceId: 84},
{id: 3705, value: 'bosaro', cap: '45033', provinceId: 29},
{id: 2768, value: 'vigano', cap: '23897', provinceId: 19},
{id: 2971, value: 'brione', cap: '38083', provinceId: 22},
{id: 6606, value: 'andria', cap: '76123', provinceId: 110},
{id: 2304, value: 'alagna', cap: '27020', provinceId: 16},
{id: 1897, value: 'calcio', cap: '24054', provinceId: 14},
{id: 44, value: 'armeno', cap: '28011', provinceId: 3},
{id: 7617, value: 'assoro', cap: '94010', provinceId: 96},
{id: 1674, value: 'arluno', cap: '20010', provinceId: 13},
{id: 7816, value: 'aritzo', cap: '08031', provinceId: 101},
{id: 6801, value: 'atella', cap: '85020', provinceId: 84},
{id: 5751, value: 'atessa', cap: '66041', provinceId: 71},
{id: 3153, value: 'varena', cap: '38030', provinceId: 22},
{id: 6390, value: 'atrani', cap: '84010', provinceId: 78},
{id: 7818, value: 'atzara', cap: '08030', provinceId: 101},
{id: 7595, value: 'butera', cap: '93011', provinceId: 95},
{id: 2976, value: 'caldes', cap: '38022', provinceId: 22},
{id: 1416, value: 'varese', cap: '21100', provinceId: 10},
{id: 1137, value: 'aurano', cap: '28812', provinceId: 8},
{id: 3972, value: 'aurigo', cap: '18020', provinceId: 34},
{id: 7819, value: 'austis', cap: '08030', provinceId: 101},
{id: 4722, value: 'arezzo', cap: '52100', provinceId: 53},
{id: 4105, value: 'avegno', cap: '16036', provinceId: 36},
{id: 345, value: 'busano', cap: '10080', provinceId: 1},
{id: 1838, value: 'varedo', cap: '20814', provinceId: 108},
{id: 5463, value: 'arnara', cap: '03020', provinceId: 67},
{id: 6267, value: 'avella', cap: '83021', provinceId: 77},
{id: 3919, value: 'arzene', cap: '33098', provinceId: 33},
{id: 5312, value: 'artena', cap: '00031', provinceId: 65},
{id: 5656, value: 'arsita', cap: '64031', provinceId: 69},
{id: 1450, value: 'caglio', cap: '22030', provinceId: 11},
{id: 2843, value: 'caines', cap: '39010', provinceId: 21},
{id: 1598, value: 'caiolo', cap: '23010', provinceId: 12},
{id: 1214, value: 'arvier', cap: '11011', provinceId: 9},
{id: 7817, value: 'arzana', cap: '08040', provinceId: 105},
{id: 6173, value: 'arzano', cap: '80022', provinceId: 76},
{id: 4894, value: 'arrone', cap: '05031', provinceId: 58},
{id: 8032, value: 'assolo', cap: '09080', provinceId: 103},
{id: 5232, value: 'ascrea', cap: '02020', provinceId: 64},
{id: 3275, value: 'asiago', cap: '36012', provinceId: 24},
{id: 611, value: 'vinovo', cap: '10048', provinceId: 1},
{id: 5464, value: 'arpino', cap: '03033', provinceId: 67},
{id: 1675, value: 'assago', cap: '20090', provinceId: 13},
{id: 6095, value: 'arpaia', cap: '82011', provinceId: 75},
{id: 1433, value: 'arosio', cap: '22060', provinceId: 11},
{id: 8042, value: 'cabras', cap: '09072', provinceId: 103},
{id: 4831, value: 'assisi', cap: '06081', provinceId: 57},
{id: 7166, value: 'ardore', cap: '89031', provinceId: 88},
{id: 4304, value: 'busana', cap: '42032', provinceId: 40},
{id: 2688, value: 'brivio', cap: '23883', provinceId: 19},
{id: 58, value: 'cameri', cap: '28062', provinceId: 3},
{id: 5461, value: 'aquino', cap: '03031', provinceId: 67},
{id: 4725, value: 'bucine', cap: '52021', provinceId: 53},
{id: 613, value: 'vische', cap: '10030', provinceId: 1},
{id: 6387, value: 'aquara', cap: '84020', provinceId: 78},
{id: 1591, value: 'aprica', cap: '23031', provinceId: 12},
{id: 1046, value: 'visone', cap: '15010', provinceId: 6},
{id: 750, value: 'bubbio', cap: '14051', provinceId: 5},
{id: 6270, value: 'baiano', cap: '83022', provinceId: 77},
{id: 7919, value: 'ballao', cap: '09040', provinceId: 102},
{id: 3924, value: 'budoia', cap: '33070', provinceId: 33},
{id: 340, value: 'bruino', cap: '10090', provinceId: 1},
{id: 7732, value: 'banari', cap: '07040', provinceId: 100},
{id: 338, value: 'brosso', cap: '10080', provinceId: 1},
{id: 7643, value: 'bronte', cap: '95034', provinceId: 97},
{id: 1286, value: 'angera', cap: '21021', provinceId: 10},
{id: 9396, value: 'brixen', cap: '39042', provinceId: 21},
{id: 7178, value: 'camini', cap: '89040', provinceId: 88},
{id: 886, value: 'camino', cap: '15020', provinceId: 6},
{id: 6704, value: 'aradeo', cap: '73040', provinceId: 83},
{id: 7829, value: 'budoni', cap: '08020', provinceId: 104},
{id: 5991, value: 'aversa', cap: '81031', provinceId: 74},
{id: 3172, value: 'arcole', cap: '37040', provinceId: 23},
{id: 7730, value: 'ardara', cap: '07010', provinceId: 100},
{id: 3920, value: 'aviano', cap: '33081', provinceId: 33},
{id: 344, value: 'burolo', cap: '10010', provinceId: 1},
{id: 7745, value: 'burgos', cap: '07010', provinceId: 100},
{id: 1672, value: 'arcore', cap: '20862', provinceId: 108},
{id: 7554, value: 'burgio', cap: '92010', provinceId: 94},
{id: 7923, value: 'burcei', cap: '09040', provinceId: 102},
{id: 7743, value: 'bultei', cap: '07010', provinceId: 100},
{id: 4172, value: 'arcola', cap: '19021', provinceId: 37},
{id: 4397, value: 'budrio', cap: '40054', provinceId: 42},
{id: 1289, value: 'azzate', cap: '21022', provinceId: 10},
{id: 1870, value: 'azzone', cap: '24020', provinceId: 14},
{id: 1138, value: 'baceno', cap: '28861', provinceId: 8},
{id: 2300, value: 'visano', cap: '25010', provinceId: 15},
{id: 6174, value: 'bacoli', cap: '80070', provinceId: 76},
{id: 1864, value: 'arcene', cap: '24040', provinceId: 14},
{id: 3458, value: 'arcade', cap: '31030', provinceId: 26},
{id: 349, value: 'caluso', cap: '10014', provinceId: 1},
{id: 7806, value: 'badesi', cap: '07030', provinceId: 104},
{id: 5702, value: 'alanno', cap: '65020', provinceId: 70},
{id: 9388, value: 'altrei', cap: '39040', provinceId: 21},
{id: 7335, value: 'zungri', cap: '89867', provinceId: 90},
{id: 5936, value: 'agnone', cap: '86081', provinceId: 73},
{id: 1219, value: 'bionaz', cap: '11010', provinceId: 9},
{id: 7640, value: 'adrano', cap: '95031', provinceId: 97},
{id: 5302, value: 'affile', cap: '00021', provinceId: 65},
{id: 7162, value: 'africo', cap: '89030', provinceId: 88},
{id: 1439, value: 'binago', cap: '22070', provinceId: 11},
{id: 3259, value: 'verona', cap: '', provinceId: 23},
{id: 7726, value: 'aggius', cap: '07020', provinceId: 104},
{id: 5539, value: 'veroli', cap: '03029', provinceId: 67},
{id: 2115, value: 'bienno', cap: '25040', provinceId: 15},
{id: 1053, value: 'biella', cap: '13900', provinceId: 7},
{id: 4496, value: 'borghi', cap: '47030', provinceId: 45},
{id: 3388, value: 'agordo', cap: '32021', provinceId: 25},
{id: 5303, value: 'agosta', cap: '00020', provinceId: 65},
{id: 8038, value: 'bidoni', cap: '09080', provinceId: 103},
{id: 7616, value: 'aidone', cap: '94010', provinceId: 96},
{id: 5547, value: 'aielli', cap: '67041', provinceId: 68},
{id: 4830, value: 'vernio', cap: '59024', provinceId: 56},
{id: 1587, value: 'zelbio', cap: '22020', provinceId: 11},
{id: 5987, value: 'ailano', cap: '81010', provinceId: 74},
{id: 625, value: 'bianze', cap: '13041', provinceId: 2},
{id: 7170, value: 'bianco', cap: '89032', provinceId: 88},
{id: 7089, value: 'borgia', cap: '88021', provinceId: 87},
{id: 1130, value: 'zimone', cap: '13887', provinceId: 7},
{id: 3968, value: 'airole', cap: '18030', provinceId: 34},
{id: 1442, value: 'blevio', cap: '22020', provinceId: 11},
{id: 4174, value: 'bolano', cap: '19020', provinceId: 37},
{id: 5853, value: 'bojano', cap: '86021', provinceId: 72},
{id: 1663, value: 'vervio', cap: '23030', provinceId: 12},
{id: 6788, value: 'ugento', cap: '73059', provinceId: 83},
{id: 126, value: 'veruno', cap: '28010', provinceId: 3},
{id: 2931, value: 'ultimo', cap: '39016', provinceId: 21},
{id: 5319, value: 'capena', cap: '00060', provinceId: 65},
{id: 852, value: 'vesime', cap: '14059', provinceId: 5},
{id: 4207, value: 'bobbio', cap: '29022', provinceId: 38},
{id: 1880, value: 'blello', cap: '24010', provinceId: 14},
{id: 1282, value: 'verres', cap: '11029', provinceId: 9},
{id: 7379, value: 'capaci', cap: '90040', provinceId: 92},
{id: 7553, value: 'bivona', cap: '92010', provinceId: 94},
{id: 6380, value: 'acerno', cap: '84042', provinceId: 78},
{id: 6169, value: 'acerra', cap: '80011', provinceId: 76},
{id: 3884, value: 'zuglio', cap: '33020', provinceId: 30},
{id: 4212, value: 'caorso', cap: '29012', provinceId: 38},
{id: 3556, value: 'caorle', cap: '30021', provinceId: 27},
{id: 6272, value: 'bonito', cap: '83032', provinceId: 77},
{id: 4170, value: 'zoagli', cap: '16035', provinceId: 36},
{id: 7823, value: 'birori', cap: '08010', provinceId: 101},
{id: 6091, value: 'airola', cap: '82011', provinceId: 75},
{id: 5311, value: 'arsoli', cap: '00023', provinceId: 65},
{id: 3687, value: 'urbana', cap: '35040', provinceId: 28},
{id: 131, value: 'aisone', cap: '12010', provinceId: 4},
{id: 2680, value: 'airuno', cap: '23881', provinceId: 19},
{id: 4254, value: 'berceto', cap: '43042', provinceId: 39},
{id: 7691, value: 'maniace', cap: '95030', provinceId: 97},
{id: 3940, value: 'maniago', cap: '33085', provinceId: 33},
{id: 3479, value: 'cornuda', cap: '31041', provinceId: 26},
{id: 691, value: 'salmour', cap: '12040', provinceId: 4},
{id: 7982, value: 'segariu', cap: '09040', provinceId: 106},
{id: 1843, value: 'vernate', cap: '20080', provinceId: 13},
{id: 6611, value: 'bitetto', cap: '70020', provinceId: 80},
{id: 7511, value: 'reitano', cap: '98070', provinceId: 93},
{id: 7742, value: 'budduso', cap: '07020', provinceId: 104},
{id: 1818, value: 'segrate', cap: '20090', provinceId: 13},
{id: 2638, value: 'mantova', cap: '46100', provinceId: 18},
{id: 3803, value: 'manzano', cap: '33044', provinceId: 30},
{id: 2358, value: 'cornale', cap: '27050', provinceId: 16},
{id: 1982, value: 'mapello', cap: '24030', provinceId: 14},
{id: 7001, value: 'mangone', cap: '87050', provinceId: 86},
{id: 3663, value: 'rovolon', cap: '35030', provinceId: 28},
{id: 3887, value: 'cormons', cap: '34071', provinceId: 31},
{id: 7205, value: 'mammola', cap: '89045', provinceId: 88},
{id: 4070, value: 'mallare', cap: '17045', provinceId: 35},
{id: 1379, value: 'malnate', cap: '21046', provinceId: 10},
{id: 2542, value: 'gombito', cap: '26020', provinceId: 17},
{id: 2198, value: 'malonno', cap: '25040', provinceId: 15},
{id: 3053, value: 'malosco', cap: '38013', provinceId: 22},
{id: 6999, value: 'malvito', cap: '87010', provinceId: 86},
{id: 6396, value: 'buccino', cap: '84021', provinceId: 78},
{id: 1479, value: 'corrido', cap: '22010', provinceId: 11},
{id: 5354, value: 'mandela', cap: '00020', provinceId: 65},
{id: 7101, value: 'cortale', cap: '88020', provinceId: 87},
{id: 2044, value: 'sedrina', cap: '24010', provinceId: 14},
{id: 1735, value: 'corsico', cap: '20094', provinceId: 13},
{id: 1952, value: 'fonteno', cap: '24060', provinceId: 14},
{id: 6722, value: 'corsano', cap: '73033', provinceId: 83},
{id: 7399, value: 'godrano', cap: '90030', provinceId: 92},
{id: 7984, value: 'selegas', cap: '09040', provinceId: 102},
{id: 1731, value: 'cormano', cap: '20032', provinceId: 13},
{id: 801, value: 'maretto', cap: '14018', provinceId: 5},
{id: 531, value: 'rubiana', cap: '10040', provinceId: 1},
{id: 4456, value: 'copparo', cap: '44034', provinceId: 43},
{id: 2356, value: 'copiano', cap: '27010', provinceId: 16},
{id: 1953, value: 'foppolo', cap: '24010', provinceId: 14},
{id: 920, value: 'conzano', cap: '15030', provinceId: 6},
{id: 4333, value: 'rubiera', cap: '42048', provinceId: 40},
{id: 5854, value: 'bonefro', cap: '86041', provinceId: 72},
{id: 5557, value: 'bugnara', cap: '67030', provinceId: 68},
{id: 4489, value: 'ravenna', cap: '', provinceId: 44},
{id: 6483, value: 'ravello', cap: '84010', provinceId: 78},
{id: 7947, value: 'guspini', cap: '09036', provinceId: 106},
{id: 2918, value: 'senales', cap: '39020', provinceId: 21},
{id: 8077, value: 'seneghe', cap: '09070', provinceId: 103},
{id: 7735, value: 'bessude', cap: '07040', provinceId: 100},
{id: 7405, value: 'marineo', cap: '90035', provinceId: 92},
{id: 2876, value: 'marebbe', cap: '39030', provinceId: 21},
{id: 6426, value: 'corbara', cap: '84010', provinceId: 78},
{id: 7738, value: 'bonorva', cap: '07012', provinceId: 100},
{id: 2045, value: 'selvino', cap: '24020', provinceId: 14},
{id: 4878, value: 'sellano', cap: '06030', provinceId: 57},
{id: 2178, value: 'gussago', cap: '25064', provinceId: 15},
{id: 40, value: 'villata', cap: '13010', provinceId: 2},
{id: 2273, value: 'sellero', cap: '25050', provinceId: 15},
{id: 7196, value: 'galatro', cap: '89054', provinceId: 88},
{id: 4526, value: 'coriano', cap: '47853', provinceId: 46},
{id: 110, value: 'recetto', cap: '28060', provinceId: 3},
{id: 5195, value: 'gallese', cap: '01035', provinceId: 63},
{id: 2451, value: 'semiana', cap: '27020', provinceId: 16},
{id: 6839, value: 'maratea', cap: '85046', provinceId: 84},
{id: 4504, value: 'galeata', cap: '47010', provinceId: 45},
{id: 3714, value: 'corbola', cap: '45015', provinceId: 29},
{id: 3409, value: 'fonzaso', cap: '32030', provinceId: 25},
{id: 1809, value: 'rozzano', cap: '20089', provinceId: 13},
{id: 6612, value: 'bitonto', cap: '70032', provinceId: 80},
{id: 4024, value: 'seborga', cap: '18012', provinceId: 34},
{id: 4737, value: 'cortona', cap: '52044', provinceId: 53},
{id: 5675, value: 'cortino', cap: '64040', provinceId: 69},
{id: 3051, value: 'luserna', cap: '38040', provinceId: 22},
{id: 2572, value: 'ricengo', cap: '26010', provinceId: 17},
{id: 1400, value: 'saltrio', cap: '21050', provinceId: 10},
{id: 870, value: 'balzola', cap: '15031', provinceId: 6},
{id: 6803, value: 'balvano', cap: '85050', provinceId: 84},
{id: 624, value: 'balocco', cap: '13040', provinceId: 2},
{id: 4972, value: 'saltara', cap: '61030', provinceId: 59},
{id: 2905, value: 'salorno', cap: '39040', provinceId: 21},
{id: 7102, value: 'cropani', cap: '88051', provinceId: 87},
{id: 1894, value: 'brumano', cap: '24037', provinceId: 14},
{id: 1446, value: 'brunate', cap: '22034', provinceId: 11},
{id: 3320, value: 'lusiana', cap: '36046', provinceId: 24},
{id: 5660, value: 'bisenti', cap: '64033', provinceId: 69},
{id: 9471, value: 'schenna', cap: '39017', provinceId: 21},
{id: 1980, value: 'luzzana', cap: '24069', provinceId: 14},
{id: 2529, value: 'cremona', cap: '26100', provinceId: 17},
{id: 4134, value: 'lumarzo', cap: '16024', provinceId: 36},
{id: 1518, value: 'luisago', cap: '22070', provinceId: 11},
{id: 9398, value: 'bruneck', cap: '39031', provinceId: 21},
{id: 2902, value: 'rifiano', cap: '39010', provinceId: 21},
{id: 4133, value: 'lorsica', cap: '16045', provinceId: 36},
{id: 1872, value: 'barbata', cap: '24040', provinceId: 14},
{id: 5393, value: 'roviano', cap: '00027', provinceId: 65},
{id: 1343, value: 'cunardo', cap: '21035', provinceId: 10},
{id: 399, value: 'cumiana', cap: '10040', provinceId: 1},
{id: 5717, value: 'cugnoli', cap: '65020', provinceId: 70},
{id: 660, value: 'lozzolo', cap: '13045', provinceId: 2},
{id: 3609, value: 'brugine', cap: '35020', provinceId: 28},
{id: 4993, value: 'barbara', cap: '60010', provinceId: 60},
{id: 339, value: 'brozolo', cap: '10020', provinceId: 1},
{id: 5982, value: 'scapoli', cap: '86070', provinceId: 73},
{id: 7269, value: 'crucoli', cap: '88812', provinceId: 89},
{id: 9458, value: 'riffian', cap: '39010', provinceId: 21},
{id: 1291, value: 'barasso', cap: '21020', provinceId: 10},
{id: 7268, value: 'crotone', cap: '88900', provinceId: 89},
{id: 4323, value: 'luzzara', cap: '42045', provinceId: 40},
{id: 2707, value: 'cremeno', cap: '23814', provinceId: 19},
{id: 9433, value: 'marling', cap: '39020', provinceId: 21},
{id: 7683, value: 'scordia', cap: '95048', provinceId: 97},
{id: 1763, value: 'magnago', cap: '20020', provinceId: 13},
{id: 1079, value: 'magnano', cap: '13887', provinceId: 7},
{id: 2802, value: 'mairago', cap: '26825', provinceId: 20},
{id: 1069, value: 'cossato', cap: '13836', provinceId: 7},
{id: 2196, value: 'mairano', cap: '25030', provinceId: 15},
{id: 2197, value: 'malegno', cap: '25053', provinceId: 15},
{id: 1173, value: 'malesco', cap: '28854', provinceId: 8},
{id: 669, value: 'revello', cap: '12036', provinceId: 4},
{id: 6971, value: 'cosenza', cap: '87100', provinceId: 86},
{id: 3779, value: 'coseano', cap: '33030', provinceId: 30},
{id: 2161, value: 'corzano', cap: '25030', provinceId: 15},
{id: 5234, value: 'borbona', cap: '02010', provinceId: 64},
{id: 7656, value: 'maletto', cap: '95035', provinceId: 97},
{id: 9569, value: 'corvara', cap: '39033', provinceId: 21},
{id: 5716, value: 'corvara', cap: '65020', provinceId: 70},
{id: 564, value: 'sciolze', cap: '10090', provinceId: 1},
{id: 1554, value: 'rezzago', cap: '22030', provinceId: 11},
{id: 3382, value: 'villaga', cap: '36021', provinceId: 24},
{id: 7943, value: 'gonnesa', cap: '09010', provinceId: 107},
{id: 444, value: 'macello', cap: '10060', provinceId: 1},
{id: 1939, value: 'credaro', cap: '24060', provinceId: 14},
{id: 3974, value: 'bajardo', cap: '18031', provinceId: 34},
{id: 3302, value: 'creazzo', cap: '36051', provinceId: 24},
{id: 7858, value: 'macomer', cap: '08015', provinceId: 101},
{id: 2842, value: 'brunico', cap: '39031', provinceId: 21},
{id: 5886, value: 'mafalda', cap: '86030', provinceId: 72},
{id: 342, value: 'bruzolo', cap: '10050', provinceId: 1},
{id: 4545, value: 'bagnone', cap: '54021', provinceId: 47},
{id: 1762, value: 'magenta', cap: '20013', provinceId: 13},
{id: 4856, value: 'magione', cap: '06063', provinceId: 57},
{id: 9474, value: 'schnals', cap: '39020', provinceId: 21},
{id: 7590, value: 'sciacca', cap: '92019', provinceId: 94},
{id: 2258, value: 'rezzato', cap: '25086', provinceId: 15},
{id: 1221, value: 'brusson', cap: '11022', provinceId: 9},
{id: 919, value: 'coniolo', cap: '15030', provinceId: 6},
{id: 2264, value: 'rudiano', cap: '25030', provinceId: 15},
{id: 248, value: 'marmora', cap: '12020', provinceId: 4},
{id: 3762, value: 'buttrio', cap: '33042', provinceId: 30},
{id: 4099, value: 'varazze', cap: '17019', provinceId: 35},
{id: 24, value: 'salasco', cap: '13040', provinceId: 2},
{id: 3096, value: 'romallo', cap: '38028', provinceId: 22},
{id: 37, value: 'varallo', cap: '13019', provinceId: 2},
{id: 4833, value: 'bettona', cap: '06084', provinceId: 57},
{id: 951, value: 'melazzo', cap: '15010', provinceId: 6},
{id: 1836, value: 'vanzago', cap: '20010', provinceId: 13},
{id: 1928, value: 'clusone', cap: '24023', provinceId: 14},
{id: 4508, value: 'meldola', cap: '47014', provinceId: 45},
{id: 1448, value: 'cabiate', cap: '22060', provinceId: 11},
{id: 81, value: 'gattico', cap: '28013', provinceId: 3},
{id: 7373, value: 'caccamo', cap: '90012', provinceId: 92},
{id: 230, value: 'guarene', cap: '12050', provinceId: 4},
{id: 7260, value: 'caccuri', cap: '88833', provinceId: 89},
{id: 533, value: 'salassa', cap: '10080', provinceId: 1},
{id: 1495, value: 'garzeno', cap: '22010', provinceId: 11},
{id: 2294, value: 'vestone', cap: '25078', provinceId: 15},
{id: 6059, value: 'ruviano', cap: '81010', provinceId: 74},
{id: 1822, value: 'settala', cap: '20090', provinceId: 13},
{id: 5912, value: 'salcito', cap: '86026', provinceId: 72},
{id: 3356, value: 'salcedo', cap: '36040', provinceId: 24},
{id: 417, value: 'giaveno', cap: '10094', provinceId: 1},
{id: 941, value: 'giarole', cap: '15036', provinceId: 6},
{id: 2787, value: 'codogno', cap: '26845', provinceId: 20},
{id: 3475, value: 'codogne', cap: '31013', provinceId: 26},
{id: 2176, value: 'gianico', cap: '25040', provinceId: 15},
{id: 7285, value: 'verzino', cap: '88819', provinceId: 89},
{id: 5582, value: 'cocullo', cap: '67030', provinceId: 68},
{id: 50, value: 'bogogno', cap: '28010', provinceId: 3},
{id: 845, value: 'settime', cap: '14020', provinceId: 5},
{id: 5550, value: 'ateleta', cap: '67030', provinceId: 68},
{id: 4363, value: 'medolla', cap: '41036', provinceId: 41},
{id: 2716, value: 'garlate', cap: '23852', provinceId: 19},
{id: 2174, value: 'gavardo', cap: '25085', provinceId: 15},
{id: 7716, value: 'melilli', cap: '96010', provinceId: 99},
{id: 70, value: 'colazza', cap: '28010', provinceId: 3},
{id: 4841, value: 'citerna', cap: '06010', provinceId: 57},
{id: 859, value: 'vinchio', cap: '14040', provinceId: 5},
{id: 4224, value: 'gazzola', cap: '29010', provinceId: 38},
{id: 3902, value: 'sagrado', cap: '34078', provinceId: 31},
{id: 5177, value: 'bomarzo', cap: '01020', provinceId: 63},
{id: 7993, value: 'siliqua', cap: '09010', provinceId: 102},
{id: 4527, value: 'gemmano', cap: '47855', provinceId: 46},
{id: 2104, value: 'artogne', cap: '25040', provinceId: 15},
{id: 348, value: 'cafasse', cap: '10070', provinceId: 1},
{id: 4589, value: 'sillano', cap: '55030', provinceId: 48},
{id: 5176, value: 'bolsena', cap: '01023', provinceId: 63},
{id: 3645, value: 'merlara', cap: '35040', provinceId: 28},
{id: 199, value: 'cissone', cap: '12050', provinceId: 4},
{id: 2807, value: 'merlino', cap: '26833', provinceId: 20},
{id: 1357, value: 'gemonio', cap: '21036', provinceId: 10},
{id: 6688, value: 'mesagne', cap: '72023', provinceId: 82},
{id: 2373, value: 'genzone', cap: '27014', provinceId: 16},
{id: 1474, value: 'civenna', cap: '22030', provinceId: 11},
{id: 3915, value: 'sgonico', cap: '34010', provinceId: 32},
{id: 7249, value: 'siderno', cap: '89048', provinceId: 88},
{id: 3722, value: 'gavello', cap: '45010', provinceId: 29},
{id: 7272, value: 'melissa', cap: '88814', provinceId: 89},
{id: 2962, value: 'bolbeno', cap: '38079', provinceId: 22},
{id: 7940, value: 'gesturi', cap: '09020', provinceId: 106},
{id: 1881, value: 'bolgare', cap: '24060', provinceId: 14},
{id: 2879, value: 'meltina', cap: '39010', provinceId: 21},
{id: 4879, value: 'sigillo', cap: '06028', provinceId: 57},
{id: 3989, value: 'civezza', cap: '18017', provinceId: 34},
{id: 1744, value: 'gessate', cap: '20060', provinceId: 13},
{id: 737, value: 'vinadio', cap: '12010', provinceId: 4},
{id: 5360, value: 'mentana', cap: '00013', provinceId: 65},
{id: 4761, value: 'asciano', cap: '53041', provinceId: 54},
{id: 7895, value: 'silanus', cap: '08017', provinceId: 101},
{id: 1689, value: 'bollate', cap: '20021', provinceId: 13},
{id: 4395, value: 'bologna', cap: '', provinceId: 42},
{id: 4121, value: 'cogorno', cap: '16030', provinceId: 36},
{id: 3755, value: 'attimis', cap: '33040', provinceId: 30},
{id: 5774, value: 'cupello', cap: '66051', provinceId: 71},
{id: 1931, value: 'colzate', cap: '24020', provinceId: 14},
{id: 1338, value: 'comerio', cap: '21025', provinceId: 10},
{id: 3666, value: 'saletto', cap: '35046', provinceId: 28},
{id: 2788, value: 'comazzo', cap: '26833', provinceId: 20},
{id: 1820, value: 'seregno', cap: '20831', provinceId: 108},
{id: 1616, value: 'forcola', cap: '23010', provinceId: 12},
{id: 631, value: 'buronzo', cap: '13040', provinceId: 2},
{id: 7657, value: 'mascali', cap: '95016', provinceId: 97},
{id: 7208, value: 'martone', cap: '89040', provinceId: 88},
{id: 4260, value: 'colorno', cap: '43052', provinceId: 39},
{id: 1476, value: 'colonno', cap: '22010', provinceId: 11},
{id: 4206, value: 'bettola', cap: '29021', provinceId: 38},
{id: 5336, value: 'colonna', cap: '00030', provinceId: 65},
{id: 1867, value: 'averara', cap: '24010', provinceId: 14},
{id: 2046, value: 'seriate', cap: '24068', provinceId: 14},
{id: 8041, value: 'busachi', cap: '09082', provinceId: 103},
{id: 2390, value: 'marzano', cap: '27010', provinceId: 16},
{id: 4149, value: 'rapallo', cap: '16035', provinceId: 36},
{id: 4109, value: 'busalla', cap: '16012', provinceId: 36},
{id: 2170, value: 'gambara', cap: '25020', provinceId: 15},
{id: 395, value: 'condove', cap: '10055', provinceId: 1},
{id: 1381, value: 'marnate', cap: '21050', provinceId: 10},
{id: 3008, value: 'condino', cap: '38083', provinceId: 22},
{id: 4641, value: 'marradi', cap: '50034', provinceId: 50},
{id: 7346, value: 'marsala', cap: '91025', provinceId: 91},
{id: 7171, value: 'bivongi', cap: '89040', provinceId: 88},
{id: 6122, value: 'forchia', cap: '82011', provinceId: 75},
{id: 3957, value: 'sequals', cap: '33090', provinceId: 33},
{id: 316, value: 'azeglio', cap: '10010', provinceId: 1},
{id: 7792, value: 'sennori', cap: '07036', provinceId: 100},
{id: 7985, value: 'senorbi', cap: '09040', provinceId: 102},
{id: 6762, value: 'ruffano', cap: '73049', provinceId: 83},
{id: 6738, value: 'martano', cap: '73025', provinceId: 83},
{id: 9434, value: 'martell', cap: '39020', provinceId: 21},
{id: 6859, value: 'rapolla', cap: '85027', provinceId: 84},
{id: 2156, value: 'cologne', cap: '25033', provinceId: 15},
{id: 6828, value: 'forenza', cap: '85023', provinceId: 84},
{id: 2204, value: 'mazzano', cap: '25080', provinceId: 15},
{id: 4385, value: 'sestola', cap: '41029', provinceId: 41},
{id: 5525, value: 'serrone', cap: '03010', provinceId: 67},
{id: 4359, value: 'guiglia', cap: '41052', provinceId: 41},
{id: 7147, value: 'sersale', cap: '88054', provinceId: 87},
{id: 844, value: 'sessame', cap: '14058', provinceId: 5},
{id: 4755, value: 'sestino', cap: '52038', provinceId: 53},
{id: 1699, value: 'bussero', cap: '20060', provinceId: 13},
{id: 6391, value: 'auletta', cap: '84031', provinceId: 78},
{id: 2899, value: 'racines', cap: '39040', provinceId: 21},
{id: 7705, value: 'augusta', cap: '96011', provinceId: 99},
{id: 4257, value: 'busseto', cap: '43011', provinceId: 39},
{id: 5887, value: 'matrice', cap: '86030', provinceId: 72},
{id: 2762, value: 'varenna', cap: '23829', provinceId: 19},
{id: 4452, value: 'bondeno', cap: '44012', provinceId: 43},
{id: 4834, value: 'bevagna', cap: '06031', provinceId: 57},
{id: 7510, value: 'raccuja', cap: '98067', provinceId: 93},
{id: 1698, value: 'busnago', cap: '20874', provinceId: 108},
{id: 6494, value: 'salento', cap: '84070', provinceId: 78},
{id: 2371, value: 'gambolo', cap: '27025', provinceId: 16},
{id: 7708, value: 'buscemi', cap: '96010', provinceId: 99},
{id: 6495, value: 'salerno', cap: '', provinceId: 78},
{id: 5876, value: 'gildone', cap: '86010', provinceId: 72},
{id: 2669, value: 'sermide', cap: '46028', provinceId: 18},
{id: 1958, value: 'gandino', cap: '24024', provinceId: 14},
{id: 1697, value: 'buscate', cap: '20010', provinceId: 13},
{id: 2964, value: 'bondone', cap: '38080', provinceId: 22},
{id: 7671, value: 'ramacca', cap: '95040', provinceId: 97},
{id: 532, value: 'rueglio', cap: '10010', provinceId: 1},
{id: 1166, value: 'gignese', cap: '28836', provinceId: 8},
{id: 5466, value: 'ausonia', cap: '03040', provinceId: 67},
{id: 7198, value: 'giffone', cap: '89020', provinceId: 88},
{id: 7248, value: 'serrata', cap: '89020', provinceId: 88},
{id: 3835, value: 'ragogna', cap: '33030', provinceId: 30},
{id: 7692, value: 'ragalna', cap: '95030', provinceId: 97},
{id: 7670, value: 'raddusa', cap: '95040', provinceId: 97},
{id: 400, value: 'cuorgne', cap: '10082', provinceId: 1},
{id: 15, value: 'rimasco', cap: '13026', provinceId: 2},
{id: 2904, value: 'rodengo', cap: '39037', provinceId: 21},
{id: 4131, value: 'lavagna', cap: '16033', provinceId: 36},
{id: 5193, value: 'faleria', cap: '01030', provinceId: 63},
{id: 7460, value: 'falcone', cap: '98060', provinceId: 93},
{id: 6876, value: 'sarconi', cap: '85050', provinceId: 84},
{id: 7980, value: 'sardara', cap: '09030', provinceId: 106},
{id: 1877, value: 'bergamo', cap: '', provinceId: 14},
{id: 4184, value: 'framura', cap: '19014', provinceId: 37},
{id: 3406, value: 'falcade', cap: '32020', provinceId: 25},
{id: 6441, value: 'laurito', cap: '84050', provinceId: 78},
{id: 3210, value: 'lavagno', cap: '37030', provinceId: 23},
{id: 6838, value: 'lavello', cap: '85024', provinceId: 84},
{id: 327, value: 'bibiana', cap: '10060', provinceId: 1},
{id: 2271, value: 'sarezzo', cap: '25068', provinceId: 15},
{id: 673, value: 'roascio', cap: '12073', provinceId: 4},
{id: 5824, value: 'rosello', cap: '66040', provinceId: 71},
{id: 7106, value: 'falerna', cap: '88042', provinceId: 87},
{id: 4662, value: 'bibbona', cap: '57020', provinceId: 51},
{id: 3785, value: 'fagagna', cap: '33034', provinceId: 30},
{id: 218, value: 'fossano', cap: '12045', provinceId: 4},
{id: 3677, value: 'saonara', cap: '35020', provinceId: 28},
{id: 4253, value: 'bedonia', cap: '43041', provinceId: 39},
{id: 3439, value: 'sappada', cap: '32047', provinceId: 25},
{id: 2954, value: 'bedollo', cap: '38043', provinceId: 22},
{id: 2433, value: 'rosasco', cap: '27030', provinceId: 16},
{id: 3363, value: 'sarcedo', cap: '36030', provinceId: 24},
{id: 1104, value: 'rosazza', cap: '13815', provinceId: 7},
{id: 6440, value: 'laurino', cap: '84057', provinceId: 78},
{id: 5957, value: 'isernia', cap: '86170', provinceId: 73},
{id: 1583, value: 'veniano', cap: '22070', provinceId: 11},
{id: 7837, value: 'flussio', cap: '08010', provinceId: 103},
{id: 9428, value: 'laurein', cap: '39040', provinceId: 21},
{id: 4393, value: 'bazzano', cap: '40053', provinceId: 42},
{id: 649, value: 'fobello', cap: '13025', provinceId: 2},
{id: 6791, value: 'vernole', cap: '73029', provinceId: 83},
{id: 4244, value: 'sarmato', cap: '29010', provinceId: 38},
{id: 3593, value: 'venezia', cap: '', provinceId: 27},
{id: 6292, value: 'flumeri', cap: '83040', provinceId: 77},
{id: 9425, value: 'leifers', cap: '39055', provinceId: 21},
{id: 5485, value: 'esperia', cap: '03045', provinceId: 67},
{id: 1754, value: 'legnano', cap: '20025', provinceId: 13},
{id: 3636, value: 'legnaro', cap: '35020', provinceId: 28},
{id: 7835, value: 'escolca', cap: '08030', provinceId: 102},
{id: 9470, value: 'sarntal', cap: '39058', provinceId: 21},
{id: 1402, value: 'saronno', cap: '21047', provinceId: 10},
{id: 86, value: 'invorio', cap: '28045', provinceId: 3},
{id: 3262, value: 'vigasio', cap: '37068', provinceId: 23},
{id: 1844, value: 'vignate', cap: '20060', provinceId: 13},
{id: 4388, value: 'vignola', cap: '41058', provinceId: 41},
{id: 2121, value: 'bovegno', cap: '25061', provinceId: 15},
{id: 2166, value: 'erbusco', cap: '25030', provinceId: 15},
{id: 3201, value: 'erbezzo', cap: '37020', provinceId: 23},
{id: 1240, value: 'gressan', cap: '11020', provinceId: 9},
{id: 654, value: 'greggio', cap: '13030', provinceId: 2},
{id: 3212, value: 'legnago', cap: '37045', provinceId: 23},
{id: 7034, value: 'rossano', cap: '87067', provinceId: 86},
{id: 8108, value: 'faetano', cap: '47896', provinceId: 111},
{id: 7367, value: 'baucina', cap: '90020', provinceId: 92},
{id: 3534, value: 'sarmede', cap: '31026', provinceId: 26},
{id: 6442, value: 'laviano', cap: '84020', provinceId: 78},
{id: 6543, value: 'biccari', cap: '71032', provinceId: 79},
{id: 8037, value: 'bauladu', cap: '09070', provinceId: 103},
{id: 1886, value: 'bossico', cap: '24060', provinceId: 14},
{id: 1502, value: 'griante', cap: '22011', provinceId: 11},
{id: 5087, value: 'sarnano', cap: '62028', provinceId: 61},
{id: 1489, value: 'eupilio', cap: '22030', provinceId: 11},
{id: 1753, value: 'lazzate', cap: '20824', provinceId: 108},
{id: 150, value: 'bergolo', cap: '12074', provinceId: 4},
{id: 2955, value: 'bersone', cap: '38085', provinceId: 22},
{id: 686, value: 'rossana', cap: '12020', provinceId: 4},
{id: 2315, value: 'battuda', cap: '27020', provinceId: 16},
{id: 2041, value: 'sarnico', cap: '24067', provinceId: 14},
{id: 402, value: 'exilles', cap: '10050', provinceId: 1},
{id: 3111, value: 'sanzeno', cap: '38010', provinceId: 22},
{id: 6687, value: 'latiano', cap: '72022', provinceId: 82},
{id: 4100, value: 'vendone', cap: '17032', provinceId: 35},
{id: 9407, value: 'klausen', cap: '39043', provinceId: 21},
{id: 4660, value: 'vicchio', cap: '50039', provinceId: 50},
{id: 3381, value: 'vicenza', cap: '36100', provinceId: 24},
{id: 7303, value: 'joppolo', cap: '89863', provinceId: 90},
{id: 9408, value: 'karneid', cap: '39053', provinceId: 21},
{id: 6827, value: 'filiano', cap: '85020', provinceId: 84},
{id: 5778, value: 'filetto', cap: '66030', provinceId: 71},
{id: 7229, value: 'roghudi', cap: '89060', provinceId: 88},
{id: 4022, value: 'sanremo', cap: '18038', provinceId: 34},
{id: 1584, value: 'vercana', cap: '22013', provinceId: 11},
{id: 3393, value: 'belluno', cap: '32100', provinceId: 25},
{id: 5993, value: 'bellona', cap: '81041', provinceId: 74},
{id: 4630, value: 'fiesole', cap: '50014', provinceId: 50},
{id: 4264, value: 'fidenza', cap: '43036', provinceId: 39},
{id: 4902, value: 'ficulle', cap: '05016', provinceId: 58},
{id: 7461, value: 'ficarra', cap: '98062', provinceId: 93},
{id: 6940, value: 'belsito', cap: '87030', provinceId: 86},
{id: 7972, value: 'sanluri', cap: '09025', provinceId: 106},
{id: 1752, value: 'lainate', cap: '20020', provinceId: 13},
{id: 1246, value: 'issogne', cap: '11020', provinceId: 9},
{id: 685, value: 'rodello', cap: '12050', provinceId: 4},
{id: 4904, value: 'guardea', cap: '05025', provinceId: 58},
{id: 684, value: 'roddino', cap: '12050', provinceId: 4},
{id: 598, value: 'vialfre', cap: '10090', provinceId: 1},
{id: 543, value: 'sangano', cap: '10090', provinceId: 1},
{id: 7195, value: 'fiumara', cap: '89050', provinceId: 88},
{id: 3491, value: 'istrana', cap: '31036', provinceId: 26},
{id: 3031, value: 'fornace', cap: '38040', provinceId: 22},
{id: 1662, value: 'verceia', cap: '23020', provinceId: 12},
{id: 6488, value: 'rofrano', cap: '84070', provinceId: 78},
{id: 854, value: 'viarigi', cap: '14030', provinceId: 5},
{id: 4632, value: 'firenze', cap: '', provinceId: 50},
{id: 2432, value: 'roncaro', cap: '27010', provinceId: 16},
{id: 5540, value: 'vicalvi', cap: '03030', provinceId: 67},
{id: 7116, value: 'jacurso', cap: '88020', provinceId: 87},
{id: 2867, value: 'lagundo', cap: '39022', provinceId: 21},
{id: 3845, value: 'ronchis', cap: '33050', provinceId: 30},
{id: 6658, value: 'laterza', cap: '74014', provinceId: 81},
{id: 6991, value: 'lappano', cap: '87050', provinceId: 86},
{id: 1103, value: 'roppolo', cap: '13883', provinceId: 7},
{id: 1623, value: 'lanzada', cap: '23020', provinceId: 12},
{id: 831, value: 'robella', cap: '14020', provinceId: 5},
{id: 6430, value: 'felitto', cap: '84055', provinceId: 78},
{id: 404, value: 'feletto', cap: '10080', provinceId: 1},
{id: 7401, value: 'isnello', cap: '90010', provinceId: 92},
{id: 3042, value: 'lardaro', cap: '38087', provinceId: 22},
{id: 1492, value: 'fenegro', cap: '22070', provinceId: 11},
{id: 7230, value: 'rosarno', cap: '89025', provinceId: 88},
{id: 5416, value: 'lariano', cap: '00040', provinceId: 65},
{id: 4695, value: 'fauglia', cap: '56043', provinceId: 52},
{id: 7403, value: 'lascari', cap: '90010', provinceId: 92},
{id: 1509, value: 'lasnigo', cap: '22030', provinceId: 11},
{id: 5194, value: 'farnese', cap: '01010', provinceId: 63},
{id: 7740, value: 'borutta', cap: '07040', provinceId: 100},
{id: 3101, value: 'ronzone', cap: '38010', provinceId: 22},
{id: 2430, value: 'rognano', cap: '27010', provinceId: 16},
{id: 2033, value: 'roncola', cap: '24030', provinceId: 14},
{id: 6942, value: 'bianchi', cap: '87050', provinceId: 86},
{id: 5055, value: 'fiastra', cap: '62035', provinceId: 61},
{id: 146, value: 'bellino', cap: '12020', provinceId: 4},
{id: 2686, value: 'bellano', cap: '23822', provinceId: 19},
{id: 792, value: 'ferrere', cap: '14012', provinceId: 5},
{id: 4975, value: 'san leo', cap: '47865', provinceId: 46},
{id: 7975, value: 'santadi', cap: '09010', provinceId: 107},
{id: 4457, value: 'ferrara', cap: '', provinceId: 43},
{id: 28, value: 'santhia', cap: '13048', provinceId: 2},
{id: 3100, value: 'roncone', cap: '38087', provinceId: 22},
{id: 656, value: 'lamporo', cap: '13046', provinceId: 2},
{id: 559, value: 'santena', cap: '10026', provinceId: 1},
{id: 3879, value: 'venzone', cap: '33010', provinceId: 30},
{id: 7851, value: 'lanusei', cap: '08045', provinceId: 105},
{id: 5351, value: 'lanuvio', cap: '00040', provinceId: 65},
{id: 4048, value: 'bormida', cap: '17045', provinceId: 35},
{id: 2122, value: 'bovezzo', cap: '25073', provinceId: 15},
{id: 4848, value: 'foligno', cap: '06034', provinceId: 57},
{id: 16, value: 'rimella', cap: '13020', provinceId: 2},
{id: 925, value: 'dernice', cap: '15056', provinceId: 6},
{id: 4670, value: 'livorno', cap: '', provinceId: 51},
{id: 83, value: 'gozzano', cap: '28024', provinceId: 3},
{id: 7673, value: 'riposto', cap: '95018', provinceId: 97},
{id: 2798, value: 'livraga', cap: '26814', provinceId: 20},
{id: 6660, value: 'lizzano', cap: '74020', provinceId: 81},
{id: 1125, value: 'verrone', cap: '13871', provinceId: 7},
{id: 1445, value: 'brienno', cap: '22010', provinceId: 11},
{id: 4942, value: 'gradara', cap: '61012', provinceId: 59},
{id: 692, value: 'saluzzo', cap: '12037', provinceId: 4},
{id: 208, value: 'demonte', cap: '12014', provinceId: 4},
{id: 6808, value: 'brienza', cap: '85050', provinceId: 84},
{id: 7469, value: 'furnari', cap: '98054', provinceId: 93},
{id: 8036, value: 'baressa', cap: '09090', provinceId: 103},
{id: 1613, value: 'delebio', cap: '23014', provinceId: 12},
{id: 1296, value: 'besozzo', cap: '21023', provinceId: 10},
{id: 1295, value: 'besnate', cap: '21010', provinceId: 10},
{id: 3856, value: 'savogna', cap: '33040', provinceId: 30},
{id: 3780, value: 'dignano', cap: '33030', provinceId: 30},
{id: 7756, value: 'illorai', cap: '07010', provinceId: 100},
{id: 1758, value: 'liscate', cap: '20060', provinceId: 13},
{id: 5196, value: 'gradoli', cap: '01010', provinceId: 63},
{id: 1759, value: 'lissone', cap: '20851', provinceId: 108},
{id: 2126, value: 'brescia', cap: '', provinceId: 15},
{id: 2969, value: 'bresimo', cap: '38020', provinceId: 22},
{id: 1873, value: 'bariano', cap: '24050', provinceId: 14},
{id: 231, value: 'igliano', cap: '12060', provinceId: 4},
{id: 1624, value: 'livigno', cap: '23030', provinceId: 12},
{id: 7281, value: 'savelli', cap: '88825', provinceId: 89},
{id: 6244, value: 'saviano', cap: '80039', provinceId: 76},
{id: 671, value: 'rittana', cap: '12010', provinceId: 4},
{id: 7517, value: 'rometta', cap: '98043', provinceId: 93},
{id: 4155, value: 'rovegno', cap: '16028', provinceId: 36},
{id: 4447, value: 'savigno', cap: '40060', provinceId: 42},
{id: 46, value: 'barengo', cap: '28010', provinceId: 3},
{id: 9390, value: 'hafling', cap: '39010', provinceId: 21},
{id: 1054, value: 'bioglio', cap: '13841', provinceId: 7},
{id: 5556, value: 'bisegna', cap: '67050', provinceId: 68},
{id: 2386, value: 'lomello', cap: '27034', provinceId: 16},
{id: 5958, value: 'longano', cap: '86090', provinceId: 73},
{id: 3892, value: 'gorizia', cap: '34170', provinceId: 31},
{id: 2035, value: 'rovetta', cap: '24020', provinceId: 14},
{id: 3760, value: 'bordano', cap: '33010', provinceId: 30},
{id: 3317, value: 'longare', cap: '36023', provinceId: 24},
{id: 1619, value: 'gordona', cap: '23020', provinceId: 12},
{id: 2722, value: 'lomagna', cap: '23871', provinceId: 19},
{id: 3154, value: 'vattaro', cap: '38049', provinceId: 22},
{id: 3604, value: 'barbona', cap: '35040', provinceId: 28},
{id: 439, value: 'loranze', cap: '10010', provinceId: 1},
{id: 2635, value: 'gonzaga', cap: '46023', provinceId: 18},
{id: 7103, value: 'curinga', cap: '88022', provinceId: 87},
{id: 3692, value: 'vigonza', cap: '35010', provinceId: 28},
{id: 9392, value: 'barbian', cap: '39040', provinceId: 21},
{id: 1516, value: 'lomazzo', cap: '22074', provinceId: 11},
{id: 4302, value: 'boretto', cap: '42022', provinceId: 40},
{id: 3412, value: 'gosaldo', cap: '32020', provinceId: 25},
{id: 1964, value: 'gorlago', cap: '24060', provinceId: 14},
{id: 1304, value: 'brinzio', cap: '21030', provinceId: 10},
{id: 4129, value: 'gorreto', cap: '16020', provinceId: 36},
{id: 1347, value: 'daverio', cap: '21020', provinceId: 10},
{id: 4124, value: 'davagna', cap: '16022', provinceId: 36},
{id: 2080, value: 'vertova', cap: '24029', provinceId: 14},
{id: 6516, value: 'scafati', cap: '84018', provinceId: 78},
{id: 2545, value: 'gussola', cap: '26040', provinceId: 17},
{id: 936, value: 'gabiano', cap: '15020', provinceId: 6},
{id: 2187, value: 'lodrino', cap: '25060', provinceId: 15},
{id: 1692, value: 'briosco', cap: '20836', provinceId: 108},
{id: 7946, value: 'guasila', cap: '09040', provinceId: 102},
{id: 1739, value: 'dairago', cap: '20020', provinceId: 13},
{id: 3544, value: 'vazzola', cap: '31028', provinceId: 26},
{id: 7331, value: 'vazzano', cap: '89834', provinceId: 90},
{id: 2188, value: 'lograto', cap: '25030', provinceId: 15},
{id: 515, value: 'rivalba', cap: '10090', provinceId: 1},
{id: 1077, value: 'graglia', cap: '13895', provinceId: 7},
{id: 3564, value: 'eraclea', cap: '30020', provinceId: 27},
{id: 237, value: 'lesegno', cap: '12076', provinceId: 4},
{id: 5986, value: 'venafro', cap: '86079', provinceId: 73},
{id: 5911, value: 'rotello', cap: '86040', provinceId: 72},
{id: 5872, value: 'duronia', cap: '86020', provinceId: 72},
{id: 1678, value: 'basiano', cap: '20060', provinceId: 13},
{id: 1348, value: 'dumenza', cap: '21010', provinceId: 10},
{id: 7967, value: 'samassi', cap: '09030', provinceId: 106},
{id: 6117, value: 'dugenta', cap: '82030', provinceId: 75},
{id: 693, value: 'sambuco', cap: '12010', provinceId: 4},
{id: 4197, value: 'sarzana', cap: '19038', provinceId: 37},
{id: 3583, value: 'salzano', cap: '30030', provinceId: 27},
{id: 7832, value: 'dualchi', cap: '08010', provinceId: 101},
{id: 1161, value: 'druogno', cap: '28853', provinceId: 8},
{id: 401, value: 'druento', cap: '10040', provinceId: 1},
{id: 211, value: 'dronero', cap: '12025', provinceId: 4},
{id: 434, value: 'lessolo', cap: '10010', provinceId: 1},
{id: 5395, value: 'sambuci', cap: '00020', provinceId: 65},
{id: 726, value: 'venasca', cap: '12020', provinceId: 4},
{id: 1078, value: 'lessona', cap: '13853', provinceId: 7},
{id: 727, value: 'verduno', cap: '12060', provinceId: 4},
{id: 7981, value: 'sarroch', cap: '09018', provinceId: 102},
{id: 5259, value: 'greccio', cap: '02045', provinceId: 64},
{id: 2837, value: 'bolzano', cap: '39100', provinceId: 21},
{id: 3483, value: 'follina', cap: '31051', provinceId: 26},
{id: 1206, value: 'vignone', cap: '28819', provinceId: 8},
{id: 3415, value: 'lentiai', cap: '32020', provinceId: 25},
{id: 7715, value: 'lentini', cap: '96016', provinceId: 99},
{id: 6734, value: 'lequile', cap: '73010', provinceId: 83},
{id: 4519, value: 'sarsina', cap: '47027', provinceId: 45},
{id: 1234, value: 'emarese', cap: '11020', provinceId: 9},
{id: 882, value: 'bozzole', cap: '15040', provinceId: 6},
{id: 4448, value: 'vergato', cap: '40038', provinceId: 42},
{id: 2615, value: 'bozzolo', cap: '46012', provinceId: 18},
{id: 419, value: 'gravere', cap: '10050', provinceId: 1},
{id: 5160, value: 'rotella', cap: '63071', provinceId: 62},
{id: 1333, value: 'cislago', cap: '21040', provinceId: 10},
{id: 6865, value: 'rotonda', cap: '85048', provinceId: 84},
{id: 7920, value: 'barrali', cap: '09040', provinceId: 102},
{id: 2683, value: 'barzago', cap: '23890', provinceId: 19},
{id: 2774, value: 'brembio', cap: '26822', provinceId: 20},
{id: 3157, value: 'vezzano', cap: '38070', provinceId: 22},
{id: 9395, value: 'brenner', cap: '39041', provinceId: 21},
{id: 1874, value: 'barzana', cap: '24030', provinceId: 14},
{id: 6863, value: 'rivello', cap: '85040', provinceId: 84},
{id: 5352, value: 'licenza', cap: '00026', provinceId: 65},
{id: 3998, value: 'imperia', cap: '18100', provinceId: 34},
{id: 2674, value: 'viadana', cap: '46019', provinceId: 18},
{id: 658, value: 'lignana', cap: '13034', provinceId: 2},
{id: 1687, value: 'binasco', cap: '20082', provinceId: 13},
{id: 6609, value: 'binetto', cap: '70020', provinceId: 80},
{id: 2709, value: 'dolzago', cap: '23843', provinceId: 19},
{id: 3525, value: 'roncade', cap: '31056', provinceId: 26},
{id: 7304, value: 'limbadi', cap: '89844', provinceId: 90},
{id: 3997, value: 'dolcedo', cap: '18020', provinceId: 34},
{id: 2684, value: 'barzano', cap: '23891', provinceId: 19},
{id: 7831, value: 'dorgali', cap: '08022', provinceId: 101},
{id: 524, value: 'roletto', cap: '10060', provinceId: 1},
{id: 6207, value: 'lettere', cap: '80050', provinceId: 76},
{id: 1741, value: 'dresano', cap: '20070', provinceId: 13},
{id: 1300, value: 'brebbia', cap: '21020', provinceId: 10},
{id: 3486, value: 'fregona', cap: '31010', provinceId: 26},
{id: 7475, value: 'graniti', cap: '98036', provinceId: 93},
{id: 6019, value: 'dragoni', cap: '81010', provinceId: 74},
{id: 1301, value: 'bregano', cap: '21020', provinceId: 10},
{id: 4187, value: 'levanto', cap: '19015', provinceId: 37},
{id: 1511, value: 'lezzeno', cap: '22025', provinceId: 11},
{id: 2312, value: 'bascape', cap: '27010', provinceId: 16},
{id: 6515, value: 'sassano', cap: '84038', provinceId: 78},
{id: 1943, value: 'dossena', cap: '24010', provinceId: 14},
{id: 1074, value: 'dorzano', cap: '13881', provinceId: 7},
{id: 3019, value: 'dorsino', cap: '38070', provinceId: 22},
{id: 6340, value: 'rotondi', cap: '83017', provinceId: 77},
{id: 7789, value: 'sassari', cap: '07100', provinceId: 100},
{id: 1942, value: 'dalmine', cap: '24044', provinceId: 14},
{id: 732, value: 'vignolo', cap: '12010', provinceId: 4},
{id: 3754, value: 'artegna', cap: '33011', provinceId: 30},
{id: 5928, value: 'termoli', cap: '86039', provinceId: 72},
{id: 5531, value: 'terelle', cap: '03040', provinceId: 67},
{id: 7626, value: 'nicosia', cap: '94014', provinceId: 96},
{id: 2923, value: 'terento', cap: '39030', provinceId: 21},
{id: 4288, value: 'terenzo', cap: '43040', provinceId: 39},
{id: 3134, value: 'terlago', cap: '38070', provinceId: 22},
{id: 2564, value: 'piadena', cap: '26034', provinceId: 17},
{id: 2924, value: 'terlano', cap: '39018', provinceId: 21},
{id: 1409, value: 'ternate', cap: '21020', provinceId: 10},
{id: 2605, value: 'vailate', cap: '26019', provinceId: 17},
{id: 309, value: 'alpette', cap: '10080', provinceId: 1},
{id: 7605, value: 'niscemi', cap: '93015', provinceId: 95},
{id: 9490, value: 'pfitsch', cap: '39049', provinceId: 21},
{id: 9488, value: 'pfatten', cap: '39051', provinceId: 21},
{id: 2485, value: 'voghera', cap: '27058', provinceId: 16},
{id: 9415, value: 'pfalzen', cap: '39030', provinceId: 21},
{id: 2238, value: 'pezzaze', cap: '25060', provinceId: 15},
{id: 2406, value: 'nicorvo', cap: '27020', provinceId: 16},
{id: 6751, value: 'neviano', cap: '73040', provinceId: 83},
{id: 6402, value: 'campora', cap: '84040', provinceId: 78},
{id: 4144, value: 'neirone', cap: '16040', provinceId: 36},
{id: 2885, value: 'naturno', cap: '39025', provinceId: 21},
{id: 9441, value: 'naturns', cap: '39025', provinceId: 21},
{id: 1651, value: 'tartano', cap: '23010', provinceId: 12},
{id: 5603, value: 'navelli', cap: '67020', provinceId: 68},
{id: 5370, value: 'nazzano', cap: '00060', provinceId: 65},
{id: 6365, value: 'taurano', cap: '83020', provinceId: 77},
{id: 6366, value: 'taurasi', cap: '83030', provinceId: 77},
{id: 6934, value: 'altilia', cap: '87040', provinceId: 86},
{id: 2060, value: 'telgate', cap: '24060', provinceId: 14},
{id: 2301, value: 'vobarno', cap: '25079', provinceId: 15},
{id: 5927, value: 'tavenna', cap: '86030', provinceId: 72},
{id: 7156, value: 'taverna', cap: '88055', provinceId: 87},
{id: 8056, value: 'neoneli', cap: '09080', provinceId: 103},
{id: 6783, value: 'taviano', cap: '73057', provinceId: 83},
{id: 5274, value: 'nespolo', cap: '02020', provinceId: 64},
{id: 1428, value: 'alserio', cap: '22040', provinceId: 11},
{id: 5373, value: 'nettuno', cap: '00048', provinceId: 65},
{id: 6, value: 'pezzana', cap: '13010', provinceId: 2},
{id: 4094, value: 'testico', cap: '17020', provinceId: 35},
{id: 276, value: 'narzole', cap: '12068', provinceId: 4},
{id: 282, value: 'nucetto', cap: '12070', provinceId: 4},
{id: 849, value: 'tonengo', cap: '14023', provinceId: 5},
{id: 7728, value: 'alghero', cap: '07041', provinceId: 100},
{id: 281, value: 'novello', cap: '12060', provinceId: 4},
{id: 1276, value: 'torgnon', cap: '11020', provinceId: 9},
{id: 2486, value: 'volpara', cap: '27047', provinceId: 16},
{id: 6645, value: 'toritto', cap: '70020', provinceId: 80},
{id: 122, value: 'tornaco', cap: '28070', provinceId: 3},
{id: 2599, value: 'tornata', cap: '26030', provinceId: 17},
{id: 3683, value: 'tombolo', cap: '35019', provinceId: 28},
{id: 5614, value: 'pescina', cap: '67057', provinceId: 68},
{id: 4290, value: 'tornolo', cap: '43059', provinceId: 39},
{id: 756, value: 'canelli', cap: '14053', provinceId: 5},
{id: 6527, value: 'torraca', cap: '84030', provinceId: 78},
{id: 8059, value: 'nurachi', cap: '09070', provinceId: 103},
{id: 7866, value: 'nuragus', cap: '08030', provinceId: 102},
{id: 1047, value: 'volpedo', cap: '15059', provinceId: 6},
{id: 2339, value: 'castana', cap: '27040', provinceId: 16},
{id: 6276, value: 'candida', cap: '83040', provinceId: 77},
{id: 7910, value: 'urzulei', cap: '08040', provinceId: 105},
{id: 1209, value: 'vogogna', cap: '28805', provinceId: 8},
{id: 3390, value: 'alleghe', cap: '32022', provinceId: 25},
{id: 7999, value: 'teulada', cap: '09019', provinceId: 102},
{id: 6850, value: 'noepoli', cap: '85035', provinceId: 84},
{id: 2597, value: 'ticengo', cap: '26020', provinceId: 17},
{id: 583, value: 'usseaux', cap: '10060', provinceId: 1},
{id: 6702, value: 'alliste', cap: '73040', provinceId: 83},
{id: 2282, value: 'tignale', cap: '25080', provinceId: 15},
{id: 7904, value: 'tinnura', cap: '08010', provinceId: 103},
{id: 7157, value: 'tiriolo', cap: '88056', provinceId: 87},
{id: 1061, value: 'candelo', cap: '13878', provinceId: 7},
{id: 7361, value: 'alimena', cap: '90020', provinceId: 92},
{id: 2607, value: 'volongo', cap: '26030', provinceId: 17},
{id: 8094, value: 'usellus', cap: '09090', provinceId: 103},
{id: 9413, value: 'toblach', cap: '39034', provinceId: 21},
{id: 2982, value: 'canazei', cap: '38032', provinceId: 22},
{id: 4095, value: 'toirano', cap: '17055', provinceId: 35},
{id: 7132, value: 'petrona', cap: '88050', provinceId: 87},
{id: 6546, value: 'candela', cap: '71024', provinceId: 79},
{id: 8083, value: 'simaxis', cap: '09088', provinceId: 103},
{id: 4892, value: 'alviano', cap: '05020', provinceId: 58},
{id: 5728, value: 'pescara', cap: '', provinceId: 70},
{id: 5148, value: 'moresco', cap: '63826', provinceId: 109},
{id: 6857, value: 'pignola', cap: '85010', provinceId: 84},
{id: 3917, value: 'andreis', cap: '33080', provinceId: 33},
{id: 186, value: 'castino', cap: '12050', provinceId: 4},
{id: 6134, value: 'morcone', cap: '82026', provinceId: 75},
{id: 4434, value: 'mordano', cap: '40027', provinceId: 42},
{id: 1989, value: 'morengo', cap: '24050', provinceId: 14},
{id: 312, value: 'andrate', cap: '10010', provinceId: 1},
{id: 272, value: 'moretta', cap: '12033', provinceId: 4},
{id: 9387, value: 'andrian', cap: '39010', provinceId: 21},
{id: 3503, value: 'morgano', cap: '31050', provinceId: 26},
{id: 6703, value: 'andrano', cap: '73032', provinceId: 83},
{id: 2922, value: 'stelvio', cap: '39029', provinceId: 21},
{id: 3124, value: 'stenico', cap: '38070', provinceId: 22},
{id: 5369, value: 'morlupo', cap: '00067', provinceId: 65},
{id: 4110, value: 'camogli', cap: '16032', provinceId: 36},
{id: 3741, value: 'stienta', cap: '45039', provinceId: 29},
{id: 4433, value: 'monzuno', cap: '40036', provinceId: 42},
{id: 4191, value: 'pignone', cap: '19020', provinceId: 37},
{id: 970, value: 'mornese', cap: '15075', provinceId: 6},
{id: 2594, value: 'spineda', cap: '26030', provinceId: 17},
{id: 3121, value: 'spiazzo', cap: '38088', provinceId: 22},
{id: 7509, value: 'piraino', cap: '98060', provinceId: 93},
{id: 989, value: 'piovera', cap: '15040', provinceId: 6},
{id: 5077, value: 'pioraco', cap: '62025', provinceId: 61},
{id: 3171, value: 'angiari', cap: '37050', provinceId: 23},
{id: 6111, value: 'cautano', cap: '82030', provinceId: 75},
{id: 3085, value: 'pinzolo', cap: '38086', provinceId: 22},
{id: 4863, value: 'montone', cap: '06014', provinceId: 57},
{id: 1649, value: 'spriana', cap: '23020', provinceId: 12},
{id: 7649, value: 'catania', cap: '', provinceId: 97},
{id: 5926, value: 'spinete', cap: '86020', provinceId: 72},
{id: 6881, value: 'spinoso', cap: '85039', provinceId: 84},
{id: 2054, value: 'spirano', cap: '24050', provinceId: 14},
{id: 4881, value: 'spoleto', cap: '06049', provinceId: 57},
{id: 3732, value: 'pincara', cap: '45020', provinceId: 29},
{id: 492, value: 'pinasca', cap: '10060', provinceId: 1},
{id: 6222, value: 'pimonte', cap: '80050', provinceId: 76},
{id: 1389, value: 'mornago', cap: '21020', provinceId: 10},
{id: 6373, value: 'vallata', cap: '83059', provinceId: 77},
{id: 7688, value: 'vizzini', cap: '95049', provinceId: 97},
{id: 1036, value: 'valenza', cap: '15048', provinceId: 6},
{id: 1533, value: 'mozzate', cap: '22076', provinceId: 11},
{id: 5643, value: 'sulmona', cap: '67039', provinceId: 68},
{id: 2279, value: 'sulzano', cap: '25058', provinceId: 15},
{id: 4171, value: 'ameglia', cap: '19031', provinceId: 37},
{id: 3579, value: 'pianiga', cap: '30030', provinceId: 27},
{id: 2011, value: 'pianico', cap: '24060', provinceId: 14},
{id: 4555, value: 'mulazzo', cap: '54026', provinceId: 47},
{id: 275, value: 'murello', cap: '12030', provinceId: 4},
{id: 4436, value: 'pianoro', cap: '40065', provinceId: 42},
{id: 294, value: 'pianfei', cap: '12080', provinceId: 4},
{id: 2673, value: 'suzzara', cap: '46029', provinceId: 18},
{id: 7544, value: 'valdina', cap: '98040', provinceId: 93},
{id: 5459, value: 'amaseno', cap: '03021', provinceId: 67},
{id: 7083, value: 'amaroni', cap: '88050', provinceId: 87},
{id: 6936, value: 'amantea', cap: '87032', provinceId: 86},
{id: 3861, value: 'taipana', cap: '33040', provinceId: 30},
{id: 1087, value: 'muzzano', cap: '13895', provinceId: 7},
{id: 6668, value: 'mottola', cap: '74017', provinceId: 81},
{id: 2755, value: 'sueglio', cap: '23835', provinceId: 19},
{id: 273, value: 'morozzo', cap: '12040', provinceId: 4},
{id: 6949, value: 'campana', cap: '87061', provinceId: 86},
{id: 2405, value: 'mortara', cap: '27036', provinceId: 16},
{id: 4870, value: 'piegaro', cap: '06066', provinceId: 57},
{id: 3811, value: 'moruzzo', cap: '33030', provinceId: 30},
{id: 5725, value: 'moscufo', cap: '65010', provinceId: 70},
{id: 3335, value: 'mossano', cap: '36024', provinceId: 24},
{id: 3859, value: 'stregna', cap: '33040', provinceId: 30},
{id: 3126, value: 'strembo', cap: '38080', provinceId: 22},
{id: 6249, value: 'striano', cap: '80040', provinceId: 76},
{id: 6076, value: 'succivo', cap: '81030', provinceId: 74},
{id: 3127, value: 'strigno', cap: '38059', provinceId: 22},
{id: 6854, value: 'picerno', cap: '85055', provinceId: 84},
{id: 713, value: 'stroppo', cap: '12020', provinceId: 4},
{id: 2056, value: 'strozza', cap: '24030', provinceId: 14},
{id: 1636, value: 'piateda', cap: '23020', provinceId: 12},
{id: 3751, value: 'ampezzo', cap: '33021', provinceId: 30},
{id: 6092, value: 'amorosi', cap: '82031', provinceId: 75},
{id: 5404, value: 'subiaco', cap: '00028', provinceId: 65},
{id: 2746, value: 'pescate', cap: '23855', provinceId: 19},
{id: 3292, value: 'cassola', cap: '36022', provinceId: 24},
{id: 6362, value: 'sperone', cap: '83020', provinceId: 77},
{id: 636, value: 'carisio', cap: '13040', provinceId: 2},
{id: 983, value: 'paderna', cap: '15050', provinceId: 6},
{id: 7219, value: 'pazzano', cap: '89040', provinceId: 88},
{id: 3766, value: 'carlino', cap: '33050', provinceId: 30},
{id: 7286, value: 'acquaro', cap: '89832', provinceId: 90},
{id: 6792, value: 'zollino', cap: '73010', provinceId: 83},
{id: 286, value: 'paesana', cap: '12034', provinceId: 4},
{id: 3966, value: 'zoppola', cap: '33080', provinceId: 33},
{id: 1131, value: 'zubiena', cap: '13888', provinceId: 7},
{id: 1316, value: 'carnago', cap: '21040', provinceId: 10},
{id: 2741, value: 'pagnona', cap: '23833', provinceId: 19},
{id: 5662, value: 'canzano', cap: '64020', provinceId: 69},
{id: 6951, value: 'cariati', cap: '87062', provinceId: 86},
{id: 6139, value: 'paupisi', cap: '82030', provinceId: 75},
{id: 2229, value: 'paitone', cap: '25080', provinceId: 15},
{id: 2692, value: 'carenno', cap: '23802', provinceId: 19},
{id: 6184, value: 'cardito', cap: '80024', provinceId: 76},
{id: 2493, value: 'zinasco', cap: '27030', provinceId: 16},
{id: 1707, value: 'carnate', cap: '20866', provinceId: 108},
{id: 7914, value: 'cardedu', cap: '08040', provinceId: 105},
{id: 6755, value: 'otranto', cap: '73028', provinceId: 83},
{id: 5570, value: 'carsoli', cap: '67061', provinceId: 68},
{id: 4597, value: 'agliana', cap: '51031', provinceId: 49},
{id: 2557, value: 'ostiano', cap: '26032', provinceId: 17},
{id: 721, value: 'trinita', cap: '12049', provinceId: 4},
{id: 4546, value: 'carrara', cap: '54033', provinceId: 47},
{id: 6549, value: 'carpino', cap: '71010', provinceId: 79},
{id: 6171, value: 'agerola', cap: '80051', provinceId: 76},
{id: 981, value: 'oviglio', cap: '15026', provinceId: 6},
{id: 4866, value: 'paciano', cap: '06060', provinceId: 57},
{id: 6603, value: 'adelfia', cap: '70010', provinceId: 80},
{id: 4297, value: 'zibello', cap: '43010', provinceId: 39},
{id: 4202, value: 'zignago', cap: '19020', provinceId: 37},
{id: 3266, value: 'zimella', cap: '37040', provinceId: 23},
{id: 1119, value: 'trivero', cap: '13835', provinceId: 7},
{id: 7452, value: 'caronia', cap: '98072', provinceId: 93},
{id: 7718, value: 'pachino', cap: '96018', provinceId: 99},
{id: 6952, value: 'carolei', cap: '87030', provinceId: 86},
{id: 7183, value: 'cardeto', cap: '89060', provinceId: 88},
{id: 3821, value: 'paularo', cap: '33027', provinceId: 30},
{id: 3618, value: 'cartura', cap: '35025', provinceId: 28},
{id: 3730, value: 'papozze', cap: '45010', provinceId: 29},
{id: 2560, value: 'pandino', cap: '26025', provinceId: 17},
{id: 5843, value: 'tufillo', cap: '66050', provinceId: 71},
{id: 1062, value: 'caprile', cap: '13864', provinceId: 7},
{id: 6538, value: 'accadia', cap: '71021', provinceId: 79},
{id: 7449, value: 'capizzi', cap: '98031', provinceId: 93},
{id: 6796, value: 'abriola', cap: '85010', provinceId: 84},
{id: 6138, value: 'paolisi', cap: '82011', provinceId: 75},
{id: 5299, value: 'turania', cap: '02020', provinceId: 64},
{id: 3076, value: 'panchia', cap: '38030', provinceId: 22},
{id: 4596, value: 'abetone', cap: '51021', provinceId: 49},
{id: 481, value: 'parella', cap: '10010', provinceId: 1},
{id: 7019, value: 'parenti', cap: '87040', provinceId: 86},
{id: 1834, value: 'turbigo', cap: '20029', provinceId: 13},
{id: 289, value: 'paroldo', cap: '12070', provinceId: 4},
{id: 7081, value: 'zumpano', cap: '87040', provinceId: 86},
{id: 4914, value: 'parrano', cap: '05010', provinceId: 58},
{id: 6379, value: 'zungoli', cap: '83030', provinceId: 77},
{id: 7909, value: 'ulassai', cap: '08040', provinceId: 105},
{id: 6615, value: 'capurso', cap: '70010', provinceId: 80},
{id: 2929, value: 'trodena', cap: '39040', provinceId: 21},
{id: 7412, value: 'palermo', cap: '', provinceId: 92},
{id: 3616, value: 'carceri', cap: '35040', provinceId: 28},
{id: 4052, value: 'carcare', cap: '17043', provinceId: 35},
{id: 6814, value: 'carbone', cap: '85030', provinceId: 84},
{id: 7780, value: 'pattada', cap: '07016', provinceId: 100},
{id: 5502, value: 'patrica', cap: '03010', provinceId: 67},
{id: 7667, value: 'paterno', cap: '95047', provinceId: 97},
{id: 6895, value: 'paterno', cap: '85050', provinceId: 84},
{id: 5500, value: 'paliano', cap: '03018', provinceId: 67},
{id: 3819, value: 'paluzza', cap: '33026', provinceId: 30},
{id: 7217, value: 'palizzi', cap: '89038', provinceId: 88},
{id: 4081, value: 'pallare', cap: '17043', provinceId: 35},
{id: 2743, value: 'pasturo', cap: '23818', provinceId: 19},
{id: 5546, value: 'acciano', cap: '67020', provinceId: 68},
{id: 4113, value: 'carasco', cap: '16042', provinceId: 36},
{id: 5807, value: 'palmoli', cap: '66050', provinceId: 71},
{id: 5501, value: 'pastena', cap: '03020', provinceId: 67},
{id: 2006, value: 'palosco', cap: '24050', provinceId: 14},
{id: 2603, value: 'trigolo', cap: '26018', provinceId: 17},
{id: 1908, value: 'carvico', cap: '24030', provinceId: 14},
{id: 5473, value: 'cassino', cap: '03043', provinceId: 67},
{id: 4115, value: 'casella', cap: '16015', provinceId: 36},
{id: 1911, value: 'casnigo', cap: '24020', provinceId: 14},
{id: 4298, value: 'albinea', cap: '42020', provinceId: 40},
{id: 2745, value: 'perledo', cap: '23828', provinceId: 19},
{id: 977, value: 'olivola', cap: '15030', provinceId: 6},
{id: 6008, value: 'caserta', cap: '81100', provinceId: 74},
{id: 7870, value: 'ollolai', cap: '08020', provinceId: 101},
{id: 4836, value: 'cannara', cap: '06033', provinceId: 57},
{id: 7356, value: 'trapani', cap: '91100', provinceId: 91},
{id: 1410, value: 'tradate', cap: '21049', provinceId: 10},
{id: 4965, value: 'pergola', cap: '61045', provinceId: 59},
{id: 4689, value: 'cascina', cap: '56021', provinceId: 52},
{id: 1670, value: 'albiate', cap: '20847', provinceId: 108},
{id: 2069, value: 'urgnano', cap: '24059', provinceId: 14},
{id: 1909, value: 'casazza', cap: '24060', provinceId: 14},
{id: 617, value: 'volvera', cap: '10040', provinceId: 1},
{id: 2947, value: 'albiano', cap: '38041', provinceId: 22},
{id: 7504, value: 'oliveri', cap: '98060', provinceId: 93},
{id: 7128, value: 'olivadi', cap: '88067', provinceId: 87},
{id: 4036, value: 'albenga', cap: '17031', provinceId: 35},
{id: 100, value: 'oleggio', cap: '28047', provinceId: 3},
{id: 5022, value: 'offagna', cap: '60020', provinceId: 60},
{id: 902, value: 'cassine', cap: '15016', provinceId: 6},
{id: 2219, value: 'offlaga', cap: '25020', provinceId: 15},
{id: 2735, value: 'oggiono', cap: '23848', provinceId: 19},
{id: 5533, value: 'torrice', cap: '03020', provinceId: 67},
{id: 7711, value: 'cassaro', cap: '96010', provinceId: 99},
{id: 4291, value: 'torrile', cap: '43056', provinceId: 39},
{id: 7907, value: 'tortoli', cap: '08048', provinceId: 105},
{id: 1427, value: 'albiolo', cap: '22070', provinceId: 11},
{id: 1033, value: 'tortona', cap: '15057', provinceId: 6},
{id: 7075, value: 'tortora', cap: '87020', provinceId: 86},
{id: 4869, value: 'perugia', cap: '', provinceId: 57},
{id: 6472, value: 'pertosa', cap: '84030', provinceId: 78},
{id: 759, value: 'casorzo', cap: '14032', provinceId: 5},
{id: 6191, value: 'casoria', cap: '80026', provinceId: 76},
{id: 488, value: 'perrero', cap: '10060', provinceId: 1},
{id: 2608, value: 'voltido', cap: '26030', provinceId: 17},
{id: 900, value: 'casasco', cap: '15050', provinceId: 6},
{id: 7874, value: 'oniferi', cap: '08020', provinceId: 101},
{id: 2986, value: 'carzano', cap: '38050', provinceId: 22},
{id: 4912, value: 'orvieto', cap: '05018', provinceId: 58},
{id: 2890, value: 'ortisei', cap: '39046', provinceId: 21},
{id: 304, value: 'airasca', cap: '10060', provinceId: 1},
{id: 4315, value: 'canossa', cap: '42026', provinceId: 40},
{id: 6371, value: 'trevico', cap: '83058', provinceId: 77},
{id: 1050, value: 'ailoche', cap: '13861', provinceId: 7},
{id: 7880, value: 'ortueri', cap: '08036', provinceId: 101},
{id: 3542, value: 'treviso', cap: '31100', provinceId: 26},
{id: 3686, value: 'tribano', cap: '35020', provinceId: 28},
{id: 4558, value: 'tresana', cap: '54012', provinceId: 47},
{id: 5275, value: 'orvinio', cap: '02035', provinceId: 64},
{id: 7774, value: 'oschiri', cap: '07027', provinceId: 104},
{id: 4080, value: 'osiglia', cap: '17010', provinceId: 35},
{id: 6786, value: 'tricase', cap: '73039', provinceId: 83},
{id: 9491, value: 'ahrntal', cap: '39030', provinceId: 21},
{id: 5563, value: 'cansano', cap: '67030', provinceId: 68},
{id: 3916, value: 'trieste', cap: '', provinceId: 32},
{id: 2492, value: 'zerbolo', cap: '27020', provinceId: 16},
{id: 6754, value: 'ortelle', cap: '73030', provinceId: 83},
{id: 167, value: 'canosio', cap: '12020', provinceId: 4},
{id: 2693, value: 'casargo', cap: '23831', provinceId: 19},
{id: 6259, value: 'trecase', cap: '80040', provinceId: 76},
{id: 3223, value: 'oppeano', cap: '37050', provinceId: 23},
{id: 5377, value: 'percile', cap: '00020', provinceId: 65},
{id: 5899, value: 'oratino', cap: '86010', provinceId: 72},
{id: 3166, value: 'zambana', cap: '38010', provinceId: 22},
{id: 3341, value: 'orgiano', cap: '36040', provinceId: 24},
{id: 5607, value: 'oricola', cap: '67063', provinceId: 68},
{id: 1392, value: 'origgio', cap: '21040', provinceId: 10},
{id: 7130, value: 'pentone', cap: '88050', provinceId: 87},
{id: 5803, value: 'orsogna', cap: '66036', provinceId: 71},
{id: 123, value: 'trecate', cap: '28069', provinceId: 3},
{id: 4035, value: 'alassio', cap: '17021', provinceId: 35},
{id: 3508, value: 'ormelle', cap: '31024', provinceId: 26},
{id: 5842, value: 'treglio', cap: '66030', provinceId: 71},
{id: 6710, value: 'cannole', cap: '73020', provinceId: 83},
{id: 2488, value: 'zeccone', cap: '27010', provinceId: 16},
{id: 4988, value: 'urbania', cap: '61049', provinceId: 59},
{id: 822, value: 'penango', cap: '14030', provinceId: 5},
{id: 497, value: 'piscina', cap: '10060', provinceId: 1},
{id: 6676, value: 'taranto', cap: '', provinceId: 81},
{id: 4368, value: 'montese', cap: '41055', provinceId: 41},
{id: 7552, value: 'aragona', cap: '92021', provinceId: 94},
{id: 6418, value: 'centola', cap: '84051', provinceId: 78},
{id: 9435, value: 'moelten', cap: '39010', provinceId: 21},
{id: 6227, value: 'portici', cap: '80055', provinceId: 76},
{id: 5990, value: 'arienzo', cap: '81021', provinceId: 74},
{id: 5479, value: 'ceprano', cap: '03024', provinceId: 67},
{id: 8031, value: 'ardauli', cap: '09081', provinceId: 103},
{id: 6558, value: 'chieuti', cap: '71010', provinceId: 79},
{id: 5750, value: 'arielli', cap: '66030', provinceId: 71},
{id: 3117, value: 'smarano', cap: '38010', provinceId: 22},
{id: 5310, value: 'ariccia', cap: '00040', provinceId: 65},
{id: 6811, value: 'calvera', cap: '85030', provinceId: 84},
{id: 4586, value: 'porcari', cap: '55016', provinceId: 48},
{id: 2850, value: 'chienes', cap: '39030', provinceId: 21},
{id: 6858, value: 'potenza', cap: '85100', provinceId: 84},
{id: 5578, value: 'cerchio', cap: '67044', provinceId: 68},
{id: 1604, value: 'cercino', cap: '23016', provinceId: 12},
{id: 6194, value: 'cercola', cap: '80040', provinceId: 76},
{id: 5949, value: 'chiauci', cap: '86097', provinceId: 73},
{id: 3286, value: 'calvene', cap: '36030', provinceId: 24},
{id: 4148, value: 'propata', cap: '16027', provinceId: 36},
{id: 6481, value: 'praiano', cap: '84010', provinceId: 78},
{id: 8030, value: 'arborea', cap: '09092', provinceId: 103},
{id: 117, value: 'sizzano', cap: '28070', provinceId: 3},
{id: 5422, value: 'aprilia', cap: '04011', provinceId: 66},
{id: 6229, value: 'procida', cap: '80079', provinceId: 76},
{id: 3834, value: 'pulfero', cap: '33046', provinceId: 30},
{id: 6961, value: 'cellara', cap: '87050', provinceId: 86},
{id: 5380, value: 'pomezia', cap: '00040', provinceId: 65},
{id: 4073, value: 'mioglia', cap: '17040', provinceId: 35},
{id: 3368, value: 'sossano', cap: '36040', provinceId: 24},
{id: 5187, value: 'celleno', cap: '01020', provinceId: 63},
{id: 5188, value: 'cellere', cap: '01010', provinceId: 63},
{id: 4041, value: 'arnasco', cap: '17032', provinceId: 35},
{id: 2592, value: 'sospiro', cap: '26048', provinceId: 17},
{id: 7723, value: 'sortino', cap: '96010', provinceId: 99},
{id: 6671, value: 'pulsano', cap: '74026', provinceId: 81},
{id: 6088, value: 'cellole', cap: '81030', provinceId: 74},
{id: 6628, value: 'modugno', cap: '70026', provinceId: 80},
{id: 5961, value: 'miranda', cap: '86080', provinceId: 73},
{id: 6800, value: 'armento', cap: '85010', provinceId: 84},
{id: 2754, value: 'sirtori', cap: '23896', provinceId: 19},
{id: 2020, value: 'pontida', cap: '24030', provinceId: 14},
{id: 1773, value: 'misinto', cap: '20826', provinceId: 108},
{id: 5178, value: 'calcata', cap: '01030', provinceId: 63},
{id: 9454, value: 'proveis', cap: '39040', provinceId: 21},
{id: 995, value: 'ponzone', cap: '15010', provinceId: 6},
{id: 2453, value: 'siziano', cap: '27010', provinceId: 16},
{id: 1570, value: 'sormano', cap: '22030', provinceId: 11},
{id: 7898, value: 'sorgono', cap: '08038', provinceId: 101},
{id: 5212, value: 'proceno', cap: '01020', provinceId: 63},
{id: 4119, value: 'cicagna', cap: '16044', provinceId: 36},
{id: 2747, value: 'premana', cap: '23834', provinceId: 19},
{id: 4025, value: 'soldano', cap: '18036', provinceId: 34},
{id: 6966, value: 'cetraro', cap: '87022', provinceId: 86},
{id: 1865, value: 'ardesio', cap: '24020', provinceId: 14},
{id: 776, value: 'cessole', cap: '14050', provinceId: 5},
{id: 754, value: 'calosso', cap: '14052', provinceId: 5},
{id: 725, value: 'valmala', cap: '12020', provinceId: 4},
{id: 5480, value: 'cervaro', cap: '03044', provinceId: 67},
{id: 9453, value: 'prettau', cap: '39030', provinceId: 21},
{id: 1592, value: 'ardenno', cap: '23011', provinceId: 12},
{id: 5815, value: 'pretoro', cap: '66010', provinceId: 71},
{id: 2146, value: 'cerveno', cap: '25040', provinceId: 15},
{id: 1225, value: 'chamois', cap: '11020', provinceId: 9},
{id: 194, value: 'cervere', cap: '12040', provinceId: 4},
{id: 3501, value: 'monfumo', cap: '31010', provinceId: 26},
{id: 1187, value: 'premeno', cap: '28818', provinceId: 8},
{id: 259, value: 'mondovi', cap: '12084', provinceId: 4},
{id: 6360, value: 'solofra', cap: '83029', provinceId: 77},
{id: 6014, value: 'cervino', cap: '81023', provinceId: 74},
{id: 6965, value: 'cerzeto', cap: '87040', provinceId: 86},
{id: 6275, value: 'calitri', cap: '83045', provinceId: 77},
{id: 2024, value: 'premolo', cap: '24020', provinceId: 14},
{id: 4386, value: 'soliera', cap: '41019', provinceId: 41},
{id: 3062, value: 'molveno', cap: '38018', provinceId: 22},
{id: 3325, value: 'molvena', cap: '36060', provinceId: 24},
{id: 3367, value: 'solagna', cap: '36020', provinceId: 24},
{id: 4286, value: 'soragna', cap: '43019', provinceId: 39},
{id: 621, value: 'arborio', cap: '13031', provinceId: 2},
{id: 10, value: 'prarolo', cap: '13012', provinceId: 2},
{id: 7086, value: 'argusto', cap: '88060', provinceId: 87},
{id: 4058, value: 'ceriale', cap: '17023', provinceId: 35},
{id: 3983, value: 'ceriana', cap: '18034', provinceId: 34},
{id: 4605, value: 'montale', cap: '51037', provinceId: 49},
{id: 2073, value: 'valleve', cap: '24010', provinceId: 14},
{id: 4992, value: 'arcevia', cap: '60011', provinceId: 60},
{id: 4693, value: 'chianni', cap: '56034', provinceId: 52},
{id: 4287, value: 'sorbolo', cap: '43058', provinceId: 39},
{id: 1111, value: 'soprana', cap: '13834', provinceId: 7},
{id: 2729, value: 'molteno', cap: '23847', provinceId: 19},
{id: 5450, value: 'sonnino', cap: '04010', provinceId: 66},
{id: 3295, value: 'chiampo', cap: '36072', provinceId: 24},
{id: 4450, value: 'argenta', cap: '44011', provinceId: 43},
{id: 1648, value: 'sondrio', cap: '23100', provinceId: 12},
{id: 665, value: 'priocca', cap: '12040', provinceId: 4},
{id: 1647, value: 'sondalo', cap: '23035', provinceId: 12},
{id: 2590, value: 'soncino', cap: '26029', provinceId: 17},
{id: 1432, value: 'argegno', cap: '22010', provinceId: 11},
{id: 5014, value: 'monsano', cap: '60030', provinceId: 60},
{id: 2023, value: 'predore', cap: '24060', provinceId: 14},
{id: 999, value: 'predosa', cap: '15077', provinceId: 6},
{id: 1552, value: 'pusiano', cap: '22030', provinceId: 11},
{id: 1097, value: 'portula', cap: '13833', provinceId: 7},
{id: 3216, value: 'minerbe', cap: '37046', provinceId: 23},
{id: 6273, value: 'cairano', cap: '83040', provinceId: 77},
{id: 6096, value: 'arpaise', cap: '82010', provinceId: 75},
{id: 3057, value: 'mezzano', cap: '38050', provinceId: 22},
{id: 1258, value: 'pollein', cap: '11020', provinceId: 9},
{id: 3056, value: 'mezzana', cap: '38020', provinceId: 22},
{id: 569, value: 'sparone', cap: '10080', provinceId: 1},
{id: 5227, value: 'viterbo', cap: '01100', provinceId: 63},
{id: 2649, value: 'piubega', cap: '46040', provinceId: 18},
{id: 4609, value: 'pistoia', cap: '51100', provinceId: 49},
{id: 7489, value: 'messina', cap: '', provinceId: 93},
{id: 7177, value: 'calanna', cap: '89050', provinceId: 88},
{id: 386, value: 'cintano', cap: '10080', provinceId: 1},
{id: 3369, value: 'sovizzo', cap: '36050', provinceId: 24},
{id: 7313, value: 'pizzoni', cap: '89834', provinceId: 90},
{id: 7782, value: 'ploaghe', cap: '07017', provinceId: 100},
{id: 1771, value: 'mezzago', cap: '20883', provinceId: 108},
{id: 7490, value: 'milazzo', cap: '98057', provinceId: 93},
{id: 3823, value: 'pocenia', cap: '33050', provinceId: 30},
{id: 2016, value: 'pognano', cap: '24040', provinceId: 14},
{id: 7445, value: 'antillo', cap: '98030', provinceId: 93},
{id: 5970, value: 'pizzone', cap: '86071', provinceId: 73},
{id: 7326, value: 'spadola', cap: '89822', provinceId: 90},
{id: 2419, value: 'pizzale', cap: '27050', provinceId: 16},
{id: 385, value: 'ciconio', cap: '10080', provinceId: 1},
{id: 5050, value: 'cingoli', cap: '62011', provinceId: 61},
{id: 95, value: 'miasino', cap: '28010', provinceId: 3},
{id: 2205, value: 'milzano', cap: '25020', provinceId: 15},
{id: 1312, value: 'cairate', cap: '21050', provinceId: 10},
{id: 2240, value: 'pisogne', cap: '25055', provinceId: 15},
{id: 4351, value: 'cavezzo', cap: '41032', provinceId: 41},
{id: 7026, value: 'plataci', cap: '87070', provinceId: 86},
{id: 1095, value: 'pollone', cap: '13814', provinceId: 7},
{id: 499, value: 'poirino', cap: '10046', provinceId: 1},
{id: 7389, value: 'ciminna', cap: '90023', provinceId: 92},
{id: 387, value: 'cinzano', cap: '10090', provinceId: 1},
{id: 6179, value: 'caivano', cap: '80023', provinceId: 76},
{id: 5617, value: 'pizzoli', cap: '67017', provinceId: 68},
{id: 5478, value: 'ceccano', cap: '03023', provinceId: 67},
{id: 11, value: 'quarona', cap: '13017', provinceId: 2},
{id: 2496, value: 'annicco', cap: '26021', provinceId: 17},
{id: 5995, value: 'caiazzo', cap: '81013', provinceId: 74},
{id: 119, value: 'sozzago', cap: '28060', provinceId: 3},
{id: 4271, value: 'mezzani', cap: '43055', provinceId: 39},
{id: 6477, value: 'pollica', cap: '84068', provinceId: 78},
{id: 7418, value: 'pollina', cap: '90010', provinceId: 92},
{id: 7536, value: 'sinagra', cap: '98069', provinceId: 93},
{id: 3273, value: 'arsiero', cap: '36011', provinceId: 24},
{id: 8098, value: 'zeddiani', cap: '09070', provinceId: 103},
{id: 6757, value: 'parabita', cap: '73052', provinceId: 83},
{id: 945, value: 'guazzora', cap: '15050', provinceId: 6},
{id: 1176, value: 'mergozzo', cap: '28802', provinceId: 8},
{id: 7212, value: 'molochio', cap: '89010', provinceId: 88},
{id: 7878, value: 'orotelli', cap: '08020', provinceId: 101},
{id: 7316, value: 'rombiolo', cap: '89841', provinceId: 90},
{id: 1806, value: 'roncello', cap: '20877', provinceId: 108},
{id: 4173, value: 'beverino', cap: '19020', provinceId: 37},
{id: 1080, value: 'massazza', cap: '13873', provinceId: 7},
{id: 1879, value: 'bianzano', cap: '24060', provinceId: 14},
{id: 6907, value: 'grottole', cap: '75010', provinceId: 85},
{id: 4079, value: 'ortovero', cap: '17037', provinceId: 35},
{id: 4045, value: 'boissano', cap: '17054', provinceId: 35},
{id: 4190, value: 'ortonovo', cap: '19034', provinceId: 37},
{id: 20, value: 'ronsecco', cap: '13036', provinceId: 2},
{id: 5440, value: 'priverno', cap: '04015', provinceId: 66},
{id: 447, value: 'massello', cap: '10060', provinceId: 1},
{id: 6131, value: 'molinara', cap: '82020', provinceId: 75},
{id: 6732, value: 'guagnano', cap: '73010', provinceId: 83},
{id: 1539, value: 'orsenigo', cap: '22030', provinceId: 11},
{id: 597, value: 'vestigne', cap: '10030', provinceId: 1},
{id: 511, value: 'quassolo', cap: '10010', provinceId: 1},
{id: 6664, value: 'massafra', cap: '74016', provinceId: 81},
{id: 1503, value: 'guanzate', cap: '22070', provinceId: 11},
{id: 5496, value: 'guarcino', cap: '03016', provinceId: 67},
{id: 8062, value: 'oristano', cap: '09170', provinceId: 103},
{id: 1963, value: 'ghisalba', cap: '24050', provinceId: 14},
{id: 2232, value: 'paspardo', cap: '25050', provinceId: 15},
{id: 8045, value: 'ghilarza', cap: '09074', provinceId: 103},
{id: 4391, value: 'argelato', cap: '40050', provinceId: 42},
{id: 2231, value: 'paratico', cap: '25030', provinceId: 15},
{id: 4612, value: 'quarrata', cap: '51039', provinceId: 49},
{id: 2891, value: 'parcines', cap: '39020', provinceId: 21},
{id: 6898, value: 'bernalda', cap: '75012', provinceId: 85},
{id: 3229, value: 'pressana', cap: '37040', provinceId: 23},
{id: 2719, value: 'introzzo', cap: '23835', provinceId: 19},
{id: 1504, value: 'inverigo', cap: '22044', provinceId: 11},
{id: 3758, value: 'bertiolo', cap: '33032', provinceId: 30},
{id: 1749, value: 'inveruno', cap: '20010', provinceId: 13},
{id: 6760, value: 'presicce', cap: '73054', provinceId: 83},
{id: 2025, value: 'presezzo', cap: '24030', provinceId: 14},
{id: 3833, value: 'prepotto', cap: '33040', provinceId: 30},
{id: 6842, value: 'maschito', cap: '85020', provinceId: 84},
{id: 6329, value: 'parolise', cap: '83050', provinceId: 77},
{id: 151, value: 'bernezzo', cap: '12010', provinceId: 4},
{id: 2112, value: 'berlingo', cap: '25030', provinceId: 15},
{id: 7825, value: 'bolotana', cap: '08011', provinceId: 101},
{id: 7781, value: 'perfugas', cap: '07034', provinceId: 100},
{id: 4044, value: 'bergeggi', cap: '17028', provinceId: 35},
{id: 7953, value: 'monastir', cap: '09023', provinceId: 102},
{id: 7273, value: 'mesoraca', cap: '88838', provinceId: 89},
{id: 7350, value: 'partanna', cap: '91028', provinceId: 91},
{id: 6650, value: 'avetrana', cap: '74020', provinceId: 81},
{id: 2556, value: 'olmeneta', cap: '26010', provinceId: 17},
{id: 808, value: 'moncalvo', cap: '14036', provinceId: 5},
{id: 8018, value: 'masainas', cap: '09010', provinceId: 107},
{id: 6932, value: 'albidona', cap: '87070', provinceId: 86},
{id: 1882, value: 'boltiere', cap: '24040', provinceId: 14},
{id: 7031, value: 'rogliano', cap: '87054', provinceId: 86},
{id: 2742, value: 'parlasco', cap: '23837', provinceId: 19},
{id: 6463, value: 'omignano', cap: '84060', provinceId: 78},
{id: 4104, value: 'arenzano', cap: '16011', provinceId: 36},
{id: 6268, value: 'avellino', cap: '83100', provinceId: 77},
{id: 2308, value: 'arena po', cap: '27040', provinceId: 16},
{id: 6296, value: 'gesualdo', cap: '83040', provinceId: 77},
{id: 4328, value: 'ramiseto', cap: '42030', provinceId: 40},
{id: 2834, value: 'avelengo', cap: '39010', provinceId: 21},
{id: 7876, value: 'orgosolo', cap: '08027', provinceId: 101},
{id: 2252, value: 'prevalle', cap: '25080', provinceId: 15},
{id: 7334, value: 'zambrone', cap: '89868', provinceId: 90},
{id: 1384, value: 'mercallo', cap: '21020', provinceId: 10},
{id: 5415, value: 'zagarolo', cap: '00039', provinceId: 65},
{id: 7160, value: 'zagarise', cap: '88050', provinceId: 87},
{id: 7672, value: 'randazzo', cap: '95036', provinceId: 97},
{id: 7948, value: 'iglesias', cap: '09016', provinceId: 107},
{id: 4251, value: 'albareto', cap: '43051', provinceId: 39},
{id: 6539, value: 'alberona', cap: '71031', provinceId: 79},
{id: 3646, value: 'mestrino', cap: '35035', provinceId: 28},
{id: 329, value: 'bollengo', cap: '10012', provinceId: 1},
{id: 2767, value: 'vestreno', cap: '23822', provinceId: 19},
{id: 1132, value: 'zumaglia', cap: '13848', provinceId: 7},
{id: 1164, value: 'germagno', cap: '28887', provinceId: 8},
{id: 5225, value: 'vetralla', cap: '01019', provinceId: 63},
{id: 2180, value: 'incudine', cap: '25040', provinceId: 15},
{id: 9437, value: 'welsberg', cap: '39035', provinceId: 21},
{id: 2251, value: 'prestine', cap: '25040', provinceId: 15},
{id: 9462, value: 'innichen', cap: '39038', provinceId: 21},
{id: 1169, value: 'intragna', cap: '28816', provinceId: 8},
{id: 2718, value: 'introbio', cap: '23815', provinceId: 19},
{id: 1175, value: 'massiola', cap: '28895', provinceId: 8},
{id: 4301, value: 'bibbiano', cap: '42021', provinceId: 40},
{id: 8050, value: 'masullas', cap: '09090', provinceId: 103},
{id: 7845, value: 'girasole', cap: '08040', provinceId: 105},
{id: 4233, value: 'pecorara', cap: '29010', provinceId: 38},
{id: 6271, value: 'bisaccia', cap: '83044', provinceId: 77},
{id: 226, value: 'gorzegno', cap: '12070', provinceId: 4},
{id: 5220, value: 'tuscania', cap: '01017', provinceId: 63},
{id: 4139, value: 'moconesi', cap: '16047', provinceId: 36},
{id: 2096, value: 'medolago', cap: '24030', provinceId: 14},
{id: 1767, value: 'mediglia', cap: '20060', provinceId: 13},
{id: 1135, value: 'arizzano', cap: '28811', provinceId: 8},
{id: 1498, value: 'gironico', cap: '22020', provinceId: 11},
{id: 314, value: 'arignano', cap: '10020', provinceId: 1},
{id: 5611, value: 'pacentro', cap: '67030', provinceId: 68},
{id: 4270, value: 'medesano', cap: '43014', provinceId: 39},
{id: 7398, value: 'giuliana', cap: '90030', provinceId: 92},
{id: 6337, value: 'quindici', cap: '83020', provinceId: 77},
{id: 3423, value: 'pedavena', cap: '32034', provinceId: 25},
{id: 4086, value: 'quiliano', cap: '17047', provinceId: 35},
{id: 4475, value: 'migliaro', cap: '44020', provinceId: 43},
{id: 2088, value: 'villongo', cap: '24060', provinceId: 14},
{id: 5229, value: 'accumoli', cap: '02011', provinceId: 64},
{id: 5610, value: 'ovindoli', cap: '67046', provinceId: 68},
{id: 6744, value: 'miggiano', cap: '73035', provinceId: 83},
{id: 2410, value: 'palestro', cap: '27030', provinceId: 16},
{id: 6203, value: 'gragnano', cap: '80054', provinceId: 76},
{id: 6170, value: 'afragola', cap: '80021', provinceId: 76},
{id: 4706, value: 'peccioli', cap: '56037', provinceId: 52},
{id: 6797, value: 'acerenza', cap: '85011', provinceId: 84},
{id: 4203, value: 'agazzano', cap: '29010', provinceId: 38},
{id: 3035, value: 'giustino', cap: '38086', provinceId: 22},
{id: 2376, value: 'godiasco', cap: '27052', provinceId: 16},
{id: 3387, value: 'zugliano', cap: '36030', provinceId: 24},
{id: 2865, value: 'glorenza', cap: '39020', provinceId: 21},
{id: 4371, value: 'palagano', cap: '41046', provinceId: 41},
{id: 2004, value: 'paladina', cap: '24030', provinceId: 14},
{id: 6705, value: 'arnesano', cap: '73010', provinceId: 83},
{id: 7113, value: 'gizzeria', cap: '88040', provinceId: 87},
{id: 7638, value: 'acireale', cap: '95024', provinceId: 97},
{id: 5435, value: 'minturno', cap: '04026', provinceId: 66},
{id: 482, value: 'pavarolo', cap: '10020', provinceId: 1},
{id: 876, value: 'bistagno', cap: '15012', provinceId: 6},
{id: 7918, value: 'assemini', cap: '09032', provinceId: 102},
{id: 3103, value: 'rovereto', cap: '38068', provinceId: 22},
{id: 1745, value: 'giussano', cap: '20833', provinceId: 108},
{id: 2375, value: 'giussago', cap: '27010', provinceId: 16},
{id: 8047, value: 'gonnosno', cap: '09090', provinceId: 103},
{id: 3816, value: 'pagnacco', cap: '33010', provinceId: 30},
{id: 6613, value: 'bitritto', cap: '70020', provinceId: 80},
{id: 5805, value: 'paglieta', cap: '66020', provinceId: 71},
{id: 7506, value: 'pagliara', cap: '98020', provinceId: 93},
{id: 6437, value: 'giungano', cap: '84050', provinceId: 78},
{id: 3547, value: 'villorba', cap: '31020', provinceId: 26},
{id: 667, value: 'prunetto', cap: '12077', provinceId: 4},
{id: 7917, value: 'armungia', cap: '09040', provinceId: 102},
{id: 2571, value: 'quintano', cap: '26017', provinceId: 17},
{id: 979, value: 'ottiglio', cap: '15038', provinceId: 6},
{id: 3311, value: 'grancona', cap: '36040', provinceId: 24},
{id: 4867, value: 'panicale', cap: '06064', provinceId: 57},
{id: 6490, value: 'roscigno', cap: '84020', provinceId: 78},
{id: 3793, value: 'grimacco', cap: '33040', provinceId: 30},
{id: 6985, value: 'grimaldi', cap: '87034', provinceId: 86},
{id: 827, value: 'quaranti', cap: '14040', provinceId: 5},
{id: 7720, value: 'rosolini', cap: '96019', provinceId: 99},
{id: 3736, value: 'rosolina', cap: '45010', provinceId: 29},
{id: 6986, value: 'grisolia', cap: '87020', provinceId: 86},
{id: 729, value: 'verzuolo', cap: '12039', provinceId: 4},
{id: 130, value: 'acceglio', cap: '12021', provinceId: 4},
{id: 944, value: 'grondona', cap: '15060', provinceId: 6},
{id: 1668, value: 'aicurzio', cap: '20886', provinceId: 108},
{id: 4724, value: 'bibbiena', cap: '52011', provinceId: 53},
{id: 5735, value: 'rosciano', cap: '65020', provinceId: 70},
{id: 3206, value: 'grezzana', cap: '37023', provinceId: 23},
{id: 4427, value: 'minerbio', cap: '40061', provinceId: 42},
{id: 1144, value: 'bognanco', cap: '28842', provinceId: 8},
{id: 1621, value: 'grosotto', cap: '23034', provinceId: 12},
{id: 4806, value: 'grosseto', cap: '58100', provinceId: 55},
{id: 1100, value: 'quaregna', cap: '13854', provinceId: 7},
{id: 1686, value: 'biassono', cap: '20853', provinceId: 108},
{id: 5062, value: 'matelica', cap: '62024', provinceId: 61},
{id: 136, value: 'arguello', cap: '12050', provinceId: 4},
{id: 1595, value: 'bianzone', cap: '23030', provinceId: 12},
{id: 6630, value: 'molfetta', cap: '70056', provinceId: 80},
{id: 5445, value: 'sabaudia', cap: '04016', provinceId: 66},
{id: 6381, value: 'agropoli', cap: '84043', provinceId: 78},
{id: 1747, value: 'grezzago', cap: '20056', provinceId: 13},
{id: 4913, value: 'otricoli', cap: '05030', provinceId: 58},
{id: 2961, value: 'bocenago', cap: '38080', provinceId: 22},
{id: 1177, value: 'miazzina', cap: '28817', provinceId: 8},
{id: 1499, value: 'grandate', cap: '22070', provinceId: 11},
{id: 6894, value: 'ginestra', cap: '85020', provinceId: 84},
{id: 1525, value: 'menaggio', cap: '22017', provinceId: 11},
{id: 1364, value: 'grantola', cap: '21030', provinceId: 10},
{id: 6906, value: 'grassano', cap: '75014', provinceId: 85},
{id: 7400, value: 'gratteri', cap: '90010', provinceId: 92},
{id: 1629, value: 'menarola', cap: '23020', provinceId: 12},
{id: 2646, value: 'ostiglia', cap: '46035', provinceId: 18},
{id: 4682, value: 'bientina', cap: '56031', provinceId: 52},
{id: 5441, value: 'prossedi', cap: '04010', provinceId: 66},
{id: 5063, value: 'mogliano', cap: '62010', provinceId: 61},
{id: 6230, value: 'qualiano', cap: '80019', provinceId: 76},
{id: 1540, value: 'ossuccio', cap: '22010', provinceId: 11},
{id: 7787, value: 'aglientu', cap: '07020', provinceId: 104},
{id: 5151, value: 'palmiano', cap: '63092', provinceId: 62},
{id: 2942, value: 'vipiteno', cap: '39049', provinceId: 21},
{id: 8099, value: 'zerfaliu', cap: '09070', provinceId: 103},
{id: 1634, value: 'pedesina', cap: '23010', provinceId: 12},
{id: 2677, value: 'virgilio', cap: '46030', provinceId: 18},
{id: 2009, value: 'pedrengo', cap: '24066', provinceId: 14},
{id: 2100, value: 'agnosine', cap: '25071', provinceId: 15},
{id: 3808, value: 'moimacco', cap: '33040', provinceId: 30},
{id: 4925, value: 'auditore', cap: '61020', provinceId: 59},
{id: 1984, value: 'mezzoldo', cap: '24010', provinceId: 14},
{id: 1255, value: 'ollomont', cap: '11010', provinceId: 9},
{id: 4720, value: 'volterra', cap: '56048', provinceId: 52},
{id: 5551, value: 'avezzano', cap: '67051', provinceId: 68},
{id: 5211, value: 'piansano', cap: '01010', provinceId: 63},
{id: 3343, value: 'pianezze', cap: '36060', provinceId: 24},
{id: 5230, value: 'amatrice', cap: '02012', provinceId: 64},
{id: 4074, value: 'murialdo', cap: '17013', provinceId: 35},
{id: 7480, value: 'librizzi', cap: '98064', provinceId: 93},
{id: 7954, value: 'muravera', cap: '09043', provinceId: 102},
{id: 1862, value: 'ambivere', cap: '24030', provinceId: 14},
{id: 4709, value: 'ponsacco', cap: '56038', provinceId: 52},
{id: 7921, value: 'barumini', cap: '09021', provinceId: 106},
{id: 6094, value: 'apollosa', cap: '82030', provinceId: 75},
{id: 4924, value: 'apecchio', cap: '61042', provinceId: 59},
{id: 6128, value: 'limatola', cap: '82030', provinceId: 75},
{id: 2243, value: 'pompiano', cap: '25030', provinceId: 15},
{id: 3086, value: 'pomarolo', cap: '38060', provinceId: 22},
{id: 1757, value: 'limbiate', cap: '20812', provinceId: 108},
{id: 4188, value: 'maissana', cap: '19010', provinceId: 37},
{id: 2723, value: 'malgrate', cap: '23864', provinceId: 19},
{id: 1004, value: 'rivarone', cap: '15040', provinceId: 6},
{id: 6917, value: 'pomarico', cap: '75016', provinceId: 85},
{id: 1635, value: 'piantedo', cap: '23010', provinceId: 12},
{id: 5882, value: 'limosano', cap: '86022', provinceId: 72},
{id: 2384, value: 'linarolo', cap: '27010', provinceId: 16},
{id: 5814, value: 'pollutri', cap: '66020', provinceId: 71},
{id: 6608, value: 'barletta', cap: '76121', provinceId: 110},
{id: 7305, value: 'maierato', cap: '89843', provinceId: 90},
{id: 3841, value: 'resiutta', cap: '33010', provinceId: 30},
{id: 6172, value: 'anacapri', cap: '80071', provinceId: 76},
{id: 2015, value: 'piazzolo', cap: '24010', provinceId: 14},
{id: 5731, value: 'picciano', cap: '65010', provinceId: 70},
{id: 445, value: 'maglione', cap: '10030', provinceId: 1},
{id: 4069, value: 'magliolo', cap: '17020', provinceId: 35},
{id: 1378, value: 'malgesso', cap: '21020', provinceId: 10},
{id: 6735, value: 'leverano', cap: '73045', provinceId: 83},
{id: 8008, value: 'villamar', cap: '09020', provinceId: 106},
{id: 4809, value: 'manciano', cap: '58014', provinceId: 55},
{id: 6659, value: 'leporano', cap: '74020', provinceId: 81},
{id: 1778, value: 'nerviano', cap: '20014', provinceId: 13},
{id: 6474, value: 'piaggine', cap: '84065', provinceId: 78},
{id: 3825, value: 'porpetto', cap: '33050', provinceId: 30},
{id: 7167, value: 'bagaladi', cap: '89060', provinceId: 88},
{id: 2200, value: 'manerbio', cap: '25025', provinceId: 15},
{id: 1550, value: 'porlezza', cap: '22018', provinceId: 11},
{id: 1679, value: 'basiglio', cap: '20080', provinceId: 13},
{id: 5098, value: 'altidona', cap: '63824', provinceId: 109},
{id: 6661, value: 'manduria', cap: '74024', provinceId: 81},
{id: 6097, value: 'baselice', cap: '82020', provinceId: 75},
{id: 7365, value: 'bagheria', cap: '90011', provinceId: 92},
{id: 97, value: 'nebbiuno', cap: '28010', provinceId: 3},
{id: 5658, value: 'basciano', cap: '64030', provinceId: 69},
{id: 7860, value: 'mamoiada', cap: '08024', provinceId: 101},
{id: 3824, value: 'pontebba', cap: '33016', provinceId: 30},
{id: 1129, value: 'viverone', cap: '13886', provinceId: 7},
{id: 491, value: 'pianezza', cap: '10044', provinceId: 1},
{id: 137, value: 'bagnasco', cap: '12071', provinceId: 4},
{id: 2565, value: 'pianengo', cap: '26010', provinceId: 17},
{id: 6256, value: 'visciano', cap: '80030', provinceId: 76},
{id: 5730, value: 'pianella', cap: '65019', provinceId: 70},
{id: 5099, value: 'amandola', cap: '63857', provinceId: 109},
{id: 3796, value: 'lestizza', cap: '33050', provinceId: 30},
{id: 3457, value: 'altivole', cap: '31030', provinceId: 26},
{id: 5438, value: 'pontinia', cap: '04014', provinceId: 66},
{id: 8055, value: 'narbolia', cap: '09070', provinceId: 103},
{id: 2310, value: 'bagnaria', cap: '27050', provinceId: 16},
{id: 7485, value: 'malvagna', cap: '98030', provinceId: 93},
{id: 3969, value: 'apricale', cap: '18035', provinceId: 34},
{id: 6541, value: 'apricena', cap: '71011', provinceId: 79},
{id: 5079, value: 'pollenza', cap: '62010', provinceId: 61},
{id: 5655, value: 'ancarano', cap: '64010', provinceId: 69},
{id: 6605, value: 'altamura', cap: '70022', provinceId: 80},
{id: 4971, value: 'piobbico', cap: '61046', provinceId: 59},
{id: 4507, value: 'longiano', cap: '47020', provinceId: 45},
{id: 2836, value: 'barbiano', cap: '39040', provinceId: 21},
{id: 3638, value: 'loreggia', cap: '35010', provinceId: 28},
{id: 1376, value: 'luvinate', cap: '21020', provinceId: 10},
{id: 1170, value: 'loreglia', cap: '28893', provinceId: 8},
{id: 443, value: 'lusiglie', cap: '10080', provinceId: 1},
{id: 493, value: 'pinerolo', cap: '10064', provinceId: 1},
{id: 3842, value: 'rigolato', cap: '33020', provinceId: 30},
{id: 2682, value: 'ballabio', cap: '23811', provinceId: 19},
{id: 3799, value: 'lusevera', cap: '33010', provinceId: 30},
{id: 4721, value: 'anghiari', cap: '52031', provinceId: 53},
{id: 4812, value: 'montieri', cap: '58026', provinceId: 55},
{id: 498, value: 'piverone', cap: '10010', provinceId: 1},
{id: 7856, value: 'lotzorai', cap: '08040', provinceId: 105},
{id: 670, value: 'rifreddo', cap: '12030', provinceId: 4},
{id: 7963, value: 'pimentel', cap: '09020', provinceId: 102},
{id: 4673, value: 'piombino', cap: '57025', provinceId: 51},
{id: 4610, value: 'piteglio', cap: '51020', provinceId: 49},
{id: 5201, value: 'lubriano', cap: '01020', provinceId: 63},
{id: 4511, value: 'montiano', cap: '47020', provinceId: 45},
{id: 323, value: 'barbania', cap: '10070', provinceId: 1},
{id: 6032, value: 'lusciano', cap: '81030', provinceId: 74},
{id: 4236, value: 'piozzano', cap: '29010', provinceId: 38},
{id: 313, value: 'angrogna', cap: '10060', provinceId: 1},
{id: 440, value: 'lugnacco', cap: '10080', provinceId: 1},
{id: 4536, value: 'riccione', cap: '47838', provinceId: 46},
{id: 8034, value: 'baradili', cap: '09090', provinceId: 103},
{id: 6167, value: 'vitulano', cap: '82038', provinceId: 75},
{id: 1848, value: 'vittuone', cap: '20010', provinceId: 13},
{id: 6915, value: 'pisticci', cap: '75015', provinceId: 85},
{id: 2190, value: 'longhena', cap: '25030', provinceId: 15},
{id: 2831, value: 'andriano', cap: '39010', provinceId: 21},
{id: 5545, value: 'viticuso', cap: '03040', provinceId: 67},
{id: 311, value: 'andezeno', cap: '10020', provinceId: 1},
{id: 4374, value: 'polinago', cap: '41040', provinceId: 41},
{id: 6916, value: 'policoro', cap: '75025', provinceId: 85},
{id: 7614, value: 'villalba', cap: '93010', provinceId: 95},
{id: 971, value: 'morsasco', cap: '15010', provinceId: 6},
{id: 6317, value: 'montella', cap: '83048', provinceId: 77},
{id: 4106, value: 'bargagli', cap: '16021', provinceId: 36},
{id: 7117, value: 'magisano', cap: '88050', provinceId: 87},
{id: 1988, value: 'montello', cap: '24060', provinceId: 14},
{id: 2388, value: 'magherno', cap: '27010', provinceId: 16},
{id: 89, value: 'maggiora', cap: '28014', provinceId: 3},
{id: 799, value: 'loazzolo', cap: '14051', provinceId: 5},
{id: 2241, value: 'polaveno', cap: '25060', provinceId: 15},
{id: 7010, value: 'mormanno', cap: '87026', provinceId: 86},
{id: 1676, value: 'bareggio', cap: '20010', provinceId: 13},
{id: 1292, value: 'bardello', cap: '21020', provinceId: 10},
{id: 7134, value: 'platania', cap: '88040', provinceId: 87},
{id: 5061, value: 'macerata', cap: '62100', provinceId: 61},
{id: 1387, value: 'monvalle', cap: '21020', provinceId: 10},
{id: 3594, value: 'vigonovo', cap: '30030', provinceId: 27},
{id: 1377, value: 'maccagno', cap: '21010', provinceId: 10},
{id: 7704, value: 'vittoria', cap: '97019', provinceId: 98},
{id: 2832, value: 'anterivo', cap: '39040', provinceId: 21},
{id: 6263, value: 'andretta', cap: '83040', provinceId: 77},
{id: 1632, value: 'morbegno', cap: '23017', provinceId: 12},
{id: 2107, value: 'bagolino', cap: '25072', provinceId: 15},
{id: 969, value: 'morbello', cap: '15010', provinceId: 6},
{id: 1761, value: 'macherio', cap: '20846', provinceId: 108},
{id: 2194, value: 'maclodio', cap: '25030', provinceId: 15},
{id: 4230, value: 'morfasso', cap: '29020', provinceId: 38},
{id: 1622, value: 'madesimo', cap: '23024', provinceId: 12},
{id: 5368, value: 'moricone', cap: '00010', provinceId: 65},
{id: 5261, value: 'leonessa', cap: '02016', provinceId: 64},
{id: 5793, value: 'lentella', cap: '66050', provinceId: 71},
{id: 8061, value: 'ollastra', cap: '09088', provinceId: 103},
{id: 6575, value: 'peschici', cap: '71010', provinceId: 79},
{id: 233, value: 'lagnasco', cap: '12030', provinceId: 4},
{id: 1529, value: 'monguzzo', cap: '22040', provinceId: 11},
{id: 1181, value: 'oggebbio', cap: '28824', provinceId: 8},
{id: 8049, value: 'marrubiu', cap: '09094', provinceId: 103},
{id: 7207, value: 'maropati', cap: '89020', provinceId: 88},
{id: 1208, value: 'villette', cap: '28856', provinceId: 8},
{id: 5313, value: 'bellegra', cap: '00030', provinceId: 65},
{id: 5659, value: 'bellante', cap: '64020', provinceId: 69},
{id: 4697, value: 'lajatico', cap: '56030', provinceId: 52},
{id: 960, value: 'monleale', cap: '15059', provinceId: 6},
{id: 1671, value: 'arconate', cap: '20020', provinceId: 13},
{id: 1436, value: 'bellagio', cap: '22021', provinceId: 11},
{id: 1507, value: 'lambrugo', cap: '22045', provinceId: 11},
{id: 675, value: 'roburent', cap: '12080', provinceId: 4},
{id: 2217, value: 'nuvolera', cap: '25080', provinceId: 15},
{id: 6537, value: 'bellizzi', cap: '84092', provinceId: 78},
{id: 616, value: 'volpiano', cap: '10088', provinceId: 1},
{id: 600, value: 'vidracco', cap: '10080', provinceId: 1},
{id: 6631, value: 'monopoli', cap: '70043', provinceId: 80},
{id: 5792, value: 'lanciano', cap: '66034', provinceId: 71},
{id: 7408, value: 'monreale', cap: '90046', provinceId: 92},
{id: 87, value: 'landiona', cap: '28064', provinceId: 3},
{id: 2382, value: 'langosco', cap: '27030', provinceId: 16},
{id: 7867, value: 'nurallao', cap: '08030', provinceId: 102},
{id: 6700, value: 'alessano', cap: '73031', provinceId: 83},
{id: 4602, value: 'marliana', cap: '51010', provinceId: 49},
{id: 2877, value: 'marlengo', cap: '39020', provinceId: 21},
{id: 5548, value: 'alfedena', cap: '67030', provinceId: 68},
{id: 3910, value: 'villesse', cap: '34070', provinceId: 31},
{id: 5120, value: 'lapedona', cap: '63823', provinceId: 109},
{id: 7807, value: 'viddalba', cap: '07030', provinceId: 100},
{id: 7202, value: 'laganadi', cap: '89050', provinceId: 88},
{id: 4601, value: 'larciano', cap: '51036', provinceId: 49},
{id: 4140, value: 'moneglia', cap: '16030', provinceId: 36},
{id: 9460, value: 'rodeneck', cap: '39037', provinceId: 21},
{id: 290, value: 'perletto', cap: '12070', provinceId: 4},
{id: 1876, value: 'berbenno', cap: '24030', provinceId: 14},
{id: 2183, value: 'isorella', cap: '25010', provinceId: 15},
{id: 4529, value: 'mondaino', cap: '47836', provinceId: 46},
{id: 1868, value: 'aviatico', cap: '24020', provinceId: 14},
{id: 426, value: 'issiglio', cap: '10080', provinceId: 1},
{id: 4950, value: 'mondavio', cap: '61040', provinceId: 59},
{id: 6663, value: 'maruggio', cap: '74020', provinceId: 81},
{id: 2737, value: 'olginate', cap: '23854', provinceId: 19},
{id: 6535, value: 'vibonati', cap: '84079', provinceId: 78},
{id: 3653, value: 'pernumia', cap: '35020', provinceId: 28},
{id: 4951, value: 'mondolfo', cap: '61037', provinceId: 59},
{id: 9463, value: 'jenesien', cap: '39050', provinceId: 21},
{id: 5, value: 'pertengo', cap: '13030', provinceId: 2},
{id: 2306, value: 'albonese', cap: '27020', provinceId: 16},
{id: 428, value: 'la cassa', cap: '10040', provinceId: 1},
{id: 5413, value: 'vicovaro', cap: '00029', provinceId: 65},
{id: 8022, value: 'piscinas', cap: '09010', provinceId: 107},
{id: 1681, value: 'bellusco', cap: '20882', provinceId: 108},
{id: 2944, value: 'la valle', cap: '39030', provinceId: 21},
{id: 1249, value: 'la salle', cap: '11015', provinceId: 9},
{id: 234, value: 'la morra', cap: '12064', provinceId: 4},
{id: 3, value: 'oldenico', cap: '13030', provinceId: 2},
{id: 7641, value: 'belpasso', cap: '95032', provinceId: 97},
{id: 5594, value: 'l aquila', cap: '67100', provinceId: 68},
{id: 7307, value: 'mongiana', cap: '89823', provinceId: 90},
{id: 1247, value: 'jovencan', cap: '11020', provinceId: 9},
{id: 2878, value: 'martello', cap: '39020', provinceId: 21},
{id: 489, value: 'pertusio', cap: '10080', provinceId: 1},
{id: 869, value: 'avolasca', cap: '15050', provinceId: 6},
{id: 7580, value: 'ravanusa', cap: '92029', provinceId: 94},
{id: 4376, value: 'ravarino', cap: '41017', provinceId: 41},
{id: 3752, value: 'aquileia', cap: '33051', provinceId: 30},
{id: 5355, value: 'manziana', cap: '00066', provinceId: 65},
{id: 505, value: 'pralormo', cap: '10040', provinceId: 1},
{id: 2184, value: 'lavenone', cap: '25074', provinceId: 15},
{id: 4472, value: 'voghiera', cap: '44019', provinceId: 43},
{id: 7124, value: 'montauro', cap: '88060', provinceId: 87},
{id: 8016, value: 'villasor', cap: '09034', provinceId: 102},
{id: 5726, value: 'nocciano', cap: '65010', provinceId: 70},
{id: 743, value: 'aramengo', cap: '14020', provinceId: 5},
{id: 6228, value: 'pozzuoli', cap: '80078', provinceId: 76},
{id: 672, value: 'roaschia', cap: '12010', provinceId: 4},
{id: 7627, value: 'nissoria', cap: '94010', provinceId: 96},
{id: 5972, value: 'pozzilli', cap: '86077', provinceId: 73},
{id: 2480, value: 'vigevano', cap: '27029', provinceId: 16},
{id: 1371, value: 'leggiuno', cap: '21038', provinceId: 10},
{id: 4234, value: 'piacenza', cap: '', provinceId: 38},
{id: 7700, value: 'pozzallo', cap: '97016', provinceId: 98},
{id: 6752, value: 'nociglia', cap: '73020', provinceId: 83},
{id: 4326, value: 'poviglio', cap: '42028', provinceId: 40},
{id: 7310, value: 'nicotera', cap: '89844', provinceId: 90},
{id: 6893, value: 'viggiano', cap: '85059', provinceId: 84},
{id: 1626, value: 'mantello', cap: '23016', provinceId: 12},
{id: 5423, value: 'bassiano', cap: '04010', provinceId: 66},
{id: 7087, value: 'badolato', cap: '88060', provinceId: 87},
{id: 7665, value: 'nicolosi', cap: '95030', provinceId: 97},
{id: 2734, value: 'nibionno', cap: '23895', provinceId: 19},
{id: 98, value: 'nibbiola', cap: '28070', provinceId: 3},
{id: 3517, value: 'possagno', cap: '31054', provinceId: 26},
{id: 4231, value: 'nibbiano', cap: '29010', provinceId: 38},
{id: 278, value: 'neviglie', cap: '12050', provinceId: 4},
{id: 6479, value: 'positano', cap: '84017', provinceId: 78},
{id: 9414, value: 'neumarkt', cap: '39044', provinceId: 21},
{id: 4329, value: 'reggiolo', cap: '42046', provinceId: 40},
{id: 4648, value: 'reggello', cap: '50066', provinceId: 50},
{id: 812, value: 'montafia', cap: '14014', provinceId: 5},
{id: 3794, value: 'latisana', cap: '33053', provinceId: 30},
{id: 3175, value: 'belfiore', cap: '37050', provinceId: 23},
{id: 145, value: 'beinette', cap: '12081', provinceId: 4},
{id: 1782, value: 'noviglio', cap: '20082', provinceId: 13},
{id: 326, value: 'beinasco', cap: '10092', provinceId: 1},
{id: 2749, value: 'robbiate', cap: '23899', provinceId: 19},
{id: 1875, value: 'bedulita', cap: '24030', provinceId: 14},
{id: 4967, value: 'petriano', cap: '61020', provinceId: 59},
{id: 2882, value: 'montagna', cap: '39040', provinceId: 21},
{id: 4739, value: 'laterina', cap: '52020', provinceId: 53},
{id: 5074, value: 'petriolo', cap: '62014', provinceId: 61},
{id: 3089, value: 'predazzo', cap: '38037', provinceId: 22},
{id: 471, value: 'novalesa', cap: '10050', provinceId: 1},
{id: 3064, value: 'montagne', cap: '38070', provinceId: 22},
{id: 3071, value: 'novaledo', cap: '38050', provinceId: 22},
{id: 7131, value: 'petrizzi', cap: '88060', provinceId: 87},
{id: 3069, value: 'nogaredo', cap: '38060', provinceId: 22},
{id: 469, value: 'nomaglio', cap: '10010', provinceId: 1},
{id: 1098, value: 'pralungo', cap: '13899', provinceId: 7},
{id: 7508, value: 'pettineo', cap: '98070', provinceId: 93},
{id: 3044, value: 'lavarone', cap: '38046', provinceId: 22},
{id: 506, value: 'pramollo', cap: '10065', provinceId: 1},
{id: 4891, value: 'allerona', cap: '05011', provinceId: 58},
{id: 5082, value: 'recanati', cap: '62019', provinceId: 61},
{id: 2639, value: 'marcaria', cap: '46010', provinceId: 18},
{id: 1287, value: 'arcisate', cap: '21051', provinceId: 10},
{id: 2201, value: 'marcheno', cap: '25060', provinceId: 15},
{id: 8057, value: 'norbello', cap: '09070', provinceId: 103},
{id: 4671, value: 'marciana', cap: '57030', provinceId: 51},
{id: 431, value: 'lauriano', cap: '10020', provinceId: 1},
{id: 6050, value: 'pratella', cap: '81010', provinceId: 74},
{id: 2872, value: 'lauregno', cap: '39040', provinceId: 21},
{id: 4426, value: 'medicina', cap: '40059', provinceId: 42},
{id: 392, value: 'collegno', cap: '10093', provinceId: 1},
{id: 7814, value: 'stintino', cap: '07040', provinceId: 100},
{id: 7327, value: 'spilinga', cap: '89864', provinceId: 90},
{id: 2943, value: 'velturno', cap: '39040', provinceId: 21},
{id: 1065, value: 'cavaglia', cap: '13881', provinceId: 7},
{id: 3280, value: 'breganze', cap: '36042', provinceId: 24},
{id: 4501, value: 'dovadola', cap: '47013', provinceId: 45},
{id: 1443, value: 'bregnano', cap: '22070', provinceId: 11},
{id: 2967, value: 'breguzzo', cap: '38081', provinceId: 22},
{id: 1890, value: 'brembate', cap: '24041', provinceId: 14},
{id: 2535, value: 'drizzona', cap: '26034', provinceId: 17},
{id: 5412, value: 'velletri', cap: '00049', provinceId: 65},
{id: 3281, value: 'brendola', cap: '36040', provinceId: 24},
{id: 2992, value: 'cavalese', cap: '38033', provinceId: 22},
{id: 4089, value: 'sassello', cap: '17046', provinceId: 35},
{id: 4680, value: 'sassetta', cap: '57020', provinceId: 51},
{id: 2839, value: 'brennero', cap: '39041', provinceId: 21},
{id: 2993, value: 'cavareno', cap: '38011', provinceId: 22},
{id: 3782, value: 'drenchia', cap: '33040', provinceId: 30},
{id: 9397, value: 'branzoll', cap: '39051', provinceId: 21},
{id: 6976, value: 'domanico', cap: '87030', provinceId: 86},
{id: 6232, value: 'ercolano', cap: '80056', provinceId: 76},
{id: 2503, value: 'camisano', cap: '26010', provinceId: 17},
{id: 5038, value: 'staffolo', cap: '60039', provinceId: 60},
{id: 6707, value: 'botrugno', cap: '73020', provinceId: 83},
{id: 7741, value: 'bottidda', cap: '07010', provinceId: 100},
{id: 4091, value: 'spotorno', cap: '17028', provinceId: 35},
{id: 6776, value: 'spongano', cap: '73038', provinceId: 83},
{id: 7173, value: 'bovalino', cap: '89034', provinceId: 88},
{id: 5741, value: 'spoltore', cap: '65010', provinceId: 70},
{id: 3304, value: 'dueville', cap: '36031', provinceId: 24},
{id: 9432, value: 'enneberg', cap: '39030', provinceId: 21},
{id: 3783, value: 'enemonzo', cap: '33020', provinceId: 30},
{id: 3180, value: 'bovolone', cap: '37051', provinceId: 23},
{id: 4790, value: 'sarteano', cap: '53047', provinceId: 54},
{id: 4211, value: 'caminata', cap: '29010', provinceId: 38},
{id: 2123, value: 'brandico', cap: '25030', provinceId: 15},
{id: 7186, value: 'caulonia', cap: '89041', provinceId: 88},
{id: 1469, value: 'cavargna', cap: '22010', provinceId: 11},
{id: 4382, value: 'sassuolo', cap: '41049', provinceId: 41},
{id: 1026, value: 'stazzano', cap: '15060', provinceId: 6},
{id: 1603, value: 'cedrasco', cap: '23010', provinceId: 12},
{id: 2077, value: 'vedeseta', cap: '24010', provinceId: 14},
{id: 3545, value: 'vedelago', cap: '31050', provinceId: 26},
{id: 1418, value: 'veddasca', cap: '21010', provinceId: 10},
{id: 6679, value: 'brindisi', cap: '72100', provinceId: 82},
{id: 1345, value: 'cuveglio', cap: '21030', provinceId: 10},
{id: 2144, value: 'cedegolo', cap: '25051', provinceId: 15},
{id: 4995, value: 'camerano', cap: '60021', provinceId: 60},
{id: 3688, value: 'veggiano', cap: '35030', provinceId: 28},
{id: 164, value: 'camerana', cap: '12072', provinceId: 4},
{id: 350, value: 'cambiano', cap: '10020', provinceId: 1},
{id: 5704, value: 'brittoli', cap: '65010', provinceId: 70},
{id: 7151, value: 'soverato', cap: '88068', provinceId: 87},
{id: 1702, value: 'cambiago', cap: '20040', provinceId: 13},
{id: 1113, value: 'sostegno', cap: '13868', provinceId: 7},
{id: 73, value: 'cureggio', cap: '28060', provinceId: 3},
{id: 6559, value: 'deliceto', cap: '71026', provinceId: 79},
{id: 2533, value: 'derovere', cap: '26040', provinceId: 17},
{id: 6400, value: 'camerota', cap: '84040', provinceId: 78},
{id: 2857, value: 'dobbiaco', cap: '39034', provinceId: 21},
{id: 5045, value: 'camerino', cap: '62032', provinceId: 61},
{id: 5775, value: 'dogliola', cap: '66050', provinceId: 71},
{id: 914, value: 'cavatore', cap: '15010', provinceId: 6},
{id: 210, value: 'dogliani', cap: '12063', provinceId: 4},
{id: 3182, value: 'brenzone', cap: '37010', provinceId: 23},
{id: 7143, value: 'satriano', cap: '88060', provinceId: 87},
{id: 6775, value: 'specchia', cap: '73040', provinceId: 83},
{id: 1483, value: 'dizzasco', cap: '22020', provinceId: 11},
{id: 2626, value: 'cavriana', cap: '46040', provinceId: 18},
{id: 2994, value: 'cavedago', cap: '38010', provinceId: 22},
{id: 2995, value: 'cavedine', cap: '38073', provinceId: 22},
{id: 4628, value: 'dicomano', cap: '50062', provinceId: 50},
{id: 6974, value: 'diamante', cap: '87023', provinceId: 86},
{id: 4314, value: 'cavriago', cap: '42025', provinceId: 40},
{id: 159, value: 'briaglia', cap: '12080', provinceId: 4},
{id: 7288, value: 'briatico', cap: '89817', provinceId: 90},
{id: 7155, value: 'staletti', cap: '88069', provinceId: 87},
{id: 4318, value: 'fabbrico', cap: '42042', provinceId: 40},
{id: 8101, value: 'curcuris', cap: '09090', provinceId: 103},
{id: 3376, value: 'valdagno', cap: '36078', provinceId: 24},
{id: 1578, value: 'valbrona', cap: '22039', provinceId: 11},
{id: 4987, value: 'tavullia', cap: '61010', provinceId: 59},
{id: 4986, value: 'tavoleto', cap: '61020', provinceId: 59},
{id: 5665, value: 'castelli', cap: '64041', provinceId: 69},
{id: 3129, value: 'tassullo', cap: '38010', provinceId: 22},
{id: 3865, value: 'tarvisio', cap: '33018', provinceId: 30},
{id: 3864, value: 'tarcento', cap: '33017', provinceId: 30},
{id: 9479, value: 'terenten', cap: '39030', provinceId: 21},
{id: 1204, value: 'verbania', cap: '', provinceId: 8},
{id: 3027, value: 'fierozzo', cap: '38050', provinceId: 22},
{id: 2933, value: 'valdaora', cap: '39030', provinceId: 21},
{id: 7538, value: 'taormina', cap: '98039', provinceId: 93},
{id: 2058, value: 'taleggio', cap: '24010', provinceId: 14},
{id: 3717, value: 'ficarolo', cap: '45036', provinceId: 29},
{id: 1120, value: 'valdengo', cap: '13855', provinceId: 7},
{id: 6525, value: 'teggiano', cap: '84039', provinceId: 78},
{id: 6644, value: 'terlizzi', cap: '70038', provinceId: 80},
{id: 722, value: 'valdieri', cap: '12010', provinceId: 4},
{id: 584, value: 'usseglio', cap: '10070', provinceId: 1},
{id: 6431, value: 'fisciano', cap: '84084', provinceId: 78},
{id: 1424, value: 'sangiano', cap: '21038', provinceId: 10},
{id: 7911, value: 'ussassai', cap: '08040', provinceId: 105},
{id: 847, value: 'tigliole', cap: '14016', provinceId: 5},
{id: 4164, value: 'tiglieto', cap: '16010', provinceId: 36},
{id: 6784, value: 'tiggiano', cap: '73030', provinceId: 83},
{id: 1032, value: 'ticineto', cap: '15040', provinceId: 6},
{id: 6078, value: 'teverola', cap: '81030', provinceId: 74},
{id: 1116, value: 'ternengo', cap: '13844', provinceId: 7},
{id: 4027, value: 'terzorio', cap: '18010', provinceId: 34},
{id: 7298, value: 'filogaso', cap: '89843', provinceId: 90},
{id: 3137, value: 'terzolas', cap: '38027', provinceId: 22},
{id: 6250, value: 'terzigno', cap: '80040', provinceId: 76},
{id: 7901, value: 'tertenia', cap: '08047', provinceId: 105},
{id: 3253, value: 'terrazzo', cap: '37040', provinceId: 23},
{id: 8089, value: 'terralba', cap: '09098', provinceId: 103},
{id: 1650, value: 'talamona', cap: '23018', provinceId: 12},
{id: 8088, value: 'tadasuni', cap: '09080', provinceId: 103},
{id: 5006, value: 'fabriano', cap: '60044', provinceId: 60},
{id: 5113, value: 'falerone', cap: '63837', provinceId: 109},
{id: 7062, value: 'saracena', cap: '87010', provinceId: 86},
{id: 6401, value: 'campagna', cap: '84022', provinceId: 78},
{id: 7252, value: 'stignano', cap: '89040', provinceId: 88},
{id: 2055, value: 'stezzano', cap: '24040', provinceId: 14},
{id: 1491, value: 'faloppio', cap: '22020', provinceId: 11},
{id: 1162, value: 'falmenta', cap: '28827', provinceId: 8},
{id: 9498, value: 'sterzing', cap: '39049', provinceId: 21},
{id: 8112, value: 'falciano', cap: '47891', provinceId: 111},
{id: 7533, value: 'saponara', cap: '98047', provinceId: 93},
{id: 6119, value: 'faicchio', cap: '82030', provinceId: 75},
{id: 1571, value: 'stazzona', cap: '22010', provinceId: 11},
{id: 6654, value: 'faggiano', cap: '74020', provinceId: 81},
{id: 4590, value: 'stazzema', cap: '55040', provinceId: 48},
{id: 7545, value: 'venetico', cap: '98040', provinceId: 93},
{id: 2323, value: 'bosnasco', cap: '27040', provinceId: 16},
{id: 4120, value: 'cogoleto', cap: '16016', provinceId: 36},
{id: 3152, value: 'vallarsa', cap: '38060', provinceId: 22},
{id: 6826, value: 'fardella', cap: '85034', provinceId: 84},
{id: 4222, value: 'ferriere', cap: '29024', provinceId: 38},
{id: 1660, value: 'valfurva', cap: '23030', provinceId: 12},
{id: 4681, value: 'suvereto', cap: '57028', provinceId: 51},
{id: 3539, value: 'susegana', cap: '31058', provinceId: 26},
{id: 2470, value: 'valeggio', cap: '27020', provinceId: 16},
{id: 6364, value: 'summonte', cap: '83010', provinceId: 77},
{id: 1407, value: 'sumirago', cap: '21040', provinceId: 10},
{id: 1827, value: 'sulbiate', cap: '20884', provinceId: 108},
{id: 2631, value: 'felonica', cap: '46022', provinceId: 18},
{id: 2322, value: 'bornasco', cap: '27010', provinceId: 16},
{id: 6591, value: 'stornara', cap: '71047', provinceId: 79},
{id: 1414, value: 'valganna', cap: '21039', provinceId: 10},
{id: 4757, value: 'subbiano', cap: '52010', provinceId: 53},
{id: 587, value: 'valgioie', cap: '10094', provinceId: 1},
{id: 5753, value: 'borrello', cap: '66040', provinceId: 71},
{id: 1055, value: 'borriana', cap: '13872', provinceId: 7},
{id: 723, value: 'valgrana', cap: '12020', provinceId: 4},
{id: 3361, value: 'santorso', cap: '36014', provinceId: 24},
{id: 7557, value: 'camastra', cap: '92020', provinceId: 94},
{id: 7282, value: 'scandale', cap: '88831', provinceId: 89},
{id: 1117, value: 'tollegno', cap: '13818', provinceId: 7},
{id: 6421, value: 'cicerale', cap: '84053', provinceId: 78},
{id: 5419, value: 'ciampino', cap: '00043', provinceId: 65},
{id: 7721, value: 'siracusa', cap: '96100', provinceId: 99},
{id: 6424, value: 'controne', cap: '84020', provinceId: 78},
{id: 2689, value: 'bulciago', cap: '23892', provinceId: 19},
{id: 6195, value: 'cicciano', cap: '80033', provinceId: 76},
{id: 6289, value: 'contrada', cap: '83020', provinceId: 77},
{id: 3626, value: 'conselve', cap: '35026', provinceId: 28},
{id: 7247, value: 'seminara', cap: '89028', provinceId: 88},
{id: 5252, value: 'configni', cap: '02040', provinceId: 64},
{id: 641, value: 'cigliano', cap: '13043', provinceId: 2},
{id: 2151, value: 'cimbergo', cap: '25050', provinceId: 15},
{id: 2158, value: 'concesio', cap: '25062', provinceId: 15},
{id: 6196, value: 'cimitile', cap: '80030', provinceId: 76},
{id: 3930, value: 'cimolais', cap: '33080', provinceId: 33},
{id: 2076, value: 'valtorta', cap: '24010', provinceId: 14},
{id: 384, value: 'chivasso', cap: '10034', provinceId: 1},
{id: 1581, value: 'valsolda', cap: '22010', provinceId: 11},
{id: 4261, value: 'compiano', cap: '43053', provinceId: 39},
{id: 1924, value: 'chiuduno', cap: '24060', provinceId: 14},
{id: 7393, value: 'corleone', cap: '90034', provinceId: 92},
{id: 5165, value: 'smerillo', cap: '63856', provinceId: 109},
{id: 7922, value: 'buggerru', cap: '09010', provinceId: 107},
{id: 5586, value: 'corfinio', cap: '67030', provinceId: 68},
{id: 2921, value: 'sluderno', cap: '39020', provinceId: 21},
{id: 3559, value: 'chioggia', cap: '30015', provinceId: 27},
{id: 6899, value: 'calciano', cap: '75010', provinceId: 85},
{id: 4598, value: 'buggiano', cap: '51011', provinceId: 49},
{id: 1730, value: 'corbetta', cap: '20011', provinceId: 13},
{id: 589, value: 'valperga', cap: '10087', provinceId: 1},
{id: 2974, value: 'calavino', cap: '38072', provinceId: 22},
{id: 2075, value: 'valsecca', cap: '24030', provinceId: 14},
{id: 4845, value: 'corciano', cap: '06073', provinceId: 57},
{id: 6925, value: 'valsinni', cap: '75029', provinceId: 85},
{id: 2276, value: 'sirmione', cap: '25019', provinceId: 15},
{id: 5559, value: 'calascio', cap: '67020', provinceId: 68},
{id: 778, value: 'cinaglio', cap: '14020', provinceId: 5},
{id: 7250, value: 'sinopoli', cap: '89020', provinceId: 88},
{id: 3535, value: 'segusino', cap: '31040', provinceId: 26},
{id: 2937, value: 'vandoies', cap: '39030', provinceId: 21},
{id: 642, value: 'civiasco', cap: '13010', provinceId: 2},
{id: 2920, value: 'silandro', cap: '39028', provinceId: 21},
{id: 7686, value: 'valverde', cap: '95028', provinceId: 97},
{id: 1449, value: 'cadorago', cap: '22071', provinceId: 11},
{id: 8081, value: 'siamanna', cap: '09080', provinceId: 103},
{id: 7988, value: 'serrenti', cap: '09027', provinceId: 106},
{id: 5527, value: 'sgurgola', cap: '03010', provinceId: 67},
{id: 1020, value: 'sezzadio', cap: '15079', provinceId: 6},
{id: 6422, value: 'colliano', cap: '84020', provinceId: 78},
{id: 389, value: 'claviere', cap: '10050', provinceId: 1},
{id: 4316, value: 'collagna', cap: '42037', provinceId: 40},
{id: 780, value: 'coazzolo', cap: '14054', provinceId: 5},
{id: 3625, value: 'codevigo', cap: '35020', provinceId: 28},
{id: 4454, value: 'codigoro', cap: '44021', provinceId: 43},
{id: 3775, value: 'codroipo', cap: '33033', provinceId: 30},
{id: 1068, value: 'coggiola', cap: '13863', provinceId: 7},
{id: 6398, value: 'caggiano', cap: '84030', provinceId: 78},
{id: 7929, value: 'collinas', cap: '09020', provinceId: 106},
{id: 343, value: 'buriasco', cap: '10060', provinceId: 1},
{id: 7986, value: 'serdiana', cap: '09040', provinceId: 102},
{id: 1195, value: 'seppiana', cap: '28843', provinceId: 8},
{id: 6016, value: 'ciorlano', cap: '81010', provinceId: 74},
{id: 3988, value: 'cipressa', cap: '18017', provinceId: 34},
{id: 7565, value: 'comitini', cap: '92020', provinceId: 94},
{id: 6114, value: 'circello', cap: '82020', provinceId: 75},
{id: 8117, value: 'cailungo', cap: '47893', provinceId: 111},
{id: 1473, value: 'cirimido', cap: '22070', provinceId: 11},
{id: 7323, value: 'simbario', cap: '89822', provinceId: 90},
{id: 7924, value: 'cagliari', cap: '', provinceId: 102},
{id: 1337, value: 'comabbio', cap: '21020', provinceId: 10},
{id: 1926, value: 'ciserano', cap: '24040', provinceId: 14},
{id: 6969, value: 'colosimi', cap: '87050', provinceId: 86},
{id: 1725, value: 'cisliano', cap: '20080', provinceId: 13},
{id: 1610, value: 'colorina', cap: '23010', provinceId: 12},
{id: 2587, value: 'sergnano', cap: '26010', provinceId: 17},
{id: 2473, value: 'valverde', cap: '27050', provinceId: 16},
{id: 2095, value: 'cornalba', cap: '24017', provinceId: 14},
{id: 4118, value: 'chiavari', cap: '16043', provinceId: 36},
{id: 2841, value: 'bronzolo', cap: '39051', provinceId: 21},
{id: 2706, value: 'cremella', cap: '23894', provinceId: 19},
{id: 4694, value: 'crespina', cap: '56040', provinceId: 52},
{id: 2627, value: 'ceresara', cap: '46040', provinceId: 18},
{id: 3365, value: 'schiavon', cap: '36060', provinceId: 24},
{id: 916, value: 'cereseto', cap: '15020', provinceId: 6},
{id: 2591, value: 'soresina', cap: '26015', provinceId: 17},
{id: 2348, value: 'cergnago', cap: '27020', provinceId: 16},
{id: 1306, value: 'brunello', cap: '21020', provinceId: 10},
{id: 7264, value: 'cerenzia', cap: '88833', provinceId: 89},
{id: 6963, value: 'cerisano', cap: '87044', provinceId: 86},
{id: 5773, value: 'crecchio', cap: '66014', provinceId: 71},
{id: 341, value: 'brusasco', cap: '10020', provinceId: 1},
{id: 3285, value: 'caltrano', cap: '36030', provinceId: 24},
{id: 7267, value: 'cotronei', cap: '88836', provinceId: 89},
{id: 1067, value: 'cerrione', cap: '13882', provinceId: 7},
{id: 1037, value: 'valmacca', cap: '15040', provinceId: 6},
{id: 3716, value: 'crespino', cap: '45030', provinceId: 29},
{id: 6198, value: 'crispano', cap: '80020', provinceId: 76},
{id: 4627, value: 'certaldo', cap: '50052', provinceId: 50},
{id: 6248, value: 'sorrento', cap: '80067', provinceId: 76},
{id: 8043, value: 'cuglieri', cap: '09073', provinceId: 103},
{id: 4565, value: 'camaiore', cap: '55041', provinceId: 48},
{id: 1736, value: 'cuggiono', cap: '20012', provinceId: 13},
{id: 398, value: 'cuceglio', cap: '10090', provinceId: 1},
{id: 1481, value: 'cucciago', cap: '22060', provinceId: 11},
{id: 4818, value: 'scansano', cap: '58054', provinceId: 55},
{id: 3010, value: 'croviana', cap: '38027', provinceId: 22},
{id: 190, value: 'centallo', cap: '12044', provinceId: 4},
{id: 6810, value: 'calvello', cap: '85010', provinceId: 84},
{id: 4819, value: 'scarlino', cap: '58020', provinceId: 55},
{id: 4177, value: 'brugnato', cap: '19020', provinceId: 37},
{id: 2050, value: 'sorisole', cap: '24010', provinceId: 14},
{id: 4117, value: 'ceranesi', cap: '16014', provinceId: 36},
{id: 3923, value: 'brugnera', cap: '33070', provinceId: 33},
{id: 2346, value: 'ceranova', cap: '27010', provinceId: 16},
{id: 206, value: 'crissolo', cap: '12030', provinceId: 4},
{id: 6948, value: 'caloveto', cap: '87060', provinceId: 86},
{id: 2822, value: 'somaglia', cap: '26867', provinceId: 20},
{id: 4820, value: 'seggiano', cap: '58038', provinceId: 55},
{id: 196, value: 'cherasco', cap: '12062', provinceId: 4},
{id: 3284, value: 'caldogno', cap: '36030', provinceId: 24},
{id: 3185, value: 'caldiero', cap: '37042', provinceId: 23},
{id: 7707, value: 'buccheri', cap: '96010', provinceId: 99},
{id: 1224, value: 'chambave', cap: '11023', provinceId: 9},
{id: 1568, value: 'solbiate', cap: '22070', provinceId: 11},
{id: 6100, value: 'bucciano', cap: '82010', provinceId: 75},
{id: 1817, value: 'sedriano', cap: '20018', provinceId: 13},
{id: 4493, value: 'solarolo', cap: '48027', provinceId: 44},
{id: 3113, value: 'scurelle', cap: '38050', provinceId: 22},
{id: 785, value: 'cortanze', cap: '14020', provinceId: 5},
{id: 6287, value: 'chianche', cap: '83010', provinceId: 77},
{id: 783, value: 'corsione', cap: '14020', provinceId: 5},
{id: 1527, value: 'mezzegra', cap: '22010', provinceId: 11},
{id: 7722, value: 'solarino', cap: '96010', provinceId: 99},
{id: 3472, value: 'chiarano', cap: '31040', provinceId: 26},
{id: 2074, value: 'valnegra', cap: '24010', provinceId: 14},
{id: 5642, value: 'secinaro', cap: '67029', provinceId: 68},
{id: 6771, value: 'scorrano', cap: '73020', provinceId: 83},
{id: 6163, value: 'solopaca', cap: '82036', provinceId: 75},
{id: 753, value: 'calliano', cap: '14031', provinceId: 5},
{id: 193, value: 'cervasca', cap: '12010', provinceId: 4},
{id: 7440, value: 'scillato', cap: '90020', provinceId: 92},
{id: 640, value: 'cervatto', cap: '13025', provinceId: 2},
{id: 1694, value: 'bubbiano', cap: '20080', provinceId: 13},
{id: 7752, value: 'cossoine', cap: '07010', provinceId: 100},
{id: 1155, value: 'cossogno', cap: '28801', provinceId: 8},
{id: 2978, value: 'calliano', cap: '38060', provinceId: 22},
{id: 4060, value: 'cosseria', cap: '17017', provinceId: 35},
{id: 3471, value: 'cessalto', cap: '31040', provinceId: 26},
{id: 30, value: 'scopello', cap: '13028', provinceId: 2},
{id: 5640, value: 'scoppito', cap: '67019', provinceId: 68},
{id: 6708, value: 'calimera', cap: '73021', provinceId: 83},
{id: 1579, value: 'valmorea', cap: '22070', provinceId: 11},
{id: 6286, value: 'cesinali', cap: '83020', provinceId: 77},
{id: 3679, value: 'solesino', cap: '35047', provinceId: 28},
{id: 7191, value: 'cosoleto', cap: '89050', provinceId: 88},
{id: 4356, value: 'fiumalbo', cap: '41022', provinceId: 41},
{id: 7295, value: 'fabrizia', cap: '89823', provinceId: 90},
{id: 3869, value: 'tolmezzo', cap: '33028', provinceId: 30},
{id: 1709, value: 'carugate', cap: '20061', provinceId: 13},
{id: 930, value: 'frascaro', cap: '15010', provinceId: 6},
{id: 1831, value: 'tribiano', cap: '20067', provinceId: 13},
{id: 5340, value: 'frascati', cap: '00044', provinceId: 65},
{id: 694, value: 'sampeyre', cap: '12020', provinceId: 4},
{id: 4166, value: 'tribogna', cap: '16030', provinceId: 36},
{id: 33, value: 'tricerro', cap: '13038', provinceId: 2},
{id: 1644, value: 'samolaco', cap: '23027', provinceId: 12},
{id: 221, value: 'frassino', cap: '12020', provinceId: 4},
{id: 7968, value: 'samatzai', cap: '09020', provinceId: 102},
{id: 698, value: 'sanfront', cap: '12030', provinceId: 4},
{id: 1401, value: 'samarate', cap: '21017', provinceId: 10},
{id: 7467, value: 'frazzano', cap: '98070', provinceId: 93},
{id: 7262, value: 'casabona', cap: '88822', provinceId: 89},
{id: 895, value: 'cartosio', cap: '15015', provinceId: 6},
{id: 1575, value: 'trezzone', cap: '22010', provinceId: 11},
{id: 6295, value: 'frigento', cap: '83040', provinceId: 77},
{id: 6023, value: 'frignano', cap: '81030', provinceId: 74},
{id: 3939, value: 'frisanco', cap: '33080', provinceId: 33},
{id: 4939, value: 'frontino', cap: '61021', provinceId: 59},
{id: 894, value: 'carrosio', cap: '15060', provinceId: 6},
{id: 4940, value: 'frontone', cap: '61040', provinceId: 59},
{id: 1281, value: 'verrayes', cap: '11020', provinceId: 9},
{id: 2477, value: 'verretto', cap: '27053', provinceId: 16},
{id: 1313, value: 'cantello', cap: '21050', provinceId: 10},
{id: 7372, value: 'borgetto', cap: '90042', provinceId: 92},
{id: 6984, value: 'fuscaldo', cap: '87024', provinceId: 86},
{id: 1708, value: 'carpiano', cap: '20080', provinceId: 13},
{id: 3375, value: 'trissino', cap: '36070', provinceId: 24},
{id: 8069, value: 'samugheo', cap: '09086', provinceId: 103},
{id: 2067, value: 'treviolo', cap: '24048', provinceId: 14},
{id: 3980, value: 'carpasio', cap: '18010', provinceId: 34},
{id: 6006, value: 'casaluce', cap: '81030', provinceId: 74},
{id: 728, value: 'vernante', cap: '12019', provinceId: 4},
{id: 4246, value: 'vernasca', cap: '29010', provinceId: 38},
{id: 1321, value: 'casciago', cap: '21020', provinceId: 10},
{id: 1163, value: 'formazza', cap: '28863', provinceId: 8},
{id: 5339, value: 'formello', cap: '00060', provinceId: 65},
{id: 1710, value: 'casarile', cap: '20080', provinceId: 13},
{id: 6714, value: 'casarano', cap: '73042', provinceId: 83},
{id: 5955, value: 'fornelli', cap: '86070', provinceId: 73},
{id: 6153, value: 'san lupo', cap: '82034', provinceId: 75},
{id: 7235, value: 'san luca', cap: '89030', provinceId: 88},
{id: 2861, value: 'fortezza', cap: '39045', provinceId: 21},
{id: 3962, value: 'travesio', cap: '33090', provinceId: 33},
{id: 1149, value: 'cannobio', cap: '28822', provinceId: 8},
{id: 3743, value: 'trecenta', cap: '45027', provinceId: 29},
{id: 7300, value: 'francica', cap: '89851', provinceId: 90},
{id: 4522, value: 'tredozio', cap: '47019', provinceId: 45},
{id: 4200, value: 'vernazza', cap: '19018', provinceId: 37},
{id: 3255, value: 'tregnago', cap: '37039', provinceId: 23},
{id: 5874, value: 'fossalto', cap: '86020', provinceId: 72},
{id: 3528, value: 'san fior', cap: '31020', provinceId: 26},
{id: 7042, value: 'san fili', cap: '87037', provinceId: 86},
{id: 6871, value: 'san fele', cap: '85020', provinceId: 84},
{id: 1574, value: 'tremezzo', cap: '22019', provinceId: 11},
{id: 62, value: 'casalino', cap: '28060', provinceId: 3},
{id: 2287, value: 'trenzano', cap: '25030', provinceId: 15},
{id: 7674, value: 'san cono', cap: '95040', provinceId: 97},
{id: 6785, value: 'trepuzzi', cap: '73019', provinceId: 83},
{id: 1657, value: 'tresivio', cap: '23020', provinceId: 12},
{id: 1034, value: 'treville', cap: '15030', provinceId: 6},
{id: 4931, value: 'carpegna', cap: '61021', provinceId: 59},
{id: 26, value: 'saluggia', cap: '13040', provinceId: 2},
{id: 7433, value: 'trappeto', cap: '90040', provinceId: 92},
{id: 4064, value: 'garlenda', cap: '17033', provinceId: 35},
{id: 358, value: 'caravino', cap: '10010', provinceId: 1},
{id: 1314, value: 'caravate', cap: '21032', provinceId: 10},
{id: 1200, value: 'trontano', cap: '28859', provinceId: 8},
{id: 1959, value: 'gandosso', cap: '24060', provinceId: 14},
{id: 6904, value: 'garaguso', cap: '75010', provinceId: 85},
{id: 938, value: 'garbagna', cap: '15050', provinceId: 6},
{id: 5105, value: 'carassai', cap: '63063', provinceId: 62},
{id: 3177, value: 'bonavigo', cap: '37040', provinceId: 23},
{id: 224, value: 'garessio', cap: '12075', provinceId: 4},
{id: 80, value: 'gargallo', cap: '28010', provinceId: 3},
{id: 9420, value: 'gargazon', cap: '39010', provinceId: 21},
{id: 2173, value: 'gargnano', cap: '25084', provinceId: 15},
{id: 2372, value: 'garlasco', cap: '27026', provinceId: 16},
{id: 169, value: 'caraglio', cap: '12023', provinceId: 4},
{id: 4798, value: 'capalbio', cap: '58011', provinceId: 55},
{id: 9405, value: 'tscherms', cap: '39010', provinceId: 21},
{id: 2135, value: 'capriolo', cap: '25031', provinceId: 15},
{id: 1355, value: 'gavirate', cap: '21026', provinceId: 10},
{id: 4344, value: 'bomporto', cap: '41030', provinceId: 41},
{id: 6919, value: 'salandra', cap: '75017', provinceId: 85},
{id: 4726, value: 'capolona', cap: '52010', provinceId: 53},
{id: 2983, value: 'capriana', cap: '38030', provinceId: 22},
{id: 1705, value: 'caponago', cap: '20867', provinceId: 108},
{id: 1150, value: 'caprezzo', cap: '28815', provinceId: 8},
{id: 168, value: 'caprauna', cap: '12070', provinceId: 4},
{id: 6277, value: 'caposele', cap: '83040', provinceId: 77},
{id: 2508, value: 'capralba', cap: '26010', provinceId: 17},
{id: 2633, value: 'gazzuolo', cap: '46010', provinceId: 18},
{id: 3909, value: 'turriaco', cap: '34070', provinceId: 31},
{id: 2467, value: 'tromello', cap: '27020', provinceId: 16},
{id: 7927, value: 'carbonia', cap: '09013', provinceId: 107},
{id: 1832, value: 'triuggio', cap: '20844', provinceId: 108},
{id: 6728, value: 'galatone', cap: '73044', provinceId: 83},
{id: 6651, value: 'carosino', cap: '74021', provinceId: 81},
{id: 1742, value: 'gaggiano', cap: '20083', provinceId: 13},
{id: 5931, value: 'trivento', cap: '86029', provinceId: 72},
{id: 4930, value: 'cantiano', cap: '61044', provinceId: 59},
{id: 7109, value: 'gagliato', cap: '88060', provinceId: 87},
{id: 5058, value: 'gagliole', cap: '62022', provinceId: 61},
{id: 6888, value: 'trivigno', cap: '85018', provinceId: 84},
{id: 3487, value: 'gaiarine', cap: '31018', provinceId: 26},
{id: 6712, value: 'carmiano', cap: '73041', provinceId: 83},
{id: 6945, value: 'bonifati', cap: '87020', provinceId: 86},
{id: 1459, value: 'carlazzo', cap: '22010', provinceId: 11},
{id: 2985, value: 'carisolo', cap: '38080', provinceId: 22},
{id: 6727, value: 'galatina', cap: '73013', provinceId: 83},
{id: 2714, value: 'galbiate', cap: '23851', provinceId: 19},
{id: 223, value: 'gambasca', cap: '12030', provinceId: 4},
{id: 6003, value: 'carinola', cap: '81030', provinceId: 74},
{id: 78, value: 'galliate', cap: '28066', provinceId: 3},
{id: 356, value: 'cantoira', cap: '10070', provinceId: 1},
{id: 6002, value: 'carinaro', cap: '81032', provinceId: 74},
{id: 1458, value: 'carimate', cap: '22060', provinceId: 11},
{id: 7747, value: 'cargeghe', cap: '07030', provinceId: 100},
{id: 7261, value: 'carfizzi', cap: '88817', provinceId: 89},
{id: 4417, value: 'galliera', cap: '40015', provinceId: 42},
{id: 5291, value: 'salisano', cap: '02040', provinceId: 64},
{id: 634, value: 'caresana', cap: '13010', provinceId: 2},
{id: 690, value: 'saliceto', cap: '12079', provinceId: 4},
{id: 7841, value: 'galtelli', cap: '08020', provinceId: 101},
{id: 937, value: 'gamalero', cap: '15010', provinceId: 6},
{id: 6404, value: 'capaccio', cap: '84047', provinceId: 78},
{id: 5647, value: 'trasacco', cap: '67059', provinceId: 68},
{id: 1726, value: 'cogliate', cap: '20815', provinceId: 108},
{id: 2331, value: 'canevino', cap: '27040', provinceId: 16},
{id: 6530, value: 'tramonti', cap: '84010', provinceId: 78},
{id: 4883, value: 'torgiano', cap: '06089', provinceId: 57},
{id: 3143, value: 'tonadico', cap: '38054', provinceId: 22},
{id: 3144, value: 'torcegno', cap: '38050', provinceId: 22},
{id: 4542, value: 'torriana', cap: '47825', provinceId: 46},
{id: 3029, value: 'folgaria', cap: '38064', provinceId: 22},
{id: 3787, value: 'flaibano', cap: '33030', provinceId: 30},
{id: 1118, value: 'torrazzo', cap: '13884', provinceId: 7},
{id: 8090, value: 'tramatza', cap: '09070', provinceId: 103},
{id: 353, value: 'candiolo', cap: '10060', provinceId: 1},
{id: 6505, value: 'san rufo', cap: '84030', provinceId: 78},
{id: 7979, value: 'san vito', cap: '09040', provinceId: 102},
{id: 38, value: 'vercelli', cap: '13100', provinceId: 2},
{id: 3870, value: 'torreano', cap: '33040', provinceId: 30},
{id: 3357, value: 'sandrigo', cap: '36066', provinceId: 24},
{id: 5698, value: 'tossicia', cap: '64049', provinceId: 69},
{id: 6765, value: 'sanarica', cap: '73030', provinceId: 83},
{id: 408, value: 'foglizzo', cap: '10090', provinceId: 1},
{id: 5240, value: 'casperia', cap: '02041', provinceId: 64},
{id: 2079, value: 'verdello', cap: '24049', provinceId: 14},
{id: 1842, value: 'vermezzo', cap: '20080', provinceId: 13},
{id: 7431, value: 'torretta', cap: '90040', provinceId: 92},
{id: 7754, value: 'florinas', cap: '07030', provinceId: 100},
{id: 9503, value: 'san siro', cap: '22010', provinceId: 11},
{id: 6370, value: 'torrioni', cap: '83010', provinceId: 77},
{id: 5562, value: 'canistro', cap: '67050', provinceId: 68},
{id: 3767, value: 'cassacco', cap: '33010', provinceId: 30},
{id: 5179, value: 'canepina', cap: '01030', provinceId: 63},
{id: 7798, value: 'torralba', cap: '07048', provinceId: 100},
{id: 7713, value: 'floridia', cap: '96014', provinceId: 99},
{id: 7463, value: 'floresta', cap: '98030', provinceId: 93},
{id: 3615, value: 'candiana', cap: '35020', provinceId: 28},
{id: 1421, value: 'vergiate', cap: '21029', provinceId: 10},
{id: 2340, value: 'casteggio', cap: '27045', provinceId: 16},
{id: 7407, value: 'misilmeri', cap: '90036', provinceId: 92},
{id: 633, value: 'carcoforo', cap: '13026', provinceId: 2},
{id: 3296, value: 'chiuppano', cap: '36010', provinceId: 24},
{id: 135, value: 'argentera', cap: '12010', provinceId: 4},
{id: 4580, value: 'molazzana', cap: '55020', provinceId: 48},
{id: 2071, value: 'valbrembo', cap: '24030', provinceId: 14},
{id: 378, value: 'chianocco', cap: '10050', provinceId: 1},
{id: 360, value: 'carignano', cap: '10041', provinceId: 1},
{id: 7957, value: 'nuraminis', cap: '09024', provinceId: 102},
{id: 4428, value: 'molinella', cap: '40062', provinceId: 42},
{id: 2003, value: 'pagazzano', cap: '24040', provinceId: 14},
{id: 891, value: 'carezzano', cap: '15051', provinceId: 6},
{id: 1605, value: 'chiavenna', cap: '23022', provinceId: 12},
{id: 5044, value: 'caldarola', cap: '62020', provinceId: 61},
{id: 6670, value: 'palagiano', cap: '74019', provinceId: 81},
{id: 7092, value: 'cardinale', cap: '88062', provinceId: 87},
{id: 4568, value: 'careggine', cap: '55030', provinceId: 48},
{id: 382, value: 'chiomonte', cap: '10050', provinceId: 1},
{id: 4276, value: 'palanzano', cap: '43025', provinceId: 39},
{id: 7493, value: 'mistretta', cap: '98073', provinceId: 93},
{id: 177, value: 'castellar', cap: '12030', provinceId: 4},
{id: 4685, value: 'calcinaia', cap: '56012', provinceId: 52},
{id: 1896, value: 'calcinate', cap: '24050', provinceId: 14},
{id: 7666, value: 'palagonia', cap: '95046', provinceId: 97},
{id: 310, value: 'alpignano', cap: '10091', provinceId: 1},
{id: 3612, value: 'campodoro', cap: '35010', provinceId: 28},
{id: 2005, value: 'palazzago', cap: '24030', provinceId: 14},
{id: 2727, value: 'missaglia', cap: '23873', provinceId: 19},
{id: 2129, value: 'calcinato', cap: '25011', provinceId: 15},
{id: 890, value: 'carentino', cap: '15026', provinceId: 6},
{id: 1580, value: 'val rezzo', cap: '22010', provinceId: 11},
{id: 3858, value: 'socchieve', cap: '33020', provinceId: 30},
{id: 1551, value: 'proserpio', cap: '22030', provinceId: 11},
{id: 2466, value: 'trivolzio', cap: '27020', provinceId: 16},
{id: 8052, value: 'mogorella', cap: '09080', provinceId: 103},
{id: 466, value: 'nichelino', cap: '10042', provinceId: 1},
{id: 7749, value: 'cheremule', cap: '07040', provinceId: 100},
{id: 364, value: 'caselette', cap: '10040', provinceId: 1},
{id: 6782, value: 'taurisano', cap: '73056', provinceId: 83},
{id: 2307, value: 'albuzzano', cap: '27010', provinceId: 16},
{id: 4285, value: 'solignano', cap: '43040', provinceId: 39},
{id: 6680, value: 'carovigno', cap: '72012', provinceId: 82},
{id: 5941, value: 'carovilli', cap: '86083', provinceId: 73},
{id: 6285, value: 'cervinara', cap: '83012', provinceId: 77},
{id: 6964, value: 'cervicati', cap: '87010', provinceId: 86},
{id: 1049, value: 'voltaggio', cap: '15060', provinceId: 6},
{id: 3272, value: 'arcugnano', cap: '36057', provinceId: 24},
{id: 5330, value: 'cerveteri', cap: '00052', provinceId: 65},
{id: 718, value: 'torresina', cap: '12070', provinceId: 4},
{id: 3342, value: 'pedemonte', cap: '36040', provinceId: 24},
{id: 2350, value: 'cervesina', cap: '27050', provinceId: 16},
{id: 1084, value: 'mongrando', cap: '13888', provinceId: 7},
{id: 258, value: 'monchiero', cap: '12060', provinceId: 4},
{id: 4985, value: 'talamello', cap: '47867', provinceId: 46},
{id: 7357, value: 'valderice', cap: '91019', provinceId: 91},
{id: 2881, value: 'monguelfo', cap: '39035', provinceId: 21},
{id: 892, value: 'carpeneto', cap: '15071', provinceId: 6},
{id: 5318, value: 'canterano', cap: '00020', provinceId: 65},
{id: 7604, value: 'mussomeli', cap: '93014', provinceId: 95},
{id: 6822, value: 'cersosimo', cap: '85030', provinceId: 84},
{id: 2409, value: 'ottobiano', cap: '27030', provinceId: 16},
{id: 3912, value: 'monrupino', cap: '34016', provinceId: 32},
{id: 7611, value: 'sommatino', cap: '93019', provinceId: 95},
{id: 1035, value: 'trisobbio', cap: '15070', provinceId: 6},
{id: 2671, value: 'solferino', cap: '46040', provinceId: 18},
{id: 974, value: 'occimiano', cap: '15040', provinceId: 6},
{id: 6845, value: 'moliterno', cap: '85047', provinceId: 84},
{id: 4622, value: 'calenzano', cap: '50041', provinceId: 50},
{id: 1229, value: 'chatillon', cap: '11024', provinceId: 9},
{id: 3386, value: 'zovencedo', cap: '36020', provinceId: 24},
{id: 8086, value: 'solarussa', cap: '09077', provinceId: 103},
{id: 7925, value: 'calasetta', cap: '09011', provinceId: 107},
{id: 1528, value: 'moltrasio', cap: '22010', provinceId: 11},
{id: 1029, value: 'tassarolo', cap: '15060', provinceId: 6},
{id: 3271, value: 'altissimo', cap: '36070', provinceId: 24},
{id: 2748, value: 'primaluna', cap: '23819', provinceId: 19},
{id: 7997, value: 'soleminis', cap: '09040', provinceId: 102},
{id: 6166, value: 'torrecuso', cap: '82030', provinceId: 75},
{id: 2303, value: 'piancogno', cap: '25052', provinceId: 15},
{id: 957, value: 'momperone', cap: '15050', provinceId: 6},
{id: 5989, value: 'alvignano', cap: '81012', provinceId: 74},
{id: 5218, value: 'tarquinia', cap: '01016', provinceId: 63},
{id: 4258, value: 'calestano', cap: '43030', provinceId: 39},
{id: 7291, value: 'cessaniti', cap: '89816', provinceId: 90},
{id: 7093, value: 'carlopoli', cap: '88040', provinceId: 87},
{id: 2216, value: 'nuvolento', cap: '25080', provinceId: 15},
{id: 8106, value: 'acquaviva', cap: '47892', provinceId: 111},
{id: 7364, value: 'altofonte', cap: '90030', provinceId: 92},
{id: 3684, value: 'torreglia', cap: '35038', provinceId: 28},
{id: 2250, value: 'preseglie', cap: '25070', provinceId: 15},
{id: 6935, value: 'altomonte', cap: '87042', provinceId: 86},
{id: 7549, value: 'torrenova', cap: '98070', provinceId: 93},
{id: 4051, value: 'calizzano', cap: '17057', provinceId: 35},
{id: 4364, value: 'mirandola', cap: '41037', provinceId: 41},
{id: 7430, value: 'terrasini', cap: '90049', provinceId: 92},
{id: 3464, value: 'carbonera', cap: '31030', provinceId: 26},
{id: 1334, value: 'cittiglio', cap: '21033', provinceId: 10},
{id: 1847, value: 'vimodrone', cap: '20090', provinceId: 13},
{id: 3610, value: 'cadoneghe', cap: '35010', provinceId: 28},
{id: 2392, value: 'menconico', cap: '27050', provinceId: 16},
{id: 4001, value: 'mendatica', cap: '18025', provinceId: 34},
{id: 7005, value: 'mendicino', cap: '87040', provinceId: 86},
{id: 5685, value: 'notaresco', cap: '64024', provinceId: 69},
{id: 4399, value: 'camugnano', cap: '40032', provinceId: 42},
{id: 3274, value: 'arzignano', cap: '36071', provinceId: 24},
{id: 1789, value: 'parabiago', cap: '20015', provinceId: 13},
{id: 3003, value: 'civezzano', cap: '38045', provinceId: 22},
{id: 7189, value: 'cittanova', cap: '89022', provinceId: 88},
{id: 8100, value: 'siapiccia', cap: '09080', provinceId: 103},
{id: 7362, value: 'aliminusa', cap: '90020', provinceId: 92},
{id: 4369, value: 'nonantola', cap: '41015', provinceId: 41},
{id: 5999, value: 'capodrise', cap: '81020', provinceId: 74},
{id: 7731, value: 'arzachena', cap: '07021', provinceId: 104},
{id: 2008, value: 'parzanica', cap: '24060', provinceId: 14},
{id: 1902, value: 'capizzone', cap: '24030', provinceId: 14},
{id: 6181, value: 'camposano', cap: '80030', provinceId: 76},
{id: 3880, value: 'verzegnis', cap: '33020', provinceId: 30},
{id: 5153, value: 'petritoli', cap: '63848', provinceId: 109},
{id: 758, value: 'capriglio', cap: '14014', provinceId: 5},
{id: 6137, value: 'pannarano', cap: '82017', provinceId: 75},
{id: 7591, value: 'siculiana', cap: '92010', provinceId: 94},
{id: 2972, value: 'caderzone', cap: '38080', provinceId: 22},
{id: 1385, value: 'mesenzana', cap: '21030', provinceId: 10},
{id: 8025, value: 'abbasanta', cap: '09071', provinceId: 103},
{id: 2133, value: 'capovalle', cap: '25070', provinceId: 15},
{id: 6913, value: 'nova siri', cap: '75020', provinceId: 85},
{id: 7926, value: 'capoterra', cap: '09012', provinceId: 102},
{id: 2354, value: 'codevilla', cap: '27050', provinceId: 16},
{id: 781, value: 'cocconato', cap: '14023', provinceId: 5},
{id: 2153, value: 'coccaglio', cap: '25030', provinceId: 15},
{id: 2655, value: 'quistello', cap: '46026', provinceId: 18},
{id: 5091, value: 'tolentino', cap: '62029', provinceId: 61},
{id: 7256, value: 'varapodio', cap: '89010', provinceId: 88},
{id: 103, value: 'paruzzaro', cap: '28040', provinceId: 3},
{id: 200, value: 'clavesana', cap: '12060', provinceId: 4},
{id: 6129, value: 'melizzano', cap: '82030', provinceId: 75},
{id: 3932, value: 'clauzetto', cap: '33090', provinceId: 33},
{id: 5182, value: 'capranica', cap: '01012', provinceId: 63},
{id: 1768, value: 'melegnano', cap: '20077', provinceId: 13},
{id: 7311, value: 'parghelia', cap: '89861', provinceId: 90},
{id: 1846, value: 'vimercate', cap: '20871', provinceId: 108},
{id: 7209, value: 'melicucca', cap: '89020', provinceId: 88},
{id: 7210, value: 'melicucco', cap: '89020', provinceId: 88},
{id: 7359, value: 'petrosino', cap: '91020', provinceId: 91},
{id: 6742, value: 'melissano', cap: '73040', provinceId: 83},
{id: 5183, value: 'caprarola', cap: '01032', provinceId: 63},
{id: 7444, value: 'ali terme', cap: '98021', provinceId: 93},
{id: 8092, value: 'ula tirso', cap: '09080', provinceId: 103},
{id: 2411, value: 'pancarana', cap: '27050', provinceId: 16},
{id: 1457, value: 'carbonate', cap: '22070', provinceId: 11},
{id: 986, value: 'pasturana', cap: '15060', provinceId: 6},
{id: 1201, value: 'valstrona', cap: '28897', provinceId: 8},
{id: 129, value: 'vinzaglio', cap: '28060', provinceId: 3},
{id: 2352, value: 'cigognola', cap: '27040', provinceId: 16},
{id: 4476, value: 'alfonsine', cap: '48011', provinceId: 44},
{id: 6637, value: 'putignano', cap: '70017', provinceId: 80},
{id: 3225, value: 'pastrengo', cap: '37010', provinceId: 23},
{id: 4072, value: 'millesimo', cap: '17017', provinceId: 35},
{id: 2523, value: 'cicognolo', cap: '26030', provinceId: 17},
{id: 5331, value: 'ciciliano', cap: '00020', provinceId: 65},
{id: 3818, value: 'palmanova', cap: '33057', provinceId: 30},
{id: 1704, value: 'canegrate', cap: '20010', provinceId: 13},
{id: 6336, value: 'quadrelle', cap: '83020', provinceId: 77},
{id: 4686, value: 'capannoli', cap: '56033', provinceId: 52},
{id: 3379, value: 'valstagna', cap: '36020', provinceId: 24},
{id: 1030, value: 'terruggia', cap: '15030', provinceId: 6},
{id: 2026, value: 'pumenengo', cap: '24050', provinceId: 14},
{id: 7564, value: 'cianciana', cap: '92012', provinceId: 94},
{id: 741, value: 'albugnano', cap: '14022', provinceId: 5},
{id: 6359, value: 'sirignano', cap: '83020', provinceId: 77},
{id: 7129, value: 'palermiti', cap: '88050', provinceId: 87},
{id: 4769, value: 'chiusdino', cap: '53012', provinceId: 54},
{id: 5453, value: 'terracina', cap: '04019', provinceId: 66},
{id: 4465, value: 'mirabello', cap: '44043', provinceId: 43},
{id: 2353, value: 'cilavegna', cap: '27024', provinceId: 16},
{id: 4138, value: 'mignanego', cap: '16018', provinceId: 36},
{id: 5994, value: 'caianello', cap: '81059', provinceId: 74},
{id: 2395, value: 'mezzanino', cap: '27040', provinceId: 16},
{id: 288, value: 'pamparato', cap: '12087', provinceId: 4},
{id: 2233, value: 'passirano', cap: '25050', provinceId: 15},
{id: 453, value: 'meugliano', cap: '10080', provinceId: 1},
{id: 6896, value: 'accettura', cap: '75011', provinceId: 85},
{id: 7180, value: 'candidoni', cap: '89020', provinceId: 88},
{id: 6468, value: 'palomonte', cap: '84020', provinceId: 78},
{id: 4792, value: 'sinalunga', cap: '53048', provinceId: 54},
{id: 7897, value: 'siniscola', cap: '08029', provinceId: 101},
{id: 4137, value: 'mezzanego', cap: '16046', provinceId: 36},
{id: 4802, value: 'cinigiano', cap: '58044', provinceId: 55},
{id: 6526, value: 'torchiara', cap: '84076', provinceId: 78},
{id: 1536, value: 'novedrate', cap: '22060', provinceId: 11},
{id: 3473, value: 'cimadolmo', cap: '31010', provinceId: 26},
{id: 5808, value: 'palombaro', cap: '66010', provinceId: 71},
{id: 5305, value: 'allumiere', cap: '00051', provinceId: 65},
{id: 454, value: 'mezzenile', cap: '10070', provinceId: 1},
{id: 6041, value: 'pastorano', cap: '81050', provinceId: 74},
{id: 7406, value: 'mezzojuso', cap: '90030', provinceId: 92},
{id: 6547, value: 'carapelle', cap: '71041', provinceId: 79},
{id: 1083, value: 'miagliano', cap: '13816', provinceId: 7},
{id: 292, value: 'peveragno', cap: '12016', provinceId: 4},
{id: 4325, value: 'novellara', cap: '42017', provinceId: 40},
{id: 4889, value: 'valtopina', cap: '06030', provinceId: 57},
{id: 4567, value: 'capannori', cap: '55012', provinceId: 48},
{id: 3647, value: 'monselice', cap: '35043', provinceId: 28},
{id: 2555, value: 'offanengo', cap: '26010', provinceId: 17},
{id: 2213, value: 'muscoline', cap: '25080', provinceId: 15},
{id: 7631, value: 'sperlinga', cap: '94010', provinceId: 96},
{id: 1711, value: 'casorezzo', cap: '20010', provinceId: 13},
{id: 1703, value: 'camparada', cap: '20857', provinceId: 108},
{id: 5653, value: 'vittorito', cap: '67030', provinceId: 68},
{id: 5504, value: 'picinisco', cap: '03040', provinceId: 67},
{id: 2286, value: 'tremosine', cap: '25010', provinceId: 15},
{id: 2072, value: 'valgoglio', cap: '24020', provinceId: 14},
{id: 571, value: 'strambino', cap: '10019', provinceId: 1},
{id: 2456, value: 'stradella', cap: '27049', provinceId: 16},
{id: 5697, value: 'tortoreto', cap: '64018', provinceId: 69},
{id: 2759, value: 'tremenico', cap: '23836', provinceId: 19},
{id: 3557, value: 'cavarzere', cap: '30014', provinceId: 27},
{id: 2618, value: 'casalmoro', cap: '46040', provinceId: 18},
{id: 4795, value: 'trequanda', cap: '53020', provinceId: 54},
{id: 7540, value: 'tortorici', cap: '98078', provinceId: 93},
{id: 6324, value: 'moschiano', cap: '83020', provinceId: 77},
{id: 5378, value: 'pisoniano', cap: '00020', provinceId: 65},
{id: 5451, value: 'sperlonga', cap: '04029', provinceId: 66},
{id: 1183, value: 'ornavasso', cap: '28877', provinceId: 8},
{id: 6475, value: 'pisciotta', cap: '84066', provinceId: 78},
{id: 6718, value: 'cavallino', cap: '73020', provinceId: 83},
{id: 6601, value: 'zapponeta', cap: '71030', provinceId: 79},
{id: 2619, value: 'casaloldo', cap: '46040', provinceId: 18},
{id: 2733, value: 'morterone', cap: '23811', provinceId: 19},
{id: 7574, value: 'montevago', cap: '92010', provinceId: 94},
{id: 7014, value: 'orsomarso', cap: '87020', provinceId: 86},
{id: 5206, value: 'monterosi', cap: '01030', provinceId: 63},
{id: 371, value: 'cavagnolo', cap: '10020', provinceId: 1},
{id: 7283, value: 'strongoli', cap: '88816', provinceId: 89},
{id: 4733, value: 'cavriglia', cap: '52022', provinceId: 53},
{id: 2222, value: 'orzinuovi', cap: '25034', provinceId: 15},
{id: 6759, value: 'poggiardo', cap: '73037', provinceId: 83},
{id: 1600, value: 'caspoggio', cap: '23020', provinceId: 12},
{id: 742, value: 'antignano', cap: '14010', provinceId: 5},
{id: 3809, value: 'montenars', cap: '33010', provinceId: 30},
{id: 6105, value: 'casalduni', cap: '82027', provinceId: 75},
{id: 4237, value: 'podenzano', cap: '29027', provinceId: 38},
{id: 4556, value: 'podenzana', cap: '54010', provinceId: 47},
{id: 4307, value: 'campegine', cap: '42040', provinceId: 40},
{id: 5609, value: 'ortucchio', cap: '67050', provinceId: 68},
{id: 2066, value: 'treviglio', cap: '24047', provinceId: 14},
{id: 6573, value: 'orta nova', cap: '71045', provinceId: 79},
{id: 4920, value: 'stroncone', cap: '05039', provinceId: 58},
{id: 5150, value: 'ortezzano', cap: '63851', provinceId: 109},
{id: 3187, value: 'casaleone', cap: '37052', provinceId: 23},
{id: 5017, value: 'monterado', cap: '60010', provinceId: 60},
{id: 4744, value: 'monterchi', cap: '52035', provinceId: 53},
{id: 1863, value: 'antegnate', cap: '24051', provinceId: 14},
{id: 7959, value: 'ortacesus', cap: '09040', provinceId: 102},
{id: 2996, value: 'cavizzana', cap: '38022', provinceId: 22},
{id: 1917, value: 'cavernago', cap: '24050', provinceId: 14},
{id: 6075, value: 'sparanise', cap: '81056', provinceId: 74},
{id: 7220, value: 'placanica', cap: '89040', provinceId: 88},
{id: 5809, value: 'pennadomo', cap: '66040', provinceId: 71},
{id: 6887, value: 'trecchina', cap: '85049', provinceId: 84},
{id: 3946, value: 'polcenigo', cap: '33070', provinceId: 33},
{id: 9446, value: 'st ulrich', cap: '39046', provinceId: 21},
{id: 1330, value: 'castronno', cap: '21040', provinceId: 10},
{id: 6470, value: 'perdifumo', cap: '84060', provinceId: 78},
{id: 6599, value: 'volturino', cap: '71030', provinceId: 79},
{id: 3538, value: 'spresiano', cap: '31027', provinceId: 26},
{id: 1425, value: 'albavilla', cap: '22031', provinceId: 11},
{id: 7863, value: 'montresta', cap: '08010', provinceId: 103},
{id: 7154, value: 'squillace', cap: '88069', provinceId: 87},
{id: 6777, value: 'squinzano', cap: '73018', provinceId: 83},
{id: 5108, value: 'castorano', cap: '63081', provinceId: 62},
{id: 8000, value: 'tratalias', cap: '09010', provinceId: 107},
{id: 4029, value: 'vallebona', cap: '18012', provinceId: 34},
{id: 1199, value: 'trasquera', cap: '28868', provinceId: 8},
{id: 6007, value: 'casapulla', cap: '81020', provinceId: 74},
{id: 1388, value: 'morazzone', cap: '21040', provinceId: 10},
{id: 9450, value: 'waidbruck', cap: '39040', provinceId: 21},
{id: 6922, value: 'stigliano', cap: '75018', provinceId: 85},
{id: 7185, value: 'casignana', cap: '89030', provinceId: 88},
{id: 3872, value: 'trasaghis', cap: '33010', provinceId: 30},
{id: 3268, value: 'albettone', cap: '36020', provinceId: 24},
{id: 2335, value: 'casatisma', cap: '27040', provinceId: 16},
{id: 354, value: 'canischio', cap: '10080', provinceId: 1},
{id: 6778, value: 'sternatia', cap: '73010', provinceId: 83},
{id: 7558, value: 'cammarata', cap: '92022', provinceId: 94},
{id: 1775, value: 'morimondo', cap: '20081', provinceId: 13},
{id: 6886, value: 'tramutola', cap: '85057', provinceId: 84},
{id: 2566, value: 'pieranica', cap: '26017', provinceId: 17},
{id: 7560, value: 'canicatti', cap: '92024', provinceId: 94},
{id: 4813, value: 'orbetello', cap: '58015', provinceId: 55},
{id: 4293, value: 'trecasali', cap: '43010', provinceId: 39},
{id: 8027, value: 'albagiara', cap: '09090', provinceId: 103},
{id: 496, value: 'piossasco', cap: '10045', provinceId: 1},
{id: 6086, value: 'vitulazio', cap: '81041', provinceId: 74},
{id: 4410, value: 'castenaso', cap: '40055', provinceId: 42},
{id: 1669, value: 'albairate', cap: '20080', provinceId: 13},
{id: 6382, value: 'albanella', cap: '84044', provinceId: 78},
{id: 8021, value: 'castiadas', cap: '09040', provinceId: 102},
{id: 2090, value: 'zandobbio', cap: '24060', provinceId: 14},
{id: 2784, value: 'cavacurta', cap: '26844', provinceId: 20},
{id: 1795, value: 'pioltello', cap: '20096', provinceId: 13},
{id: 473, value: 'orbassano', cap: '10043', provinceId: 1},
{id: 5166, value: 'spinetoli', cap: '63078', provinceId: 62},
{id: 579, value: 'trausella', cap: '10080', provinceId: 1},
{id: 1063, value: 'casapinta', cap: '13866', provinceId: 7},
{id: 4525, value: 'cattolica', cap: '47841', provinceId: 46},
{id: 5710, value: 'catignano', cap: '65011', provinceId: 70},
{id: 2211, value: 'montirone', cap: '25010', provinceId: 15},
{id: 1252, value: 'montjovet', cap: '11020', provinceId: 9},
{id: 3963, value: 'valvasone', cap: '33098', provinceId: 33},
{id: 2552, value: 'montodine', cap: '26010', provinceId: 17},
{id: 4142, value: 'montoggio', cap: '16026', provinceId: 36},
{id: 7962, value: 'perdaxius', cap: '09010', provinceId: 107},
{id: 5239, value: 'casaprota', cap: '02030', provinceId: 64},
{id: 7094, value: 'catanzaro', cap: '88100', provinceId: 87},
{id: 7537, value: 'spadafora', cap: '98048', provinceId: 93},
{id: 3733, value: 'polesella', cap: '45038', provinceId: 29},
{id: 811, value: 'montabone', cap: '14040', provinceId: 5},
{id: 8119, value: 'cerbaiola', cap: '47898', provinceId: 111},
{id: 4180, value: 'carrodano', cap: '19020', provinceId: 37},
{id: 36, value: 'valduggia', cap: '13018', provinceId: 2},
{id: 972, value: 'murisengo', cap: '15020', provinceId: 6},
{id: 3713, value: 'ceregnano', cap: '45010', provinceId: 29},
{id: 503, value: 'pragelato', cap: '10060', provinceId: 1},
{id: 302, value: 'pradleves', cap: '12027', provinceId: 4},
{id: 3828, value: 'pradamano', cap: '33040', provinceId: 30},
{id: 5237, value: 'cantalice', cap: '02014', provinceId: 64},
{id: 3662, value: 'pozzonovo', cap: '35020', provinceId: 28},
{id: 274, value: 'murazzano', cap: '12060', provinceId: 4},
{id: 3826, value: 'povoletto', cap: '33040', provinceId: 30},
{id: 5222, value: 'vallerano', cap: '01030', provinceId: 63},
{id: 2502, value: 'calvatone', cap: '26030', provinceId: 17},
{id: 2495, value: 'agnadello', cap: '26020', provinceId: 17},
{id: 6264, value: 'aquilonia', cap: '83041', provinceId: 77},
{id: 4932, value: 'cartoceto', cap: '61030', provinceId: 59},
{id: 472, value: 'oglianico', cap: '10080', provinceId: 1},
{id: 5537, value: 'vallemaio', cap: '03040', provinceId: 67},
{id: 4147, value: 'portofino', cap: '16034', provinceId: 36},
{id: 5221, value: 'valentano', cap: '01018', provinceId: 63},
{id: 6649, value: 'valenzano', cap: '70010', provinceId: 80},
{id: 6112, value: 'ceppaloni', cap: '82010', provinceId: 75},
{id: 5711, value: 'cepagatti', cap: '65012', provinceId: 70},
{id: 4013, value: 'pornassio', cap: '18024', provinceId: 34},
{id: 2249, value: 'pralboino', cap: '25020', provinceId: 15},
{id: 5267, value: 'montasola', cap: '02040', provinceId: 64},
{id: 2809, value: 'mulazzano', cap: '26837', provinceId: 20},
{id: 1471, value: 'cermenate', cap: '22072', provinceId: 11},
{id: 961, value: 'montacuto', cap: '15050', provinceId: 6},
{id: 5891, value: 'montagano', cap: '86023', provinceId: 72},
{id: 4513, value: 'predappio', cap: '47016', provinceId: 45},
{id: 6219, value: 'ottaviano', cap: '80044', provinceId: 76},
{id: 6311, value: 'montaguto', cap: '83030', provinceId: 77},
{id: 4007, value: 'perinaldo', cap: '18032', provinceId: 34},
{id: 4642, value: 'montaione', cap: '50050', provinceId: 50},
{id: 1112, value: 'sordevolo', cap: '13817', provinceId: 7},
{id: 962, value: 'montaldeo', cap: '15060', provinceId: 6},
{id: 1472, value: 'cernobbio', cap: '22012', provinceId: 11},
{id: 4796, value: 'arcidosso', cap: '58031', provinceId: 55},
{id: 4308, value: 'carpineti', cap: '42033', provinceId: 40},
{id: 4466, value: 'ostellato', cap: '44020', provinceId: 43},
{id: 757, value: 'cantarana', cap: '14010', provinceId: 5},
{id: 6557, value: 'cerignola', cap: '71042', provinceId: 79},
{id: 4582, value: 'pescaglia', cap: '55064', provinceId: 48},
{id: 4217, value: 'cerignale', cap: '29020', provinceId: 38},
{id: 4886, value: 'umbertide', cap: '06019', provinceId: 57},
{id: 3512, value: 'pederobba', cap: '31040', provinceId: 26},
{id: 463, value: 'montanaro', cap: '10017', provinceId: 1},
{id: 6399, value: 'calvanico', cap: '84080', provinceId: 78},
{id: 265, value: 'montanera', cap: '12040', provinceId: 4},
{id: 5942, value: 'carpinone', cap: '86093', provinceId: 73},
{id: 355, value: 'cantalupa', cap: '10060', provinceId: 1},
{id: 7621, value: 'centuripe', cap: '94010', provinceId: 96},
{id: 3948, value: 'pordenone', cap: '33170', provinceId: 33},
{id: 5231, value: 'antrodoco', cap: '02013', provinceId: 64},
{id: 4708, value: 'pomarance', cap: '56045', provinceId: 52},
{id: 3875, value: 'tricesimo', cap: '33019', provinceId: 30},
{id: 1096, value: 'ponderano', cap: '13875', provinceId: 7},
{id: 2244, value: 'poncarale', cap: '25020', provinceId: 15},
{id: 2, value: 'olcenengo', cap: '13047', provinceId: 2},
{id: 6923, value: 'tricarico', cap: '75019', provinceId: 85},
{id: 4011, value: 'pompeiana', cap: '18015', provinceId: 34},
{id: 2484, value: 'vistarino', cap: '27010', provinceId: 16},
{id: 2145, value: 'cellatica', cap: '25060', provinceId: 15},
{id: 614, value: 'vistrorio', cap: '10080', provinceId: 1},
{id: 6619, value: 'cellamare', cap: '70010', provinceId: 80},
{id: 500, value: 'pomaretto', cap: '10063', provinceId: 1},
{id: 1991, value: 'mozzanica', cap: '24050', provinceId: 14},
{id: 3219, value: 'mozzecane', cap: '37060', provinceId: 23},
{id: 5027, value: 'polverigi', cap: '60020', provinceId: 60},
{id: 3658, value: 'polverara', cap: '35020', provinceId: 28},
{id: 4165, value: 'torriglia', cap: '16029', provinceId: 36},
{id: 6665, value: 'monteiasi', cap: '74020', provinceId: 81},
{id: 1147, value: 'cambiasca', cap: '28814', provinceId: 8},
{id: 3444, value: 'soverzene', cap: '32010', provinceId: 25},
{id: 4793, value: 'sovicille', cap: '53018', provinceId: 54},
{id: 6280, value: 'casalbore', cap: '83034', provinceId: 77},
{id: 7222, value: 'polistena', cap: '89024', provinceId: 88},
{id: 2490, value: 'zenevredo', cap: '27049', provinceId: 16},
{id: 7133, value: 'pianopoli', cap: '88040', provinceId: 87},
{id: 3443, value: 'sospirolo', cap: '32037', provinceId: 25},
{id: 3448, value: 'trichiana', cap: '32028', provinceId: 25},
{id: 7096, value: 'centrache', cap: '88067', provinceId: 87},
{id: 1912, value: 'cassiglio', cap: '24010', provinceId: 14},
{id: 2131, value: 'calvisano', cap: '25012', provinceId: 15},
{id: 5758, value: 'carunchio', cap: '66050', provinceId: 71},
{id: 3712, value: 'ceneselli', cap: '45030', provinceId: 29},
{id: 2247, value: 'pontoglio', cap: '25037', provinceId: 15},
{id: 8087, value: 'sorradile', cap: '09080', provinceId: 103},
{id: 2647, value: 'pegognaga', cap: '46020', provinceId: 18},
{id: 6779, value: 'supersano', cap: '73040', provinceId: 83},
{id: 6647, value: 'triggiano', cap: '70019', provinceId: 80},
{id: 7550, value: 'agrigento', cap: '92100', provinceId: 94},
{id: 851, value: 'valfenera', cap: '14017', provinceId: 5},
{id: 6004, value: 'casagiove', cap: '81022', provinceId: 74},
{id: 4990, value: 'agugliano', cap: '60020', provinceId: 60},
{id: 1285, value: 'albizzate', cap: '21041', provinceId: 10},
{id: 9472, value: 'muehlwald', cap: '39030', provinceId: 21},
{id: 2246, value: 'pontevico', cap: '25026', provinceId: 15},
{id: 4239, value: 'pontenure', cap: '29010', provinceId: 38},
{id: 4710, value: 'pontedera', cap: '56025', provinceId: 52},
{id: 7603, value: 'montedoro', cap: '93010', provinceId: 95},
{id: 1058, value: 'camandona', cap: '13821', provinceId: 7},
{id: 9459, value: 'muehlbach', cap: '39037', provinceId: 21},
{id: 5067, value: 'montefano', cap: '62010', provinceId: 61},
{id: 3267, value: 'agugliaro', cap: '36020', provinceId: 24},
{id: 5041, value: 'appignano', cap: '62010', provinceId: 61},
{id: 1259, value: 'pontboset', cap: '11020', provinceId: 9},
{id: 5680, value: 'montefino', cap: '64030', provinceId: 69},
{id: 2775, value: 'camairago', cap: '26823', provinceId: 20},
{id: 7226, value: 'rizziconi', cap: '89016', provinceId: 88},
{id: 1101, value: 'quittengo', cap: '13812', provinceId: 7},
{id: 5852, value: 'baranello', cap: '86011', provinceId: 72},
{id: 4741, value: 'lucignano', cap: '52046', provinceId: 53},
{id: 5829, value: 'san salvo', cap: '66050', provinceId: 71},
{id: 4000, value: 'lucinasco', cap: '18020', provinceId: 34},
{id: 2717, value: 'imbersago', cap: '23898', provinceId: 19},
{id: 793, value: 'fontanile', cap: '14044', provinceId: 5},
{id: 2956, value: 'besenello', cap: '38060', provinceId: 22},
{id: 1693, value: 'brugherio', cap: '20861', provinceId: 108},
{id: 3630, value: 'fontaniva', cap: '35014', provinceId: 28},
{id: 9508, value: 'baranzate', cap: '20021', provinceId: 13},
{id: 4205, value: 'besenzone', cap: '29010', provinceId: 38},
{id: 6805, value: 'baragiano', cap: '85050', provinceId: 84},
{id: 4637, value: 'impruneta', cap: '50023', provinceId: 50},
{id: 563, value: 'scarmagno', cap: '10010', provinceId: 1},
{id: 4266, value: 'fontevivo', cap: '43010', provinceId: 39},
{id: 553, value: 'san ponso', cap: '10080', provinceId: 1},
{id: 322, value: 'banchette', cap: '10010', provinceId: 1},
{id: 2235, value: 'san paolo', cap: '25020', provinceId: 15},
{id: 6972, value: 'cropalati', cap: '87060', provinceId: 86},
{id: 3155, value: 'vermiglio', cap: '38029', provinceId: 22},
{id: 4321, value: 'guastalla', cap: '42016', provinceId: 40},
{id: 2193, value: 'lumezzane', cap: '25065', provinceId: 15},
{id: 2579, value: 'romanengo', cap: '26014', provinceId: 17},
{id: 7438, value: 'villabate', cap: '90039', provinceId: 92},
{id: 2431, value: 'romagnese', cap: '27050', provinceId: 16},
{id: 6021, value: 'formicola', cap: '81040', provinceId: 74},
{id: 3283, value: 'brogliano', cap: '36070', provinceId: 24},
{id: 2764, value: 'vercurago', cap: '23808', provinceId: 19},
{id: 4377, value: 'riolunato', cap: '41020', provinceId: 41},
{id: 1220, value: 'brissogne', cap: '11020', provinceId: 9},
{id: 3418, value: 'longarone', cap: '32013', provinceId: 25},
{id: 2629, value: 'curtatone', cap: '46010', provinceId: 18},
{id: 3628, value: 'curtarolo', cap: '35010', provinceId: 28},
{id: 7687, value: 'viagrande', cap: '95029', provinceId: 97},
{id: 2081, value: 'viadanica', cap: '24060', provinceId: 14},
{id: 5384, value: 'riofreddo', cap: '00020', provinceId: 65},
{id: 5115, value: 'folignano', cap: '63084', provinceId: 62},
{id: 4495, value: 'bertinoro', cap: '47032', provinceId: 45},
{id: 2108, value: 'barbariga', cap: '25030', provinceId: 15},
{id: 161, value: 'brondello', cap: '12030', provinceId: 4},
{id: 562, value: 'scalenghe', cap: '10060', provinceId: 1},
{id: 4699, value: 'lorenzana', cap: '56043', provinceId: 52},
{id: 1040, value: 'viguzzolo', cap: '15058', provinceId: 6},
{id: 4804, value: 'follonica', cap: '58022', provinceId: 55},
{id: 4337, value: 'scandiano', cap: '42019', provinceId: 40},
{id: 4592, value: 'vergemoli', cap: '55020', provinceId: 48},
{id: 162, value: 'brossasco', cap: '12020', provinceId: 4},
{id: 2770, value: 'bertonico', cap: '26821', provinceId: 20},
{id: 4654, value: 'scandicci', cap: '50018', provinceId: 50},
{id: 7054, value: 'san sosti', cap: '87010', provinceId: 86},
{id: 112, value: 'romentino', cap: '28068', provinceId: 3},
{id: 6305, value: 'luogosano', cap: '83040', provinceId: 77},
{id: 153, value: 'borgomale', cap: '12050', provinceId: 4},
{id: 7065, value: 'scigliano', cap: '87057', provinceId: 86},
{id: 5648, value: 'villalago', cap: '67030', provinceId: 68},
{id: 6178, value: 'brusciano', cap: '80031', provinceId: 76},
{id: 2543, value: 'grontardo', cap: '26044', provinceId: 17},
{id: 943, value: 'grognardo', cap: '15010', provinceId: 6},
{id: 5825, value: 'san buono', cap: '66050', provinceId: 71},
{id: 5913, value: 'san biase', cap: '86020', provinceId: 72},
{id: 4484, value: 'cotignola', cap: '48010', provinceId: 44},
{id: 85, value: 'grignasco', cap: '28075', provinceId: 3},
{id: 3759, value: 'bicinicco', cap: '33050', provinceId: 30},
{id: 1056, value: 'brusnengo', cap: '13862', provinceId: 7},
{id: 942, value: 'gremiasco', cap: '15056', provinceId: 6},
{id: 6034, value: 'maddaloni', cap: '81024', provinceId: 74},
{id: 595, value: 'verolengo', cap: '10038', provinceId: 1},
{id: 5223, value: 'vasanello', cap: '01030', provinceId: 63},
{id: 6245, value: 'scisciano', cap: '80030', provinceId: 76},
{id: 5639, value: 'scontrone', cap: '67030', provinceId: 68},
{id: 2424, value: 'retorbido', cap: '27050', provinceId: 16},
{id: 7606, value: 'resuttano', cap: '93010', provinceId: 95},
{id: 7859, value: 'magomadas', cap: '08010', provinceId: 103},
{id: 1501, value: 'gravedona', cap: '22015', provinceId: 11},
{id: 1522, value: 'magreglio', cap: '22030', provinceId: 11},
{id: 2941, value: 'villandro', cap: '39040', provinceId: 21},
{id: 2548, value: 'madignano', cap: '26020', provinceId: 17},
{id: 1171, value: 'macugnaga', cap: '28876', provinceId: 8},
{id: 2537, value: 'formigara', cap: '26020', provinceId: 17},
{id: 7344, value: 'favignana', cap: '91023', provinceId: 91},
{id: 4357, value: 'formigine', cap: '41043', provinceId: 41},
{id: 4655, value: 'scarperia', cap: '50038', provinceId: 50},
{id: 4320, value: 'gualtieri', cap: '42044', provinceId: 40},
{id: 5552, value: 'balsorano', cap: '67052', provinceId: 68},
{id: 6653, value: 'crispiano', cap: '74012', provinceId: 81},
{id: 623, value: 'balmuccia', cap: '13020', provinceId: 2},
{id: 1002, value: 'ricaldone', cap: '15010', provinceId: 6},
{id: 4153, value: 'rondanina', cap: '16025', provinceId: 36},
{id: 2367, value: 'fortunago', cap: '27040', provinceId: 16},
{id: 4877, value: 'scheggino', cap: '06040', provinceId: 57},
{id: 4551, value: 'fosdinovo', cap: '54035', provinceId: 47},
{id: 7413, value: 'partinico', cap: '90047', provinceId: 92},
{id: 2530, value: 'cremosano', cap: '26010', provinceId: 17},
{id: 922, value: 'cremolino', cap: '15010', provinceId: 6},
{id: 48, value: 'biandrate', cap: '28061', provinceId: 3},
{id: 1339, value: 'cremenaga', cap: '21030', provinceId: 10},
{id: 7201, value: 'grotteria', cap: '89043', provinceId: 88},
{id: 514, value: 'ribordone', cap: '10080', provinceId: 1},
{id: 3744, value: 'villadose', cap: '45010', provinceId: 29},
{id: 7135, value: 'san floro', cap: '88021', provinceId: 87},
{id: 1566, value: 'schignano', cap: '22020', provinceId: 11},
{id: 318, value: 'balangero', cap: '10070', provinceId: 1},
{id: 1156, value: 'craveggia', cap: '28852', provinceId: 8},
{id: 7342, value: 'custonaci', cap: '91015', provinceId: 91},
{id: 7734, value: 'berchidda', cap: '07022', provinceId: 104},
{id: 3634, value: 'grantorto', cap: '35010', provinceId: 28},
{id: 3725, value: 'lendinara', cap: '45026', provinceId: 29},
{id: 1235, value: 'etroubles', cap: '11014', provinceId: 9},
{id: 7079, value: 'verbicaro', cap: '87020', provinceId: 86},
{id: 7836, value: 'esterzili', cap: '08030', provinceId: 102},
{id: 5417, value: 'ladispoli', cap: '00055', provinceId: 65},
{id: 7753, value: 'esporlatu', cap: '07010', provinceId: 100},
{id: 3112, value: 'sarnonico', cap: '38011', provinceId: 22},
{id: 4343, value: 'bastiglia', cap: '41030', provinceId: 41},
{id: 6301, value: 'lacedonia', cap: '83046', provinceId: 77},
{id: 1887, value: 'bottanuco', cap: '24040', provinceId: 14},
{id: 2120, value: 'botticino', cap: '25082', provinceId: 15},
{id: 128, value: 'vicolungo', cap: '28060', provinceId: 3},
{id: 2479, value: 'vidigulfo', cap: '27018', provinceId: 16},
{id: 2763, value: 'vendrogno', cap: '23838', provinceId: 19},
{id: 7420, value: 'roccamena', cap: '90040', provinceId: 92},
{id: 6825, value: 'episcopia', cap: '85033', provinceId: 84},
{id: 1945, value: 'entratico', cap: '24060', provinceId: 14},
{id: 213, value: 'entracque', cap: '12010', provinceId: 4},
{id: 3608, value: 'bovolenta', cap: '35024', provinceId: 28},
{id: 7625, value: 'leonforte', cap: '94013', provinceId: 96},
{id: 5314, value: 'bracciano', cap: '00062', provinceId: 65},
{id: 3757, value: 'basiliano', cap: '33031', provinceId: 30},
{id: 2540, value: 'genivolta', cap: '26020', provinceId: 17},
{id: 6266, value: 'atripalda', cap: '83042', provinceId: 77},
{id: 6834, value: 'lagonegro', cap: '85042', provinceId: 84},
{id: 1250, value: 'la thuile', cap: '11016', provinceId: 9},
{id: 2381, value: 'landriano', cap: '27015', provinceId: 16},
{id: 217, value: 'feisoglio', cap: '12050', provinceId: 4},
{id: 2383, value: 'lardirago', cap: '27016', provinceId: 16},
{id: 1205, value: 'viganella', cap: '28841', provinceId: 8},
{id: 7088, value: 'belcastro', cap: '88050', provinceId: 87},
{id: 5454, value: 'ventotene', cap: '04020', provinceId: 66},
{id: 7827, value: 'bortigali', cap: '08012', provinceId: 101},
{id: 927, value: 'felizzano', cap: '15023', provinceId: 6},
{id: 674, value: 'robilante', cap: '12017', provinceId: 4},
{id: 5487, value: 'ferentino', cap: '03013', provinceId: 67},
{id: 5719, value: 'farindola', cap: '65010', provinceId: 70},
{id: 6835, value: 'latronico', cap: '85043', provinceId: 84},
{id: 2966, value: 'bosentino', cap: '38049', provinceId: 22},
{id: 6992, value: 'lattarico', cap: '87010', provinceId: 86},
{id: 2111, value: 'bedizzole', cap: '25081', provinceId: 15},
{id: 5235, value: 'borgorose', cap: '02021', provinceId: 64},
{id: 5628, value: 'rocca pia', cap: '67030', provinceId: 68},
{id: 1142, value: 'belgirate', cap: '28832', provinceId: 8},
{id: 6375, value: 'venticano', cap: '83030', provinceId: 77},
{id: 335, value: 'bosconero', cap: '10080', provinceId: 1},
{id: 7394, value: 'ficarazzi', cap: '90010', provinceId: 92},
{id: 2914, value: 'sarentino', cap: '39058', provinceId: 21},
{id: 4460, value: 'lagosanto', cap: '44023', provinceId: 43},
{id: 1017, value: 'sarezzano', cap: '15050', provinceId: 6},
{id: 5168, value: 'venarotta', cap: '63091', provinceId: 62},
{id: 6118, value: 'durazzano', cap: '82015', provinceId: 75},
{id: 4550, value: 'fivizzano', cap: '54013', provinceId: 47},
{id: 3174, value: 'bardolino', cap: '37011', provinceId: 23},
{id: 4392, value: 'baricella', cap: '40052', provinceId: 42},
{id: 6975, value: 'dipignano', cap: '87045', provinceId: 86},
{id: 5953, value: 'filignano', cap: '86074', provinceId: 73},
{id: 4160, value: 'savignone', cap: '16010', provinceId: 36},
{id: 6768, value: 'sannicola', cap: '73017', provinceId: 83},
{id: 7043, value: 'sangineto', cap: '87020', provinceId: 86},
{id: 7757, value: 'ittireddu', cap: '07010', provinceId: 100},
{id: 1977, value: 'locatello', cap: '24030', provinceId: 14},
{id: 747, value: 'belveglio', cap: '14040', provinceId: 5},
{id: 3977, value: 'borgomaro', cap: '18021', provinceId: 34},
{id: 4043, value: 'bardineto', cap: '17057', provinceId: 35},
{id: 4303, value: 'brescello', cap: '42041', provinceId: 40},
{id: 4593, value: 'viareggio', cap: '55049', provinceId: 48},
{id: 7169, value: 'benestare', cap: '89030', provinceId: 88},
{id: 7733, value: 'benetutti', cap: '07010', provinceId: 100},
{id: 149, value: 'benevello', cap: '12050', provinceId: 4},
{id: 4718, value: 'vecchiano', cap: '56019', provinceId: 52},
{id: 6098, value: 'benevento', cap: '82100', provinceId: 75},
{id: 705, value: 'scagnello', cap: '12070', provinceId: 4},
{id: 3999, value: 'isolabona', cap: '18035', provinceId: 34},
{id: 5421, value: 'fiumicino', cap: '00054', provinceId: 65},
{id: 5057, value: 'fiuminata', cap: '62025', provinceId: 61},
{id: 3047, value: 'lisignago', cap: '38030', provinceId: 22},
{id: 74, value: 'divignano', cap: '28010', provinceId: 3},
{id: 336, value: 'brandizzo', cap: '10032', provinceId: 1},
{id: 6291, value: 'domicella', cap: '83020', provinceId: 77},
{id: 4185, value: 'la spezia', cap: '', provinceId: 37},
{id: 5288, value: 'rivodutri', cap: '02010', provinceId: 64},
{id: 7479, value: 'letojanni', cap: '98037', provinceId: 93},
{id: 6864, value: 'roccanova', cap: '85036', provinceId: 84},
{id: 3844, value: 'rivignano', cap: '33050', provinceId: 30},
{id: 429, value: 'la loggia', cap: '10040', provinceId: 1},
{id: 871, value: 'basaluzzo', cap: '15060', provinceId: 6},
{id: 4240, value: 'rivergaro', cap: '29029', provinceId: 38},
{id: 1892, value: 'brembilla', cap: '24012', provinceId: 14},
{id: 7297, value: 'filandari', cap: '89841', provinceId: 90},
{id: 6162, value: 'sassinoro', cap: '82026', provinceId: 75},
{id: 2366, value: 'filighera', cap: '27010', provinceId: 16},
{id: 520, value: 'rivarossa', cap: '10040', provinceId: 1},
{id: 5629, value: 'roccaraso', cap: '67037', provinceId: 68},
{id: 4322, value: 'ligonchio', cap: '42039', provinceId: 40},
{id: 3798, value: 'ligosullo', cap: '33020', provinceId: 30},
{id: 1251, value: 'lillianes', cap: '11020', provinceId: 9},
{id: 8116, value: 'domagnano', cap: '47895', provinceId: 111},
{id: 5488, value: 'filettino', cap: '03010', provinceId: 67},
{id: 7932, value: 'dolianova', cap: '09041', provinceId: 102},
{id: 731, value: 'vicoforte', cap: '12080', provinceId: 4},
{id: 6392, value: 'baronissi', cap: '84081', provinceId: 78},
{id: 4247, value: 'vigolzone', cap: '29020', provinceId: 38},
{id: 2612, value: 'bigarello', cap: '46030', provinceId: 18},
{id: 8040, value: 'boroneddu', cap: '09080', provinceId: 103},
{id: 2580, value: 'salvirola', cap: '26010', provinceId: 17},
{id: 1441, value: 'blessagno', cap: '22028', provinceId: 11},
{id: 7100, value: 'conflenti', cap: '88040', provinceId: 87},
{id: 6025, value: 'galluccio', cap: '81044', provinceId: 74},
{id: 2641, value: 'marmirolo', cap: '46045', provinceId: 18},
{id: 3526, value: 'salgareda', cap: '31040', provinceId: 26},
{id: 7190, value: 'condofuri', cap: '89030', provinceId: 88},
{id: 7112, value: 'girifalco', cap: '88024', provinceId: 87},
{id: 1440, value: 'bizzarone', cap: '22020', provinceId: 11},
{id: 3323, value: 'marostica', cap: '36063', provinceId: 24},
{id: 2370, value: 'gambarana', cap: '27030', provinceId: 16},
{id: 5875, value: 'gambatesa', cap: '86013', provinceId: 72},
{id: 6357, value: 'senerchia', cap: '83050', provinceId: 77},
{id: 4033, value: 'vessalico', cap: '18026', provinceId: 34},
{id: 591, value: 'varisella', cap: '10070', provinceId: 1},
{id: 249, value: 'marsaglia', cap: '12060', provinceId: 4},
{id: 4857, value: 'marsciano', cap: '06055', provinceId: 57},
{id: 127, value: 'vespolate', cap: '28079', provinceId: 3},
{id: 2497, value: 'azzanello', cap: '26010', provinceId: 17},
{id: 5785, value: 'gamberale', cap: '66040', provinceId: 71},
{id: 5110, value: 'comunanza', cap: '63087', provinceId: 62},
{id: 9451, value: 'burgstall', cap: '39014', provinceId: 21},
{id: 6197, value: 'comiziano', cap: '80030', provinceId: 76},
{id: 4483, value: 'conselice', cap: '48017', provinceId: 44},
{id: 7472, value: 'gallodoro', cap: '98030', provinceId: 93},
{id: 5157, value: 'rapagnano', cap: '63831', provinceId: 109},
{id: 2499, value: 'bonemerse', cap: '26040', provinceId: 17},
{id: 7630, value: 'regalbuto', cap: '94017', provinceId: 96},
{id: 7736, value: 'bonnanaro', cap: '07043', provinceId: 100},
{id: 2423, value: 'redavalle', cap: '27050', provinceId: 16},
{id: 7791, value: 'semestene', cap: '07010', provinceId: 100},
{id: 800, value: 'maranzana', cap: '14040', provinceId: 5},
{id: 3934, value: 'cordovado', cap: '33075', provinceId: 33},
{id: 7119, value: 'marcedusa', cap: '88050', provinceId: 87},
{id: 3933, value: 'cordenons', cap: '33084', provinceId: 33},
{id: 1360, value: 'golasecca', cap: '21010', provinceId: 10},
{id: 1353, value: 'gallarate', cap: '21013', provinceId: 10},
{id: 5191, value: 'corchiano', cap: '01030', provinceId: 63},
{id: 6729, value: 'gallipoli', cap: '73014', provinceId: 83},
{id: 3973, value: 'badalucco', cap: '18010', provinceId: 34},
{id: 418, value: 'givoletto', cap: '10040', provinceId: 1},
{id: 4066, value: 'giusvalla', cap: '17010', provinceId: 35},
{id: 7581, value: 'realmonte', cap: '92010', provinceId: 94},
{id: 6720, value: 'copertino', cap: '73043', provinceId: 83},
{id: 446, value: 'marentino', cap: '10020', provinceId: 1},
{id: 4575, value: 'gallicano', cap: '55027', provinceId: 48},
{id: 247, value: 'margarita', cap: '12040', provinceId: 4},
{id: 3236, value: 'salizzole', cap: '37056', provinceId: 23},
{id: 5494, value: 'gallinaro', cap: '03040', provinceId: 67},
{id: 7121, value: 'martirano', cap: '88040', provinceId: 87},
{id: 6802, value: 'avigliano', cap: '85021', provinceId: 84},
{id: 5004, value: 'corinaldo', cap: '60013', provinceId: 60},
{id: 1358, value: 'gerenzano', cap: '21040', provinceId: 10},
{id: 4578, value: 'massarosa', cap: '55054', provinceId: 48},
{id: 7301, value: 'gerocarne', cap: '89831', provinceId: 90},
{id: 1497, value: 'germasino', cap: '22010', provinceId: 11},
{id: 1081, value: 'masserano', cap: '13866', provinceId: 7},
{id: 1961, value: 'gazzaniga', cap: '24025', provinceId: 14},
{id: 3054, value: 'massimeno', cap: '38086', provinceId: 22},
{id: 4071, value: 'massimino', cap: '12071', provinceId: 35},
{id: 5671, value: 'colledara', cap: '64042', provinceId: 69},
{id: 5703, value: 'bolognano', cap: '65020', provinceId: 70},
{id: 2374, value: 'gerenzago', cap: '27010', provinceId: 16},
{id: 4805, value: 'gavorrano', cap: '58023', provinceId: 55},
{id: 565, value: 'sestriere', cap: '10058', provinceId: 1},
{id: 5043, value: 'bolognola', cap: '62035', provinceId: 61},
{id: 7371, value: 'bompietro', cap: '90020', provinceId: 92},
{id: 6568, value: 'mattinata', cap: '71030', provinceId: 79},
{id: 5481, value: 'colfelice', cap: '03030', provinceId: 67},
{id: 346, value: 'bussoleno', cap: '10053', provinceId: 1},
{id: 668, value: 'racconigi', cap: '12035', provinceId: 4},
{id: 7601, value: 'mazzarino', cap: '93013', provinceId: 95},
{id: 7578, value: 'racalmuto', cap: '92020', provinceId: 94},
{id: 5343, value: 'genazzano', cap: '00030', provinceId: 65},
{id: 5394, value: 'sacrofano', cap: '00060', provinceId: 65},
{id: 5342, value: 'gavignano', cap: '00030', provinceId: 65},
{id: 315, value: 'avigliana', cap: '10051', provinceId: 1},
{id: 5052, value: 'colmurano', cap: '62020', provinceId: 61},
{id: 71, value: 'comignago', cap: '28060', provinceId: 3},
{id: 4588, value: 'seravezza', cap: '55047', provinceId: 48},
{id: 2028, value: 'ranzanico', cap: '24060', provinceId: 14},
{id: 2606, value: 'vescovato', cap: '26039', provinceId: 17},
{id: 4455, value: 'comacchio', cap: '44022', provinceId: 43},
{id: 1728, value: 'colturano', cap: '20060', provinceId: 13},
{id: 1076, value: 'gifflenga', cap: '13874', provinceId: 7},
{id: 6901, value: 'colobraro', cap: '75021', provinceId: 85},
{id: 7345, value: 'gibellina', cap: '91024', provinceId: 91},
{id: 3689, value: 'vescovana', cap: '35040', provinceId: 28},
{id: 4107, value: 'bogliasco', cap: '16031', provinceId: 36},
{id: 8039, value: 'bonarcado', cap: '09070', provinceId: 103},
{id: 5448, value: 'sermoneta', cap: '04013', provinceId: 66},
{id: 416, value: 'giaglione', cap: '10050', provinceId: 1},
{id: 7110, value: 'gasperina', cap: '88060', provinceId: 87},
{id: 7391, value: 'collesano', cap: '90016', provinceId: 92},
{id: 4319, value: 'gattatico', cap: '42043', provinceId: 40},
{id: 9418, value: 'villnoess', cap: '39040', provinceId: 21},
{id: 652, value: 'gattinara', cap: '13045', provinceId: 2},
{id: 939, value: 'gavazzana', cap: '15063', provinceId: 6},
{id: 4175, value: 'bonassola', cap: '19011', provinceId: 37},
{id: 7579, value: 'raffadali', cap: '92015', provinceId: 94},
{id: 4361, value: 'maranello', cap: '41053', provinceId: 41},
{id: 2355, value: 'confienza', cap: '27030', provinceId: 16},
{id: 1732, value: 'cornaredo', cap: '20010', provinceId: 13},
{id: 4543, value: 'verucchio', cap: '47826', provinceId: 46},
{id: 2820, value: 'secugnago', cap: '26826', provinceId: 20},
{id: 1871, value: 'bagnatica', cap: '24060', provinceId: 14},
{id: 6943, value: 'bisignano', cap: '87043', provinceId: 86},
{id: 2478, value: 'verrua po', cap: '27040', provinceId: 16},
{id: 331, value: 'borgiallo', cap: '10080', provinceId: 1},
{id: 4634, value: 'fucecchio', cap: '50054', provinceId: 50},
{id: 949, value: 'malvicino', cap: '15015', provinceId: 6},
{id: 2549, value: 'malagnino', cap: '26030', provinceId: 17},
{id: 2751, value: 'rovagnate', cap: '23888', provinceId: 19},
{id: 4538, value: 'saludecio', cap: '47835', provinceId: 46},
{id: 934, value: 'frugarolo', cap: '15065', provinceId: 6},
{id: 6610, value: 'bisceglie', cap: '76011', provinceId: 110},
{id: 2500, value: 'bordolano', cap: '26020', provinceId: 17},
{id: 5674, value: 'corropoli', cap: '64013', provinceId: 69},
{id: 5492, value: 'frosinone', cap: '03100', provinceId: 67},
{id: 2257, value: 'remedello', cap: '25010', provinceId: 15},
{id: 22, value: 'rovasenda', cap: '13040', provinceId: 2},
{id: 412, value: 'frossasco', cap: '10060', provinceId: 1},
{id: 5956, value: 'frosolone', cap: '86095', provinceId: 73},
{id: 7486, value: 'mandanici', cap: '98020', provinceId: 93},
{id: 7634, value: 'villarosa', cap: '94010', provinceId: 96},
{id: 2434, value: 'rovescala', cap: '27040', provinceId: 16},
{id: 3213, value: 'malcesine', cap: '37018', provinceId: 23},
{id: 1107, value: 'salussola', cap: '13885', provinceId: 7},
{id: 4317, value: 'correggio', cap: '42015', provinceId: 40},
{id: 1298, value: 'bisuschio', cap: '21050', provinceId: 10},
{id: 7983, value: 'selargius', cap: '09047', provinceId: 102},
{id: 933, value: 'fresonara', cap: '15064', provinceId: 6},
{id: 4486, value: 'fusignano', cap: '48010', provinceId: 44},
{id: 152, value: 'bonvicino', cap: '12060', provinceId: 4},
{id: 3114, value: 'segonzano', cap: '38047', provinceId: 22},
{id: 4262, value: 'corniglio', cap: '43021', provinceId: 39},
{id: 2703, value: 'cortenova', cap: '23813', provinceId: 19},
{id: 3260, value: 'veronella', cap: '37040', provinceId: 23},
{id: 9496, value: 'niederdorf', cap: '39039', provinceId: 21},
{id: 5859, value: 'campolieto', cap: '86040', provinceId: 72},
{id: 2318, value: 'borgarello', cap: '27010', provinceId: 16},
{id: 7290, value: 'capistrano', cap: '89818', provinceId: 90},
{id: 6638, value: 'rutigliano', cap: '70018', provinceId: 80},
{id: 4928, value: 'borgo pace', cap: '61040', provinceId: 59},
{id: 7369, value: 'bisacquino', cap: '90032', provinceId: 92},
{id: 541, value: 'san didero', cap: '10050', provinceId: 1},
{id: 7037, value: 'san basile', cap: '87010', provinceId: 86},
{id: 4549, value: 'filattiera', cap: '54023', provinceId: 47},
{id: 1572, value: 'tavernerio', cap: '22038', provinceId: 11},
{id: 1557, value: 'rovellasca', cap: '22069', provinceId: 11},
{id: 1957, value: 'gandellino', cap: '24020', provinceId: 14},
{id: 573, value: 'tavagnasco', cap: '10010', provinceId: 1},
{id: 3866, value: 'tavagnacco', cap: '33010', provinceId: 30},
{id: 6405, value: 'casalbuono', cap: '84030', provinceId: 78},
{id: 6022, value: 'francolise', cap: '81050', provinceId: 74},
{id: 7016, value: 'panettieri', cap: '87050', provinceId: 86},
{id: 5289, value: 'roccantica', cap: '02040', provinceId: 64},
{id: 3541, value: 'trevignano', cap: '31040', provinceId: 26},
{id: 3981, value: 'castellaro', cap: '18011', provinceId: 34},
{id: 227, value: 'gottasecca', cap: '12070', provinceId: 4},
{id: 2177, value: 'gottolengo', cap: '25023', provinceId: 15},
{id: 7696, value: 'giarratana', cap: '97010', provinceId: 98},
{id: 765, value: 'castellero', cap: '14013', provinceId: 5},
{id: 1661, value: 'val masino', cap: '23010', provinceId: 12},
{id: 5338, value: 'filacciano', cap: '00060', provinceId: 65},
{id: 4473, value: 'tresigallo', cap: '44039', provinceId: 43},
{id: 2864, value: 'gargazzone', cap: '39010', provinceId: 21},
{id: 2662, value: 'sabbioneta', cap: '46018', provinceId: 18},
{id: 2223, value: 'orzivecchi', cap: '25030', provinceId: 15},
{id: 3079, value: 'pellizzano', cap: '38020', provinceId: 22},
{id: 7296, value: 'filadelfia', cap: '89814', provinceId: 90},
{id: 3665, value: 'saccolongo', cap: '35030', provinceId: 28},
{id: 3675, value: 'sant elena', cap: '35040', provinceId: 28},
{id: 6469, value: 'pellezzano', cap: '84080', provinceId: 78},
{id: 5564, value: 'capestrano', cap: '67022', provinceId: 68},
{id: 1115, value: 'tavigliano', cap: '13811', provinceId: 7},
{id: 4225, value: 'gossolengo', cap: '29020', provinceId: 38},
{id: 480, value: 'pancalieri', cap: '10060', provinceId: 1},
{id: 6756, value: 'palmariggi', cap: '73020', provinceId: 83},
{id: 5474, value: 'castelliri', cap: '03030', provinceId: 67},
{id: 6983, value: 'frascineto', cap: '87010', provinceId: 86},
{id: 7254, value: 'taurianova', cap: '89029', provinceId: 88},
{id: 4268, value: 'langhirano', cap: '43013', provinceId: 39},
{id: 5701, value: 'abbateggio', cap: '65020', provinceId: 70},
{id: 4241, value: 'rottofreno', cap: '29010', provinceId: 38},
{id: 5873, value: 'ferrazzano', cap: '86010', provinceId: 72},
{id: 6903, value: 'ferrandina', cap: '75013', provinceId: 85},
{id: 2316, value: 'belgioioso', cap: '27011', provinceId: 16},
{id: 7370, value: 'bolognetta', cap: '90030', provinceId: 92},
{id: 4936, value: 'fermignano', cap: '61033', provinceId: 59},
{id: 2002, value: 'osio sotto', cap: '24046', provinceId: 14},
{id: 173, value: 'cartignano', cap: '12020', provinceId: 4},
{id: 1496, value: 'gera lario', cap: '22010', provinceId: 11},
{id: 1271, value: 'saint oyen', cap: '11014', provinceId: 9},
{id: 4358, value: 'frassinoro', cap: '41044', provinceId: 41},
{id: 676, value: 'roccabruna', cap: '12020', provinceId: 4},
{id: 6937, value: 'amendolara', cap: '87071', provinceId: 86},
{id: 628, value: 'borgosesia', cap: '13011', provinceId: 2},
{id: 4901, value: 'ferentillo', cap: '05034', provinceId: 58},
{id: 6496, value: 'salvitelle', cap: '84020', provinceId: 78},
{id: 9499, value: 'feldthurns', cap: '39040', provinceId: 21},
{id: 1967, value: 'grassobbio', cap: '24050', provinceId: 14},
{id: 9401, value: 'freienfeld', cap: '39040', provinceId: 21},
{id: 3106, value: 'sagron mis', cap: '38050', provinceId: 22},
{id: 6108, value: 'castelpoto', cap: '82030', provinceId: 75},
{id: 5940, value: 'capracotta', cap: '86082', provinceId: 73},
{id: 6918, value: 'rotondella', cap: '75026', provinceId: 85},
{id: 5568, value: 'cappadocia', cap: '67060', provinceId: 68},
{id: 2672, value: 'sustinente', cap: '46030', provinceId: 18},
{id: 1659, value: 'valdisotto', cap: '23030', provinceId: 12},
{id: 7284, value: 'umbriatico', cap: '88823', provinceId: 89},
{id: 3377, value: 'valdastico', cap: '36040', provinceId: 24},
{id: 4719, value: 'vicopisano', cap: '56010', provinceId: 52},
{id: 2980, value: 'campodenno', cap: '38010', provinceId: 22},
{id: 7352, value: 'salaparuta', cap: '91020', provinceId: 91},
{id: 4665, value: 'capoliveri', cap: '57031', provinceId: 51},
{id: 7018, value: 'papasidero', cap: '87020', provinceId: 86},
{id: 5692, value: 'sant omero', cap: '64027', provinceId: 69},
{id: 2368, value: 'frascarolo', cap: '27030', provinceId: 16},
{id: 6531, value: 'trentinara', cap: '84070', provinceId: 78},
{id: 1359, value: 'germignaga', cap: '21010', provinceId: 10},
{id: 714, value: 'tarantasca', cap: '12020', provinceId: 4},
{id: 4561, value: 'altopascio', cap: '55011', provinceId: 48},
{id: 415, value: 'germagnano', cap: '10070', provinceId: 1},
{id: 3862, value: 'talmassons', cap: '33030', provinceId: 30},
{id: 2001, value: 'osio sopra', cap: '24040', provinceId: 14},
{id: 5856, value: 'campobasso', cap: '86100', provinceId: 72},
{id: 6028, value: 'grazzanise', cap: '81046', provinceId: 74},
{id: 4067, value: 'laigueglia', cap: '17053', provinceId: 35},
{id: 5567, value: 'caporciano', cap: '67020', provinceId: 68},
{id: 4715, value: 'santa luce', cap: '56040', provinceId: 52},
{id: 7456, value: 'castelmola', cap: '98030', provinceId: 93},
{id: 5442, value: 'roccagorga', cap: '04010', provinceId: 66},
{id: 3336, value: 'mussolente', cap: '36065', provinceId: 24},
{id: 3607, value: 'borgoricco', cap: '35010', provinceId: 28},
{id: 7035, value: 'rota greca', cap: '87010', provinceId: 86},
{id: 5256, value: 'fiamignano', cap: '02023', provinceId: 64},
{id: 7194, value: 'ferruzzano', cap: '89030', provinceId: 88},
{id: 7111, value: 'gimigliano', cap: '88045', provinceId: 87},
{id: 1905, value: 'caravaggio', cap: '24043', provinceId: 14},
{id: 4964, value: 'pennabilli', cap: '47864', provinceId: 46},
{id: 6188, value: 'casandrino', cap: '80025', provinceId: 76},
{id: 1951, value: 'fontanella', cap: '24056', provinceId: 14},
{id: 8111, value: 'san marino', cap: '47890', provinceId: 111},
{id: 760, value: 'cassinasco', cap: '14050', provinceId: 5},
{id: 1589, value: 'albosaggia', cap: '23010', provinceId: 12},
{id: 2032, value: 'roncobello', cap: '24010', provinceId: 14},
{id: 5093, value: 'urbisaglia', cap: '62010', provinceId: 61},
{id: 903, value: 'cassinelle', cap: '15070', provinceId: 6},
{id: 2369, value: 'galliavola', cap: '27034', provinceId: 16},
{id: 2613, value: 'borgoforte', cap: '46030', provinceId: 18},
{id: 4103, value: 'zuccarello', cap: '17039', provinceId: 35},
{id: 3226, value: 'pescantina', cap: '37026', provinceId: 23},
{id: 2338, value: 'cassolnovo', cap: '27023', provinceId: 16},
{id: 1075, value: 'gaglianico', cap: '13894', provinceId: 7},
{id: 2113, value: 'berzo demo', cap: '25040', provinceId: 15},
{id: 4065, value: 'giustenice', cap: '17027', provinceId: 35},
{id: 7048, value: 'san lucido', cap: '87038', provinceId: 86},
{id: 6830, value: 'gallicchio', cap: '85010', provinceId: 84},
{id: 3188, value: 'castagnaro', cap: '37043', provinceId: 23},
{id: 5678, value: 'giulianova', cap: '64021', provinceId: 69},
{id: 175, value: 'castagnito', cap: '12050', provinceId: 4},
{id: 527, value: 'rondissone', cap: '10030', provinceId: 1},
{id: 5839, value: 'torrebruna', cap: '66050', provinceId: 71},
{id: 1048, value: 'volpeglino', cap: '15050', provinceId: 6},
{id: 3975, value: 'bordighera', cap: '18012', provinceId: 34},
{id: 6120, value: 'foglianise', cap: '82030', provinceId: 75},
{id: 6562, value: 'ischitella', cap: '71010', provinceId: 79},
{id: 6677, value: 'torricella', cap: '74020', provinceId: 81},
{id: 4458, value: 'formignana', cap: '44035', provinceId: 43},
{id: 7960, value: 'pabillonis', cap: '09030', provinceId: 106},
{id: 6928, value: 'acquappesa', cap: '87020', provinceId: 86},
{id: 2636, value: 'guidizzolo', cap: '46040', provinceId: 18},
{id: 6189, value: 'casavatore', cap: '80020', provinceId: 76},
{id: 3146, value: 'transacqua', cap: '38054', provinceId: 22},
{id: 361, value: 'carmagnola', cap: '10022', provinceId: 1},
{id: 6403, value: 'cannalonga', cap: '84040', provinceId: 78},
{id: 2694, value: 'casatenovo', cap: '23880', provinceId: 19},
{id: 6067, value: 'san prisco', cap: '81054', provinceId: 74},
{id: 7928, value: 'carloforte', cap: '09014', provinceId: 107},
{id: 6020, value: 'fontegreca', cap: '81014', provinceId: 74},
{id: 5588, value: 'fontecchio', cap: '67020', provinceId: 68},
{id: 3145, value: 'trambileno', cap: '38068', provinceId: 22},
{id: 7710, value: 'carlentini', cap: '96013', provinceId: 99},
{id: 5667, value: 'castilenti', cap: '64035', provinceId: 69},
{id: 2262, value: 'roncadelle', cap: '25030', provinceId: 15},
{id: 3455, value: 'zoldo alto', cap: '32010', provinceId: 25},
{id: 3871, value: 'torviscosa', cap: '33050', provinceId: 30},
{id: 2377, value: 'golferenzo', cap: '27047', provinceId: 16},
{id: 4923, value: 'acqualagna', cap: '61041', provinceId: 59},
{id: 4825, value: 'carmignano', cap: '59015', provinceId: 56},
{id: 6548, value: 'carlantino', cap: '71030', provinceId: 79},
{id: 3176, value: 'bevilacqua', cap: '37040', provinceId: 23},
{id: 3074, value: 'padergnone', cap: '38070', provinceId: 22},
{id: 2795, value: 'galgagnano', cap: '26832', provinceId: 20},
{id: 6588, value: 'san severo', cap: '71016', provinceId: 79},
{id: 3484, value: 'fontanelle', cap: '31043', provinceId: 26},
{id: 6529, value: 'tortorella', cap: '84030', provinceId: 78},
{id: 3704, value: 'bergantino', cap: '45032', provinceId: 29},
{id: 2137, value: 'castegnato', cap: '25045', provinceId: 15},
{id: 3135, value: 'terragnolo', cap: '38060', provinceId: 22},
{id: 5219, value: 'tessennano', cap: '01010', provinceId: 63},
{id: 5184, value: 'carbognano', cap: '01030', provinceId: 63},
{id: 4346, value: 'camposanto', cap: '41031', provinceId: 41},
{id: 4633, value: 'firenzuola', cap: '50033', provinceId: 50},
{id: 681, value: 'roccavione', cap: '12018', provinceId: 4},
{id: 8109, value: 'fiorentino', cap: '47897', provinceId: 111},
{id: 3385, value: 'zermeghedo', cap: '36050', provinceId: 24},
{id: 6633, value: 'noicattaro', cap: '70016', provinceId: 80},
{id: 1091, value: 'pettinengo', cap: '13843', provinceId: 7},
{id: 3979, value: 'caravonica', cap: '18020', provinceId: 34},
{id: 7680, value: 'sant alfio', cap: '95010', provinceId: 97},
{id: 3978, value: 'camporosso', cap: '18033', provinceId: 34},
{id: 7378, value: 'camporeale', cap: '90043', provinceId: 92},
{id: 5008, value: 'filottrano', cap: '60024', provinceId: 60},
{id: 5561, value: 'campotosto', cap: '67013', provinceId: 68},
{id: 5375, value: 'palestrina', cap: '00036', provinceId: 65},
{id: 626, value: 'boccioleto', cap: '13022', provinceId: 2},
{id: 4505, value: 'gambettola', cap: '47035', provinceId: 45},
{id: 5879, value: 'guglionesi', cap: '86034', provinceId: 72},
{id: 7274, value: 'pallagorio', cap: '88818', provinceId: 89},
{id: 5514, value: 'roccasecca', cap: '03038', provinceId: 67},
{id: 6657, value: 'grottaglie', cap: '74023', provinceId: 81},
{id: 1184, value: 'pallanzeno', cap: '28884', provinceId: 8},
{id: 6691, value: 'san donaci', cap: '72025', provinceId: 82},
{id: 9404, value: 'kastelruth', cap: '39040', provinceId: 21},
{id: 928, value: 'fraconalto', cap: '15060', provinceId: 6},
{id: 6655, value: 'fragagnano', cap: '74022', provinceId: 81},
{id: 7864, value: 'noragugume', cap: '08010', provinceId: 101},
{id: 6813, value: 'cancellara', cap: '85010', provinceId: 84},
{id: 5472, value: 'casalvieri', cap: '03034', provinceId: 67},
{id: 422, value: 'grugliasco', cap: '10095', provinceId: 1},
{id: 3293, value: 'castegnero', cap: '36020', provinceId: 24},
{id: 2078, value: 'verdellino', cap: '24040', provinceId: 14},
{id: 874, value: 'bergamasco', cap: '15022', provinceId: 6},
{id: 4824, value: 'cantagallo', cap: '59025', provinceId: 56},
{id: 7637, value: 'aci catena', cap: '95022', provinceId: 97},
{id: 2101, value: 'alfianello', cap: '25020', provinceId: 15},
{id: 545, value: 'san gillio', cap: '10040', provinceId: 1},
{id: 2317, value: 'bereguardo', cap: '27021', provinceId: 16},
{id: 2810, value: 'orio litta', cap: '26863', provinceId: 20},
{id: 6459, value: 'novi velia', cap: '84060', provinceId: 78},
{id: 490, value: 'pessinetto', cap: '10070', provinceId: 1},
{id: 5901, value: 'petacciato', cap: '86038', provinceId: 72},
{id: 1746, value: 'gorgonzola', cap: '20064', provinceId: 13},
{id: 1437, value: 'bene lario', cap: '22010', provinceId: 11},
{id: 3786, value: 'fiumicello', cap: '33050', provinceId: 30},
{id: 425, value: 'isolabella', cap: '10046', provinceId: 1},
{id: 6623, value: 'giovinazzo', cap: '70054', provinceId: 80},
{id: 6953, value: 'carpanzano', cap: '87050', provinceId: 86},
{id: 1297, value: 'biandronno', cap: '21024', provinceId: 10},
{id: 7548, value: 'acquedolci', cap: '98070', provinceId: 93},
{id: 582, value: 'trofarello', cap: '10028', provinceId: 1},
{id: 3309, value: 'gambellara', cap: '36053', provinceId: 24},
{id: 2661, value: 'roverbella', cap: '46048', provinceId: 18},
{id: 5779, value: 'fossacesia', cap: '66022', provinceId: 71},
{id: 105, value: 'pettenasco', cap: '28028', provinceId: 3},
{id: 4918, value: 'san gemini', cap: '05029', provinceId: 58},
{id: 7076, value: 'trebisacce', cap: '87075', provinceId: 86},
{id: 4773, value: 'montalcino', cap: '53024', provinceId: 54},
{id: 32, value: 'stroppiana', cap: '13010', provinceId: 2},
{id: 3198, value: 'costermano', cap: '37010', provinceId: 23},
{id: 3897, value: 'monfalcone', cap: '34074', provinceId: 31},
{id: 5411, value: 'valmontone', cap: '00038', provinceId: 65},
{id: 810, value: 'mongardino', cap: '14040', provinceId: 5},
{id: 4429, value: 'monghidoro', cap: '40063', provinceId: 42},
{id: 6376, value: 'villamaina', cap: '83050', provinceId: 77},
{id: 3519, value: 'preganziol', cap: '31022', provinceId: 26},
{id: 5847, value: 'villamagna', cap: '66010', provinceId: 71},
{id: 644, value: 'costanzana', cap: '13033', provinceId: 2},
{id: 4151, value: 'rezzoaglio', cap: '16048', provinceId: 36},
{id: 2761, value: 'valmadrera', cap: '23868', provinceId: 19},
{id: 6038, value: 'mondragone', cap: '81034', provinceId: 74},
{id: 9477, value: 'schluderns', cap: '39020', provinceId: 21},
{id: 9476, value: 'schlanders', cap: '39028', provinceId: 21},
{id: 8024, value: 'monserrato', cap: '09042', provinceId: 102},
{id: 2049, value: 'songavazzo', cap: '24020', provinceId: 14},
{id: 5254, value: 'cottanello', cap: '02040', provinceId: 64},
{id: 1231, value: 'courmayeur', cap: '11013', provinceId: 9},
{id: 724, value: 'valloriate', cap: '12010', provinceId: 4},
{id: 5171, value: 'bagnoregio', cap: '01022', provinceId: 63},
{id: 57, value: 'caltignaga', cap: '28010', provinceId: 3},
{id: 260, value: 'monesiglio', cap: '12077', provinceId: 4},
{id: 9497, value: 'villanders', cap: '39040', provinceId: 21},
{id: 3830, value: 'precenicco', cap: '33050', provinceId: 30},
{id: 4294, value: 'valmozzola', cap: '43050', provinceId: 39},
{id: 202, value: 'cortemilia', cap: '12074', provinceId: 4},
{id: 804, value: 'mombaruzzo', cap: '14046', provinceId: 5},
{id: 6255, value: 'villaricca', cap: '80010', provinceId: 76},
{id: 1934, value: 'cortenuova', cap: '24050', provinceId: 14},
{id: 2977, value: 'caldonazzo', cap: '38052', provinceId: 22},
{id: 4210, value: 'calendasco', cap: '29010', provinceId: 38},
{id: 456, value: 'mompantero', cap: '10059', provinceId: 1},
{id: 2359, value: 'corteolona', cap: '27014', provinceId: 16},
{id: 39, value: 'villarboit', cap: '13030', provinceId: 2},
{id: 4499, value: 'cesenatico', cap: '47042', provinceId: 45},
{id: 5111, value: 'cossignano', cap: '63067', provinceId: 62},
{id: 6051, value: 'presenzano', cap: '81050', provinceId: 74},
{id: 1801, value: 'rescaldina', cap: '20027', provinceId: 13},
{id: 4424, value: 'malalbergo', cap: '40051', provinceId: 42},
{id: 458, value: 'moncalieri', cap: '10024', provinceId: 1},
{id: 459, value: 'moncenisio', cap: '10050', provinceId: 1},
{id: 958, value: 'moncestino', cap: '15020', provinceId: 6},
{id: 8012, value: 'villaputzu', cap: '09040', provinceId: 102},
{id: 1057, value: 'callabiana', cap: '13821', provinceId: 7},
{id: 7080, value: 'villapiana', cap: '87076', provinceId: 86},
{id: 3648, value: 'montagnana', cap: '35044', provinceId: 28},
{id: 1895, value: 'brusaporto', cap: '24060', provinceId: 14},
{id: 803, value: 'mombaldone', cap: '14050', provinceId: 5},
{id: 4141, value: 'montebruno', cap: '16025', provinceId: 36},
{id: 706, value: 'scarnafigi', cap: '12030', provinceId: 4},
{id: 2328, value: 'calvignano', cap: '27040', provinceId: 16},
{id: 2387, value: 'lungavilla', cap: '27053', provinceId: 16},
{id: 5676, value: 'crognaleto', cap: '64043', provinceId: 69},
{id: 7964, value: 'portoscuso', cap: '09010', provinceId: 107},
{id: 3748, value: 'porto viro', cap: '45014', provinceId: 29},
{id: 8007, value: 'villacidro', cap: '09039', provinceId: 106},
{id: 2421, value: 'portalbera', cap: '27040', provinceId: 16},
{id: 6484, value: 'ricigliano', cap: '84020', provinceId: 78},
{id: 5268, value: 'montebuono', cap: '02040', provinceId: 64},
{id: 373, value: 'cercenasco', cap: '10060', provinceId: 1},
{id: 4581, value: 'montecarlo', cap: '55015', provinceId: 48},
{id: 6938, value: 'aprigliano', cap: '87051', provinceId: 86},
{id: 6180, value: 'calvizzano', cap: '80012', provinceId: 76},
{id: 7330, value: 'vallelonga', cap: '89821', provinceId: 90},
{id: 4557, value: 'pontremoli', cap: '54027', provinceId: 47},
{id: 4907, value: 'montecchio', cap: '05020', provinceId: 58},
{id: 4085, value: 'pontinvrea', cap: '17042', provinceId: 35},
{id: 2940, value: 'villabassa', cap: '39039', provinceId: 21},
{id: 992, value: 'pontestura', cap: '15027', provinceId: 6},
{id: 1640, value: 'postalesio', cap: '23010', provinceId: 12},
{id: 3518, value: 'povegliano', cap: '31050', provinceId: 26},
{id: 2043, value: 'schilpario', cap: '24020', provinceId: 14},
{id: 4042, value: 'balestrino', cap: '17020', provinceId: 35},
{id: 205, value: 'cravanzana', cap: '12050', provinceId: 4},
{id: 5669, value: 'cermignano', cap: '64037', provinceId: 69},
{id: 7439, value: 'villafrati', cap: '90030', provinceId: 92},
{id: 507, value: 'prarostino', cap: '10060', provinceId: 1},
{id: 8006, value: 'vallermosa', cap: '09010', provinceId: 102},
{id: 1041, value: 'villadeati', cap: '15020', provinceId: 6},
{id: 7366, value: 'balestrate', cap: '90041', provinceId: 92},
{id: 5128, value: 'montappone', cap: '63835', provinceId: 109},
{id: 5962, value: 'montaquila', cap: '86070', provinceId: 73},
{id: 3303, value: 'crespadoro', cap: '36070', provinceId: 24},
{id: 7324, value: 'sorianello', cap: '89831', provinceId: 90},
{id: 5797, value: 'montazzoli', cap: '66030', provinceId: 71},
{id: 442, value: 'lusernetta', cap: '10060', provinceId: 1},
{id: 1070, value: 'crevacuore', cap: '13864', provinceId: 7},
{id: 4413, value: 'crevalcore', cap: '40014', provinceId: 42},
{id: 2022, value: 'pradalunga', cap: '24020', provinceId: 14},
{id: 3348, value: 'pozzoleone', cap: '36050', provinceId: 24},
{id: 2248, value: 'pozzolengo', cap: '25010', provinceId: 15},
{id: 3770, value: 'cercivento', cap: '33020', provinceId: 30},
{id: 1899, value: 'calvenzano', cap: '24040', provinceId: 14},
{id: 253, value: 'mombarcaro', cap: '12070', provinceId: 4},
{id: 5122, value: 'maltignano', cap: '63085', provinceId: 62},
{id: 3660, value: 'pontelongo', cap: '35029', provinceId: 28},
{id: 116, value: 'sillavengo', cap: '28064', provinceId: 3},
{id: 5871, value: 'colletorto', cap: '86044', provinceId: 72},
{id: 5245, value: 'cittareale', cap: '02010', provinceId: 64},
{id: 1001, value: 'quattordio', cap: '15028', provinceId: 6},
{id: 1524, value: 'maslianico', cap: '22026', provinceId: 11},
{id: 8020, value: 'quartucciu', cap: '09044', provinceId: 102},
{id: 452, value: 'mercenasco', cap: '10010', provinceId: 1},
{id: 643, value: 'collobiano', cap: '13030', provinceId: 2},
{id: 3624, value: 'cittadella', cap: '35013', provinceId: 28},
{id: 6683, value: 'cisternino', cap: '72014', provinceId: 82},
{id: 5672, value: 'colonnella', cap: '64010', provinceId: 69},
{id: 8114, value: 'serravalle', cap: '47899', provinceId: 111},
{id: 7658, value: 'mascalucia', cap: '95030', provinceId: 97},
{id: 3753, value: 'arta terme', cap: '33022', provinceId: 30},
{id: 3777, value: 'comeglians', cap: '33023', provinceId: 30},
{id: 6900, value: 'cirigliano', cap: '75010', provinceId: 85},
{id: 4425, value: 'marzabotto', cap: '40043', provinceId: 42},
{id: 1000, value: 'quargnento', cap: '15044', provinceId: 6},
{id: 1983, value: 'martinengo', cap: '24057', provinceId: 14},
{id: 6739, value: 'martignano', cap: '73020', provinceId: 83},
{id: 6274, value: 'calabritto', cap: '83040', provinceId: 77},
{id: 7987, value: 'serramanna', cap: '09038', provinceId: 106},
{id: 1311, value: 'cadrezzate', cap: '21020', provinceId: 10},
{id: 3572, value: 'martellago', cap: '30030', provinceId: 27},
{id: 4259, value: 'collecchio', cap: '43044', provinceId: 39},
{id: 4895, value: 'attigliano', cap: '05012', provinceId: 58},
{id: 5526, value: 'settefrati', cap: '03040', provinceId: 67},
{id: 4934, value: 'colbordolo', cap: '61022', provinceId: 59},
{id: 7690, value: 'mazzarrone', cap: '95040', provinceId: 97},
{id: 3183, value: 'bussolengo', cap: '37012', provinceId: 23},
{id: 5583, value: 'collarmele', cap: '67040', provinceId: 68},
{id: 4844, value: 'collazzone', cap: '06050', provinceId: 57},
{id: 2154, value: 'collebeato', cap: '25060', provinceId: 15},
{id: 6741, value: 'melendugno', cap: '73026', provinceId: 83},
{id: 2291, value: 'valvestino', cap: '25080', provinceId: 15},
{id: 6719, value: 'collepasso', cap: '73040', provinceId: 83},
{id: 5124, value: 'massignano', cap: '63061', provinceId: 62},
{id: 5335, value: 'colleferro', cap: '00034', provinceId: 65},
{id: 3642, value: 'massanzago', cap: '35010', provinceId: 28},
{id: 2805, value: 'massalengo', cap: '26815', provinceId: 20},
{id: 6743, value: 'melpignano', cap: '73020', provinceId: 83},
{id: 5248, value: 'collegiove', cap: '02020', provinceId: 64},
{id: 5584, value: 'collelongo', cap: '67050', provinceId: 68},
{id: 4783, value: 'radicofani', cap: '53040', provinceId: 54},
{id: 5482, value: 'collepardo', cap: '03010', provinceId: 67},
{id: 1217, value: 'aymavilles', cap: '11010', provinceId: 9},
{id: 8079, value: 'sennariolo', cap: '09078', provinceId: 103},
{id: 3857, value: 'sedegliano', cap: '33039', provinceId: 30},
{id: 5722, value: 'manoppello', cap: '65024', provinceId: 70},
{id: 6844, value: 'missanello', cap: '85010', provinceId: 84},
{id: 3478, value: 'cordignano', cap: '31016', provinceId: 26},
{id: 4734, value: 'chitignano', cap: '52010', provinceId: 53},
{id: 4510, value: 'modigliana', cap: '47015', provinceId: 45},
{id: 2656, value: 'redondesco', cap: '46010', provinceId: 18},
{id: 828, value: 'refrancore', cap: '14030', provinceId: 5},
{id: 3521, value: 'refrontolo', cap: '31020', provinceId: 26},
{id: 379, value: 'chiaverano', cap: '10010', provinceId: 1},
{id: 1278, value: 'valpelline', cap: '11010', provinceId: 9},
{id: 1734, value: 'correzzana', cap: '20856', provinceId: 108},
{id: 5264, value: 'marcetelli', cap: '02020', provinceId: 64},
{id: 3627, value: 'correzzola', cap: '35020', provinceId: 28},
{id: 5053, value: 'corridonia', cap: '62014', provinceId: 61},
{id: 3839, value: 'remanzacco', cap: '33047', provinceId: 30},
{id: 784, value: 'cortandone', cap: '14013', provinceId: 5},
{id: 1845, value: 'villasanta', cap: '20852', provinceId: 108},
{id: 8013, value: 'villasalto', cap: '09040', provinceId: 102},
{id: 786, value: 'cortazzone', cap: '14010', provinceId: 5},
{id: 1695, value: 'buccinasco', cap: '20090', provinceId: 13},
{id: 1228, value: 'charvensod', cap: '11020', provinceId: 9},
{id: 5357, value: 'marcellina', cap: '00010', provinceId: 65},
{id: 1380, value: 'marchirolo', cap: '21030', provinceId: 10},
{id: 510, value: 'quagliuzzo', cap: '10010', provinceId: 1},
{id: 1729, value: 'concorezzo', cap: '20863', provinceId: 108},
{id: 9455, value: 'ratschings', cap: '39040', provinceId: 21},
{id: 7555, value: 'calamonaci', cap: '92010', provinceId: 94},
{id: 5265, value: 'micigliano', cap: '02010', provinceId: 64},
{id: 5796, value: 'miglianico', cap: '66010', provinceId: 71},
{id: 4464, value: 'migliarino', cap: '44027', provinceId: 43},
{id: 5034, value: 'senigallia', cap: '60019', provinceId: 60},
{id: 7123, value: 'miglierina', cap: '88040', provinceId: 87},
{id: 6910, value: 'miglionico', cap: '75010', provinceId: 85},
{id: 6946, value: 'buonvicino', cap: '87020', provinceId: 86},
{id: 3477, value: 'conegliano', cap: '31015', provinceId: 26},
{id: 3986, value: 'chiusanico', cap: '18027', provinceId: 34},
{id: 2202, value: 'marmentino', cap: '25060', provinceId: 15},
{id: 7785, value: 'putifigari', cap: '07040', provinceId: 100},
{id: 6211, value: 'marigliano', cap: '80034', provinceId: 76},
{id: 1283, value: 'villeneuve', cap: '11018', provinceId: 9},
{id: 3383, value: 'villaverla', cap: '36030', provinceId: 24},
{id: 4579, value: 'minucciano', cap: '55034', provinceId: 48},
{id: 6620, value: 'conversano', cap: '70014', provinceId: 80},
{id: 1308, value: 'buguggiate', cap: '21020', provinceId: 10},
{id: 2389, value: 'marcignago', cap: '27020', provinceId: 16},
{id: 6035, value: 'marcianise', cap: '81025', provinceId: 74},
{id: 140, value: 'barbaresco', cap: '12050', provinceId: 4},
{id: 7761, value: 'luogosanto', cap: '07020', provinceId: 104},
{id: 4523, value: 'verghereto', cap: '47028', provinceId: 45},
{id: 5894, value: 'montelongo', cap: '86040', provinceId: 72},
{id: 5895, value: 'montemitro', cap: '86030', provinceId: 72},
{id: 2644, value: 'monzambano', cap: '46040', provinceId: 18},
{id: 4781, value: 'poggibonsi', cap: '53036', provinceId: 54},
{id: 4872, value: 'poggiodomo', cap: '06040', provinceId: 57},
{id: 1531, value: 'montemezzo', cap: '22010', provinceId: 11},
{id: 3445, value: 'sovramonte', cap: '32030', provinceId: 25},
{id: 816, value: 'montemagno', cap: '14030', provinceId: 5},
{id: 9468, value: 'st pankraz', cap: '39010', provinceId: 21},
{id: 818, value: 'moransengo', cap: '14023', provinceId: 5},
{id: 7090, value: 'botricello', cap: '88070', provinceId: 87},
{id: 144, value: 'battifollo', cap: '12070', provinceId: 4},
{id: 7165, value: 'antonimina', cap: '89040', provinceId: 88},
{id: 5138, value: 'montelparo', cap: '63853', provinceId: 109},
{id: 7435, value: 'valledolmo', cap: '90029', provinceId: 92},
{id: 6847, value: 'montemurro', cap: '85053', provinceId: 84},
{id: 6724, value: 'cutrofiano', cap: '73020', provinceId: 83},
{id: 5107, value: 'castignano', cap: '63072', provinceId: 62},
{id: 3332, value: 'monteviale', cap: '36050', provinceId: 24},
{id: 3680, value: 'stanghella', cap: '35048', provinceId: 28},
{id: 3908, value: 'staranzano', cap: '34079', provinceId: 31},
{id: 7804, value: 'valledoria', cap: '07039', provinceId: 100},
{id: 9447, value: 'partschins', cap: '39020', provinceId: 21},
{id: 156, value: 'bossolasco', cap: '12060', provinceId: 4},
{id: 4599, value: 'cutigliano', cap: '51024', provinceId: 49},
{id: 6141, value: 'pietraroja', cap: '82030', provinceId: 75},
{id: 437, value: 'lombardore', cap: '10040', provinceId: 1},
{id: 1059, value: 'camburzano', cap: '13891', provinceId: 7},
{id: 438, value: 'lombriasco', cap: '10040', provinceId: 1},
{id: 4826, value: 'montemurlo', cap: '59013', provinceId: 56},
{id: 4711, value: 'riparbella', cap: '56046', provinceId: 52},
{id: 6176, value: 'boscoreale', cap: '80041', provinceId: 76},
{id: 2840, value: 'bressanone', cap: '39042', provinceId: 21},
{id: 7934, value: 'domusnovas', cap: '09015', provinceId: 107},
{id: 1468, value: 'cavallasca', cap: '22020', provinceId: 11},
{id: 5997, value: 'camigliano', cap: '81050', provinceId: 74},
{id: 4533, value: 'montescudo', cap: '47854', provinceId: 46},
{id: 2593, value: 'spinadesco', cap: '26020', provinceId: 17},
{id: 68, value: 'cavallirio', cap: '28010', provinceId: 3},
{id: 2400, value: 'montescano', cap: '27040', provinceId: 16},
{id: 4814, value: 'pitigliano', cap: '58017', provinceId: 55},
{id: 3996, value: 'dolceacqua', cap: '18035', provinceId: 34},
{id: 2968, value: 'brentonico', cap: '38060', provinceId: 22},
{id: 6643, value: 'spinazzola', cap: '76014', provinceId: 110},
{id: 1677, value: 'barlassina', cap: '20825', provinceId: 108},
{id: 5554, value: 'barisciano', cap: '67021', provinceId: 68},
{id: 4777, value: 'monticiano', cap: '53015', provinceId: 54},
{id: 160, value: 'briga alta', cap: '18025', provinceId: 4},
{id: 5601, value: 'montereale', cap: '67015', provinceId: 68},
{id: 4554, value: 'montignoso', cap: '54038', provinceId: 47},
{id: 1532, value: 'montorfano', cap: '22030', provinceId: 11},
{id: 704, value: 'savigliano', cap: '12038', provinceId: 4},
{id: 7820, value: 'bari sardo', cap: '08042', provinceId: 105},
{id: 5536, value: 'vallecorsa', cap: '03020', provinceId: 67},
{id: 872, value: 'bassignana', cap: '15042', provinceId: 6},
{id: 3123, value: 'sporminore', cap: '38010', provinceId: 22},
{id: 5226, value: 'vignanello', cap: '01039', provinceId: 63},
{id: 7125, value: 'montepaone', cap: '88060', provinceId: 87},
{id: 5147, value: 'montottone', cap: '63843', provinceId: 109},
{id: 299, value: 'pocapaglia', cap: '12060', provinceId: 4},
{id: 6736, value: 'lizzanello', cap: '73023', provinceId: 83},
{id: 7192, value: 'delianuova', cap: '89012', provinceId: 88},
{id: 6456, value: 'morigerati', cap: '84030', provinceId: 78},
{id: 7409, value: 'montelepre', cap: '90040', provinceId: 92},
{id: 6320, value: 'monteverde', cap: '83049', provinceId: 77},
{id: 6993, value: 'longobardi', cap: '87030', provinceId: 86},
{id: 5510, value: 'pontecorvo', cap: '03037', provinceId: 67},
{id: 3330, value: 'montegalda', cap: '36047', provinceId: 24},
{id: 4676, value: 'rio marina', cap: '57038', provinceId: 51},
{id: 2450, value: 'scaldasole', cap: '27020', provinceId: 16},
{id: 6316, value: 'montefusco', cap: '83030', provinceId: 77},
{id: 2553, value: 'moscazzano', cap: '26010', provinceId: 17},
{id: 215, value: 'farigliano', cap: '12060', provinceId: 4},
{id: 2675, value: 'villa poma', cap: '46020', provinceId: 18},
{id: 4366, value: 'montecreto', cap: '41025', provinceId: 41},
{id: 4108, value: 'borzonasca', cap: '16041', provinceId: 36},
{id: 5071, value: 'morrovalle', cap: '62010', provinceId: 61},
{id: 6994, value: 'longobucco', cap: '87066', provinceId: 86},
{id: 3050, value: 'lona lases', cap: '38040', provinceId: 22},
{id: 5904, value: 'pietracupa', cap: '86020', provinceId: 72},
{id: 2651, value: 'pomponesco', cap: '46030', provinceId: 18},
{id: 7289, value: 'brognaturo', cap: '89822', provinceId: 90},
{id: 5133, value: 'montegallo', cap: '63094', provinceId: 62},
{id: 4860, value: 'montefalco', cap: '06036', provinceId: 57},
{id: 966, value: 'montegioco', cap: '15050', provinceId: 6},
{id: 5486, value: 'falvaterra', cap: '03020', provinceId: 67},
{id: 300, value: 'polonghera', cap: '12030', provinceId: 4},
{id: 6836, value: 'laurenzana', cap: '85014', provinceId: 84},
{id: 6510, value: 'santomenna', cap: '84020', provinceId: 78},
{id: 5523, value: 'santopadre', cap: '03030', provinceId: 67},
{id: 3316, value: 'lastebasse', cap: '36040', provinceId: 24},
{id: 2521, value: 'cella dati', cap: '26040', provinceId: 17},
{id: 772, value: 'cellarengo', cap: '14010', provinceId: 5},
{id: 5732, value: 'pietranico', cap: '65020', provinceId: 70},
{id: 522, value: 'robassomero', cap: '10070', provinceId: 1},
{id: 5623, value: 'rivisondoli', cap: '67036', provinceId: 68},
{id: 4823, value: 'semproniano', cap: '58055', provinceId: 55},
{id: 512, value: 'quincinetto', cap: '10010', provinceId: 1},
{id: 8080, value: 'siamaggiore', cap: '09070', provinceId: 103},
{id: 8015, value: 'villasimius', cap: '09049', provinceId: 102},
{id: 2418, value: 'pinarolo po', cap: '27040', provinceId: 16},
{id: 3691, value: 'vigodarzere', cap: '35010', provinceId: 28},
{id: 5734, value: 'roccamorice', cap: '65020', provinceId: 70},
{id: 6145, value: 'puglianello', cap: '82030', provinceId: 75},
{id: 2070, value: 'valbondione', cap: '24020', provinceId: 14},
{id: 1268, value: 'saint denis', cap: '11023', provinceId: 9},
{id: 3453, value: 'vodo cadore', cap: '32040', provinceId: 25},
{id: 6073, value: 'sant arpino', cap: '81030', provinceId: 74},
{id: 5906, value: 'provvidenti', cap: '86040', provinceId: 72},
{id: 4032, value: 'ventimiglia', cap: '18039', provinceId: 34},
{id: 5164, value: 'servigliano', cap: '63839', provinceId: 109},
{id: 1853, value: 'vanzaghello', cap: '20020', provinceId: 13},
{id: 1185, value: 'piedimulera', cap: '28885', provinceId: 8},
{id: 3151, value: 'valfloriana', cap: '38040', provinceId: 22},
{id: 6592, value: 'stornarella', cap: '71048', provinceId: 79},
{id: 824, value: 'pino d asti', cap: '14020', provinceId: 5},
{id: 4168, value: 'valbrevenna', cap: '16010', provinceId: 36},
{id: 7148, value: 'settingiano', cap: '88040', provinceId: 87},
{id: 8096, value: 'villaurbana', cap: '09080', provinceId: 103},
{id: 2654, value: 'quingentole', cap: '46020', provinceId: 18},
{id: 1121, value: 'vallanzengo', cap: '13847', provinceId: 7},
{id: 5634, value: 'sante marie', cap: '67067', provinceId: 68},
{id: 2259, value: 'roccafranca', cap: '25030', provinceId: 15},
{id: 6052, value: 'raviscanina', cap: '81017', provinceId: 74},
{id: 1122, value: 'valle mosso', cap: '13825', provinceId: 7},
{id: 3676, value: 'sant urbano', cap: '35040', provinceId: 28},
{id: 5624, value: 'roccacasale', cap: '67030', provinceId: 68},
{id: 4373, value: 'pievepelago', cap: '41027', provinceId: 41},
{id: 6142, value: 'pietrelcina', cap: '82020', provinceId: 75},
{id: 4817, value: 'santa fiora', cap: '58037', provinceId: 55},
{id: 7023, value: 'piane crati', cap: '87050', provinceId: 86},
{id: 7354, value: 'santa ninfa', cap: '91029', provinceId: 91},
{id: 534, value: 'salbertrand', cap: '10050', provinceId: 1},
{id: 2676, value: 'villimpenta', cap: '46039', provinceId: 18},
{id: 6330, value: 'paternopoli', cap: '83052', provinceId: 77},
{id: 7025, value: 'pietrapaola', cap: '87060', provinceId: 86},
{id: 7328, value: 'stefanaconi', cap: '89843', provinceId: 90},
{id: 1833, value: 'truccazzano', cap: '20060', provinceId: 13},
{id: 4584, value: 'pietrasanta', cap: '55045', provinceId: 48},
{id: 4093, value: 'stellanello', cap: '17020', provinceId: 35},
{id: 5644, value: 'tagliacozzo', cap: '67069', provinceId: 68},
{id: 1658, value: 'valdidentro', cap: '23038', provinceId: 12},
{id: 1016, value: 'sardigliano', cap: '15060', provinceId: 6},
{id: 4161, value: 'serra ricco', cap: '16010', provinceId: 36},
{id: 5934, value: 'vinchiaturo', cap: '86019', provinceId: 72},
{id: 6892, value: 'viggianello', cap: '85040', provinceId: 84},
{id: 4784, value: 'radicondoli', cap: '53030', provinceId: 54},
{id: 3836, value: 'ravascletto', cap: '33020', provinceId: 30},
{id: 4887, value: 'valfabbrica', cap: '06029', provinceId: 57},
{id: 8065, value: 'paulilatino', cap: '09070', provinceId: 103},
{id: 4008, value: 'pietrabruna', cap: '18010', provinceId: 34},
{id: 6855, value: 'pietragalla', cap: '85016', provinceId: 84},
{id: 9465, value: 'st lorenzen', cap: '39030', provinceId: 21},
{id: 4158, value: 'sant olcese', cap: '16010', provinceId: 36},
{id: 7513, value: 'roccalumera', cap: '98027', provinceId: 93},
{id: 2239, value: 'pian camuno', cap: '25050', provinceId: 15},
{id: 5400, value: 'sant oreste', cap: '00060', provinceId: 65},
{id: 4815, value: 'roccalbegna', cap: '58053', provinceId: 55},
{id: 5294, value: 'stimigliano', cap: '02048', provinceId: 64},
{id: 4280, value: 'roccabianca', cap: '43010', provinceId: 39},
{id: 4749, value: 'pian di sco', cap: '52026', provinceId: 53},
{id: 3261, value: 'vestenanova', cap: '37030', provinceId: 23},
{id: 4518, value: 'santa sofia', cap: '47018', provinceId: 45},
{id: 5402, value: 'saracinesco', cap: '00020', provinceId: 65},
{id: 4871, value: 'pietralunga', cap: '06026', provinceId: 57},
{id: 7024, value: 'pietrafitta', cap: '87050', provinceId: 86},
{id: 6047, value: 'pontelatone', cap: '81040', provinceId: 74},
{id: 3959, value: 'spilimbergo', cap: '33097', provinceId: 33},
{id: 580, value: 'traversella', cap: '10080', provinceId: 1},
{id: 4919, value: 'san venanzo', cap: '05010', provinceId: 58},
{id: 6480, value: 'postiglione', cap: '84026', provinceId: 78},
{id: 2014, value: 'piazzatorre', cap: '24010', provinceId: 14},
{id: 4713, value: 'san miniato', cap: '56028', provinceId: 52},
{id: 9443, value: 'welschnofen', cap: '39056', provinceId: 21},
{id: 7333, value: 'zaccanopoli', cap: '89867', provinceId: 90},
{id: 5920, value: 'san massimo', cap: '86027', provinceId: 72},
{id: 5213, value: 'ronciglione', cap: '01037', provinceId: 63},
{id: 2285, value: 'travagliato', cap: '25039', provinceId: 15},
{id: 4516, value: 'roncofreddo', cap: '47020', provinceId: 45},
{id: 7063, value: 'scala coeli', cap: '87060', provinceId: 86},
{id: 7539, value: 'torregrotta', cap: '98040', provinceId: 93},
{id: 8067, value: 'riola sardo', cap: '09070', provinceId: 103},
{id: 7234, value: 'san lorenzo', cap: '89069', provinceId: 88},
{id: 4652, value: 'san godenzo', cap: '50060', provinceId: 50},
{id: 1108, value: 'sandigliano', cap: '13876', provinceId: 7},
{id: 4490, value: 'riolo terme', cap: '48025', provinceId: 44},
{id: 5084, value: 'san ginesio', cap: '62026', provinceId: 61},
{id: 7684, value: 'trecastagni', cap: '95039', provinceId: 97},
{id: 5646, value: 'tornimparte', cap: '67049', provinceId: 68},
{id: 5838, value: 'tornareccio', cap: '66046', provinceId: 71},
{id: 6853, value: 'pescopagano', cap: '85020', provinceId: 84},
{id: 5409, value: 'vallepietra', cap: '00020', provinceId: 65},
{id: 739, value: 'vottignasco', cap: '12020', provinceId: 4},
{id: 5503, value: 'pescosolido', cap: '03030', provinceId: 67},
{id: 7238, value: 'san roberto', cap: '89050', provinceId: 88},
{id: 2018, value: 'ponteranica', cap: '24010', provinceId: 14},
{id: 4012, value: 'pontedassio', cap: '18027', provinceId: 34},
{id: 8097, value: 'villa verde', cap: '09090', provinceId: 103},
{id: 7974, value: 'san sperate', cap: '09026', provinceId: 102},
{id: 5292, value: 'scandriglia', cap: '02038', provinceId: 64},
{id: 6071, value: 'san tammaro', cap: '81050', provinceId: 74},
{id: 7531, value: 'san teodoro', cap: '98030', provinceId: 93},
{id: 991, value: 'pontecurone', cap: '15055', provinceId: 6},
{id: 2297, value: 'villachiara', cap: '25030', provinceId: 15},
{id: 2017, value: 'ponte nossa', cap: '24028', provinceId: 14},
{id: 3524, value: 'riese pio x', cap: '31039', provinceId: 26},
{id: 3359, value: 'san nazario', cap: '36020', provinceId: 24},
{id: 7890, value: 'san teodoro', cap: '08020', provinceId: 104},
{id: 3955, value: 'san quirino', cap: '33080', provinceId: 33},
{id: 2420, value: 'ponte nizza', cap: '27050', provinceId: 16},
{id: 826, value: 'portacomaro', cap: '14037', provinceId: 5},
{id: 6356, value: 'scampitella', cap: '83050', provinceId: 77},
{id: 4647, value: 'pontassieve', cap: '50065', provinceId: 50},
{id: 7223, value: 'portigliola', cap: '89040', provinceId: 88},
{id: 3735, value: 'porto tolle', cap: '45018', provinceId: 29},
{id: 3580, value: 'portogruaro', cap: '30026', provinceId: 27},
{id: 4192, value: 'portovenere', cap: '19025', provinceId: 37},
{id: 6156, value: 'san nazzaro', cap: '82018', provinceId: 75},
{id: 3581, value: 'pramaggiore', cap: '30020', provinceId: 27},
{id: 508, value: 'prascorsano', cap: '10080', provinceId: 1},
{id: 5535, value: 'trivigliano', cap: '03010', provinceId: 67},
{id: 606, value: 'villareggia', cap: '10030', provinceId: 1},
{id: 3240, value: 'sanguinetto', cap: '37058', provinceId: 23},
{id: 3964, value: 'vito d asio', cap: '33090', provinceId: 33},
{id: 4754, value: 'sansepolcro', cap: '52037', provinceId: 53},
{id: 605, value: 'villar dora', cap: '10040', provinceId: 1},
{id: 833, value: 'roccaverano', cap: '14050', provinceId: 5},
{id: 5228, value: 'vitorchiano', cap: '01030', provinceId: 63},
{id: 6241, value: 'sant antimo', cap: '80029', provinceId: 76},
{id: 7139, value: 'san sostene', cap: '88060', provinceId: 87},
{id: 4816, value: 'roccastrada', cap: '58036', provinceId: 55},
{id: 6594, value: 'trinitapoli', cap: '76015', provinceId: 110},
{id: 842, value: 'scurzolengo', cap: '14030', provinceId: 5},
{id: 4514, value: 'premilcuore', cap: '47010', provinceId: 45},
{id: 5975, value: 'roccasicura', cap: '86080', provinceId: 73},
{id: 4717, value: 'terricciola', cap: '56030', provinceId: 52},
{id: 6057, value: 'roccaromana', cap: '81051', provinceId: 74},
{id: 4017, value: 'riva ligure', cap: '18015', provinceId: 34},
{id: 3551, value: 'zero branco', cap: '31059', provinceId: 26},
{id: 3233, value: 'roverchiara', cap: '37050', provinceId: 23},
{id: 4098, value: 'vado ligure', cap: '17047', provinceId: 35},
{id: 121, value: 'terdobbiate', cap: '28070', provinceId: 3},
{id: 4591, value: 'vagli sotto', cap: '55030', provinceId: 48},
{id: 4387, value: 'spilamberto', cap: '41057', provinceId: 41},
{id: 2483, value: 'villanterio', cap: '27019', provinceId: 16},
{id: 1023, value: 'solonghello', cap: '15020', provinceId: 6},
{id: 2815, value: 'san fiorano', cap: '26848', provinceId: 20},
{id: 3256, value: 'trevenzuolo', cap: '37060', provinceId: 23},
{id: 509, value: 'pratiglione', cap: '10080', provinceId: 1},
{id: 109, value: 'prato sesia', cap: '28077', provinceId: 3},
{id: 6696, value: 'torchiarolo', cap: '72020', provinceId: 82},
{id: 7516, value: 'rodi milici', cap: '98059', provinceId: 93},
{id: 4194, value: 'riomaggiore', cap: '19017', provinceId: 37},
{id: 5420, value: 'san cesareo', cap: '00030', provinceId: 65},
{id: 7608, value: 'san cataldo', cap: '93017', provinceId: 95},
{id: 5410, value: 'vallinfreda', cap: '00020', provinceId: 65},
{id: 2906, value: 'san candido', cap: '39038', provinceId: 21},
{id: 5910, value: 'roccavivara', cap: '86020', provinceId: 72},
{id: 3739, value: 'san bellino', cap: '45020', provinceId: 29},
{id: 8004, value: 'ussaramanna', cap: '09020', provinceId: 106},
{id: 5908, value: 'ripabottoni', cap: '86040', provinceId: 72},
{id: 2581, value: 'san bassano', cap: '26020', provinceId: 17},
{id: 7969, value: 'san basilio', cap: '09040', provinceId: 102},
{id: 4030, value: 'vallecrosia', cap: '18019', provinceId: 34},
{id: 6635, value: 'poggiorsini', cap: '70020', provinceId: 80},
{id: 7351, value: 'poggioreale', cap: '91020', provinceId: 91},
{id: 3523, value: 'revine lago', cap: '31020', provinceId: 26},
{id: 2292, value: 'verolanuova', cap: '25028', provinceId: 15},
{id: 6862, value: 'ripacandida', cap: '85020', provinceId: 84},
{id: 4154, value: 'rossiglione', cap: '16010', provinceId: 36},
{id: 3831, value: 'premariacco', cap: '33040', provinceId: 30},
{id: 604, value: 'villarbasse', cap: '10090', provinceId: 1},
{id: 3595, value: 'abano terme', cap: '35031', provinceId: 28},
{id: 5181, value: 'capodimonte', cap: '01010', provinceId: 63},
{id: 1320, value: 'casalzuigno', cap: '21030', provinceId: 10},
{id: 7381, value: 'castelbuono', cap: '90013', provinceId: 92},
{id: 5802, value: 'mozzagrogna', cap: '66030', provinceId: 71},
{id: 2645, value: 'motteggiana', cap: '46020', provinceId: 18},
{id: 1086, value: 'mottalciata', cap: '13874', provinceId: 7},
{id: 3621, value: 'castelbaldo', cap: '35040', provinceId: 28},
{id: 6626, value: 'locorotondo', cap: '70010', provinceId: 80},
{id: 651, value: 'formigliana', cap: '13030', provinceId: 2},
{id: 4479, value: 'brisighella', cap: '48013', provinceId: 44},
{id: 1307, value: 'brusimpiano', cap: '21050', provinceId: 10},
{id: 6089, value: 'casapesenna', cap: '81030', provinceId: 74},
{id: 6616, value: 'casamassima', cap: '70010', provinceId: 80},
{id: 5068, value: 'montelupone', cap: '62010', provinceId: 61},
{id: 5794, value: 'lettopalena', cap: '66010', provinceId: 71},
{id: 63, value: 'casalvolone', cap: '28060', provinceId: 3},
{id: 6848, value: 'muro lucano', cap: '85054', provinceId: 84},
{id: 3620, value: 'casalserugo', cap: '35020', provinceId: 28},
{id: 2620, value: 'casalromano', cap: '46040', provinceId: 18},
{id: 899, value: 'casalnoceto', cap: '15052', provinceId: 6},
{id: 3810, value: 'mortegliano', cap: '33050', provinceId: 30},
{id: 2515, value: 'casalmorano', cap: '26020', provinceId: 17},
{id: 5754, value: 'bucchianico', cap: '66011', provinceId: 71},
{id: 174, value: 'casalgrasso', cap: '12030', provinceId: 4},
{id: 4309, value: 'casalgrande', cap: '42013', provinceId: 40},
{id: 7949, value: 'las plassas', cap: '09020', provinceId: 106},
{id: 4574, value: 'fosciandora', cap: '55020', provinceId: 48},
{id: 6101, value: 'buonalbergo', cap: '82020', provinceId: 75},
{id: 6318, value: 'montemarano', cap: '83040', provinceId: 77},
{id: 5682, value: 'morro d oro', cap: '64020', provinceId: 69},
{id: 4937, value: 'fossombrone', cap: '61034', provinceId: 59},
{id: 337, value: 'bricherasio', cap: '10060', provinceId: 1},
{id: 3467, value: 'castelcucco', cap: '31030', provinceId: 26},
{id: 6666, value: 'montemesola', cap: '74020', provinceId: 81},
{id: 7174, value: 'bova marina', cap: '89035', provinceId: 88},
{id: 5865, value: 'castelmauro', cap: '86031', provinceId: 72},
{id: 4961, value: 'novafeltria', cap: '47863', provinceId: 46},
{id: 3710, value: 'castelmassa', cap: '45035', provinceId: 29},
{id: 1465, value: 'castelmarte', cap: '22030', provinceId: 11},
{id: 182, value: 'castelmagno', cap: '12020', provinceId: 4},
{id: 6566, value: 'manfredonia', cap: '71043', provinceId: 79},
{id: 7739, value: 'bortigiadas', cap: '07030', provinceId: 104},
{id: 862, value: 'alessandria', cap: '', provinceId: 6},
{id: 6293, value: 'fontanarosa', cap: '83040', provinceId: 77},
{id: 4415, value: 'fontanelice', cap: '40025', provinceId: 42},
{id: 2518, value: 'castelleone', cap: '26012', provinceId: 17},
{id: 1325, value: 'castellanza', cap: '21053', provinceId: 10},
{id: 904, value: 'castellania', cap: '15051', provinceId: 6},
{id: 7114, value: 'guardavalle', cap: '88065', provinceId: 87},
{id: 4933, value: 'casteldelci', cap: '47861', provinceId: 46},
{id: 6395, value: 'bracigliano', cap: '84082', provinceId: 78},
{id: 2801, value: 'maccastorna', cap: '26843', provinceId: 20},
{id: 9500, value: 'fonte nuova', cap: '00013', provinceId: 65},
{id: 5491, value: 'fontechiari', cap: '03030', provinceId: 67},
{id: 2886, value: 'naz sciaves', cap: '39040', provinceId: 21},
{id: 7175, value: 'brancaleone', cap: '89036', provinceId: 88},
{id: 7950, value: 'lunamatrona', cap: '09022', provinceId: 106},
{id: 5060, value: 'loro piceno', cap: '62020', provinceId: 61},
{id: 9442, value: 'natz schabs', cap: '39040', provinceId: 21},
{id: 5363, value: 'montelanico', cap: '00030', provinceId: 65},
{id: 7309, value: 'nardodipace', cap: '89824', provinceId: 90},
{id: 5425, value: 'castelforte', cap: '04021', provinceId: 66},
{id: 2988, value: 'castelfondo', cap: '38020', provinceId: 22},
{id: 3282, value: 'bressanvido', cap: '36050', provinceId: 24},
{id: 5471, value: 'casalattico', cap: '03030', provinceId: 67},
{id: 5861, value: 'casacalenda', cap: '86043', provinceId: 72},
{id: 2991, value: 'castelnuovo', cap: '38050', provinceId: 22},
{id: 5197, value: 'graffignano', cap: '01020', provinceId: 63},
{id: 5786, value: 'gessopalena', cap: '66010', provinceId: 71},
{id: 653, value: 'ghislarengo', cap: '13030', provinceId: 2},
{id: 7397, value: 'giardinello', cap: '90040', provinceId: 92},
{id: 7199, value: 'gioia tauro', cap: '89013', provinceId: 88},
{id: 4576, value: 'giuncugnano', cap: '55030', provinceId: 48},
{id: 6731, value: 'giurdignano', cap: '73020', provinceId: 83},
{id: 6947, value: 'calopezzati', cap: '87060', provinceId: 86},
{id: 6667, value: 'monteparano', cap: '74020', provinceId: 81},
{id: 6905, value: 'gorgoglione', cap: '75010', provinceId: 85},
{id: 632, value: 'campertogno', cap: '13023', provinceId: 2},
{id: 270, value: 'montezemolo', cap: '12070', provinceId: 4},
{id: 5593, value: 'introdacqua', cap: '67030', provinceId: 68},
{id: 2796, value: 'graffignana', cap: '26813', provinceId: 20},
{id: 7645, value: 'caltagirone', cap: '95041', provinceId: 97},
{id: 1618, value: 'gerola alta', cap: '23010', provinceId: 12},
{id: 4432, value: 'monteveglio', cap: '40050', provinceId: 42},
{id: 7652, value: 'grammichele', cap: '95042', provinceId: 97},
{id: 4746, value: 'montevarchi', cap: '52025', provinceId: 53},
{id: 4418, value: 'granaglione', cap: '40045', provinceId: 42},
{id: 4430, value: 'monterenzio', cap: '40050', provinceId: 42},
{id: 2401, value: 'montesegale', cap: '27052', provinceId: 16},
{id: 4227, value: 'gropparello', cap: '29025', provinceId: 38},
{id: 1179, value: 'montescheno', cap: '28843', provinceId: 8},
{id: 420, value: 'groscavallo', cap: '10070', provinceId: 1},
{id: 5118, value: 'grottammare', cap: '63066', provinceId: 62},
{id: 6299, value: 'grottolella', cap: '83010', provinceId: 77},
{id: 1701, value: 'calvignasco', cap: '20080', provinceId: 13},
{id: 5964, value: 'monteroduni', cap: '86075', provinceId: 73},
{id: 7374, value: 'caltavuturo', cap: '90022', provinceId: 92},
{id: 2210, value: 'montichiari', cap: '25018', provinceId: 15},
{id: 3209, value: 'isola rizza', cap: '37050', provinceId: 23},
{id: 3291, value: 'cartigliano', cap: '36050', provinceId: 24},
{id: 410, value: 'frassinetto', cap: '10080', provinceId: 1},
{id: 2136, value: 'carpenedolo', cap: '25013', provinceId: 15},
{id: 1456, value: 'carate urio', cap: '22010', provinceId: 11},
{id: 7451, value: 'capri leone', cap: '98070', provinceId: 93},
{id: 8054, value: 'morgongiori', cap: '09090', provinceId: 103},
{id: 3184, value: 'buttapietra', cap: '37060', provinceId: 23},
{id: 5566, value: 'capitignano', cap: '67014', provinceId: 68},
{id: 5565, value: 'capistrello', cap: '67053', provinceId: 68},
{id: 7714, value: 'francofonte', cap: '96015', provinceId: 99},
{id: 6989, value: 'laino borgo', cap: '87014', provinceId: 86},
{id: 4566, value: 'camporgiano', cap: '55031', provinceId: 48},
{id: 3032, value: 'frassilongo', cap: '38050', provinceId: 22},
{id: 3555, value: 'camponogara', cap: '30010', provinceId: 27},
{id: 4112, value: 'campomorone', cap: '16014', provinceId: 36},
{id: 5860, value: 'campomarino', cap: '86042', provinceId: 72},
{id: 7644, value: 'calatabiano', cap: '95011', provinceId: 97},
{id: 6206, value: 'lacco ameno', cap: '80076', provinceId: 76},
{id: 4938, value: 'fratte rosa', cap: '61040', provinceId: 59},
{id: 7597, value: 'campofranco', cap: '93010', provinceId: 95},
{id: 2501, value: 'ca d andrea', cap: '26030', provinceId: 17},
{id: 3310, value: 'gambugliano', cap: '36050', provinceId: 24},
{id: 7746, value: 'calangianus', cap: '07023', provinceId: 104},
{id: 5104, value: 'campofilone', cap: '63828', provinceId: 109},
{id: 5424, value: 'campodimele', cap: '04020', provinceId: 66},
{id: 6846, value: 'montemilone', cap: '85020', provinceId: 84},
{id: 413, value: 'garzigliana', cap: '10060', provinceId: 1},
{id: 3601, value: 'arzergrande', cap: '35020', provinceId: 28},
{id: 5857, value: 'campochiaro', cap: '86020', provinceId: 72},
{id: 5139, value: 'montemonaco', cap: '63088', provinceId: 62},
{id: 2846, value: 'campo tures', cap: '39032', provinceId: 21},
{id: 6306, value: 'manocalzati', cap: '83030', provinceId: 77},
{id: 5664, value: 'castellalto', cap: '64020', provinceId: 69},
{id: 7462, value: 'fiumedinisi', cap: '98022', provinceId: 93},
{id: 4908, value: 'montefranco', cap: '05030', provinceId: 58},
{id: 7931, value: 'decimoputzu', cap: '09010', provinceId: 102},
{id: 7105, value: 'decollatura', cap: '88041', provinceId: 87},
{id: 3072, value: 'ospedaletto', cap: '38050', provinceId: 22},
{id: 4006, value: 'ospedaletti', cap: '18014', provinceId: 34},
{id: 4394, value: 'bentivoglio', cap: '40010', provinceId: 42},
{id: 1682, value: 'bernareggio', cap: '20881', provinceId: 108},
{id: 6450, value: 'montecorice', cap: '84060', provinceId: 78},
{id: 7266, value: 'ciro marina', cap: '88811', provinceId: 89},
{id: 7213, value: 'monasterace', cap: '89040', provinceId: 88},
{id: 5890, value: 'monacilioni', cap: '86040', provinceId: 72},
{id: 5066, value: 'montecosaro', cap: '62010', provinceId: 61},
{id: 805, value: 'mombercelli', cap: '14047', provinceId: 5},
{id: 1160, value: 'domodossola', cap: '28845', provinceId: 8},
{id: 3698, value: 'due carrare', cap: '35020', provinceId: 28},
{id: 3063, value: 'monclassico', cap: '38020', provinceId: 22},
{id: 5129, value: 'montedinove', cap: '63069', provinceId: 62},
{id: 254, value: 'mombasiglio', cap: '12070', provinceId: 4},
{id: 5054, value: 'esanatoglia', cap: '62024', provinceId: 61},
{id: 7834, value: 'escalaplano', cap: '08043', provinceId: 102},
{id: 2713, value: 'esino lario', cap: '23825', provinceId: 19},
{id: 4949, value: 'mombaroccio', cap: '61024', provinceId: 59},
{id: 3773, value: 'chiusaforte', cap: '33010', provinceId: 30},
{id: 2351, value: 'chignolo po', cap: '27013', provinceId: 16},
{id: 381, value: 'chiesanuova', cap: '10080', provinceId: 1},
{id: 6823, value: 'chiaromonte', cap: '85032', provinceId: 84},
{id: 5003, value: 'chiaravalle', cap: '60033', provinceId: 60},
{id: 7750, value: 'chiaramonti', cap: '07030', provinceId: 100},
{id: 7642, value: 'biancavilla', cap: '95033', provinceId: 97},
{id: 7930, value: 'decimomannu', cap: '09033', provinceId: 102},
{id: 2224, value: 'ospitaletto', cap: '25035', provinceId: 15},
{id: 1226, value: 'champdepraz', cap: '11020', provinceId: 9},
{id: 7573, value: 'montallegro', cap: '92010', provinceId: 94},
{id: 5144, value: 'monte urano', cap: '63813', provinceId: 109},
{id: 7349, value: 'pantelleria', cap: '91017', provinceId: 91},
{id: 5585, value: 'collepietro', cap: '67020', provinceId: 68},
{id: 2311, value: 'barbianello', cap: '27041', provinceId: 16},
{id: 7618, value: 'barrafranca', cap: '94012', provinceId: 96},
{id: 5039, value: 'acquacanina', cap: '62035', provinceId: 61},
{id: 1932, value: 'comun nuovo', cap: '24040', provinceId: 14},
{id: 2098, value: 'acquafredda', cap: '25010', provinceId: 15},
{id: 3197, value: 'concamarise', cap: '37050', provinceId: 23},
{id: 5251, value: 'concerviano', cap: '02020', provinceId: 64},
{id: 2208, value: 'monte isola', cap: '25050', provinceId: 15},
{id: 5253, value: 'contigliano', cap: '02043', provinceId: 64},
{id: 6136, value: 'pago veiano', cap: '82020', provinceId: 75},
{id: 6393, value: 'battipaglia', cap: '84091', provinceId: 78},
{id: 662, value: 'moncrivello', cap: '13040', provinceId: 2},
{id: 461, value: 'montalenghe', cap: '10090', provinceId: 1},
{id: 787, value: 'cortiglione', cap: '14040', provinceId: 5},
{id: 788, value: 'cossombrato', cap: '14020', provinceId: 5},
{id: 4890, value: 'acquasparta', cap: '05021', provinceId: 58},
{id: 4846, value: 'costacciaro', cap: '06021', provinceId: 57},
{id: 5713, value: 'civitaquana', cap: '65010', provinceId: 70},
{id: 645, value: 'cravagliana', cap: '13020', provinceId: 2},
{id: 646, value: 'crescentino', cap: '13044', provinceId: 2},
{id: 4412, value: 'crespellano', cap: '40056', provinceId: 42},
{id: 2793, value: 'crespiatica', cap: '26835', provinceId: 20},
{id: 860, value: 'acqui terme', cap: '15011', provinceId: 6},
{id: 7006, value: 'mongrassano', cap: '87040', provinceId: 86},
{id: 5244, value: 'cittaducale', cap: '02015', provinceId: 64},
{id: 1227, value: 'champorcher', cap: '11020', provinceId: 9},
{id: 75, value: 'dormelletto', cap: '28040', provinceId: 3},
{id: 405, value: 'fenestrelle', cap: '10060', provinceId: 1},
{id: 6604, value: 'alberobello', cap: '70011', provinceId: 80},
{id: 627, value: 'borgo d ale', cap: '13040', provinceId: 2},
{id: 7861, value: 'meana sardo', cap: '08030', provinceId: 101},
{id: 66, value: 'cavaglietto', cap: '28010', provinceId: 3},
{id: 2344, value: 'cava manara', cap: '27051', provinceId: 16},
{id: 7620, value: 'catenanuova', cap: '94010', provinceId: 96},
{id: 6958, value: 'castroregio', cap: '87070', provinceId: 86},
{id: 3729, value: 'occhiobello', cap: '45030', provinceId: 29},
{id: 7457, value: 'castroreale', cap: '98053', provinceId: 93},
{id: 3805, value: 'martignacco', cap: '33035', provinceId: 30},
{id: 5476, value: 'castrocielo', cap: '03030', provinceId: 67},
{id: 2142, value: 'castrezzato', cap: '25030', provinceId: 15},
{id: 2140, value: 'castenedolo', cap: '25014', provinceId: 15},
{id: 6309, value: 'mercogliano', cap: '83013', provinceId: 77},
{id: 7600, value: 'marianopoli', cap: '93010', provinceId: 95},
{id: 2519, value: 'castelverde', cap: '26022', provinceId: 17},
{id: 973, value: 'novi ligure', cap: '15067', provinceId: 6},
{id: 913, value: 'castelspina', cap: '15070', provinceId: 6},
{id: 5362, value: 'monteflavio', cap: '00010', provinceId: 65},
{id: 3597, value: 'albignasego', cap: '35020', provinceId: 28},
{id: 7748, value: 'castelsardo', cap: '07031', provinceId: 100},
{id: 2848, value: 'castelrotto', cap: '39040', provinceId: 21},
{id: 53, value: 'borgomanero', cap: '28021', provinceId: 3},
{id: 5356, value: 'marano equo', cap: '00020', provinceId: 65},
{id: 333, value: 'borgomasino', cap: '10031', provinceId: 1},
{id: 5056, value: 'fiordimonte', cap: '62035', provinceId: 61},
{id: 915, value: 'cella monte', cap: '15034', provinceId: 6},
{id: 7945, value: 'guamaggiore', cap: '09040', provinceId: 102},
{id: 1788, value: 'pantigliate', cap: '20090', provinceId: 13},
{id: 3058, value: 'mezzocorona', cap: '38016', provinceId: 22},
{id: 2408, value: 'oliva gessi', cap: '27050', provinceId: 16},
{id: 94, value: 'mezzomerico', cap: '28040', provinceId: 3},
{id: 7594, value: 'bompensiere', cap: '93010', provinceId: 95},
{id: 2800, value: 'lodi vecchio', cap: '26855', provinceId: 20},
{id: 6629, value: 'mola di bari', cap: '70042', provinceId: 80},
{id: 5236, value: 'borgo velino', cap: '02010', provinceId: 64},
{id: 5469, value: 'broccostella', cap: '03030', provinceId: 67},
{id: 1797, value: 'pozzo d adda', cap: '20060', provinceId: 13},
{id: 4832, value: 'bastia umbra', cap: '06083', provinceId: 57},
{id: 7027, value: 'praia a mare', cap: '87028', provinceId: 86},
{id: 4960, value: 'monte porzio', cap: '61040', provinceId: 59},
{id: 2637, value: 'magnacavallo', cap: '46020', provinceId: 18},
{id: 798, value: 'isola d asti', cap: '14057', provinceId: 5},
{id: 7120, value: 'marcellinara', cap: '88044', provinceId: 87},
{id: 6177, value: 'boscotrecase', cap: '80042', provinceId: 76},
{id: 54, value: 'borgo ticino', cap: '28040', provinceId: 3},
{id: 7655, value: 'linguaglossa', cap: '95015', provinceId: 97},
{id: 2261, value: 'roe volciano', cap: '25077', provinceId: 15},
{id: 2118, value: 'borgosatollo', cap: '25010', provinceId: 15},
{id: 5205, value: 'monte romano', cap: '01010', provinceId: 63},
{id: 334, value: 'borgone susa', cap: '10050', provinceId: 1},
{id: 5905, value: 'portocannone', cap: '86045', provinceId: 72},
{id: 7783, value: 'porto torres', cap: '07046', provinceId: 100},
{id: 5789, value: 'guardiagrele', cap: '66016', provinceId: 71},
{id: 5878, value: 'guardiaregia', cap: '86014', provinceId: 72},
{id: 3606, value: 'boara pisani', cap: '35040', provinceId: 28},
{id: 6944, value: 'bocchigliero', cap: '87060', provinceId: 86},
{id: 3516, value: 'portobuffole', cap: '31040', provinceId: 26},
{id: 3046, value: 'levico terme', cap: '38056', provinceId: 22},
{id: 4752, value: 'pratovecchio', cap: '52015', provinceId: 53},
{id: 4675, value: 'portoferraio', cap: '57037', provinceId: 51},
{id: 3162, value: 'villa agnedo', cap: '38059', provinceId: 22},
{id: 2290, value: 'vallio terme', cap: '25080', provinceId: 15},
{id: 324, value: 'bardonecchia', cap: '10052', provinceId: 1},
{id: 4696, value: 'guardistallo', cap: '56040', provinceId: 52},
{id: 3161, value: 'vigo rendena', cap: '38080', provinceId: 22},
{id: 5985, value: 'vastogirardi', cap: '86089', provinceId: 73},
{id: 1417, value: 'vedano olona', cap: '21040', provinceId: 10},
{id: 4762, value: 'buonconvento', cap: '53022', provinceId: 54},
{id: 3950, value: 'pravisdomini', cap: '33076', provinceId: 33},
{id: 4360, value: 'lama mocogno', cap: '41023', provinceId: 41},
{id: 1751, value: 'lacchiarella', cap: '20084', provinceId: 13},
{id: 1883, value: 'bonate sopra', cap: '24040', provinceId: 14},
{id: 1190, value: 'quarna sopra', cap: '28898', provinceId: 8},
{id: 1191, value: 'quarna sotto', cap: '28896', provinceId: 8},
{id: 5599, value: 'massa d albe', cap: '67050', provinceId: 68},
{id: 2319, value: 'borgo priolo', cap: '27040', provinceId: 16},
{id: 1884, value: 'bonate sotto', cap: '24040', provinceId: 14},
{id: 6056, value: 'roccamonfina', cap: '81035', provinceId: 74},
{id: 4101, value: 'vezzi portio', cap: '17028', provinceId: 35},
{id: 5388, value: 'roccagiovine', cap: '00020', provinceId: 65},
{id: 6485, value: 'roccadaspide', cap: '84069', provinceId: 78},
{id: 6672, value: 'roccaforzata', cap: '74020', provinceId: 81},
{id: 730, value: 'vezza d alba', cap: '12040', provinceId: 4},
{id: 5818, value: 'ripa teatina', cap: '66010', provinceId: 71},
{id: 5301, value: 'varco sabino', cap: '02020', provinceId: 64},
{id: 4461, value: 'masi torello', cap: '44020', provinceId: 43},
{id: 7760, value: 'la maddalena', cap: '07024', provinceId: 104},
{id: 7512, value: 'roccafiorita', cap: '98030', provinceId: 93},
{id: 5700, value: 'martinsicuro', cap: '64014', provinceId: 69},
{id: 6394, value: 'bellosguardo', cap: '84020', provinceId: 78},
{id: 6254, value: 'vico equense', cap: '80069', provinceId: 76},
{id: 4600, value: 'lamporecchio', cap: '51035', provinceId: 49},
{id: 677, value: 'rocca ciglie', cap: '12060', provinceId: 4},
{id: 5513, value: 'rocca d arce', cap: '03030', provinceId: 67},
{id: 5158, value: 'ripatransone', cap: '63065', provinceId: 62},
{id: 7619, value: 'calascibetta', cap: '94010', provinceId: 96},
{id: 5909, value: 'ripalimosani', cap: '86025', provinceId: 72},
{id: 7421, value: 'roccapalumba', cap: '90020', provinceId: 92},
{id: 752, value: 'calamandrana', cap: '14042', provinceId: 5},
{id: 7571, value: 'lucca sicula', cap: '92010', provinceId: 94},
{id: 4088, value: 'roccavignale', cap: '17017', provinceId: 35},
{id: 7663, value: 'misterbianco', cap: '95045', provinceId: 97},
{id: 5389, value: 'rocca priora', cap: '00040', provinceId: 65},
{id: 4331, value: 'rio saliceto', cap: '42010', provinceId: 40},
{id: 6233, value: 'roccarainola', cap: '80030', provinceId: 76},
{id: 7514, value: 'roccavaldina', cap: '98040', provinceId: 93},
{id: 1366, value: 'induno olona', cap: '21056', provinceId: 10},
{id: 2797, value: 'guardamiglio', cap: '26862', provinceId: 20},
{id: 8071, value: 'santa giusta', cap: '09096', provinceId: 103},
{id: 7321, value: 'sant onofrio', cap: '89843', provinceId: 90},
{id: 4056, value: 'celle ligure', cap: '17015', provinceId: 35},
{id: 1919, value: 'cenate sopra', cap: '24060', provinceId: 14},
{id: 1920, value: 'cenate sotto', cap: '24069', provinceId: 14},
{id: 5868, value: 'cercepiccola', cap: '86010', provinceId: 72},
{id: 7426, value: 'santa flavia', cap: '90017', provinceId: 92},
{id: 5337, value: 'fiano romano', cap: '00065', provinceId: 65},
{id: 6508, value: 'sant arsenio', cap: '84037', provinceId: 78},
{id: 917, value: 'cerreto grue', cap: '15050', provinceId: 6},
{id: 775, value: 'cerro tanaro', cap: '14030', provinceId: 5},
{id: 6506, value: 'santa marina', cap: '84070', provinceId: 78},
{id: 5049, value: 'cessapalombo', cap: '62020', provinceId: 61},
{id: 377, value: 'chialamberto', cap: '10070', provinceId: 1},
{id: 5677, value: 'fano adriano', cap: '64044', provinceId: 69},
{id: 7385, value: 'cefala diana', cap: '90030', provinceId: 92},
{id: 7978, value: 'sant antioco', cap: '09017', provinceId: 107},
{id: 3935, value: 'erto e casso', cap: '33080', provinceId: 33},
{id: 5978, value: 'sant agapito', cap: '86070', provinceId: 73},
{id: 1237, value: 'fontainemore', cap: '11020', provinceId: 9},
{id: 6164, value: 'telese terme', cap: '82037', provinceId: 75},
{id: 4679, value: 'san vincenzo', cap: '57027', provinceId: 51},
{id: 3742, value: 'taglio di po', cap: '45019', provinceId: 29},
{id: 3937, value: 'fiume veneto', cap: '33080', provinceId: 33},
{id: 6107, value: 'castelpagano', cap: '82024', provinceId: 75},
{id: 5001, value: 'castelplanio', cap: '60031', provinceId: 60},
{id: 2595, value: 'spino d adda', cap: '26016', provinceId: 17},
{id: 1327, value: 'castelseprio', cap: '21050', provinceId: 10},
{id: 7263, value: 'castelsilano', cap: '88834', provinceId: 89},
{id: 6239, value: 'sant agnello', cap: '80065', provinceId: 76},
{id: 6109, value: 'castelvenere', cap: '82037', provinceId: 75},
{id: 570, value: 'strambinello', cap: '10010', provinceId: 1},
{id: 3122, value: 'spormaggiore', cap: '38010', provinceId: 22},
{id: 6957, value: 'castrolibero', cap: '87040', provinceId: 86},
{id: 5587, value: 'fagnano alto', cap: '67020', provinceId: 68},
{id: 7188, value: 'cinquefrondi', cap: '89021', provinceId: 88},
{id: 4265, value: 'fontanellato', cap: '43012', provinceId: 39},
{id: 5715, value: 'collecorvino', cap: '65010', provinceId: 70},
{id: 4198, value: 'sesta godano', cap: '19020', provinceId: 37},
{id: 5673, value: 'controguerra', cap: '64010', provinceId: 69},
{id: 4984, value: 'serrungarina', cap: '61030', provinceId: 59},
{id: 5952, value: 'conca casale', cap: '86070', provinceId: 73},
{id: 3006, value: 'commezzadura', cap: '38020', provinceId: 22},
{id: 2628, value: 'commessaggio', cap: '46010', provinceId: 18},
{id: 9699, value: 'comano terme', cap: '38077', provinceId: 22},
{id: 2791, value: 'cornovecchio', cap: '26842', provinceId: 20},
{id: 5772, value: 'colledimezzo', cap: '66040', provinceId: 71},
{id: 5249, value: 'collevecchio', cap: '02042', provinceId: 64},
{id: 7067, value: 'serra pedace', cap: '87050', provinceId: 86},
{id: 7610, value: 'serradifalco', cap: '93010', provinceId: 95},
{id: 7146, value: 'serrastretta', cap: '88040', provinceId: 87},
{id: 5089, value: 'serrapetrona', cap: '62020', provinceId: 61},
{id: 4384, value: 'serramazzoni', cap: '41028', provinceId: 41},
{id: 1933, value: 'corna imagna', cap: '24030', provinceId: 14},
{id: 7751, value: 'codrongianos', cap: '07040', provinceId: 100},
{id: 3001, value: 'cinte tesino', cap: '38050', provinceId: 22},
{id: 9444, value: 'deutschnofen', cap: '39050', provinceId: 21},
{id: 4981, value: 'sassocorvaro', cap: '61028', provinceId: 59},
{id: 4982, value: 'sassofeltrio', cap: '61013', provinceId: 59},
{id: 5033, value: 'sassoferrato', cap: '60041', provinceId: 60},
{id: 561, value: 'sauze d oulx', cap: '10050', provinceId: 1},
{id: 3994, value: 'diano marina', cap: '18013', provinceId: 34},
{id: 209, value: 'diano d alba', cap: '12055', provinceId: 4},
{id: 4182, value: 'deiva marina', cap: '19013', provinceId: 37},
{id: 2159, value: 'corte franca', cap: '25040', provinceId: 15},
{id: 6116, value: 'cusano mutri', cap: '82033', provinceId: 75},
{id: 5005, value: 'cupramontana', cap: '60034', provinceId: 60},
{id: 4123, value: 'crocefieschi', cap: '16010', provinceId: 36},
{id: 2668, value: 'schivenoglia', cap: '46020', provinceId: 18},
{id: 3991, value: 'costarainera', cap: '18017', provinceId: 34},
{id: 3301, value: 'costabissara', cap: '36030', provinceId: 24},
{id: 2093, value: 'costa serina', cap: '24010', provinceId: 14},
{id: 5490, value: 'fontana liri', cap: '03035', provinceId: 67},
{id: 4311, value: 'castellarano', cap: '42014', provinceId: 40},
{id: 655, value: 'guardabosone', cap: '13010', provinceId: 2},
{id: 7468, value: 'furci siculo', cap: '98023', provinceId: 93},
{id: 2469, value: 'val di nizza', cap: '27050', provinceId: 16},
{id: 6024, value: 'gallo matese', cap: '81010', provinceId: 74},
{id: 7377, value: 'campofiorito', cap: '90030', provinceId: 92},
{id: 3764, value: 'campoformido', cap: '33030', provinceId: 30},
{id: 4941, value: 'gabicce mare', cap: '61011', provinceId: 59},
{id: 6103, value: 'campolattaro', cap: '82020', provinceId: 75},
{id: 850, value: 'vaglio serra', cap: '14049', provinceId: 5},
{id: 1599, value: 'campodolcino', cap: '23021', provinceId: 12},
{id: 6341, value: 'salza irpina', cap: '83050', provinceId: 77},
{id: 6201, value: 'frattaminore', cap: '80020', provinceId: 76},
{id: 2329, value: 'campospinoso', cap: '27040', provinceId: 16},
{id: 2505, value: 'capergnanica', cap: '26010', provinceId: 17},
{id: 7317, value: 'san calogero', cap: '89842', provinceId: 90},
{id: 6793, value: 'san cassiano', cap: '73020', provinceId: 83},
{id: 2934, value: 'val di vizze', cap: '39049', provinceId: 21},
{id: 3611, value: 'campodarsego', cap: '35011', provinceId: 28},
{id: 4539, value: 'san clemente', cap: '47832', provinceId: 46},
{id: 4797, value: 'campagnatico', cap: '58042', provinceId: 55},
{id: 5538, value: 'vallerotonda', cap: '03040', provinceId: 67},
{id: 2660, value: 'roncoferraro', cap: '46037', provinceId: 18},
{id: 6204, value: 'grumo nevano', cap: '80028', provinceId: 76},
{id: 6625, value: 'grumo appula', cap: '70025', provinceId: 80},
{id: 7159, value: 'vallefiorita', cap: '88050', provinceId: 87},
{id: 2935, value: 'valle aurina', cap: '39030', provinceId: 21},
{id: 1277, value: 'valgrisenche', cap: '11010', provinceId: 9},
{id: 4281, value: 'sala baganza', cap: '43038', provinceId: 39},
{id: 1362, value: 'gorla minore', cap: '21055', provinceId: 10},
{id: 8046, value: 'gonnoscodina', cap: '09090', provinceId: 103},
{id: 7808, value: 'golfo aranci', cap: '07020', provinceId: 104},
{id: 6730, value: 'giuggianello', cap: '73030', provinceId: 83},
{id: 4111, value: 'campo ligure', cap: '16013', provinceId: 36},
{id: 1269, value: 'saint marcel', cap: '11020', provinceId: 9},
{id: 1272, value: 'saint pierre', cap: '11010', provinceId: 9},
{id: 2084, value: 'villa d adda', cap: '24030', provinceId: 14},
{id: 4973, value: 'san costanzo', cap: '61039', provinceId: 59},
{id: 6652, value: 'castellaneta', cap: '74011', provinceId: 81},
{id: 2516, value: 'casteldidone', cap: '26030', provinceId: 17},
{id: 4503, value: 'forlimpopoli', cap: '47034', provinceId: 45},
{id: 2139, value: 'castel mella', cap: '25030', provinceId: 15},
{id: 6165, value: 'tocco caudio', cap: '82030', provinceId: 75},
{id: 4054, value: 'castelbianco', cap: '17030', provinceId: 35},
{id: 6409, value: 'castelcivita', cap: '84020', provinceId: 78},
{id: 2138, value: 'castelcovati', cap: '25030', provinceId: 15},
{id: 6283, value: 'castelfranci', cap: '83040', provinceId: 77},
{id: 5030, value: 'san marcello', cap: '60030', provinceId: 60},
{id: 7073, value: 'terravecchia', cap: '87060', provinceId: 86},
{id: 6816, value: 'castelgrande', cap: '85050', provinceId: 84},
{id: 8044, value: 'fordongianus', cap: '09083', provinceId: 103},
{id: 7237, value: 'san procopio', cap: '89020', provinceId: 88},
{id: 4381, value: 'san prospero', cap: '41030', provinceId: 41},
{id: 6410, value: 'castellabate', cap: '84048', provinceId: 78},
{id: 4127, value: 'fontanigorda', cap: '16023', provinceId: 36},
{id: 5695, value: 'torano nuovo', cap: '64010', provinceId: 69},
{id: 6528, value: 'torre orsaia', cap: '84077', provinceId: 78},
{id: 635, value: 'caresanablot', cap: '13030', provinceId: 2},
{id: 1319, value: 'casale litta', cap: '21020', provinceId: 10},
{id: 6407, value: 'casal velino', cap: '84040', provinceId: 78},
{id: 5760, value: 'casalanguida', cap: '66031', provinceId: 71},
{id: 5761, value: 'casalbordino', cap: '66021', provinceId: 71},
{id: 362, value: 'casalborgone', cap: '10020', provinceId: 1},
{id: 5862, value: 'casalciprano', cap: '86010', provinceId: 72},
{id: 7519, value: 'san fratello', cap: '98075', provinceId: 93},
{id: 8091, value: 'tresnuraghes', cap: '09079', provinceId: 103},
{id: 3850, value: 'san leonardo', cap: '33040', provinceId: 30},
{id: 7465, value: 'forza d agro', cap: '98030', provinceId: 93},
{id: 2777, value: 'casalmaiocco', cap: '26831', provinceId: 20},
{id: 6186, value: 'casamarciano', cap: '80032', provinceId: 76},
{id: 3685, value: 'trebaseleghe', cap: '35010', provinceId: 28},
{id: 4292, value: 'traversetolo', cap: '43029', provinceId: 39},
{id: 2336, value: 'casei gerola', cap: '27050', provinceId: 16},
{id: 4874, value: 'san giustino', cap: '06016', provinceId: 57},
{id: 7382, value: 'casteldaccia', cap: '90014', provinceId: 92},
{id: 6518, value: 'serramezzana', cap: '84070', provinceId: 78},
{id: 2085, value: 'villa d alme', cap: '24018', provinceId: 14},
{id: 4969, value: 'piandimeleto', cap: '61026', provinceId: 59},
{id: 2103, value: 'angolo terme', cap: '25040', provinceId: 15},
{id: 2567, value: 'pieve d olmi', cap: '26040', provinceId: 17},
{id: 7022, value: 'pedivigliano', cap: '87050', provinceId: 86},
{id: 5542, value: 'villa latina', cap: '03040', provinceId: 67},
{id: 7011, value: 'mottafollone', cap: '87010', provinceId: 86},
{id: 6749, value: 'muro leccese', cap: '73036', provinceId: 83},
{id: 5025, value: 'ostra vetere', cap: '60010', provinceId: 60},
{id: 6223, value: 'poggiomarino', cap: '80040', provinceId: 76},
{id: 3066, value: 'nago torbole', cap: '38069', provinceId: 22},
{id: 5812, value: 'pizzoferrato', cap: '66040', provinceId: 71},
{id: 2487, value: 'zavattarello', cap: '27059', provinceId: 16},
{id: 4449, value: 'zola predosa', cap: '40069', provinceId: 42},
{id: 4477, value: 'bagnacavallo', cap: '48012', provinceId: 44},
{id: 5799, value: 'montelapiano', cap: '66040', provinceId: 71},
{id: 5613, value: 'pescasseroli', cap: '67032', provinceId: 68},
{id: 4958, value: 'montelabbate', cap: '61025', provinceId: 59},
{id: 4082, value: 'piana crixia', cap: '17058', provinceId: 35},
{id: 5366, value: 'monterotondo', cap: '00015', provinceId: 65},
{id: 279, value: 'niella belbo', cap: '12050', provinceId: 4},
{id: 4146, value: 'pieve ligure', cap: '16031', provinceId: 36},
{id: 2236, value: 'pertica alta', cap: '25070', provinceId: 15},
{id: 4956, value: 'montefelcino', cap: '61030', provinceId: 59},
{id: 4864, value: 'nocera umbra', cap: '06025', provinceId: 57},
{id: 5687, value: 'pietracamela', cap: '64047', provinceId: 69},
{id: 4743, value: 'montemignaio', cap: '52010', provinceId: 53},
{id: 820, value: 'olmo gentile', cap: '14050', provinceId: 5},
{id: 2650, value: 'poggio rusco', cap: '46025', provinceId: 18},
{id: 1093, value: 'piedicavallo', cap: '13812', provinceId: 7},
{id: 967, value: 'montemarzino', cap: '15050', provinceId: 6},
{id: 1549, value: 'ponte lambro', cap: '22037', provinceId: 11},
{id: 7886, value: 'perdasdefogu', cap: '08046', provinceId: 105},
{id: 1638, value: 'poggiridenti', cap: '23020', provinceId: 12},
{id: 4078, value: 'orco feglino', cap: '17024', provinceId: 35},
{id: 4955, value: 'montecopiolo', cap: '61014', provinceId: 59},
{id: 8026, value: 'aidomaggiore', cap: '09070', provinceId: 103},
{id: 5892, value: 'montecilfone', cap: '86032', provinceId: 72},
{id: 6332, value: 'pietradefusi', cap: '83030', provinceId: 77},
{id: 5020, value: 'morro d alba', cap: '60030', provinceId: 60},
{id: 4535, value: 'poggio berni', cap: '47824', provinceId: 46},
{id: 6319, value: 'montemiletto', cap: '83038', provinceId: 77},
{id: 6044, value: 'pietramelara', cap: '81051', provinceId: 74},
{id: 7629, value: 'pietraperzia', cap: '94016', provinceId: 96},
{id: 296, value: 'pietraporzio', cap: '12010', provinceId: 4},
{id: 4970, value: 'pietrarubbia', cap: '61023', provinceId: 59},
{id: 3745, value: 'villamarzana', cap: '45030', provinceId: 29},
{id: 305, value: 'ala di stura', cap: '10070', provinceId: 1},
{id: 6133, value: 'montesarchio', cap: '82016', provinceId: 75},
{id: 7636, value: 'aci castello', cap: '95021', provinceId: 97},
{id: 4367, value: 'montefiorino', cap: '41045', provinceId: 41},
{id: 1207, value: 'villadossola', cap: '28844', provinceId: 8},
{id: 464, value: 'monteu da po', cap: '10020', provinceId: 1},
{id: 269, value: 'monteu roero', cap: '12040', provinceId: 4},
{id: 2731, value: 'montevecchia', cap: '23874', provinceId: 19},
{id: 2309, value: 'badia pavese', cap: '27010', provinceId: 16},
{id: 6927, value: 'acquaformosa', cap: '87010', provinceId: 86},
{id: 5455, value: 'acquafondata', cap: '03040', provinceId: 67},
{id: 3502, value: 'montebelluna', cap: '31044', provinceId: 26},
{id: 1042, value: 'villalvernia', cap: '15050', provinceId: 6},
{id: 5136, value: 'montegranaro', cap: '63812', provinceId: 109},
{id: 6389, value: 'atena lucana', cap: '84030', provinceId: 78},
{id: 5132, value: 'montefortino', cap: '63858', provinceId: 109},
{id: 5135, value: 'montegiorgio', cap: '63833', provinceId: 109},
{id: 2087, value: 'villa d ogna', cap: '24020', provinceId: 14},
{id: 2888, value: 'nova ponente', cap: '39050', provinceId: 21},
{id: 6315, value: 'montefredane', cap: '83030', provinceId: 77},
{id: 2105, value: 'azzano mella', cap: '25020', provinceId: 15},
{id: 2887, value: 'nova levante', cap: '39056', provinceId: 21},
{id: 5015, value: 'montecarotto', cap: '60036', provinceId: 60},
{id: 5076, value: 'pieve torina', cap: '62036', provinceId: 61},
{id: 3084, value: 'pieve tesino', cap: '38050', provinceId: 22},
{id: 4701, value: 'montescudaio', cap: '56040', provinceId: 52},
{id: 5724, value: 'montesilvano', cap: '65015', provinceId: 70},
{id: 6669, value: 'palagianello', cap: '74018', provinceId: 81},
{id: 8019, value: 'villaperuccio', cap: '09010', provinceId: 107},
{id: 716, value: 'torre mondovi', cap: '12080', provinceId: 4},
{id: 366, value: 'castagneto po', cap: '10090', provinceId: 1},
{id: 5386, value: 'rocca di cave', cap: '00030', provinceId: 65},
{id: 1715, value: 'castano primo', cap: '20022', provinceId: 13},
{id: 577, value: 'torre pellice', cap: '10066', provinceId: 1},
{id: 368, value: 'castellamonte', cap: '10081', provinceId: 1},
{id: 2758, value: 'torre de busi', cap: '23806', provinceId: 19},
{id: 1547, value: 'pognana lario', cap: '22020', provinceId: 11},
{id: 5574, value: 'castellafiume', cap: '67050', provinceId: 68},
{id: 880, value: 'bosco marengo', cap: '15062', provinceId: 6},
{id: 7277, value: 'rocca di neto', cap: '88821', provinceId: 89},
{id: 3921, value: 'azzano decimo', cap: '33082', provinceId: 33},
{id: 2462, value: 'torre d isola', cap: '27020', provinceId: 16},
{id: 280, value: 'niella tanaro', cap: '12060', provinceId: 4},
{id: 5016, value: 'montemarciano', cap: '60018', provinceId: 60},
{id: 3703, value: 'bagnolo di po', cap: '45022', provinceId: 29},
{id: 6397, value: 'buonabitacolo', cap: '84032', provinceId: 78},
{id: 2343, value: 'castelnovetto', cap: '27030', provinceId: 16},
{id: 7008, value: 'montegiordano', cap: '87070', provinceId: 86},
{id: 7161, value: 'lamezia terme', cap: '88046', provinceId: 87},
{id: 5846, value: 'villalfonsina', cap: '66020', provinceId: 71},
{id: 7276, value: 'roccabernarda', cap: '88835', provinceId: 89},
{id: 2429, value: 'rocca susella', cap: '27052', provinceId: 16},
{id: 6954, value: 'casole bruzio', cap: '87050', provinceId: 86},
{id: 1309, value: 'busto arsizio', cap: '21052', provinceId: 10},
{id: 4763, value: 'casole d elsa', cap: '53031', provinceId: 54},
{id: 5387, value: 'rocca di papa', cap: '00040', provinceId: 65},
{id: 4199, value: 'varese ligure', cap: '19028', provinceId: 37},
{id: 3938, value: 'fontanafredda', cap: '33074', provinceId: 33},
{id: 3396, value: 'castellavazzo', cap: '32010', provinceId: 25},
{id: 3431, value: 'rocca pietore', cap: '32020', provinceId: 25},
{id: 5443, value: 'rocca massima', cap: '04010', provinceId: 66},
{id: 6819, value: 'castelmezzano', cap: '85010', provinceId: 84},
{id: 6593, value: 'torremaggiore', cap: '71017', provinceId: 79},
{id: 650, value: 'fontanetto po', cap: '13040', provinceId: 2},
{id: 2460, value: 'torre d arese', cap: '27010', provinceId: 16},
{id: 243, value: 'magliano alpi', cap: '12060', provinceId: 4},
{id: 1780, value: 'nova milanese', cap: '20834', provinceId: 108},
{id: 2029, value: 'riva di solto', cap: '24060', provinceId: 14},
{id: 5324, value: 'castel madama', cap: '00024', provinceId: 65},
{id: 5414, value: 'vivaro romano', cap: '00020', provinceId: 65},
{id: 3258, value: 'velo veronese', cap: '37030', provinceId: 23},
{id: 2912, value: 'san pancrazio', cap: '39010', provinceId: 21},
{id: 2106, value: 'bagnolo mella', cap: '25021', provinceId: 15},
{id: 5960, value: 'macchiagodena', cap: '86096', provinceId: 73},
{id: 2061, value: 'terno d isola', cap: '24030', provinceId: 14},
{id: 5364, value: 'montelibretti', cap: '00010', provinceId: 65},
{id: 4997, value: 'castelbellino', cap: '60030', provinceId: 60},
{id: 5765, value: 'castelguidone', cap: '66040', provinceId: 71},
{id: 1043, value: 'villamiroglio', cap: '15020', provinceId: 6},
{id: 985, value: 'parodi ligure', cap: '15060', provinceId: 6},
{id: 1519, value: 'lurago d erba', cap: '22040', provinceId: 11},
{id: 4999, value: 'castelfidardo', cap: '60022', provinceId: 60},
{id: 594, value: 'venaria reale', cap: '10078', provinceId: 1},
{id: 1051, value: 'andorno micca', cap: '13811', provinceId: 7},
{id: 4532, value: 'montegridolfo', cap: '47837', provinceId: 46},
{id: 1447, value: 'bulgarograsso', cap: '22070', provinceId: 11},
{id: 180, value: 'castellinaldo', cap: '12050', provinceId: 4},
{id: 3591, value: 'teglio veneto', cap: '30025', provinceId: 27},
{id: 715, value: 'torre bormida', cap: '12050', provinceId: 4},
{id: 3380, value: 'velo d astico', cap: '36010', provinceId: 24},
{id: 2062, value: 'torre boldone', cap: '24020', provinceId: 14},
{id: 235, value: 'lequio berria', cap: '12050', provinceId: 4},
{id: 2624, value: 'castellucchio', cap: '46014', provinceId: 18},
{id: 6243, value: 'san vitaliano', cap: '80030', provinceId: 76},
{id: 4677, value: 'rio nell elba', cap: '57039', provinceId: 51},
{id: 236, value: 'lequio tanaro', cap: '12060', provinceId: 4},
{id: 863, value: 'alfiano natta', cap: '15021', provinceId: 6},
{id: 1328, value: 'castelveccana', cap: '21010', provinceId: 10},
{id: 2622, value: 'castel d ario', cap: '46033', provinceId: 18},
{id: 3788, value: 'forni avoltri', cap: '33020', provinceId: 30},
{id: 3843, value: 'rive d arcano', cap: '33030', provinceId: 30},
{id: 7654, value: 'licodia eubea', cap: '95040', provinceId: 97},
{id: 176, value: 'casteldelfino', cap: '12020', provinceId: 4},
{id: 1837, value: 'vaprio d adda', cap: '20069', provinceId: 13},
{id: 5813, value: 'poggiofiorito', cap: '66030', provinceId: 71},
{id: 4723, value: 'badia tedalda', cap: '52032', provinceId: 53},
{id: 4345, value: 'campogalliano', cap: '41011', provinceId: 41},
{id: 5347, value: 'grottaferrata', cap: '00046', provinceId: 65},
{id: 6298, value: 'grottaminarda', cap: '83035', provinceId: 77},
{id: 5119, value: 'grottazzolina', cap: '63844', provinceId: 109},
{id: 6265, value: 'ariano irpino', cap: '83031', provinceId: 77},
{id: 8017, value: 'villaspeciosa', cap: '09010', provinceId: 102},
{id: 5996, value: 'calvi risorta', cap: '81042', provinceId: 74},
{id: 3077, value: 'ronzo chienis', cap: '38060', provinceId: 22},
{id: 6832, value: 'grumento nova', cap: '85050', provinceId: 84},
{id: 5142, value: 'monterubbiano', cap: '63825', provinceId: 109},
{id: 4853, value: 'gualdo tadino', cap: '06023', provinceId: 57},
{id: 2266, value: 'sale marasino', cap: '25057', provinceId: 15},
{id: 4152, value: 'ronco scrivia', cap: '16019', provinceId: 36},
{id: 4850, value: 'fratta todina', cap: '06054', provinceId: 57},
{id: 6812, value: 'campomaggiore', cap: '85010', provinceId: 84},
{id: 5258, value: 'frasso sabino', cap: '02030', provinceId: 64},
{id: 2569, value: 'pizzighettone', cap: '26026', provinceId: 17},
{id: 3724, value: 'guarda veneta', cap: '45030', provinceId: 29},
{id: 4009, value: 'pieve di teco', cap: '18026', provinceId: 34},
{id: 6374, value: 'vallesaccarda', cap: '83050', provinceId: 77},
{id: 932, value: 'frassineto po', cap: '15040', provinceId: 6},
{id: 9417, value: 'franzensfeste', cap: '39045', provinceId: 21},
{id: 968, value: 'morano sul po', cap: '15025', provinceId: 6},
{id: 5858, value: 'campodipietra', cap: '86010', provinceId: 72},
{id: 3082, value: 'pieve di bono', cap: '38085', provinceId: 22},
{id: 825, value: 'piova massaia', cap: '14026', provinceId: 5},
{id: 2827, value: 'valera fratta', cap: '26859', provinceId: 20},
{id: 7179, value: 'campo calabro', cap: '89052', provinceId: 88},
{id: 3543, value: 'valdobbiadene', cap: '31049', provinceId: 26},
{id: 6026, value: 'giano vetusto', cap: '81042', provinceId: 74},
{id: 2265, value: 'sabbio chiese', cap: '25070', provinceId: 15},
{id: 745, value: 'azzano d asti', cap: '14030', provinceId: 5},
{id: 3656, value: 'piombino dese', cap: '35017', provinceId: 28},
{id: 494, value: 'pino torinese', cap: '10025', provinceId: 1},
{id: 7396, value: 'geraci siculo', cap: '90010', provinceId: 92},
{id: 1270, value: 'saint nicolas', cap: '11010', provinceId: 9},
{id: 1558, value: 'rovello porro', cap: '22070', provinceId: 11},
{id: 1288, value: 'arsago seprio', cap: '21010', provinceId: 10},
{id: 1363, value: 'gornate olona', cap: '21040', provinceId: 10},
{id: 1274, value: 'saint vincent', cap: '11027', provinceId: 9},
{id: 5102, value: 'ascoli piceno', cap: '63100', provinceId: 62},
{id: 610, value: 'villastellone', cap: '10029', provinceId: 1},
{id: 1106, value: 'sala biellese', cap: '13884', provinceId: 7},
{id: 1559, value: 'sala comacina', cap: '22010', provinceId: 11},
{id: 4644, value: 'montespertoli', cap: '50025', provinceId: 50},
{id: 2034, value: 'rota d imagna', cap: '24037', provinceId: 14},
{id: 1866, value: 'arzago d adda', cap: '24040', provinceId: 14},
{id: 3033, value: 'garniga terme', cap: '38060', provinceId: 22},
{id: 3613, value: 'camposampiero', cap: '35012', provinceId: 28},
{id: 1835, value: 'usmate velate', cap: '20865', provinceId: 108},
{id: 5159, value: 'roccafluvione', cap: '63093', provinceId: 62},
{id: 2514, value: 'casalmaggiore', cap: '26041', provinceId: 17},
{id: 5273, value: 'morro reatino', cap: '02010', provinceId: 64},
{id: 5280, value: 'poggio catino', cap: '02040', provinceId: 64},
{id: 4787, value: 'san gimignano', cap: '53037', provinceId: 54},
{id: 680, value: 'roccasparvera', cap: '12010', provinceId: 4},
{id: 3874, value: 'treppo grande', cap: '33010', provinceId: 30},
{id: 609, value: 'villar perosa', cap: '10069', provinceId: 1},
{id: 3331, value: 'montegaldella', cap: '36047', provinceId: 24},
{id: 5821, value: 'roccascalegna', cap: '66040', provinceId: 71},
{id: 4083, value: 'pietra ligure', cap: '17027', provinceId: 35},
{id: 6487, value: 'roccapiemonte', cap: '84086', provinceId: 78},
{id: 1415, value: 'varano borghi', cap: '21020', provinceId: 10},
{id: 6563, value: 'isole tremiti', cap: '71040', provinceId: 79},
{id: 5282, value: 'poggio moiano', cap: '02037', provinceId: 64},
{id: 1248, value: 'la magdeleine', cap: '11020', provinceId: 9},
{id: 3552, value: 'annone veneto', cap: '30020', provinceId: 27},
{id: 5283, value: 'poggio nativo', cap: '02030', provinceId: 64},
{id: 5974, value: 'roccamandolfi', cap: '86092', provinceId: 73},
{id: 2554, value: 'motta baluffi', cap: '26045', provinceId: 17},
{id: 6486, value: 'roccagloriosa', cap: '84060', provinceId: 78},
{id: 637, value: 'casanova elvo', cap: '13030', provinceId: 2},
{id: 2779, value: 'caselle landi', cap: '26842', provinceId: 20},
{id: 1700, value: 'busto garolfo', cap: '20020', provinceId: 13},
{id: 3041, value: 'ivano fracena', cap: '38059', provinceId: 22},
{id: 1828, value: 'trezzano rosa', cap: '20060', provinceId: 13},
{id: 5877, value: 'guardialfiera', cap: '86030', provinceId: 72},
{id: 5801, value: 'monteodorisio', cap: '66050', provinceId: 71},
{id: 3756, value: 'bagnaria arsa', cap: '33050', provinceId: 30},
{id: 4775, value: 'monteriggioni', cap: '53035', provinceId: 54},
{id: 4888, value: 'vallo di nera', cap: '06040', provinceId: 57},
{id: 2289, value: 'urago d oglio', cap: '25030', provinceId: 15},
{id: 1748, value: 'gudo visconti', cap: '20088', provinceId: 13},
{id: 7596, value: 'caltanissetta', cap: '93100', provinceId: 95},
{id: 3237, value: 'san bonifacio', cap: '37047', provinceId: 23},
{id: 4774, value: 'montepulciano', cap: '53045', provinceId: 54},
{id: 7556, value: 'caltabellotta', cap: '92010', provinceId: 94},
{id: 5140, value: 'monteprandone', cap: '63076', provinceId: 62},
{id: 1852, value: 'villa cortese', cap: '20020', provinceId: 13},
{id: 5903, value: 'pietracatella', cap: '86040', provinceId: 72},
{id: 2132, value: 'capo di ponte', cap: '25044', provinceId: 15},
{id: 6045, value: 'pietravairano', cap: '81040', provinceId: 74},
{id: 5800, value: 'montenerodomo', cap: '66010', provinceId: 71},
{id: 2691, value: 'calolziocorte', cap: '23801', provinceId: 19},
{id: 7422, value: 'san cipirello', cap: '90040', provinceId: 92},
{id: 4666, value: 'capraia isola', cap: '57032', provinceId: 51},
{id: 4050, value: 'calice ligure', cap: '17020', provinceId: 35},
{id: 1279, value: 'valsavarenche', cap: '11010', provinceId: 9},
{id: 6856, value: 'pietrapertosa', cap: '85010', provinceId: 84},
{id: 60, value: 'casalbeltrame', cap: '28060', provinceId: 3},
{id: 1280, value: 'valtournenche', cap: '11028', provinceId: 9},
{id: 5944, value: 'castelpetroso', cap: '86090', provinceId: 73},
{id: 7474, value: 'gioiosa marea', cap: '98063', provinceId: 93},
{id: 1494, value: 'fino mornasco', cap: '22073', provinceId: 11},
{id: 6353, value: 'santa paolina', cap: '83030', provinceId: 77},
{id: 5169, value: 'acquapendente', cap: '01021', provinceId: 63},
{id: 2738, value: 'oliveto lario', cap: '23865', provinceId: 19},
{id: 3250, value: 'sommacampagna', cap: '37066', provinceId: 23},
{id: 2393, value: 'mezzana bigli', cap: '27030', provinceId: 16},
{id: 2792, value: 'corte palasio', cap: '26834', provinceId: 20},
{id: 2894, value: 'ponte gardena', cap: '39040', provinceId: 21},
{id: 4530, value: 'monte colombo', cap: '47854', provinceId: 46},
{id: 4220, value: 'cortemaggiore', cap: '29016', provinceId: 38},
{id: 5065, value: 'monte cavallo', cap: '62036', provinceId: 61},
{id: 3329, value: 'monte di malo', cap: '36030', provinceId: 24},
{id: 2048, value: 'solto collina', cap: '24060', provinceId: 14},
{id: 3059, value: 'mezzolombardo', cap: '38017', provinceId: 22},
{id: 4034, value: 'villa faraldi', cap: '18010', provinceId: 34},
{id: 2160, value: 'corteno golgi', cap: '25040', provinceId: 15},
{id: 203, value: 'cossano belbo', cap: '12054', provinceId: 4},
{id: 2704, value: 'costa masnaga', cap: '23845', provinceId: 19},
{id: 1937, value: 'costa volpino', cap: '24062', provinceId: 14},
{id: 5333, value: 'civitavecchia', cap: '00053', provinceId: 65},
{id: 2790, value: 'corno giovine', cap: '26846', provinceId: 20},
{id: 2702, value: 'colle brianza', cap: '23886', provinceId: 19},
{id: 3428, value: 'puos d alpago', cap: '32015', provinceId: 25},
{id: 191, value: 'ceresole alba', cap: '12040', provinceId: 4},
{id: 1153, value: 'ceppo morelli', cap: '28875', provinceId: 8},
{id: 2293, value: 'verolavecchia', cap: '25029', provinceId: 15},
{id: 2730, value: 'monte marenzo', cap: '23804', provinceId: 19},
{id: 5867, value: 'cercemaggiore', cap: '86012', provinceId: 72},
{id: 5247, value: 'colle di tora', cap: '02020', provinceId: 64},
{id: 7145, value: 'sellia marina', cap: '88050', provinceId: 87},
{id: 1403, value: 'sesto calende', cap: '21018', provinceId: 10},
{id: 5984, value: 'sesto campano', cap: '86078', provinceId: 73},
{id: 1391, value: 'olgiate olona', cap: '21057', provinceId: 10},
{id: 7784, value: 'pozzomaggiore', cap: '07018', provinceId: 100},
{id: 6361, value: 'sorbo serpico', cap: '83050', provinceId: 77},
{id: 996, value: 'pozzol groppo', cap: '15050', provinceId: 6},
{id: 5134, value: 'monte giberto', cap: '63846', provinceId: 109},
{id: 6308, value: 'melito irpino', cap: '83030', provinceId: 77},
{id: 596, value: 'verrua savoia', cap: '10020', provinceId: 1},
{id: 5002, value: 'cerreto d esi', cap: '60043', provinceId: 60},
{id: 6462, value: 'oliveto citra', cap: '84020', provinceId: 78},
{id: 4626, value: 'cerreto guidi', cap: '50050', provinceId: 50},
{id: 3398, value: 'cesiomaggiore', cap: '32030', provinceId: 25},
{id: 1299, value: 'bodio lomnago', cap: '21020', provinceId: 10},
{id: 451, value: 'meana di susa', cap: '10050', provinceId: 1},
{id: 7332, value: 'vibo valentia', cap: '89900', provinceId: 90},
{id: 6144, value: 'pontelandolfo', cap: '82027', provinceId: 75},
{id: 1999, value: 'orio al serio', cap: '24050', provinceId: 14},
{id: 2295, value: 'vezza d oglio', cap: '25059', provinceId: 15},
{id: 1944, value: 'endine gaiano', cap: '24060', provinceId: 14},
{id: 474, value: 'orio canavese', cap: '10010', provinceId: 1},
{id: 4954, value: 'monteciccardo', cap: '61024', provinceId: 59},
{id: 6335, value: 'pratola serra', cap: '83039', provinceId: 77},
{id: 148, value: 'bene vagienna', cap: '12041', provinceId: 4},
{id: 5209, value: 'oriolo romano', cap: '01010', provinceId: 63},
{id: 740, value: 'agliano terme', cap: '14041', provinceId: 5},
{id: 6085, value: 'villa literno', cap: '81039', provinceId: 74},
{id: 4342, value: 'villa minozzo', cap: '42030', provinceId: 40},
{id: 4446, value: 'sasso marconi', cap: '40037', provinceId: 42},
{id: 7149, value: 'simeri crichi', cap: '88050', provinceId: 87},
{id: 7497, value: 'montagnareale', cap: '98060', provinceId: 93},
{id: 3623, value: 'cinto euganeo', cap: '35030', provinceId: 28},
{id: 599, value: 'vico canavese', cap: '10080', provinceId: 1},
{id: 3164, value: 'villa rendena', cap: '38080', provinceId: 22},
{id: 1178, value: 'montecrestese', cap: '28864', provinceId: 8},
{id: 3829, value: 'prato carnico', cap: '33020', provinceId: 30},
{id: 1404, value: 'solbiate arno', cap: '21048', provinceId: 10},
{id: 1986, value: 'moio de calvi', cap: '24010', provinceId: 14},
{id: 462, value: 'montalto dora', cap: '10016', provinceId: 1},
{id: 7727, value: 'ala dei sardi', cap: '07020', provinceId: 104},
{id: 7505, value: 'pace del mela', cap: '98042', provinceId: 93},
{id: 1586, value: 'villa guardia', cap: '22079', provinceId: 11},
{id: 2531, value: 'crotta d adda', cap: '26020', provinceId: 17},
{id: 3881, value: 'villa santina', cap: '33029', provinceId: 30},
{id: 6851, value: 'oppido lucano', cap: '85015', provinceId: 84},
{id: 7000, value: 'mandatoriccio', cap: '87060', provinceId: 86},
{id: 76, value: 'fara novarese', cap: '28073', provinceId: 3},
{id: 6313, value: 'montefalcione', cap: '83030', provinceId: 77},
{id: 6049, value: 'prata sannita', cap: '81010', provinceId: 74},
{id: 5600, value: 'molina aterno', cap: '67020', provinceId: 68},
{id: 3987, value: 'chiusavecchia', cap: '18027', provinceId: 34},
{id: 1350, value: 'fagnano olona', cap: '21054', provinceId: 10},
{id: 301, value: 'pontechianale', cap: '12020', provinceId: 4},
{id: 2856, value: 'curon venosta', cap: '39027', provinceId: 21},
{id: 1490, value: 'faggeto lario', cap: '22020', provinceId: 11},
{id: 5332, value: 'cineto romano', cap: '00020', provinceId: 65},
{id: 3694, value: 'villa estense', cap: '35040', provinceId: 28},
{id: 2228, value: 'paisco loveno', cap: '25050', provinceId: 15},
{id: 5866, value: 'castropignano', cap: '86010', provinceId: 72},
{id: 4470, value: 'sant agostino', cap: '44047', provinceId: 43},
{id: 7341, value: 'castelvetrano', cap: '91022', provinceId: 91},
{id: 6074, value: 'sessa aurunca', cap: '81037', provinceId: 74},
{id: 6115, value: 'colle sannita', cap: '82024', provinceId: 75},
{id: 1567, value: 'senna comasco', cap: '22070', provinceId: 11},
{id: 4354, value: 'finale emilia', cap: '41034', provinceId: 41},
{id: 6959, value: 'castrovillari', cap: '87012', provinceId: 86},
{id: 3476, value: 'colle umberto', cap: '31014', provinceId: 26},
{id: 4468, value: 'portomaggiore', cap: '44015', provinceId: 43},
{id: 733, value: 'villafalletto', cap: '12020', provinceId: 4},
{id: 5771, value: 'colledimacine', cap: '66010', provinceId: 71},
{id: 6036, value: 'marzano appio', cap: '81035', provinceId: 74},
{id: 6795, value: 'porto cesareo', cap: '73010', provinceId: 83},
{id: 1139, value: 'bannio anzino', cap: '28871', provinceId: 8},
{id: 250, value: 'martiniana po', cap: '12030', provinceId: 4},
{id: 3350, value: 'recoaro terme', cap: '36076', provinceId: 24},
{id: 7561, value: 'casteltermini', cap: '92025', provinceId: 94},
{id: 6840, value: 'marsico nuovo', cap: '85052', provinceId: 84},
{id: 4669, value: 'collesalvetti', cap: '57014', provinceId: 51},
{id: 6140, value: 'pesco sannita', cap: '82020', provinceId: 75},
{id: 2296, value: 'villa carcina', cap: '25069', provinceId: 15},
{id: 4063, value: 'finale ligure', cap: '17024', provinceId: 35},
{id: 6210, value: 'mariglianella', cap: '80030', provinceId: 76},
{id: 6590, value: 'serracapriola', cap: '71010', provinceId: 79},
{id: 6841, value: 'marsicovetere', cap: '85050', provinceId: 84},
{id: 91, value: 'marano ticino', cap: '28040', provinceId: 3},
{id: 5947, value: 'castelverrino', cap: '86080', provinceId: 73},
{id: 5966, value: 'pescolanciano', cap: '86097', provinceId: 73},
{id: 861, value: 'albera ligure', cap: '15060', provinceId: 6},
{id: 501, value: 'pont canavese', cap: '10085', provinceId: 1},
{id: 9456, value: 'rasen antholz', cap: '39030', provinceId: 21},
{id: 7562, value: 'castrofilippo', cap: '92020', provinceId: 94},
{id: 6520, value: 'sessa cilento', cap: '84074', provinceId: 78},
{id: 5064, value: 'montecassiano', cap: '62010', provinceId: 61},
{id: 5992, value: 'baia e latina', cap: '81010', provinceId: 74},
{id: 7961, value: 'pauli arbarei', cap: '09020', provinceId: 106},
{id: 5746, value: 'villa celiera', cap: '65010', provinceId: 70},
{id: 1110, value: 'selve marcone', cap: '13841', provinceId: 7},
{id: 5945, value: 'castelpizzuto', cap: '86090', provinceId: 73},
{id: 2039, value: 'santa brigida', cap: '24010', provinceId: 14},
{id: 4858, value: 'massa martana', cap: '06056', provinceId: 57},
{id: 1666, value: 'abbiategrasso', cap: '20081', provinceId: 13},
{id: 3159, value: 'vigo di fassa', cap: '38039', provinceId: 22},
{id: 2237, value: 'pertica bassa', cap: '25078', provinceId: 15},
{id: 4909, value: 'montegabbione', cap: '05010', provinceId: 58},
{id: 4674, value: 'porto azzurro', cap: '57036', provinceId: 51},
{id: 5141, value: 'monte rinaldo', cap: '63852', provinceId: 109},
{id: 187, value: 'cavallerleone', cap: '12030', provinceId: 4},
{id: 5018, value: 'monte roberto', cap: '60030', provinceId: 60},
{id: 5123, value: 'massa fermana', cap: '63834', provinceId: 109},
{id: 4330, value: 'reggio emilia', cap: '', provinceId: 40},
{id: 5204, value: 'montefiascone', cap: '01027', provinceId: 63},
{id: 1396, value: 'porto ceresio', cap: '21050', provinceId: 10},
{id: 3928, value: 'cavasso nuovo', cap: '33092', provinceId: 33},
{id: 5798, value: 'monteferrante', cap: '66040', provinceId: 71},
{id: 4980, value: 'sant ippolito', cap: '61040', provinceId: 59},
{id: 964, value: 'montecastello', cap: '15040', provinceId: 6},
{id: 5511, value: 'posta fibreno', cap: '03030', provinceId: 67},
{id: 5615, value: 'pescocostanzo', cap: '67033', provinceId: 68},
{id: 7952, value: 'maracalagonis', cap: '09040', provinceId: 102},
{id: 6867, value: 'ruvo del monte', cap: '85020', provinceId: 84},
{id: 3425, value: 'pieve d alpago', cap: '32010', provinceId: 25},
{id: 3993, value: 'diano castello', cap: '18013', provinceId: 34},
{id: 7473, value: 'giardini naxos', cap: '98035', provinceId: 93},
{id: 1667, value: 'agrate brianza', cap: '20864', provinceId: 108},
{id: 1159, value: 'cursolo orasso', cap: '28827', provinceId: 8},
{id: 1021, value: 'silvano d orba', cap: '15060', provinceId: 6},
{id: 5744, value: 'turrivalignani', cap: '65020', provinceId: 70},
{id: 7635, value: 'aci bonaccorsi', cap: '95020', provinceId: 97},
{id: 7163, value: 'agnana calabra', cap: '89040', provinceId: 88},
{id: 6639, value: 'ruvo di puglia', cap: '70037', provinceId: 80},
{id: 2452, value: 'silvano pietra', cap: '27050', provinceId: 16},
{id: 3992, value: 'diano arentino', cap: '18013', provinceId: 34},
{id: 5560, value: 'campo di giove', cap: '67030', provinceId: 68},
{id: 2845, value: 'campo di trens', cap: '39040', provinceId: 21},
{id: 1186, value: 'pieve vergonte', cap: '28886', provinceId: 8},
{id: 5035, value: 'serra de conti', cap: '60030', provinceId: 60},
{id: 6425, value: 'contursi terme', cap: '84024', provinceId: 78},
{id: 4585, value: 'pieve fosciana', cap: '55036', provinceId: 48},
{id: 4439, value: 'sala bolognese', cap: '40010', provinceId: 42},
{id: 4162, value: 'sestri levante', cap: '16039', provinceId: 36},
{id: 6493, value: 'sala consilina', cap: '84036', provinceId: 78},
{id: 1960, value: 'gaverina terme', cap: '24060', provinceId: 14},
{id: 612, value: 'virle piemonte', cap: '10060', provinceId: 1},
{id: 1733, value: 'cornate d adda', cap: '20872', provinceId: 108},
{id: 1543, value: 'pellio intelvi', cap: '22020', provinceId: 11},
{id: 3822, value: 'pavia di udine', cap: '33050', provinceId: 30},
{id: 2403, value: 'montu beccaria', cap: '27040', provinceId: 16},
{id: 5484, value: 'coreno ausonio', cap: '03040', provinceId: 67},
{id: 2588, value: 'sesto ed uniti', cap: '26028', provinceId: 17},
{id: 3701, value: 'arqua polesine', cap: '45031', provinceId: 29},
{id: 4416, value: 'gaggio montano', cap: '40041', provinceId: 42},
{id: 7009, value: 'morano calabro', cap: '87016', provinceId: 86},
{id: 4635, value: 'gambassi terme', cap: '50050', provinceId: 50},
{id: 2526, value: 'corte de frati', cap: '26010', provinceId: 17},
{id: 6200, value: 'frattamaggiore', cap: '80027', provinceId: 76},
{id: 1105, value: 'sagliano micca', cap: '13816', provinceId: 7},
{id: 2981, value: 'canal san bovo', cap: '38050', provinceId: 22},
{id: 7066, value: 'serra d aiello', cap: '87030', provinceId: 86},
{id: 5756, value: 'canosa sannita', cap: '66010', provinceId: 71},
{id: 6428, value: 'cuccaro vetere', cap: '84050', provinceId: 78},
{id: 2042, value: 'scanzorosciate', cap: '24020', provinceId: 14},
{id: 2332, value: 'canneto pavese', cap: '27044', provinceId: 16},
{id: 6634, value: 'palo del colle', cap: '70027', provinceId: 80},
{id: 1157, value: 'crevoladossola', cap: '28865', provinceId: 8},
{id: 3599, value: 'arqua petrarca', cap: '35032', provinceId: 28},
{id: 1794, value: 'pieve emanuele', cap: '20090', provinceId: 13},
{id: 2740, value: 'paderno d adda', cap: '23877', provinceId: 19},
{id: 4437, value: 'pieve di cento', cap: '40066', provinceId: 42},
{id: 3205, value: 'gazzo veronese', cap: '37060', provinceId: 23},
{id: 3195, value: 'cologna veneta', cap: '37044', provinceId: 23},
{id: 8063, value: 'palmas arborea', cap: '09090', provinceId: 103},
{id: 7428, value: 'sclafani bagni', cap: '90020', provinceId: 92},
{id: 6220, value: 'palma campania', cap: '80036', provinceId: 76},
{id: 6333, value: 'pietrastornina', cap: '83015', provinceId: 77},
{id: 3306, value: 'fara vicentino', cap: '36030', provinceId: 24},
{id: 1706, value: 'carate brianza', cap: '20841', provinceId: 108},
{id: 189, value: 'celle di macra', cap: '12020', provinceId: 4},
{id: 1423, value: 'vizzola ticino', cap: '21010', provinceId: 10},
{id: 867, value: 'alzano scrivia', cap: '15050', provinceId: 6},
{id: 1429, value: 'alzate brianza', cap: '22040', provinceId: 11},
{id: 1493, value: 'figino serenza', cap: '22060', provinceId: 11},
{id: 2621, value: 'castelbelforte', cap: '46032', provinceId: 18},
{id: 7153, value: 'soveria simeri', cap: '88050', provinceId: 87},
{id: 1914, value: 'castel rozzone', cap: '24040', provinceId: 14},
{id: 5374, value: 'olevano romano', cap: '00035', provinceId: 65},
{id: 1776, value: 'motta visconti', cap: '20086', provinceId: 13},
{id: 4838, value: 'castel ritaldi', cap: '06044', provinceId: 57},
{id: 6012, value: 'castel morrone', cap: '81020', provinceId: 74},
{id: 4898, value: 'castel giorgio', cap: '05013', provinceId: 58},
{id: 4779, value: 'piancastagnaio', cap: '53025', provinceId: 54},
{id: 5241, value: 'castel di tora', cap: '02020', provinceId: 64},
{id: 773, value: 'celle enomondo', cap: '14010', provinceId: 5},
{id: 5106, value: 'castel di lama', cap: '63082', provinceId: 62},
{id: 5572, value: 'castel di ieri', cap: '67020', provinceId: 68},
{id: 2561, value: 'persico dosimo', cap: '26043', provinceId: 17},
{id: 4403, value: 'castel del rio', cap: '40022', provinceId: 42},
{id: 4402, value: 'castel d aiano', cap: '40034', provinceId: 42},
{id: 2987, value: 'castel condino', cap: '38082', provinceId: 22},
{id: 4998, value: 'castel colonna', cap: '60010', provinceId: 60},
{id: 2458, value: 'torrazza coste', cap: '27050', provinceId: 16},
{id: 2443, value: 'santa giuletta', cap: '27046', provinceId: 16},
{id: 3435, value: 'santa giustina', cap: '32035', provinceId: 25},
{id: 6875, value: 'sant arcangelo', cap: '85037', provinceId: 84},
{id: 3389, value: 'alano di piave', cap: '32031', provinceId: 25},
{id: 375, value: 'ceresole reale', cap: '10080', provinceId: 1},
{id: 5529, value: 'strangolagalli', cap: '03020', provinceId: 67},
{id: 1949, value: 'fino del monte', cap: '24020', provinceId: 14},
{id: 5278, value: 'petrella salto', cap: '02025', provinceId: 64},
{id: 1633, value: 'novate mezzola', cap: '23025', provinceId: 12},
{id: 5047, value: 'castelraimondo', cap: '62022', provinceId: 61},
{id: 6820, value: 'castelsaraceno', cap: '85031', provinceId: 84},
{id: 4370, value: 'novi di modena', cap: '41016', provinceId: 41},
{id: 6331, value: 'petruro irpino', cap: '83010', provinceId: 77},
{id: 5277, value: 'pescorocchiano', cap: '02024', provinceId: 64},
{id: 7450, value: 'capo d orlando', cap: '98071', provinceId: 93},
{id: 5967, value: 'pescopennataro', cap: '86080', provinceId: 73},
{id: 8074, value: 'san vero milis', cap: '09070', provinceId: 103},
{id: 3532, value: 'san vendemiano', cap: '31020', provinceId: 26},
{id: 7442, value: 'alcara li fusi', cap: '98070', provinceId: 93},
{id: 5654, value: 'alba adriatica', cap: '64011', provinceId: 69},
{id: 3131, value: 'telve di sopra', cap: '38050', provinceId: 22},
{id: 6240, value: 'sant anastasia', cap: '80048', provinceId: 76},
{id: 2520, value: 'castelvisconti', cap: '26010', provinceId: 17},
{id: 6523, value: 'stella cilento', cap: '84070', provinceId: 78},
{id: 620, value: 'alice castello', cap: '13040', provinceId: 2},
{id: 3068, value: 'nave san rocco', cap: '38010', provinceId: 22},
{id: 5304, value: 'albano laziale', cap: '00041', provinceId: 65},
{id: 4800, value: 'castell azzara', cap: '58034', provinceId: 55},
{id: 4380, value: 'san possidonio', cap: '41039', provinceId: 41},
{id: 1954, value: 'foresto sparso', cap: '24060', provinceId: 14},
{id: 764, value: 'castell alfero', cap: '14033', provinceId: 5},
{id: 3294, value: 'castelgomberto', cap: '36070', provinceId: 24},
{id: 6282, value: 'castel baronia', cap: '83040', provinceId: 77},
{id: 5532, value: 'torre cajetani', cap: '03010', provinceId: 67},
{id: 1011, value: 'san cristoforo', cap: '15060', provinceId: 6},
{id: 988, value: 'pietra marazzi', cap: '15040', provinceId: 6},
{id: 1, value: 'motta de conti', cap: '13010', provinceId: 2},
{id: 3399, value: 'chies d alpago', cap: '32010', provinceId: 25},
{id: 2221, value: 'ono san pietro', cap: '25040', provinceId: 15},
{id: 7499, value: 'motta camastra', cap: '98030', provinceId: 93},
{id: 3890, value: 'farra d isonzo', cap: '34072', provinceId: 31},
{id: 3407, value: 'farra d alpago', cap: '32016', provinceId: 25},
{id: 5255, value: 'fara in sabina', cap: '02032', provinceId: 64},
{id: 4401, value: 'casalfiumanese', cap: '40020', provinceId: 42},
{id: 3873, value: 'treppo carnico', cap: '33020', provinceId: 30},
{id: 4704, value: 'orciano pisano', cap: '56040', provinceId: 52},
{id: 1850, value: 'zelo surrigone', cap: '20080', provinceId: 13},
{id: 720, value: 'trezzo tinella', cap: '12050', provinceId: 4},
{id: 4688, value: 'casciana terme', cap: '56034', provinceId: 52},
{id: 3911, value: 'duino aurisina', cap: '34011', provinceId: 32},
{id: 1486, value: 'dosso del liro', cap: '22010', provinceId: 11},
{id: 7933, value: 'domus de maria', cap: '09010', provinceId: 102},
{id: 2404, value: 'mornico losana', cap: '27040', provinceId: 16},
{id: 978, value: 'orsara bormida', cap: '15010', provinceId: 6},
{id: 7258, value: 'san ferdinando', cap: '89026', provinceId: 88},
{id: 896, value: 'casal cermelli', cap: '15072', provinceId: 6},
{id: 5759, value: 'casacanditella', cap: '66010', provinceId: 71},
{id: 893, value: 'carrega ligure', cap: '15060', provinceId: 6},
{id: 6930, value: 'aiello calabro', cap: '87031', provinceId: 86},
{id: 2582, value: 'san daniele po', cap: '26046', provinceId: 17},
{id: 6039, value: 'orta di atella', cap: '81030', provinceId: 74},
{id: 4114, value: 'casarza ligure', cap: '16030', provinceId: 36},
{id: 1995, value: 'oltre il colle', cap: '24013', provinceId: 14},
{id: 576, value: 'torre canavese', cap: '10010', provinceId: 1},
{id: 3194, value: 'cerro veronese', cap: '37020', provinceId: 23},
{id: 774, value: 'cerreto d asti', cap: '14020', provinceId: 5},
{id: 3789, value: 'forni di sopra', cap: '33024', provinceId: 30},
{id: 2461, value: 'torre de negri', cap: '27011', provinceId: 16},
{id: 3592, value: 'torre di mosto', cap: '30020', provinceId: 27},
{id: 6063, value: 'san marcellino', cap: '81030', provinceId: 74},
{id: 192, value: 'cerreto langhe', cap: '12050', provinceId: 4},
{id: 3790, value: 'forni di sotto', cap: '33020', provinceId: 30},
{id: 1720, value: 'cerro maggiore', cap: '20023', provinceId: 13},
{id: 409, value: 'forno canavese', cap: '10084', provinceId: 1},
{id: 3411, value: 'forno di zoldo', cap: '32012', provinceId: 25},
{id: 3373, value: 'torrebelvicino', cap: '36036', provinceId: 24},
{id: 1406, value: 'somma lombardo', cap: '21019', provinceId: 10},
{id: 6914, value: 'oliveto lucano', cap: '75010', provinceId: 85},
{id: 1994, value: 'olmo al brembo', cap: '24010', provinceId: 14},
{id: 6151, value: 'san lorenzello', cap: '82030', provinceId: 75},
{id: 6281, value: 'cassano irpino', cap: '83040', provinceId: 77},
{id: 7280, value: 'santa severina', cap: '88832', provinceId: 89},
{id: 1712, value: 'cassano d adda', cap: '20062', provinceId: 13},
{id: 2337, value: 'casorate primo', cap: '27022', provinceId: 16},
{id: 2699, value: 'cesana brianza', cap: '23861', provinceId: 19},
{id: 7682, value: 'santa venerina', cap: '95010', provinceId: 97},
{id: 1721, value: 'cesano boscone', cap: '20090', provinceId: 13},
{id: 1722, value: 'cesano maderno', cap: '20811', provinceId: 108},
{id: 1462, value: 'caslino d erba', cap: '22030', provinceId: 11},
{id: 1405, value: 'solbiate olona', cap: '21058', provinceId: 10},
{id: 2780, value: 'caselle lurani', cap: '26853', provinceId: 20},
{id: 3222, value: 'nogarole rocca', cap: '37060', provinceId: 23},
{id: 7404, value: 'lercara friddi', cap: '90025', provinceId: 92},
{id: 3104, value: 'ruffre mendola', cap: '38010', provinceId: 22},
{id: 3095, value: 'riva del garda', cap: '38066', provinceId: 22},
{id: 2427, value: 'robecco pavese', cap: '27042', provinceId: 16},
{id: 4639, value: 'lastra a signa', cap: '50055', provinceId: 50},
{id: 2577, value: 'rivolta d adda', cap: '26027', provinceId: 17},
{id: 8010, value: 'villanovaforru', cap: '09020', provinceId: 106},
{id: 4552, value: 'licciana nardi', cap: '54016', provinceId: 47},
{id: 1512, value: 'limido comasco', cap: '22070', provinceId: 11},
{id: 7913, value: 'villanova tulo', cap: '08030', provinceId: 102},
{id: 592, value: 'vauda canavese', cap: '10070', provinceId: 1},
{id: 17, value: 'riva valdobbia', cap: '13020', provinceId: 2},
{id: 2573, value: 'ripalta arpina', cap: '26010', provinceId: 17},
{id: 523, value: 'rocca canavese', cap: '10070', provinceId: 1},
{id: 55, value: 'briga novarese', cap: '28010', provinceId: 3},
{id: 8009, value: 'villamassargia', cap: '09010', provinceId: 107},
{id: 1372, value: 'lonate ceppino', cap: '21050', provinceId: 10},
{id: 1373, value: 'lonate pozzolo', cap: '21015', provinceId: 10},
{id: 5262, value: 'longone sabino', cap: '02020', provinceId: 64},
{id: 4740, value: 'loro ciuffenna', cap: '52024', provinceId: 53},
{id: 3639, value: 'lozzo atestino', cap: '35034', provinceId: 28},
{id: 7200, value: 'gioiosa ionica', cap: '89042', provinceId: 88},
{id: 5596, value: 'luco dei marsi', cap: '67056', provinceId: 68},
{id: 3461, value: 'breda di piave', cap: '31030', provinceId: 26},
{id: 430, value: 'lanzo torinese', cap: '10074', provinceId: 1},
{id: 6269, value: 'bagnoli irpino', cap: '83043', provinceId: 77},
{id: 2687, value: 'bosisio parini', cap: '23842', provinceId: 19},
{id: 884, value: 'cabella ligure', cap: '15060', provinceId: 6},
{id: 5740, value: 'serramonacesca', cap: '65025', provinceId: 70},
{id: 5497, value: 'isola del liri', cap: '03036', provinceId: 67},
{id: 4618, value: 'bagno a ripoli', cap: '50012', provinceId: 50},
{id: 6580, value: 'rodi garganico', cap: '71012', provinceId: 79},
{id: 590, value: 'valprato soana', cap: '10080', provinceId: 1},
{id: 2260, value: 'rodengo saiano', cap: '25050', provinceId: 15},
{id: 2546, value: 'isola dovarese', cap: '26031', provinceId: 17},
{id: 6545, value: 'cagnano varano', cap: '71010', provinceId: 79},
{id: 5279, value: 'poggio bustone', cap: '02018', provinceId: 64},
{id: 125, value: 'varallo pombia', cap: '28040', provinceId: 3},
{id: 832, value: 'rocca d arazzo', cap: '14030', provinceId: 5},
{id: 5281, value: 'poggio mirteto', cap: '02047', provinceId: 64},
{id: 5618, value: 'poggio picenze', cap: '67026', provinceId: 68},
{id: 608, value: 'villar pellice', cap: '10060', provinceId: 1},
{id: 6990, value: 'laino castello', cap: '87015', provinceId: 86},
{id: 5971, value: 'poggio sannita', cap: '86086', provinceId: 73},
{id: 6338, value: 'roccabascerana', cap: '83016', provinceId: 77},
{id: 1006, value: 'rocca grimalda', cap: '15078', provinceId: 6},
{id: 5627, value: 'rocca di mezzo', cap: '67048', provinceId: 68},
{id: 5625, value: 'rocca di botte', cap: '67066', provinceId: 68},
{id: 678, value: 'rocca de baldi', cap: '12047', provinceId: 4},
{id: 5468, value: 'boville ernica', cap: '03022', provinceId: 67},
{id: 2875, value: 'malles venosta', cap: '39024', provinceId: 21},
{id: 4562, value: 'bagni di lucca', cap: '55022', provinceId: 48},
{id: 3515, value: 'ponzano veneto', cap: '31050', provinceId: 26},
{id: 264, value: 'montaldo roero', cap: '12040', provinceId: 4},
{id: 3163, value: 'villa lagarina', cap: '38060', provinceId: 22},
{id: 1530, value: 'montano lucino', cap: '22070', provinceId: 11},
{id: 5361, value: 'monte compatri', cap: '00040', provinceId: 65},
{id: 2551, value: 'monte cremasco', cap: '26010', provinceId: 17},
{id: 143, value: 'bastia mondovi', cap: '12060', provinceId: 4},
{id: 5173, value: 'bassano romano', cap: '01030', provinceId: 63},
{id: 8110, value: 'monte giardino', cap: '47898', provinceId: 111},
{id: 5381, value: 'ponzano romano', cap: '00060', provinceId: 65},
{id: 3087, value: 'pozza di fassa', cap: '38036', provinceId: 22},
{id: 1683, value: 'bernate ticino', cap: '20010', provinceId: 13},
{id: 3452, value: 'vigo di cadore', cap: '32040', provinceId: 25},
{id: 5081, value: 'potenza picena', cap: '62018', provinceId: 61},
{id: 4906, value: 'montecastrilli', cap: '05026', provinceId: 58},
{id: 3160, value: 'vigolo vattaro', cap: '38049', provinceId: 22},
{id: 5019, value: 'monte san vito', cap: '60037', provinceId: 60},
{id: 2086, value: 'villa di serio', cap: '24020', provinceId: 14},
{id: 4438, value: 'porretta terme', cap: '40046', provinceId: 42},
{id: 4594, value: 'villa basilica', cap: '55019', provinceId: 48},
{id: 2481, value: 'villa biscossi', cap: '27035', provinceId: 16},
{id: 6698, value: 'villa castelli', cap: '72029', provinceId: 82},
{id: 5541, value: 'vico nel lazio', cap: '03010', provinceId: 67},
{id: 4201, value: 'vezzano ligure', cap: '19020', provinceId: 37},
{id: 1841, value: 'verano brianza', cap: '20843', provinceId: 108},
{id: 4488, value: 'massa lombarda', cap: '48024', provinceId: 44},
{id: 90, value: 'mandello vitta', cap: '28060', provinceId: 3},
{id: 52, value: 'borgolavezzaro', cap: '28071', provinceId: 3},
{id: 629, value: 'borgo vercelli', cap: '13012', provinceId: 2},
{id: 2321, value: 'borgo san siro', cap: '27020', provinceId: 16},
{id: 6662, value: 'martina franca', cap: '74015', provinceId: 81},
{id: 4785, value: 'rapolano terme', cap: '53040', provinceId: 54},
{id: 1383, value: 'masciago primo', cap: '21030', provinceId: 10},
{id: 1553, value: 'ramponio verna', cap: '22020', provinceId: 11},
{id: 6260, value: 'massa di somma', cap: '80040', provinceId: 76},
{id: 6212, value: 'massa lubrense', cap: '80061', provinceId: 76},
{id: 1143, value: 'beura cardezza', cap: '28851', provinceId: 8},
{id: 5359, value: 'mazzano romano', cap: '00060', provinceId: 65},
{id: 8115, value: 'borgo maggiore', cap: '47893', provinceId: 111},
{id: 1885, value: 'borgo di terzo', cap: '24060', provinceId: 14},
{id: 4047, value: 'borgio verezzi', cap: '17022', provinceId: 35},
{id: 2245, value: 'ponte di legno', cap: '25056', provinceId: 15},
{id: 3514, value: 'ponte di piave', cap: '31047', provinceId: 26},
{id: 328, value: 'bobbio pellice', cap: '10060', provinceId: 1},
{id: 2396, value: 'miradolo terme', cap: '27010', provinceId: 16},
{id: 3807, value: 'moggio udinese', cap: '33015', provinceId: 30},
{id: 7494, value: 'moio alcantara', cap: '98030', provinceId: 93},
{id: 588, value: 'vallo torinese', cap: '10070', provinceId: 1},
{id: 5080, value: 'porto recanati', cap: '62017', provinceId: 61},
{id: 1045, value: 'villaromagnano', cap: '15050', provinceId: 6},
{id: 3553, value: 'campagna lupia', cap: '30010', provinceId: 27},
{id: 3702, value: 'badia polesine', cap: '45021', provinceId: 29},
{id: 3354, value: 'rossano veneto', cap: '36028', provinceId: 24},
{id: 6912, value: 'montescaglioso', cap: '75024', provinceId: 85},
{id: 3098, value: 'roncegno terme', cap: '38050', provinceId: 22},
{id: 5592, value: 'goriano sicoli', cap: '67030', provinceId: 68},
{id: 4623, value: 'campi bisenzio', cap: '50013', provinceId: 50},
{id: 8048, value: 'gonnostramatza', cap: '09093', provinceId: 103},
{id: 7944, value: 'gonnosfanadiga', cap: '09035', provinceId: 106},
{id: 1898, value: 'calusco d adda', cap: '24033', provinceId: 14},
{id: 5315, value: 'camerata nuova', cap: '00020', provinceId: 65},
{id: 1102, value: 'ronco biellese', cap: '13845', provinceId: 7},
{id: 6082, value: 'valle agricola', cap: '81010', provinceId: 74},
{id: 3173, value: 'badia calavena', cap: '37030', provinceId: 23},
{id: 1361, value: 'gorla maggiore', cap: '21050', provinceId: 10},
{id: 297, value: 'piobesi d alba', cap: '12040', provinceId: 4},
{id: 3657, value: 'piove di sacco', cap: '35028', provinceId: 28},
{id: 526, value: 'ronco canavese', cap: '10080', provinceId: 1},
{id: 2760, value: 'valgreghentino', cap: '23857', provinceId: 19},
{id: 3450, value: 'valle di cadore', cap: '32040', provinceId: 25},
{id: 2053, value: 'spinone al lago', cap: '24060', provinceId: 14},
{id: 7689, value: 'zafferana etnea', cap: '95019', provinceId: 97},
{id: 4948, value: 'mercatino conca', cap: '61013', provinceId: 59},
{id: 6715, value: 'castri di lecce', cap: '73020', provinceId: 83},
{id: 2596, value: 'stagno lombardo', cap: '26049', provinceId: 17},
{id: 5328, value: 'cerreto laziale', cap: '00020', provinceId: 65},
{id: 3494, value: 'mareno di piave', cap: '31010', provinceId: 26},
{id: 1523, value: 'mariano comense', cap: '22066', provinceId: 11},
{id: 6307, value: 'marzano di nola', cap: '83020', provinceId: 77},
{id: 2965, value: 'borgo valsugana', cap: '38051', provinceId: 22},
{id: 4672, value: 'marciana marina', cap: '57033', provinceId: 51},
{id: 6113, value: 'cerreto sannita', cap: '82032', provinceId: 75},
{id: 2171, value: 'gardone riviera', cap: '25083', provinceId: 15},
{id: 3804, value: 'marano lagunare', cap: '33050', provinceId: 30},
{id: 5729, value: 'pescosansonesco', cap: '65020', provinceId: 70},
{id: 7224, value: 'reggio calabria', cap: '', provinceId: 88},
{id: 306, value: 'albiano d ivrea', cap: '10010', provinceId: 1},
{id: 1025, value: 'spineto scrivia', cap: '15050', provinceId: 6},
{id: 4564, value: 'borgo a mozzano', cap: '55023', provinceId: 48},
{id: 975, value: 'odalengo grande', cap: '15020', provinceId: 6},
{id: 1332, value: 'cazzago brabbia', cap: '21020', provinceId: 10},
{id: 4810, value: 'massa marittima', cap: '58024', provinceId: 55},
{id: 7325, value: 'soriano calabro', cap: '89831', provinceId: 90},
{id: 1167, value: 'gravellona toce', cap: '28883', provinceId: 8},
{id: 3565, value: 'fiesso d artico', cap: '30032', provinceId: 27},
{id: 487, value: 'perosa canavese', cap: '10010', provinceId: 1},
{id: 1009, value: 'sala monferrato', cap: '15030', provinceId: 6},
{id: 4462, value: 'massa fiscaglia', cap: '44025', provinceId: 43},
{id: 4603, value: 'massa e cozzile', cap: '51010', provinceId: 49},
{id: 618, value: 'alagna valsesia', cap: '13021', provinceId: 2},
{id: 2785, value: 'cavenago d adda', cap: '26824', provinceId: 20},
{id: 1537, value: 'olgiate comasco', cap: '22077', provinceId: 11},
{id: 3769, value: 'cavazzo carnico', cap: '33020', provinceId: 30},
{id: 3324, value: 'mason vicentino', cap: '36064', provinceId: 24},
{id: 3410, value: 'canale d agordo', cap: '32020', provinceId: 25},
{id: 5452, value: 'spigno saturnia', cap: '04020', provinceId: 66},
{id: 1398, value: 'rancio valcuvia', cap: '21030', provinceId: 10},
{id: 5521, value: 'sant apollinare', cap: '03048', provinceId: 67},
{id: 2736, value: 'olgiate molgora', cap: '23887', provinceId: 19},
{id: 6416, value: 'cava de tirreni', cap: '84013', provinceId: 78},
{id: 4238, value: 'ponte dell olio', cap: '29028', provinceId: 38},
{id: 5367, value: 'montorio romano', cap: '00010', provinceId: 65},
{id: 9402, value: 'sand in taufers', cap: '39032', provinceId: 21},
{id: 2936, value: 'valle di casies', cap: '39030', provinceId: 21},
{id: 3576, value: 'musile di piave', cap: '30024', provinceId: 27},
{id: 25, value: 'sali vercellese', cap: '13040', provinceId: 2},
{id: 5921, value: 'san polo matese', cap: '86020', provinceId: 72},
{id: 4214, value: 'castell arquato', cap: '29014', provinceId: 38},
{id: 4335, value: 'san polo d enza', cap: '42020', provinceId: 40},
{id: 3631, value: 'galliera veneta', cap: '35015', provinceId: 28},
{id: 3709, value: 'castelguglielmo', cap: '45020', provinceId: 29},
{id: 5075, value: 'pievebovigliana', cap: '62035', provinceId: 61},
{id: 2575, value: 'ripalta guerina', cap: '26010', provinceId: 17},
{id: 1515, value: 'locate varesino', cap: '22070', provinceId: 11},
{id: 1861, value: 'alzano lombardo', cap: '24022', provinceId: 14},
{id: 5863, value: 'castelbottaccio', cap: '86030', provinceId: 72},
{id: 7455, value: 'castell umberto', cap: '98070', provinceId: 93},
{id: 7522, value: 'san piero patti', cap: '98068', provinceId: 93},
{id: 7521, value: 'san pier niceto', cap: '98045', provinceId: 93},
{id: 6013, value: 'castel volturno', cap: '81030', provinceId: 74},
{id: 3982, value: 'castel vittorio', cap: '18030', provinceId: 34},
{id: 4899, value: 'castel viscardo', cap: '05014', provinceId: 58},
{id: 1109, value: 'san paolo cervo', cap: '13812', provinceId: 7},
{id: 3141, value: 'tione di trento', cap: '38079', provinceId: 22},
{id: 771, value: 'castel rocchero', cap: '14044', provinceId: 5},
{id: 1003, value: 'rivalta bormida', cap: '15010', provinceId: 6},
{id: 4408, value: 'castel maggiore', cap: '40013', provinceId: 42},
{id: 2623, value: 'castel goffredo', cap: '46042', provinceId: 18},
{id: 2903, value: 'rio di pusteria', cap: '39037', provinceId: 21},
{id: 307, value: 'alice superiore', cap: '10010', provinceId: 1},
{id: 2471, value: 'valle lomellina', cap: '27020', provinceId: 16},
{id: 2990, value: 'castello tesino', cap: '38053', provinceId: 22},
{id: 7936, value: 'fluminimaggiore', cap: '09010', provinceId: 107},
{id: 3394, value: 'borca di cadore', cap: '32040', provinceId: 25},
{id: 1781, value: 'novate milanese', cap: '20026', provinceId: 13},
{id: 6444, value: 'magliano vetere', cap: '84050', provinceId: 78},
{id: 3446, value: 'taibon agordino', cap: '32027', provinceId: 25},
{id: 5263, value: 'magliano sabina', cap: '02046', provinceId: 64},
{id: 5353, value: 'magliano romano', cap: '00060', provinceId: 65},
{id: 5401, value: 'san vito romano', cap: '00030', provinceId: 65},
{id: 5835, value: 'taranta peligna', cap: '66018', provinceId: 71},
{id: 868, value: 'arquata scrivia', cap: '15061', provinceId: 6},
{id: 1521, value: 'lurate caccivio', cap: '22075', provinceId: 11},
{id: 7429, value: 'termini imerese', cap: '90018', provinceId: 92},
{id: 1520, value: 'lurago marinone', cap: '22070', provinceId: 11},
{id: 2472, value: 'valle salimbene', cap: '27010', provinceId: 16},
{id: 3319, value: 'lugo di vicenza', cap: '36030', provinceId: 24},
{id: 229, value: 'grinzane cavour', cap: '12060', provinceId: 4},
{id: 586, value: 'val della torre', cap: '10040', provinceId: 1},
{id: 3420, value: 'lozzo di cadore', cap: '32040', provinceId: 25},
{id: 7795, value: 'tempio pausania', cap: '07029', provinceId: 104},
{id: 7127, value: 'nocera terinese', cap: '88047', provinceId: 87},
{id: 864, value: 'alice bel colle', cap: '15010', provinceId: 6},
{id: 4996, value: 'camerata picena', cap: '60020', provinceId: 60},
{id: 5721, value: 'loreto aprutino', cap: '65014', provinceId: 70},
{id: 1719, value: 'cerro al lambro', cap: '20070', provinceId: 13},
{id: 6542, value: 'ascoli satriano', cap: '71022', provinceId: 79},
{id: 2752, value: 'santa maria hoe', cap: '23889', provinceId: 19},
{id: 4953, value: 'monte cerignone', cap: '61010', provinceId: 59},
{id: 5652, value: 'villetta barrea', cap: '67030', provinceId: 68},
{id: 3248, value: 'selva di progno', cap: '37030', provinceId: 23},
{id: 3441, value: 'selva di cadore', cap: '32020', provinceId: 25},
{id: 1039, value: 'vignole borbera', cap: '15060', provinceId: 6},
{id: 5651, value: 'villavallelonga', cap: '67050', provinceId: 68},
{id: 5870, value: 'colle d anchise', cap: '86020', provinceId: 72},
{id: 5246, value: 'collalto sabino', cap: '02022', provinceId: 64},
{id: 5276, value: 'paganico sabino', cap: '02020', provinceId: 64},
{id: 855, value: 'vigliano d asti', cap: '14040', provinceId: 5},
{id: 4922, value: 'avigliano umbro', cap: '05020', provinceId: 58},
{id: 483, value: 'pavone canavese', cap: '10018', provinceId: 1},
{id: 3347, value: 'pove del grappa', cap: '36020', provinceId: 24},
{id: 566, value: 'settimo rottaro', cap: '10010', provinceId: 1},
{id: 568, value: 'settimo vittone', cap: '10010', provinceId: 1},
{id: 2361, value: 'costa de nobili', cap: '27010', provinceId: 16},
{id: 3715, value: 'costa di rovigo', cap: '45023', provinceId: 29},
{id: 6449, value: 'montano antilia', cap: '84060', provinceId: 78},
{id: 921, value: 'costa vescovato', cap: '15050', provinceId: 6},
{id: 7007, value: 'montalto uffugo', cap: '87046', provinceId: 86},
{id: 1787, value: 'paderno dugnano', cap: '20037', provinceId: 13},
{id: 2397, value: 'montalto pavese', cap: '27040', provinceId: 16},
{id: 4003, value: 'montalto ligure', cap: '18010', provinceId: 34},
{id: 5769, value: 'civitaluparella', cap: '66040', provinceId: 71},
{id: 6709, value: 'campi salentina', cap: '73012', provinceId: 83},
{id: 4122, value: 'coreglia ligure', cap: '16040', provinceId: 36},
{id: 5579, value: 'civita d antino', cap: '67050', provinceId: 68},
{id: 1127, value: 'villa del bosco', cap: '13868', provinceId: 7},
{id: 3820, value: 'pasian di prato', cap: '33037', provinceId: 30},
{id: 2679, value: 'abbadia lariana', cap: '23821', provinceId: 19},
{id: 6246, value: 'serrara fontana', cap: '80070', provinceId: 76},
{id: 6027, value: 'gioia sannitica', cap: '81010', provinceId: 74},
{id: 7577, value: 'porto empedocle', cap: '92014', provinceId: 94},
{id: 2653, value: 'porto mantovano', cap: '46047', provinceId: 18},
{id: 2670, value: 'serravalle a po', cap: '46030', provinceId: 18},
{id: 7322, value: 'serra san bruno', cap: '89822', provinceId: 90},
{id: 6622, value: 'gioia del colle', cap: '70023', provinceId: 80},
{id: 5591, value: 'gioia dei marsi', cap: '67055', provinceId: 68},
{id: 1727, value: 'cologno monzese', cap: '20093', provinceId: 13},
{id: 5483, value: 'colle san magno', cap: '03030', provinceId: 67},
{id: 3693, value: 'villa del conte', cap: '35010', provinceId: 28},
{id: 6084, value: 'villa di briano', cap: '81030', provinceId: 74},
{id: 1665, value: 'villa di tirano', cap: '23030', provinceId: 12},
{id: 7020, value: 'paterno calabro', cap: '87040', provinceId: 86},
{id: 1060, value: 'campiglia cervo', cap: '13812', provinceId: 7},
{id: 2821, value: 'senna lodigiana', cap: '26856', provinceId: 20},
{id: 325, value: 'barone canavese', cap: '10010', provinceId: 1},
{id: 3456, value: 'zoppe di cadore', cap: '32010', provinceId: 25},
{id: 2559, value: 'palazzo pignano', cap: '26020', provinceId: 17},
{id: 7411, value: 'palazzo adriano', cap: '90030', provinceId: 92},
{id: 2953, value: 'baselga di pine', cap: '38042', provinceId: 22},
{id: 3167, value: 'ziano di fiemme', cap: '38030', provinceId: 22},
{id: 3605, value: 'battaglia terme', cap: '35041', provinceId: 28},
{id: 3582, value: 'quarto d altino', cap: '30020', provinceId: 27},
{id: 2277, value: 'soiano del lago', cap: '25080', provinceId: 15},
{id: 5192, value: 'fabrica di roma', cap: '01034', provinceId: 63},
{id: 777, value: 'chiusano d asti', cap: '14025', provinceId: 5},
{id: 7725, value: 'priolo gargallo', cap: '96010', provinceId: 99},
{id: 7388, value: 'chiusa sclafani', cap: '90033', provinceId: 92},
{id: 197, value: 'chiusa di pesio', cap: '12013', provinceId: 4},
{id: 3235, value: 'rovere veronese', cap: '37028', provinceId: 23},
{id: 3499, value: 'mogliano veneto', cap: '31021', provinceId: 26},
{id: 8073, value: 'santu lussurgiu', cap: '09075', provinceId: 103},
{id: 3482, value: 'farra di soligo', cap: '31010', provinceId: 26},
{id: 6773, value: 'sogliano cavour', cap: '73010', provinceId: 83},
{id: 7107, value: 'feroleto antico', cap: '88040', provinceId: 87},
{id: 1878, value: 'berzo san fermo', cap: '24060', provinceId: 14},
{id: 6627, value: 'minervino murge', cap: '76013', provinceId: 110},
{id: 3882, value: 'villa vicentina', cap: '33059', provinceId: 30},
{id: 376, value: 'cesana torinese', cap: '10054', provinceId: 1},
{id: 2769, value: 'abbadia cerreto', cap: '26834', provinceId: 20},
{id: 5329, value: 'cervara di roma', cap: '00020', provinceId: 65},
{id: 5398, value: 'santa marinella', cap: '00058', provinceId: 65},
{id: 6175, value: 'barano d ischia', cap: '80070', provinceId: 76},
{id: 2517, value: 'castel gabbiano', cap: '26010', provinceId: 17},
{id: 3806, value: 'mereto di tomba', cap: '33036', provinceId: 30},
{id: 6247, value: 'somma vesuviana', cap: '80049', provinceId: 76},
{id: 712, value: 'sommariva perno', cap: '12040', provinceId: 4},
{id: 926, value: 'fabbrica curone', cap: '15054', provinceId: 6},
{id: 2114, value: 'berzo inferiore', cap: '25040', provinceId: 15},
{id: 1341, value: 'cuasso al monte', cap: '21050', provinceId: 10},
{id: 5620, value: 'pratola peligna', cap: '67035', provinceId: 68},
{id: 6926, value: 'scanzano jonico', cap: '75020', provinceId: 85},
{id: 2152, value: 'cividate camuno', cap: '25040', provinceId: 15},
{id: 5112, value: 'cupra marittima', cap: '63064', provinceId: 62},
{id: 1293, value: 'bedero valcuvia', cap: '21039', provinceId: 10},
{id: 2363, value: 'cura carpignano', cap: '27010', provinceId: 16},
{id: 779, value: 'cisterna d asti', cap: '14010', provinceId: 5},
{id: 6536, value: 'vietri sul mare', cap: '84019', provinceId: 78},
{id: 1738, value: 'cusano milanino', cap: '20095', provinceId: 13},
{id: 3404, value: 'danta di cadore', cap: '32040', provinceId: 25},
{id: 3234, value: 'roveredo di gua', cap: '37040', provinceId: 23},
{id: 2021, value: 'pontirolo nuovo', cap: '24040', provinceId: 14},
{id: 2524, value: 'cingia de botti', cap: '26042', provinceId: 17},
{id: 4059, value: 'cisano sul neva', cap: '17035', provinceId: 35},
{id: 5344, value: 'genzano di roma', cap: '00045', provinceId: 65},
{id: 560, value: 'sauze di cesana', cap: '10054', provinceId: 1},
{id: 102, value: 'orta san giulio', cap: '28016', provinceId: 3},
{id: 3550, value: 'zenson di piave', cap: '31050', provinceId: 26},
{id: 7496, value: 'mongiuffi melia', cap: '98030', provinceId: 93},
{id: 5103, value: 'belmonte piceno', cap: '63838', provinceId: 109},
{id: 261, value: 'monforte d alba', cap: '12065', provinceId: 4},
{id: 5810, value: 'pennapiedimonte', cap: '66010', provinceId: 71},
{id: 4273, value: 'montechiarugolo', cap: '43022', provinceId: 39},
{id: 4664, value: 'campo nell elba', cap: '57034', provinceId: 51},
{id: 5323, value: 'castel gandolfo', cap: '00040', provinceId: 65},
{id: 2678, value: 'volta mantovana', cap: '46049', provinceId: 18},
{id: 5385, value: 'rocca canterano', cap: '00020', provinceId: 65},
{id: 5663, value: 'castel castagna', cap: '64030', provinceId: 69},
{id: 888, value: 'capriata d orba', cap: '15060', provinceId: 6},
{id: 2813, value: 'pieve fissiraga', cap: '26854', provinceId: 20},
{id: 4573, value: 'forte dei marmi', cap: '55042', provinceId: 48},
{id: 1134, value: 'anzola d ossola', cap: '28877', provinceId: 8},
{id: 3426, value: 'pieve di cadore', cap: '32044', provinceId: 25},
{id: 5762, value: 'casalincontrada', cap: '66012', provinceId: 71},
{id: 219, value: 'frabosa soprana', cap: '12082', provinceId: 4},
{id: 2436, value: 'san cipriano po', cap: '27043', provinceId: 16},
{id: 2695, value: 'cassago brianza', cap: '23893', provinceId: 19},
{id: 3230, value: 'rivoli veronese', cap: '37010', provinceId: 23},
{id: 3513, value: 'pieve di soligo', cap: '31053', provinceId: 26},
{id: 5720, value: 'lettomanoppello', cap: '65020', provinceId: 70},
{id: 6125, value: 'frasso telesino', cap: '82030', provinceId: 75},
{id: 7228, value: 'roccella ionica', cap: '89047', provinceId: 88},
{id: 8011, value: 'villanovafranca', cap: '09020', provinceId: 106},
{id: 3314, value: 'isola vicentina', cap: '36033', provinceId: 24},
{id: 3189, value: 'castel d azzano', cap: '37060', provinceId: 23},
{id: 1508, value: 'lanzo d intelvi', cap: '22024', provinceId: 11},
{id: 5290, value: 'rocca sinibalda', cap: '02026', provinceId: 64},
{id: 5298, value: 'torri in sabina', cap: '02049', provinceId: 64},
{id: 2013, value: 'piazza brembana', cap: '24014', provinceId: 14},
{id: 763, value: 'castel boglione', cap: '14040', provinceId: 5},
{id: 5590, value: 'gagliano aterno', cap: '67020', provinceId: 68},
{id: 5822, value: 'roccaspinalveti', cap: '66050', provinceId: 71},
{id: 2578, value: 'robecco d oglio', cap: '26010', provinceId: 17},
{id: 901, value: 'cassano spinola', cap: '15063', provinceId: 6},
{id: 220, value: 'frabosa sottana', cap: '12083', provinceId: 4},
{id: 5969, value: 'pietrabbondante', cap: '86085', provinceId: 73},
{id: 1323, value: 'cassano magnago', cap: '21012', provinceId: 10},
{id: 2063, value: 'torre de roveri', cap: '24060', provinceId: 14},
{id: 6252, value: 'torre del greco', cap: '80059', provinceId: 76},
{id: 7699, value: 'monterosso almo', cap: '97010', provinceId: 98},
{id: 3345, value: 'pojana maggiore', cap: '36026', provinceId: 24},
{id: 3232, value: 'ronco all adige', cap: '37055', provinceId: 23},
{id: 4943, value: 'isola del piano', cap: '61030', provinceId: 59},
{id: 3465, value: 'casale sul sile', cap: '31032', provinceId: 26},
{id: 1370, value: 'laveno mombello', cap: '21014', provinceId: 10},
{id: 5595, value: 'lecce nei marsi', cap: '67050', provinceId: 68},
{id: 124, value: 'vaprio d agogna', cap: '28010', provinceId: 3},
{id: 1597, value: 'buglio in monte', cap: '23010', provinceId: 12},
{id: 2617, value: 'carbonara di po', cap: '46020', provinceId: 18},
{id: 5534, value: 'trevi nel lazio', cap: '03010', provinceId: 67},
{id: 7628, value: 'piazza armerina', cap: '94015', provinceId: 96},
{id: 1431, value: 'appiano gentile', cap: '22070', provinceId: 11},
{id: 4480, value: 'casola valsenio', cap: '48010', provinceId: 44},
{id: 2068, value: 'ubiale clanezzo', cap: '24010', provinceId: 14},
{id: 4827, value: 'poggio a caiano', cap: '59016', provinceId: 56},
{id: 3720, value: 'fratta polesine', cap: '45025', provinceId: 29},
{id: 1910, value: 'casirate d adda', cap: '24040', provinceId: 14},
{id: 7500, value: 'motta d affermo', cap: '98070', provinceId: 93},
{id: 7074, value: 'torano castello', cap: '87010', provinceId: 86},
{id: 6079, value: 'tora e piccilli', cap: '81044', provinceId: 74},
{id: 7454, value: 'castel di lucio', cap: '98070', provinceId: 93},
{id: 2334, value: 'casanova lonati', cap: '27041', provinceId: 16},
{id: 6010, value: 'castel di sasso', cap: '81040', provinceId: 74},
{id: 7168, value: 'bagnara calabra', cap: '89011', provinceId: 88},
{id: 525, value: 'romano canavese', cap: '10090', provinceId: 1},
{id: 2428, value: 'rocca de giorgi', cap: '27040', provinceId: 16},
{id: 6921, value: 'san mauro forte', cap: '75010', provinceId: 85},
{id: 1901, value: 'canonica d adda', cap: '24040', provinceId: 14},
{id: 3900, value: 'romans d isonzo', cap: '34076', provinceId: 31},
{id: 1148, value: 'cannero riviera', cap: '28821', provinceId: 8},
{id: 682, value: 'rocchetta belbo', cap: '12050', provinceId: 4},
{id: 5626, value: 'rocca di cambio', cap: '67047', provinceId: 68},
{id: 5764, value: 'castel frentano', cap: '66032', provinceId: 71},
{id: 352, value: 'candia canavese', cap: '10010', provinceId: 1},
{id: 239, value: 'limone piemonte', cap: '12015', provinceId: 4},
{id: 4852, value: 'gualdo cattaneo', cap: '06035', provinceId: 57},
{id: 1807, value: 'ronco briantino', cap: '20885', provinceId: 108},
{id: 4467, value: 'poggio renatico', cap: '44028', provinceId: 43},
{id: 2416, value: 'pieve del cairo', cap: '27037', provinceId: 16},
{id: 4849, value: 'fossato di vico', cap: '06022', provinceId: 57},
{id: 2604, value: 'vaiano cremasco', cap: '26010', provinceId: 17},
{id: 6055, value: 'rocca d evandro', cap: '81040', provinceId: 74},
{id: 7029, value: 'rocca imperiale', cap: '87074', provinceId: 86},
{id: 424, value: 'inverso pinasca', cap: '10060', provinceId: 1},
{id: 5823, value: 'roio del sangro', cap: '66040', provinceId: 71},
{id: 1577, value: 'uggiate trevano', cap: '22029', provinceId: 11},
{id: 5819, value: 'roccamontepiano', cap: '66010', provinceId: 71},
{id: 3548, value: 'vittorio veneto', cap: '31029', provinceId: 26},
{id: 4404, value: 'castel di casio', cap: '40030', provinceId: 42},
{id: 2415, value: 'pieve albignola', cap: '27030', provinceId: 16},
{id: 1971, value: 'isola di fondra', cap: '24010', provinceId: 14},
{id: 111, value: 'romagnano sesia', cap: '28078', provinceId: 3},
{id: 4267, value: 'fornovo di taro', cap: '43045', provinceId: 39},
{id: 4608, value: 'pieve a nievole', cap: '51018', provinceId: 49},
{id: 4687, value: 'casale marittimo', cap: '56040', provinceId: 52},
{id: 2206, value: 'moniga del garda', cap: '25080', provinceId: 15},
{id: 3995, value: 'diano san pietro', cap: '18013', provinceId: 34},
{id: 4049, value: 'cairo montenotte', cap: '17014', provinceId: 35},
{id: 2652, value: 'ponti sul mincio', cap: '46040', provinceId: 18},
{id: 3888, value: 'doberdo del lago', cap: '34070', provinceId: 31},
{id: 6482, value: 'prignano cilento', cap: '84060', provinceId: 78},
{id: 8014, value: 'villa san pietro', cap: '09010', provinceId: 102},
{id: 897, value: 'casaleggio boiro', cap: '15070', provinceId: 6},
{id: 6572, value: 'orsara di puglia', cap: '71027', provinceId: 79},
{id: 1368, value: 'jerago con orago', cap: '21040', provinceId: 10},
{id: 3099, value: 'ronchi valsugana', cap: '38050', provinceId: 22},
{id: 1830, value: 'trezzo sull adda', cap: '20056', provinceId: 13},
{id: 147, value: 'belvedere langhe', cap: '12060', provinceId: 4},
{id: 6596, value: 'vico del gargano', cap: '71018', provinceId: 79},
{id: 1267, value: 'saint christophe', cap: '11020', provinceId: 9},
{id: 4604, value: 'monsummano terme', cap: '51015', provinceId: 49},
{id: 1923, value: 'chignolo d isola', cap: '24040', provinceId: 14},
{id: 1615, value: 'faedo valtellino', cap: '23020', provinceId: 12},
{id: 2349, value: 'certosa di pavia', cap: '27012', provinceId: 16},
{id: 2771, value: 'boffalora d adda', cap: '26811', provinceId: 20},
{id: 51, value: 'bolzano novarese', cap: '28010', provinceId: 3},
{id: 5307, value: 'anticoli corrado', cap: '00022', provinceId: 65},
{id: 5172, value: 'barbarano romano', cap: '01010', provinceId: 63},
{id: 3427, value: 'ponte nelle alpi', cap: '32014', provinceId: 25},
{id: 2778, value: 'casalpusterlengo', cap: '26841', provinceId: 20},
{id: 3215, value: 'mezzane di sotto', cap: '37030', provinceId: 23},
{id: 7669, value: 'piedimonte etneo', cap: '95017', provinceId: 97},
{id: 6310, value: 'mirabella eclano', cap: '83036', provinceId: 77},
{id: 347, value: 'buttigliera alta', cap: '10090', provinceId: 1},
{id: 2883, value: 'moso in passiria', cap: '39013', provinceId: 21},
{id: 4053, value: 'casanova lerrone', cap: '17033', provinceId: 35},
{id: 4509, value: 'mercato saraceno', cap: '47025', provinceId: 45},
{id: 330, value: 'borgaro torinese', cap: '10071', provinceId: 1},
{id: 4327, value: 'quattro castella', cap: '42020', provinceId: 40},
{id: 1066, value: 'cerreto castello', cap: '13852', provinceId: 7},
{id: 7150, value: 'sorbo san basile', cap: '88050', provinceId: 87},
{id: 1430, value: 'anzano del parco', cap: '22040', provinceId: 11},
{id: 3661, value: 'ponte san nicolo', cap: '35020', provinceId: 28},
{id: 6977, value: 'fagnano castello', cap: '87013', provinceId: 86},
{id: 6080, value: 'trentola ducenta', cap: '81038', provinceId: 74},
{id: 955, value: 'molino dei torti', cap: '15050', provinceId: 6},
{id: 4002, value: 'molini di triora', cap: '18010', provinceId: 34},
{id: 5558, value: 'cagnano amiterno', cap: '67012', provinceId: 68},
{id: 7216, value: 'oppido mamertina', cap: '89014', provinceId: 88},
{id: 1946, value: 'fara gera d adda', cap: '24045', provinceId: 14},
{id: 5777, value: 'fara san martino', cap: '66015', provinceId: 71},
{id: 2513, value: 'casaletto vaprio', cap: '26010', provinceId: 17},
{id: 6577, value: 'poggio imperiale', cap: '71010', provinceId: 79},
{id: 3276, value: 'asigliano veneto', cap: '36020', provinceId: 24},
{id: 7996, value: 'siurgus donigala', cap: '09040', provinceId: 102},
{id: 3772, value: 'chiopris viscone', cap: '33048', provinceId: 30},
{id: 2253, value: 'provaglio d iseo', cap: '25050', provinceId: 15},
{id: 2414, value: 'pietra de giorgi', cap: '27040', provinceId: 16},
{id: 1996, value: 'oltressenda alta', cap: '24020', provinceId: 14},
{id: 4528, value: 'misano adriatico', cap: '47843', provinceId: 46},
{id: 4748, value: 'pergine valdarno', cap: '52020', provinceId: 53},
{id: 2019, value: 'ponte san pietro', cap: '24036', provinceId: 14},
{id: 6355, value: 'savignano irpino', cap: '83030', provinceId: 77},
{id: 5954, value: 'forli del sannio', cap: '86084', provinceId: 73},
{id: 6939, value: 'belmonte calabro', cap: '87033', provinceId: 86},
{id: 4624, value: 'capraia e limite', cap: '50050', provinceId: 50},
{id: 7115, value: 'isca sullo ionio', cap: '88060', provinceId: 87},
{id: 5436, value: 'monte san biagio', cap: '04020', provinceId: 66},
{id: 5788, value: 'giuliano teatino', cap: '66010', provinceId: 71},
{id: 2506, value: 'cappella cantone', cap: '26020', provinceId: 17},
{id: 495, value: 'piobesi torinese', cap: '10040', provinceId: 1},
{id: 5199, value: 'ischia di castro', cap: '01010', provinceId: 63},
{id: 3958, value: 'sesto al reghena', cap: '33079', provinceId: 33},
{id: 4807, value: 'isola del giglio', cap: '58012', provinceId: 55},
{id: 2916, value: 'selva dei molini', cap: '39030', provinceId: 21},
{id: 5069, value: 'monte san giusto', cap: '62015', provinceId: 61},
{id: 5286, value: 'pozzaglia sabina', cap: '02030', provinceId: 64},
{id: 1869, value: 'azzano san paolo', cap: '24052', provinceId: 14},
{id: 6427, value: 'corleto monforte', cap: '84020', provinceId: 78},
{id: 7030, value: 'roggiano gravina', cap: '87017', provinceId: 86},
{id: 2826, value: 'turano lodigiano', cap: '26828', provinceId: 20},
{id: 4656, value: 'sesto fiorentino', cap: '50019', provinceId: 50},
{id: 3158, value: 'vignola falesina', cap: '38057', provinceId: 22},
{id: 6215, value: 'monte di procida', cap: '80070', provinceId: 76},
{id: 5309, value: 'arcinazzo romano', cap: '00020', provinceId: 65},
{id: 5495, value: 'giuliano di roma', cap: '03020', provinceId: 67},
{id: 6278, value: 'capriglia irpina', cap: '83010', provinceId: 77},
{id: 5109, value: 'colli del tronto', cap: '63079', provinceId: 62},
{id: 5317, value: 'canale monterano', cap: '00060', provinceId: 65},
{id: 2330, value: 'candia lomellina', cap: '27031', provinceId: 16},
{id: 2663, value: 'san benedetto po', cap: '46027', provinceId: 18},
{id: 3075, value: 'palu del fersina', cap: '38050', provinceId: 22},
{id: 6614, value: 'canosa di puglia', cap: '76012', provinceId: 110},
{id: 5376, value: 'palombara sabina', cap: '00018', provinceId: 65},
{id: 3263, value: 'villa bartolomea', cap: '37049', provinceId: 23},
{id: 5951, value: 'colli a volturno', cap: '86073', provinceId: 73},
{id: 5250, value: 'colli sul velino', cap: '02010', provinceId: 64},
{id: 479, value: 'palazzo canavese', cap: '10010', provinceId: 1},
{id: 31, value: 'serravalle sesia', cap: '13037', provinceId: 2},
{id: 887, value: 'cantalupo ligure', cap: '15060', provinceId: 6},
{id: 1930, value: 'cologno al serio', cap: '24055', provinceId: 14},
{id: 3442, value: 'seren del grappa', cap: '32030', provinceId: 25},
{id: 7639, value: 'aci sant antonio', cap: '95025', provinceId: 97},
{id: 4745, value: 'monte san savino', cap: '52048', provinceId: 53},
{id: 6423, value: 'conca dei marini', cap: '84010', provinceId: 78},
{id: 4431, value: 'monte san pietro', cap: '40050', provinceId: 42},
{id: 3778, value: 'corno di rosazzo', cap: '33040', provinceId: 30},
{id: 3186, value: 'caprino veronese', cap: '37013', provinceId: 23},
{id: 42, value: 'agrate conturbia', cap: '28010', provinceId: 3},
{id: 835, value: 'rocchetta tanaro', cap: '14030', provinceId: 5},
{id: 2313, value: 'bastida de dossi', cap: '27050', provinceId: 16},
{id: 1717, value: 'ceriano laghetto', cap: '20816', provinceId: 108},
{id: 1990, value: 'mornico al serio', cap: '24050', provinceId: 14},
{id: 5270, value: 'montenero sabino', cap: '02040', provinceId: 64},
{id: 4494, value: 'bagno di romagna', cap: '47021', provinceId: 45},
{id: 2812, value: 'ossago lodigiano', cap: '26816', provinceId: 20},
{id: 6540, value: 'anzano di puglia', cap: '71020', provinceId: 79},
{id: 7535, value: 'scaletta zanclea', cap: '98029', provinceId: 93},
{id: 6323, value: 'morra de sanctis', cap: '83040', provinceId: 77},
{id: 5321, value: 'carpineto romano', cap: '00032', provinceId: 65},
{id: 1855, value: 'adrara san rocco', cap: '24060', provinceId: 14},
{id: 1007, value: 'rocchetta ligure', cap: '15060', provinceId: 6},
{id: 7108, value: 'fossato serralta', cap: '88050', provinceId: 87},
{id: 963, value: 'montaldo bormida', cap: '15010', provinceId: 6},
{id: 3907, value: 'savogna d isonzo', cap: '34070', provinceId: 31},
{id: 1262, value: 'pre saint didier', cap: '11010', provinceId: 9},
{id: 5608, value: 'ortona dei marsi', cap: '67050', provinceId: 68},
{id: 5849, value: 'pietraferrazzana', cap: '66040', provinceId: 71},
{id: 5154, value: 'ponzano di fermo', cap: '63845', provinceId: 109},
{id: 4250, value: 'ziano piacentino', cap: '29010', provinceId: 38},
{id: 5707, value: 'caramanico terme', cap: '65023', provinceId: 70},
{id: 1935, value: 'costa di mezzate', cap: '24060', provinceId: 14},
{id: 6300, value: 'guardia lombardi', cap: '83040', provinceId: 77},
{id: 1823, value: 'settimo milanese', cap: '20019', provinceId: 13},
{id: 2855, value: 'corvara in badia', cap: '39033', provinceId: 21},
{id: 567, value: 'settimo torinese', cap: '10036', provinceId: 1},
{id: 1611, value: 'cosio valtellino', cap: '23013', provinceId: 12},
{id: 4811, value: 'monte argentario', cap: '58019', provinceId: 55},
{id: 397, value: 'cossano canavese', cap: '10010', provinceId: 1},
{id: 2234, value: 'pavone del mella', cap: '25020', provinceId: 15},
{id: 1315, value: 'cardano al campo', cap: '21010', provinceId: 10},
{id: 2527, value: 'credera rubbiano', cap: '26010', provinceId: 17},
{id: 2648, value: 'pieve di coriano', cap: '46020', provinceId: 18},
{id: 6123, value: 'fragneto l abate', cap: '82020', provinceId: 75},
{id: 1318, value: 'caronno varesino', cap: '21040', provinceId: 10},
{id: 5096, value: 'acquasanta terme', cap: '63095', provinceId: 62},
{id: 5097, value: 'acquaviva picena', cap: '63075', provinceId: 62},
{id: 5581, value: 'civitella roveto', cap: '67054', provinceId: 68},
{id: 485, value: 'pecetto torinese', cap: '10020', provinceId: 1},
{id: 59, value: 'carpignano sesia', cap: '28064', provinceId: 3},
{id: 6213, value: 'melito di napoli', cap: '80017', provinceId: 76},
{id: 1411, value: 'travedona monate', cap: '21028', provinceId: 10},
{id: 2611, value: 'bagnolo san vito', cap: '46031', provinceId: 18},
{id: 4945, value: 'macerata feltria', cap: '61023', provinceId: 59},
{id: 2448, value: 'san zenone al po', cap: '27010', provinceId: 16},
{id: 5198, value: 'grotte di castro', cap: '01025', provinceId: 63},
{id: 3454, value: 'voltago agordino', cap: '32020', provinceId: 25},
{id: 9439, value: 'moos in passeier', cap: '39013', provinceId: 21},
{id: 7355, value: 'san vito lo capo', cap: '91010', provinceId: 91},
{id: 242, value: 'magliano alfieri', cap: '12050', provinceId: 4},
{id: 5170, value: 'arlena di castro', cap: '01010', provinceId: 63},
{id: 1690, value: 'bovisio masciago', cap: '20813', provinceId: 108},
{id: 1913, value: 'castelli calepio', cap: '24060', provinceId: 14},
{id: 4037, value: 'albissola marina', cap: '17012', provinceId: 35},
{id: 138, value: 'bagnolo piemonte', cap: '12031', provinceId: 4},
{id: 2379, value: 'gropello cairoli', cap: '27027', provinceId: 16},
{id: 6439, value: 'laureana cilento', cap: '84050', provinceId: 78},
{id: 6251, value: 'torre annunziata', cap: '80058', provinceId: 76},
{id: 4299, value: 'bagnolo in piano', cap: '42011', provinceId: 40},
{id: 4420, value: 'grizzana morandi', cap: '40030', provinceId: 42},
{id: 6458, value: 'nocera superiore', cap: '84015', provinceId: 78},
{id: 5383, value: 'rignano flaminio', cap: '00068', provinceId: 65},
{id: 6369, value: 'torre le nocelle', cap: '83030', provinceId: 77},
{id: 3178, value: 'boschi sant anna', cap: '37040', provinceId: 23},
{id: 819, value: 'nizza monferrato', cap: '14049', provinceId: 5},
{id: 6209, value: 'marano di napoli', cap: '80016', provinceId: 76},
{id: 2900, value: 'rasun anterselva', cap: '39030', provinceId: 21},
{id: 4531, value: 'montefiore conca', cap: '47834', provinceId: 46},
{id: 4396, value: 'borgo tossignano', cap: '40021', provinceId: 42},
{id: 3254, value: 'torri del benaco', cap: '37010', provinceId: 23},
{id: 2463, value: 'torrevecchia pia', cap: '27010', provinceId: 16},
{id: 3838, value: 'reana del rojale', cap: '33010', provinceId: 30},
{id: 3322, value: 'marano vicentino', cap: '36035', provinceId: 24},
{id: 6378, value: 'volturara irpina', cap: '83050', provinceId: 77},
{id: 3650, value: 'noventa padovana', cap: '35027', provinceId: 28},
{id: 7415, value: 'petralia sottana', cap: '90027', provinceId: 92},
{id: 6598, value: 'volturara appula', cap: '71030', provinceId: 79},
{id: 3578, value: 'noventa di piave', cap: '30020', provinceId: 27},
{id: 5782, value: 'fresagrandinaria', cap: '66050', provinceId: 71},
{id: 4636, value: 'greve in chianti', cap: '50022', provinceId: 50},
{id: 1950, value: 'fiorano al serio', cap: '24020', provinceId: 14},
{id: 3460, value: 'borso del grappa', cap: '31030', provinceId: 26},
{id: 7414, value: 'petralia soprana', cap: '90026', provinceId: 92},
{id: 3602, value: 'bagnoli di sopra', cap: '35023', provinceId: 28},
{id: 4355, value: 'fiorano modenese', cap: '41042', provinceId: 41},
{id: 6457, value: 'nocera inferiore', cap: '84014', provinceId: 78},
{id: 7502, value: 'nizza di sicilia', cap: '98026', provinceId: 93},
{id: 3170, value: 'albaredo d adige', cap: '37041', provinceId: 23},
{id: 4855, value: 'lisciano niccone', cap: '06060', provinceId: 57},
{id: 857, value: 'villanova d asti', cap: '14019', provinceId: 5},
{id: 4768, value: 'chianciano terme', cap: '53042', provinceId: 54},
{id: 3370, value: 'tezze sul brenta', cap: '36056', provinceId: 24},
{id: 5083, value: 'ripe san ginesio', cap: '62020', provinceId: 61},
{id: 2283, value: 'torbole casaglia', cap: '25030', provinceId: 15},
{id: 659, value: 'livorno ferraris', cap: '13046', provinceId: 2},
{id: 755, value: 'camerano casasco', cap: '14020', provinceId: 5},
{id: 1839, value: 'vedano al lambro', cap: '20854', provinceId: 108},
{id: 6081, value: 'vairano patenora', cap: '81058', provinceId: 74},
{id: 4625, value: 'castelfiorentino', cap: '50051', provinceId: 50},
{id: 5573, value: 'castel di sangro', cap: '67031', provinceId: 68},
{id: 5185, value: 'castel sant elia', cap: '01030', provinceId: 63},
{id: 735, value: 'villanova solaro', cap: '12030', provinceId: 4},
{id: 266, value: 'montelupo albese', cap: '12050', provinceId: 4},
{id: 7471, value: 'galati mamertino', cap: '98070', provinceId: 93},
{id: 2186, value: 'limone sul garda', cap: '25010', provinceId: 15},
{id: 4728, value: 'castel focognano', cap: '52016', provinceId: 53},
{id: 5743, value: 'torre de passeri', cap: '65029', provinceId: 70},
{id: 6636, value: 'polignano a mare', cap: '70044', provinceId: 80},
{id: 7647, value: 'castel di iudica', cap: '95040', provinceId: 97},
{id: 9416, value: 'voels am schlern', cap: '39050', provinceId: 21},
{id: 2476, value: 'vellezzo bellini', cap: '27010', provinceId: 16},
{id: 2189, value: 'lonato del garda', cap: '25017', provinceId: 15},
{id: 4481, value: 'castel bolognese', cap: '48014', provinceId: 44},
{id: 3654, value: 'piacenza d adige', cap: '35040', provinceId: 28},
{id: 7547, value: 'terme vigliatore', cap: '98050', provinceId: 93},
{id: 3181, value: 'brentino belluno', cap: '37020', provinceId: 23},
{id: 2498, value: 'bagnolo cremasco', cap: '26010', provinceId: 17},
{id: 3632, value: 'galzignano terme', cap: '35030', provinceId: 28},
{id: 865, value: 'alluvioni cambio', cap: '15040', provinceId: 6},
{id: 6346, value: 'san potito ultra', cap: '83050', provinceId: 77},
{id: 6763, value: 'salice salentino', cap: '73015', provinceId: 83},
{id: 3868, value: 'terzo d aquileia', cap: '33050', provinceId: 30},
{id: 1303, value: 'brezzo di bedero', cap: '21010', provinceId: 10},
{id: 5571, value: 'castel del monte', cap: '67023', provinceId: 68},
{id: 5837, value: 'torino di sangro', cap: '66020', provinceId: 71},
{id: 4799, value: 'castel del piano', cap: '58033', provinceId: 55},
{id: 6262, value: 'altavilla irpina', cap: '83011', provinceId: 77},
{id: 6224, value: 'pollena trocchia', cap: '80040', provinceId: 76},
{id: 7417, value: 'polizzi generosa', cap: '90028', provinceId: 92},
{id: 2574, value: 'ripalta cremasca', cap: '26010', provinceId: 17},
{id: 3670, value: 'san pietro in gu', cap: '35010', provinceId: 28},
{id: 1464, value: 'cassina rizzardi', cap: '22070', provinceId: 11},
{id: 407, value: 'fiorano canavese', cap: '10010', provinceId: 1},
{id: 2550, value: 'martignana di po', cap: '26040', provinceId: 17},
{id: 5699, value: 'valle castellana', cap: '64010', provinceId: 69},
{id: 101, value: 'oleggio castello', cap: '28040', provinceId: 3},
{id: 4611, value: 'ponte buggianese', cap: '51019', provinceId: 49},
{id: 1324, value: 'cassano valcuvia', cap: '21030', provinceId: 10},
{id: 2998, value: 'centa san nicolo', cap: '38040', provinceId: 22},
{id: 7337, value: 'buseto palizzolo', cap: '91012', provinceId: 91},
{id: 6681, value: 'ceglie messapica', cap: '72013', provinceId: 82},
{id: 7977, value: 'sant anna arresi', cap: '09010', provinceId: 107},
{id: 5407, value: 'torrita tiberina', cap: '00060', provinceId: 65},
{id: 976, value: 'odalengo piccolo', cap: '15020', provinceId: 6},
{id: 268, value: 'monterosso grana', cap: '12020', provinceId: 4},
{id: 2256, value: 'quinzano d oglio', cap: '25027', provinceId: 15},
{id: 4794, value: 'torrita di siena', cap: '53049', provinceId: 54},
{id: 1356, value: 'gazzada schianno', cap: '21045', provinceId: 10},
{id: 3640, value: 'masera di padova', cap: '35020', provinceId: 28},
{id: 7347, value: 'mazara del vallo', cap: '91026', provinceId: 91},
{id: 92, value: 'massino visconti', cap: '28040', provinceId: 3},
{id: 1152, value: 'cavaglio spoccia', cap: '28825', provinceId: 8},
{id: 5705, value: 'bussi sul tirino', cap: '65022', provinceId: 70},
{id: 4782, value: 'radda in chianti', cap: '53017', provinceId: 54},
{id: 7152, value: 'soveria mannelli', cap: '88049', provinceId: 87},
{id: 6190, value: 'casola di napoli', cap: '80050', provinceId: 76},
{id: 1470, value: 'cerano d intelvi', cap: '22020', provinceId: 11},
{id: 4096, value: 'tovo san giacomo', cap: '17020', provinceId: 35},
{id: 4631, value: 'figline valdarno', cap: '50063', provinceId: 50},
{id: 7070, value: 'spezzano piccolo', cap: '87050', provinceId: 86},
{id: 5791, value: 'lama dei peligni', cap: '66010', provinceId: 71},
{id: 6339, value: 'rocca san felice', cap: '83050', provinceId: 77},
{id: 3349, value: 'quinto vicentino', cap: '36050', provinceId: 24},
{id: 3191, value: 'cavaion veronese', cap: '37010', provinceId: 23},
{id: 3449, value: 'vallada agordina', cap: '32020', provinceId: 25},
{id: 486, value: 'perosa argentina', cap: '10063', provinceId: 1},
{id: 2860, value: 'fie allo sciliar', cap: '39050', provinceId: 21},
{id: 414, value: 'gassino torinese', cap: '10090', provinceId: 1},
{id: 188, value: 'cavallermaggiore', cap: '12030', provinceId: 4},
{id: 3470, value: 'cavaso del tomba', cap: '31034', provinceId: 26},
{id: 7586, value: 'santa elisabetta', cap: '92020', provinceId: 94},
{id: 3505, value: 'motta di livenza', cap: '31045', provinceId: 26},
{id: 365, value: 'caselle torinese', cap: '10072', provinceId: 1},
{id: 1198, value: 'trarego viggiona', cap: '28826', provinceId: 8},
{id: 6261, value: 'aiello del sabato', cap: '83020', provinceId: 77},
{id: 7685, value: 'tremestieri etneo', cap: '95030', provinceId: 97},
{id: 2284, value: 'toscolano maderno', cap: '25088', provinceId: 15},
{id: 4398, value: 'calderara di reno', cap: '40012', provinceId: 42},
{id: 1336, value: 'cocquio trevisago', cap: '21034', provinceId: 10},
{id: 1891, value: 'brembate di sopra', cap: '24030', provinceId: 14},
{id: 2110, value: 'bassano bresciano', cap: '25020', provinceId: 15},
{id: 2681, value: 'annone di brianza', cap: '23841', provinceId: 19},
{id: 6009, value: 'castel campagnano', cap: '81010', provinceId: 74},
{id: 2589, value: 'solarolo rainerio', cap: '26030', provinceId: 17},
{id: 7383, value: 'castellana sicula', cap: '90020', provinceId: 92},
{id: 6618, value: 'castellana grotte', cap: '70013', provinceId: 80},
{id: 1322, value: 'casorate sempione', cap: '21011', provinceId: 10},
{id: 2598, value: 'torlino vimercati', cap: '26017', provinceId: 17},
{id: 7694, value: 'chiaramonte gulfi', cap: '97012', provinceId: 98},
{id: 2475, value: 'velezzo lomellina', cap: '27020', provinceId: 16},
{id: 3401, value: 'colle santa lucia', cap: '32020', provinceId: 25},
{id: 5706, value: 'cappelle sul tavo', cap: '65010', provinceId: 70},
{id: 1452, value: 'campione d italia', cap: '22060', provinceId: 11},
{id: 1900, value: 'camerata cornello', cap: '24010', provinceId: 14},
{id: 7709, value: 'canicattini bagni', cap: '96010', provinceId: 99},
{id: 4176, value: 'borghetto di vara', cap: '19020', provinceId: 37},
{id: 5742, value: 'tocco da casauria', cap: '65028', provinceId: 70},
{id: 1461, value: 'casasco d intelvi', cap: '22022', provinceId: 11},
{id: 6809, value: 'brindisi montagna', cap: '85010', provinceId: 84},
{id: 4289, value: 'tizzano val parma', cap: '43028', provinceId: 39},
{id: 607, value: 'villar focchiardo', cap: '10050', provinceId: 1},
{id: 708, value: 'serravalle langhe', cap: '12050', provinceId: 4},
{id: 5948, value: 'cerro al volturno', cap: '86072', provinceId: 73},
{id: 1590, value: 'andalo valtellino', cap: '23014', provinceId: 12},
{id: 5470, value: 'campoli appennino', cap: '03030', provinceId: 67},
{id: 734, value: 'villanova mondovi', cap: '12089', provinceId: 4},
{id: 1455, value: 'capiago intimiano', cap: '22070', provinceId: 11},
{id: 3378, value: 'valli del pasubio', cap: '36030', provinceId: 24},
{id: 3463, value: 'cappella maggiore', cap: '31012', provinceId: 26},
{id: 883, value: 'brignano frascata', cap: '15050', provinceId: 6},
{id: 3961, value: 'tramonti di sotto', cap: '33090', provinceId: 33},
{id: 7176, value: 'bruzzano zeffirio', cap: '89030', provinceId: 88},
{id: 7363, value: 'altavilla milicia', cap: '90010', provinceId: 92},
{id: 3960, value: 'tramonti di sopra', cap: '33090', provinceId: 33},
{id: 6789, value: 'uggiano la chiesa', cap: '73020', provinceId: 83},
{id: 6682, value: 'cellino san marco', cap: '72020', provinceId: 82},
{id: 5668, value: 'cellino attanasio', cap: '64036', provinceId: 69},
{id: 1126, value: 'vigliano biellese', cap: '13856', provinceId: 7},
{id: 3682, value: 'terrassa padovana', cap: '35020', provinceId: 28},
{id: 2786, value: 'cervignano d adda', cap: '26832', provinceId: 20},
{id: 6556, value: 'celle di san vito', cap: '71020', provinceId: 79},
{id: 1211, value: 'antey saint andre', cap: '11020', provinceId: 9},
{id: 3279, value: 'bolzano vicentino', cap: '36050', provinceId: 24},
{id: 2960, value: 'bleggio superiore', cap: '38071', provinceId: 22},
{id: 1064, value: 'castelletto cervo', cap: '13851', provinceId: 7},
{id: 6183, value: 'carbonara di nola', cap: '80030', provinceId: 76},
{id: 3395, value: 'calalzo di cadore', cap: '32042', provinceId: 25},
{id: 6889, value: 'vaglio basilicata', cap: '85010', provinceId: 84},
{id: 717, value: 'torre san giorgio', cap: '12030', provinceId: 4},
{id: 3297, value: 'cismon del grappa', cap: '36020', provinceId: 24},
{id: 2064, value: 'torre pallavicina', cap: '24050', provinceId: 14},
{id: 4621, value: 'borgo san lorenzo', cap: '50032', provinceId: 50},
{id: 61, value: 'casaleggio novara', cap: '28060', provinceId: 3},
{id: 6798, value: 'albano di lucania', cap: '85010', provinceId: 84},
{id: 4897, value: 'calvi dell umbria', cap: '05032', provinceId: 58},
{id: 1713, value: 'cassina de pecchi', cap: '20060', provinceId: 13},
{id: 5696, value: 'torricella sicura', cap: '64010', provinceId: 69},
{id: 1684, value: 'besana in brianza', cap: '20842', provinceId: 108},
{id: 2829, value: 'zelo buon persico', cap: '26839', provinceId: 20},
{id: 6891, value: 'vietri di potenza', cap: '85058', provinceId: 84},
{id: 7563, value: 'cattolica eraclea', cap: '92011', provinceId: 94},
{id: 3400, value: 'cibiana di cadore', cap: '32040', provinceId: 25},
{id: 6005, value: 'casal di principe', cap: '81033', provinceId: 74},
{id: 1925, value: 'cisano bergamasco', cap: '24034', provinceId: 14},
{id: 5650, value: 'villa sant angelo', cap: '67020', provinceId: 68},
{id: 4843, value: 'citta di castello', cap: '06012', provinceId: 57},
{id: 619, value: 'albano vercellese', cap: '13030', provinceId: 2},
{id: 5477, value: 'castro dei volsci', cap: '03020', provinceId: 67},
{id: 5408, value: 'trevignano romano', cap: '00069', provinceId: 65},
{id: 3749, value: 'aiello del friuli', cap: '33041', provinceId: 30},
{id: 3560, value: 'cinto caomaggiore', cap: '30020', provinceId: 27},
{id: 7368, value: 'belmonte mezzagno', cap: '90031', provinceId: 92},
{id: 2288, value: 'treviso bresciano', cap: '25070', provinceId: 15},
{id: 898, value: 'casale monferrato', cap: '15033', provinceId: 6},
{id: 2783, value: 'castiraga vidardo', cap: '26866', provinceId: 20},
{id: 5467, value: 'belmonte castello', cap: '03040', provinceId: 67},
{id: 1329, value: 'castiglione olona', cap: '21043', provinceId: 10},
{id: 879, value: 'borgo san martino', cap: '15032', provinceId: 6},
{id: 1724, value: 'cinisello balsamo', cap: '20092', provinceId: 13},
{id: 139, value: 'baldissero d alba', cap: '12040', provinceId: 4},
{id: 6532, value: 'valle dell angelo', cap: '84070', provinceId: 78},
{id: 4256, value: 'borgo val di taro', cap: '43043', provinceId: 39},
{id: 858, value: 'villa san secondo', cap: '14020', provinceId: 5},
{id: 4842, value: 'citta della pieve', cap: '06062', provinceId: 57},
{id: 5712, value: 'citta sant angelo', cap: '65013', provinceId: 70},
{id: 575, value: 'torrazza piemonte', cap: '10037', provinceId: 1},
{id: 3392, value: 'auronzo di cadore', cap: '32041', provinceId: 25},
{id: 2342, value: 'castello d agogna', cap: '27030', provinceId: 16},
{id: 181, value: 'castellino tanaro', cap: '12060', provinceId: 4},
{id: 4306, value: 'campagnola emilia', cap: '42012', provinceId: 40},
{id: 1475, value: 'claino con osteno', cap: '22010', provinceId: 11},
{id: 3690, value: 'vighizzolo d este', cap: '35040', provinceId: 28},
{id: 178, value: 'castelletto stura', cap: '12040', provinceId: 4},
{id: 2324, value: 'brallo di pregola', cap: '27050', provinceId: 16},
{id: 909, value: 'castelletto merli', cap: '15020', provinceId: 6},
{id: 1024, value: 'spigno monferrato', cap: '15018', provinceId: 6},
{id: 7068, value: 'spezzano albanese', cap: '87019', provinceId: 86},
{id: 3598, value: 'anguillara veneta', cap: '35022', provinceId: 28},
{id: 390, value: 'coassolo torinese', cap: '10070', provinceId: 1},
{id: 889, value: 'carbonara scrivia', cap: '15050', provinceId: 6},
{id: 4617, value: 'chiesina uzzanese', cap: '51013', provinceId: 49},
{id: 5848, value: 'villa santa maria', cap: '66047', provinceId: 71},
{id: 1123, value: 'valle san nicolao', cap: '13847', provinceId: 7},
{id: 4406, value: 'castello d argile', cap: '40050', provinceId: 42},
{id: 7593, value: 'acquaviva platani', cap: '93010', provinceId: 95},
{id: 4471, value: 'vigarano mainarda', cap: '44049', provinceId: 43},
{id: 67, value: 'cavaglio d agogna', cap: '28010', provinceId: 3},
{id: 1927, value: 'cividate al piano', cap: '24050', provinceId: 14},
{id: 369, value: 'castelnuovo nigra', cap: '10080', provinceId: 1},
{id: 2117, value: 'borgo san giacomo', cap: '25022', provinceId: 15},
{id: 2602, value: 'trescore cremasco', cap: '26017', provinceId: 17},
{id: 4181, value: 'castelnuovo magra', cap: '19033', provinceId: 37},
{id: 768, value: 'castelnuovo belbo', cap: '14043', provinceId: 5},
{id: 5189, value: 'civita castellana', cap: '01033', provinceId: 63},
{id: 2314, value: 'bastida pancarana', cap: '27050', provinceId: 16},
{id: 1696, value: 'burago di molgora', cap: '20875', provinceId: 108},
{id: 5543, value: 'villa santa lucia', cap: '03030', provinceId: 67},
{id: 5757, value: 'carpineto sinello', cap: '66030', provinceId: 71},
{id: 5869, value: 'civitacampomarano', cap: '86030', provinceId: 72},
{id: 7158, value: 'torre di ruggiero', cap: '88060', provinceId: 87},
{id: 3614, value: 'campo san martino', cap: '35010', provinceId: 28},
{id: 5051, value: 'civitanova marche', cap: '62012', provinceId: 61},
{id: 3179, value: 'bosco chiesanuova', cap: '37021', provinceId: 23},
{id: 2347, value: 'ceretto lomellina', cap: '27030', provinceId: 16},
{id: 516, value: 'rivalta di torino', cap: '10040', provinceId: 1},
{id: 5446, value: 'san felice circeo', cap: '04017', provinceId: 66},
{id: 3497, value: 'meduna di livenza', cap: '31040', provinceId: 26},
{id: 809, value: 'moncucco torinese', cap: '14024', provinceId: 5},
{id: 700, value: 'sant albano stura', cap: '12040', provinceId: 4},
{id: 807, value: 'monastero bormida', cap: '14058', provinceId: 5},
{id: 6124, value: 'fragneto monforte', cap: '82020', provinceId: 75},
{id: 2394, value: 'mezzana rabattone', cap: '27030', provinceId: 16},
{id: 7966, value: 'quartu sant elena', cap: '09045', provinceId: 102},
{id: 3520, value: 'quinto di treviso', cap: '31055', provinceId: 26},
{id: 12, value: 'quinto vercellese', cap: '13030', provinceId: 2},
{id: 6868, value: 'san chirico nuovo', cap: '85010', provinceId: 84},
{id: 6911, value: 'montalbano jonico', cap: '75023', provinceId: 85},
{id: 929, value: 'francavilla bisio', cap: '15060', provinceId: 6},
{id: 2640, value: 'mariana mantovana', cap: '46010', provinceId: 18},
{id: 4362, value: 'marano sul panaro', cap: '41054', provinceId: 41},
{id: 5117, value: 'francavilla d ete', cap: '63816', provinceId: 109},
{id: 7003, value: 'marano principato', cap: '87040', provinceId: 86},
{id: 7002, value: 'marano marchesato', cap: '87040', provinceId: 86},
{id: 2199, value: 'manerba del garda', cap: '25080', provinceId: 15},
{id: 5012, value: 'maiolati spontini', cap: '60030', provinceId: 60},
{id: 5121, value: 'magliano di tenna', cap: '63832', provinceId: 109},
{id: 1799, value: 'pregnana milanese', cap: '20010', provinceId: 13},
{id: 696, value: 'san damiano macra', cap: '12029', provinceId: 4},
{id: 3026, value: 'fiera di primiero', cap: '38054', provinceId: 22},
{id: 6570, value: 'monte sant angelo', cap: '71037', provinceId: 79},
{id: 990, value: 'pomaro monferrato', cap: '15040', provinceId: 6},
{id: 6225, value: 'pomigliano d arco', cap: '80038', provinceId: 76},
{id: 6314, value: 'monteforte irpino', cap: '83024', provinceId: 77},
{id: 1261, value: 'pont saint martin', cap: '11026', provinceId: 9},
{id: 4324, value: 'montecchio emilia', cap: '42027', provinceId: 40},
{id: 4606, value: 'montecatini terme', cap: '51016', provinceId: 49},
{id: 6312, value: 'montecalvo irpino', cap: '83037', provinceId: 77},
{id: 7214, value: 'montebello ionico', cap: '89064', provinceId: 88},
{id: 6581, value: 'roseto valfortore', cap: '71039', provinceId: 79},
{id: 5070, value: 'monte san martino', cap: '62020', provinceId: 61},
{id: 813, value: 'montaldo scarampi', cap: '14048', provinceId: 5},
{id: 6454, value: 'monte san giacomo', cap: '84030', provinceId: 78},
{id: 997, value: 'pozzolo formigaro', cap: '15068', provinceId: 6},
{id: 3566, value: 'fossalta di piave', cap: '30020', provinceId: 27},
{id: 1352, value: 'ferrera di varese', cap: '21030', provinceId: 10},
{id: 2365, value: 'ferrera erbognone', cap: '27032', provinceId: 16},
{id: 3584, value: 'san dona di piave', cap: '30027', provinceId: 27},
{id: 5032, value: 'santa maria nuova', cap: '60030', provinceId: 60},
{id: 5619, value: 'prata d ansidonia', cap: '67020', provinceId: 68},
{id: 460, value: 'montaldo torinese', cap: '10020', provinceId: 1},
{id: 5598, value: 'magliano de marsi', cap: '67062', provinceId: 68},
{id: 4613, value: 'sambuca pistoiese', cap: '51020', provinceId: 49},
{id: 7423, value: 'san giuseppe jato', cap: '90048', provinceId: 92},
{id: 6058, value: 'rocchetta e croce', cap: '81042', provinceId: 74},
{id: 1005, value: 'roccaforte ligure', cap: '15060', provinceId: 6},
{id: 3414, value: 'la valle agordina', cap: '32020', provinceId: 25},
{id: 3951, value: 'roveredo in piano', cap: '33080', provinceId: 33},
{id: 3102, value: 'rovere della luna', cap: '38030', provinceId: 22},
{id: 3893, value: 'gradisca d isonzo', cap: '34072', provinceId: 31},
{id: 7568, value: 'joppolo giancaxio', cap: '92010', provinceId: 94},
{id: 4459, value: 'jolanda di savoia', cap: '44037', provinceId: 43},
{id: 1500, value: 'grandola ed uniti', cap: '22010', provinceId: 11},
{id: 4195, value: 'rocchetta di vara', cap: '19020', provinceId: 37},
{id: 4018, value: 'rocchetta nervina', cap: '18030', provinceId: 34},
{id: 4851, value: 'giano dell umbria', cap: '06030', provinceId: 57},
{id: 834, value: 'rocchetta palafea', cap: '14042', provinceId: 5},
{id: 3208, value: 'isola della scala', cap: '37063', provinceId: 23},
{id: 4130, value: 'isola del cantone', cap: '16017', provinceId: 36},
{id: 797, value: 'incisa scapaccino', cap: '14045', provinceId: 5},
{id: 3352, value: 'romano d ezzelino', cap: '36060', provinceId: 24},
{id: 6624, value: 'gravina in puglia', cap: '70024', provinceId: 80},
{id: 6833, value: 'guardia perticara', cap: '85010', provinceId: 84},
{id: 7976, value: 'sant andrea frius', cap: '09040', provinceId: 102},
{id: 796, value: 'grazzano badoglio', cap: '14035', provinceId: 5},
{id: 5689, value: 'rocca santa maria', cap: '64010', provinceId: 69},
{id: 2541, value: 'gerre de caprioli', cap: '26040', provinceId: 17},
{id: 3110, value: 'sant orsola terme', cap: '38050', provinceId: 22},
{id: 6726, value: 'gagliano del capo', cap: '73034', provinceId: 83},
{id: 3718, value: 'fiesso umbertiano', cap: '45024', provinceId: 29},
{id: 1264, value: 'rhemes notre dame', cap: '11010', provinceId: 9},
{id: 1172, value: 'madonna del sasso', cap: '28894', provinceId: 8},
{id: 6033, value: 'macerata campania', cap: '81047', provinceId: 74},
{id: 536, value: 'salza di pinerolo', cap: '10060', provinceId: 1},
{id: 5959, value: 'macchia d isernia', cap: '86070', provinceId: 73},
{id: 6578, value: 'rignano garganico', cap: '71010', provinceId: 79},
{id: 4649, value: 'rignano sull arno', cap: '50067', provinceId: 50},
{id: 14, value: 'rima san giuseppe', cap: '13026', provinceId: 2},
{id: 4772, value: 'gaiole in chianti', cap: '53013', provinceId: 54},
{id: 3791, value: 'gemona del friuli', cap: '33013', provinceId: 30},
{id: 1354, value: 'galliate lombardo', cap: '21020', provinceId: 10},
{id: 5973, value: 'rionero sannitico', cap: '86087', provinceId: 73},
{id: 1760, value: 'locate di triulzi', cap: '20085', provinceId: 13},
{id: 535, value: 'salerano canavese', cap: '10010', provinceId: 1},
{id: 79, value: 'garbagna novarese', cap: '28070', provinceId: 3},
{id: 2425, value: 'rivanazzano terme', cap: '27055', provinceId: 16},
{id: 519, value: 'rivarolo canavese', cap: '10086', provinceId: 1},
{id: 689, value: 'sale san giovanni', cap: '12070', provinceId: 4},
{id: 688, value: 'sale delle langhe', cap: '12070', provinceId: 4},
{id: 4126, value: 'favale di malvaro', cap: '16040', provinceId: 36},
{id: 5155, value: 'porto san giorgio', cap: '63822', provinceId: 109},
{id: 3649, value: 'montegrotto terme', cap: '35036', provinceId: 28},
{id: 6221, value: 'piano di sorrento', cap: '80063', provinceId: 76},
{id: 2563, value: 'pessina cremonese', cap: '26030', provinceId: 17},
{id: 4219, value: 'corte brugnatella', cap: '29020', provinceId: 38},
{id: 3340, value: 'noventa vicentina', cap: '36025', provinceId: 24},
{id: 3403, value: 'cortina d ampezzo', cap: '32043', provinceId: 25},
{id: 7503, value: 'novara di sicilia', cap: '98058', provinceId: 93},
{id: 3990, value: 'cosio di arroscia', cap: '18023', provinceId: 34},
{id: 7973, value: 'san nicolo gerrei', cap: '09040', provinceId: 102},
{id: 6216, value: 'mugnano di napoli', cap: '80018', provinceId: 76},
{id: 7126, value: 'motta santa lucia', cap: '88040', provinceId: 87},
{id: 2037, value: 'san paolo d argon', cap: '24060', provinceId: 14},
{id: 4583, value: 'piazza al serchio', cap: '55035', provinceId: 48},
{id: 114, value: 'san nazzaro sesia', cap: '28060', provinceId: 3},
{id: 6043, value: 'piedimonte matese', cap: '81016', provinceId: 74},
{id: 465, value: 'moriondo torinese', cap: '10020', provinceId: 1},
{id: 6502, value: 'san mauro cilento', cap: '84070', provinceId: 78},
{id: 1815, value: 'san vittore olona', cap: '20028', provinceId: 13},
{id: 2568, value: 'pieve san giacomo', cap: '26035', provinceId: 17},
{id: 6748, value: 'morciano di leuca', cap: '73040', provinceId: 83},
{id: 2270, value: 'san zeno naviglio', cap: '25010', provinceId: 15},
{id: 201, value: 'corneliano d alba', cap: '12040', provinceId: 4},
{id: 3300, value: 'cornedo vicentino', cap: '36073', provinceId: 24},
{id: 2585, value: 'scandolara ravara', cap: '26040', provinceId: 17},
{id: 4915, value: 'penna in teverina', cap: '05028', provinceId: 58},
{id: 707, value: 'serralunga d alba', cap: '12050', provinceId: 4},
{id: 7719, value: 'palazzolo acreide', cap: '96010', provinceId: 99},
{id: 5036, value: 'serra san quirico', cap: '60048', provinceId: 60},
{id: 3530, value: 'san polo di piave', cap: '31020', provinceId: 26},
{id: 982, value: 'ozzano monferrato', cap: '15039', provinceId: 6},
{id: 6065, value: 'san pietro infine', cap: '81049', provinceId: 74},
{id: 2157, value: 'comezzano cizzago', cap: '25030', provinceId: 15},
{id: 4653, value: 'san piero a sieve', cap: '50037', provinceId: 50},
{id: 1478, value: 'consiglio di rumo', cap: '22010', provinceId: 11},
{id: 2945, value: 'senale san felice', cap: '39010', provinceId: 21},
{id: 5031, value: 'san paolo di jesi', cap: '60038', provinceId: 60},
{id: 3906, value: 'san pier d isonzo', cap: '34070', provinceId: 31},
{id: 5686, value: 'penna sant andrea', cap: '64039', provinceId: 69},
{id: 3853, value: 'san vito al torre', cap: '33050', provinceId: 30},
{id: 4962, value: 'orciano di pesaro', cap: '61038', provinceId: 59},
{id: 3678, value: 'selvazzano dentro', cap: '35030', provinceId: 28},
{id: 3081, value: 'pergine valsugana', cap: '38057', provinceId: 22},
{id: 6460, value: 'ogliastro cilento', cap: '84061', provinceId: 78},
{id: 5832, value: 'san vito chietino', cap: '66038', provinceId: 71},
{id: 6824, value: 'corleto perticara', cap: '85012', provinceId: 84},
{id: 1342, value: 'cugliate fabiasco', cap: '21030', provinceId: 10},
{id: 4517, value: 'san mauro pascoli', cap: '47030', provinceId: 45},
{id: 6877, value: 'sasso di castalda', cap: '85050', provinceId: 84},
{id: 271, value: 'monticello d alba', cap: '12066', provinceId: 4},
{id: 3405, value: 'domegge di cadore', cap: '32040', provinceId: 25},
{id: 4776, value: 'monteroni d arbia', cap: '53014', provinceId: 54},
{id: 3344, value: 'piovene rocchette', cap: '36013', provinceId: 24},
{id: 5078, value: 'poggio san vicino', cap: '62021', provinceId: 61},
{id: 2402, value: 'monticelli pavese', cap: '27010', provinceId: 16},
{id: 5215, value: 'san lorenzo nuovo', cap: '01020', provinceId: 63},
{id: 6879, value: 'savoia di lucania', cap: '85050', provinceId: 84},
{id: 1796, value: 'pogliano milanese', cap: '20010', provinceId: 13},
{id: 5269, value: 'monteleone sabino', cap: '02033', provinceId: 64},
{id: 6322, value: 'montoro superiore', cap: '83026', provinceId: 77},
{id: 6321, value: 'montoro inferiore', cap: '83025', provinceId: 77},
{id: 4279, value: 'polesine parmense', cap: '43010', provinceId: 39},
{id: 77, value: 'fontaneto d agogna', cap: '28010', provinceId: 3},
{id: 6434, value: 'giffoni sei casali', cap: '84090', provinceId: 78},
{id: 907, value: 'castelletto d erro', cap: '15010', provinceId: 6},
{id: 5841, value: 'torricella peligna', cap: '66019', provinceId: 71},
{id: 2696, value: 'cassina valsassina', cap: '23817', provinceId: 19},
{id: 6831, value: 'genzano di lucania', cap: '85013', provinceId: 84},
{id: 6673, value: 'san giorgio ionico', cap: '74027', provinceId: 81},
{id: 5517, value: 'san giorgio a liri', cap: '03047', provinceId: 67},
{id: 6406, value: 'casaletto spartano', cap: '84030', provinceId: 78},
{id: 840, value: 'san paolo solbrito', cap: '14010', provinceId: 5},
{id: 5167, value: 'torre san patrizio', cap: '63814', provinceId: 109},
{id: 2512, value: 'casaletto di sopra', cap: '26014', provinceId: 17},
{id: 2511, value: 'casaletto ceredano', cap: '26010', provinceId: 17},
{id: 6499, value: 'san gregorio magno', cap: '84020', provinceId: 78},
{id: 6237, value: 'san paolo bel sito', cap: '80030', provinceId: 76},
{id: 351, value: 'campiglione fenile', cap: '10060', provinceId: 1},
{id: 3490, value: 'gorgo al monticano', cap: '31040', provinceId: 26},
{id: 6920, value: 'san giorgio lucano', cap: '75027', provinceId: 85},
{id: 1655, value: 'tovo di sant agata', cap: '23030', provinceId: 12},
{id: 2464, value: 'torricella verzate', cap: '27050', provinceId: 16},
{id: 7653, value: 'gravina di catania', cap: '95030', provinceId: 97},
{id: 9412, value: 'graun im vinschgau', cap: '39027', provinceId: 21},
{id: 5316, value: 'campagnano di roma', cap: '00063', provinceId: 65},
{id: 115, value: 'san pietro mosezzo', cap: '28060', provinceId: 3},
{id: 4712, value: 'san giuliano terme', cap: '56017', provinceId: 52},
{id: 6408, value: 'caselle in pittari', cap: '84030', provinceId: 78},
{id: 2817, value: 'san rocco al porto', cap: '26865', provinceId: 20},
{id: 6769, value: 'san pietro in lama', cap: '73010', provinceId: 83},
{id: 7137, value: 'san pietro a maida', cap: '88025', provinceId: 87},
{id: 363, value: 'cascinette d ivrea', cap: '10010', provinceId: 1},
{id: 6347, value: 'san sossio baronia', cap: '83050', provinceId: 77},
{id: 2465, value: 'travaco siccomario', cap: '27020', provinceId: 16},
{id: 6187, value: 'casamicciola terme', cap: '80074', provinceId: 76},
{id: 547, value: 'san giorio di susa', cap: '10050', provinceId: 1},
{id: 5569, value: 'carapelle calvisio', cap: '67020', provinceId: 68},
{id: 6815, value: 'san paolo albanese', cap: '85030', provinceId: 84},
{id: 2065, value: 'trescore balneario', cap: '24069', provinceId: 14},
{id: 3876, value: 'trivignano udinese', cap: '33050', provinceId: 30},
{id: 6955, value: 'cassano allo ionio', cap: '87011', provinceId: 86},
{id: 6503, value: 'san mauro la bruca', cap: '84070', provinceId: 78},
{id: 539, value: 'san carlo canavese', cap: '10070', provinceId: 1},
{id: 6869, value: 'san chirico raparo', cap: '85030', provinceId: 84},
{id: 6711, value: 'caprarica di lecce', cap: '73010', provinceId: 83},
{id: 1317, value: 'caronno pertusella', cap: '21042', provinceId: 10},
{id: 5929, value: 'torella del sannio', cap: '86028', provinceId: 72},
{id: 6872, value: 'san martino d agri', cap: '85030', provinceId: 84},
{id: 6584, value: 'san marco in lamis', cap: '71014', provinceId: 79},
{id: 2134, value: 'capriano del colle', cap: '25020', provinceId: 15},
{id: 5943, value: 'castel del giudice', cap: '86080', provinceId: 73},
{id: 1904, value: 'caprino bergamasco', cap: '24030', provinceId: 14},
{id: 7039, value: 'san cosmo albanese', cap: '87060', provinceId: 86},
{id: 3886, value: 'capriva del friuli', cap: '34070', provinceId: 31},
{id: 7182, value: 'caraffa del bianco', cap: '89030', provinceId: 88},
{id: 4334, value: 'san martino in rio', cap: '42018', provinceId: 40},
{id: 170, value: 'caramagna piemonte', cap: '12030', provinceId: 4},
{id: 551, value: 'san mauro torinese', cap: '10099', provinceId: 1},
{id: 7584, value: 'san biagio platani', cap: '92020', provinceId: 94},
{id: 3619, value: 'casale di scodosia', cap: '35040', provinceId: 28},
{id: 6500, value: 'san mango piemonte', cap: '84090', provinceId: 78},
{id: 836, value: 'san damiano d asti', cap: '14015', provinceId: 5},
{id: 5243, value: 'castel sant angelo', cap: '02010', provinceId: 64},
{id: 4730, value: 'castel san niccolo', cap: '52018', provinceId: 53},
{id: 7136, value: 'san mango d aquino', cap: '88040', provinceId: 87},
{id: 1151, value: 'casale corte cerro', cap: '28881', provinceId: 8},
{id: 6414, value: 'castel san lorenzo', cap: '84049', provinceId: 78},
{id: 6413, value: 'castel san giorgio', cap: '84083', provinceId: 78},
{id: 7583, value: 'sambuca di sicilia', cap: '92017', provinceId: 94},
{id: 6345, value: 'san nicola baronia', cap: '83050', provinceId: 77},
{id: 2616, value: 'canneto sull oglio', cap: '46013', provinceId: 18},
{id: 1018, value: 'serralunga di crea', cap: '15020', provinceId: 6},
{id: 1561, value: 'san fedele intelvi', cap: '22028', provinceId: 11},
{id: 6640, value: 'sammichele di bari', cap: '70010', provinceId: 80},
{id: 7646, value: 'camporotondo etneo', cap: '95040', provinceId: 97},
{id: 7051, value: 'san nicola arcella', cap: '87020', provinceId: 86},
{id: 5998, value: 'cancello ed arnone', cap: '81030', provinceId: 74},
{id: 2600, value: 'torre de picenardi', cap: '26038', provinceId: 17},
{id: 3372, value: 'tonezza del cimone', cap: '36040', provinceId: 24},
{id: 6083, value: 'valle di maddaloni', cap: '81020', provinceId: 74},
{id: 1420, value: 'venegono superiore', cap: '21040', provinceId: 10},
{id: 3287, value: 'camisano vicentino', cap: '36043', provinceId: 24},
{id: 5284, value: 'poggio san lorenzo', cap: '02030', provinceId: 64},
{id: 3970, value: 'aquila di arroscia', cap: '18020', provinceId: 34},
{id: 2417, value: 'pieve porto morone', cap: '27017', provinceId: 16},
{id: 5101, value: 'arquata del tronto', cap: '63096', provinceId: 62},
{id: 6046, value: 'pignataro maggiore', cap: '81052', provinceId: 74},
{id: 3334, value: 'montorso vicentino', cap: '36050', provinceId: 24},
{id: 5681, value: 'montorio al vomano', cap: '64046', provinceId: 69},
{id: 2732, value: 'monticello brianza', cap: '23876', provinceId: 19},
{id: 2209, value: 'monticelli brusati', cap: '25040', provinceId: 15},
{id: 7308, value: 'monterosso calabro', cap: '89819', provinceId: 90},
{id: 4189, value: 'monterosso al mare', cap: '19016', provinceId: 37},
{id: 4478, value: 'bagnara di romagna', cap: '48010', provinceId: 44},
{id: 6746, value: 'monteroni di lecce', cap: '73047', provinceId: 83},
{id: 5937, value: 'bagnoli del trigno', cap: '86091', provinceId: 73},
{id: 6576, value: 'pietramontecorvino', cap: '71038', provinceId: 79},
{id: 267, value: 'montemale di cuneo', cap: '12025', provinceId: 4},
{id: 603, value: 'villanova canavese', cap: '10070', provinceId: 1},
{id: 1128, value: 'villanova biellese', cap: '13877', provinceId: 7},
{id: 815, value: 'montegrosso d asti', cap: '14048', provinceId: 5},
{id: 746, value: 'baldichieri d asti', cap: '14011', provinceId: 5},
{id: 7592, value: 'villafranca sicula', cap: '92020', provinceId: 94},
{id: 856, value: 'villafranca d asti', cap: '14018', provinceId: 5},
{id: 6453, value: 'monteforte cilento', cap: '84060', provinceId: 78},
{id: 8072, value: 'villa sant antonio', cap: '09080', provinceId: 103},
{id: 814, value: 'montechiaro d asti', cap: '14025', provinceId: 5},
{id: 7257, value: 'villa san giovanni', cap: '89018', provinceId: 88},
{id: 994, value: 'ponzano monferrato', cap: '15020', provinceId: 6},
{id: 1664, value: 'villa di chiavenna', cap: '23029', provinceId: 12},
{id: 4390, value: 'anzola dell emilia', cap: '40011', provinceId: 42},
{id: 5898, value: 'morrone del sannio', cap: '86040', provinceId: 72},
{id: 4595, value: 'villa collemandina', cap: '55030', provinceId: 48},
{id: 2562, value: 'pescarolo ed uniti', cap: '26033', provinceId: 17},
{id: 6699, value: 'acquarica del capo', cap: '73040', provinceId: 83},
{id: 2558, value: 'paderno ponchielli', cap: '26024', provinceId: 17},
{id: 987, value: 'pecetto di valenza', cap: '15040', provinceId: 6},
{id: 1851, value: 'zibido san giacomo', cap: '20080', provinceId: 13},
{id: 3510, value: 'paderno del grappa', cap: '31017', provinceId: 26},
{id: 2226, value: 'padenghe sul garda', cap: '25080', provinceId: 15},
{id: 4435, value: 'ozzano dell emilia', cap: '40064', provinceId: 42},
{id: 1854, value: 'adrara san martino', cap: '24060', provinceId: 14},
{id: 3422, value: 'ospitale di cadore', cap: '32010', provinceId: 25},
{id: 4747, value: 'ortignano raggiolo', cap: '52010', provinceId: 53},
{id: 5073, value: 'penna san giovanni', cap: '62020', provinceId: 61},
{id: 3424, value: 'perarolo di cadore', cap: '32010', provinceId: 25},
{id: 2305, value: 'albaredo arnaboldi', cap: '27040', provinceId: 16},
{id: 1133, value: 'antrona schieranco', cap: '28841', provinceId: 8},
{id: 1792, value: 'peschiera borromeo', cap: '20068', provinceId: 13},
{id: 7769, value: 'nughedu san nicolo', cap: '07010', provinceId: 100},
{id: 1426, value: 'albese con cassano', cap: '22032', provinceId: 11},
{id: 7275, value: 'petilia policastro', cap: '88837', provinceId: 89},
{id: 4038, value: 'albisola superiore', cap: '17011', provinceId: 35},
{id: 5902, value: 'petrella tifernina', cap: '86024', provinceId: 72},
{id: 3338, value: 'nogarole vicentino', cap: '36070', provinceId: 24},
{id: 3731, value: 'pettorazza grimani', cap: '45010', provinceId: 29},
{id: 1544, value: 'pianello del lario', cap: '22010', provinceId: 11},
{id: 1849, value: 'vizzolo predabissi', cap: '20070', provinceId: 13},
{id: 7215, value: 'motta san giovanni', cap: '89065', provinceId: 88},
{id: 5306, value: 'anguillara sabazia', cap: '00061', provinceId: 65},
{id: 6571, value: 'motta montecorvino', cap: '71030', provinceId: 79},
{id: 6048, value: 'portico di caserta', cap: '81050', provinceId: 74},
{id: 5156, value: 'porto sant elpidio', cap: '63821', provinceId: 109},
{id: 885, value: 'camagna monferrato', cap: '15030', provinceId: 6},
{id: 1369, value: 'lavena ponte tresa', cap: '21037', provinceId: 10},
{id: 766, value: 'castelletto molina', cap: '14040', provinceId: 5},
{id: 1419, value: 'venegono inferiore', cap: '21040', provinceId: 10},
{id: 5885, value: 'macchia valfortore', cap: '86040', provinceId: 72},
{id: 2326, value: 'bressana bottarone', cap: '27042', provinceId: 16},
{id: 1517, value: 'longone al segrino', cap: '22030', provinceId: 11},
{id: 6861, value: 'rionero in vulture', cap: '85028', provinceId: 84},
{id: 517, value: 'riva presso chieri', cap: '10020', provinceId: 1},
{id: 3430, value: 'rivamonte agordino', cap: '32020', provinceId: 25},
{id: 2658, value: 'rivarolo mantovano', cap: '46017', provinceId: 18},
{id: 3797, value: 'lignano sabbiadoro', cap: '33054', provinceId: 30},
{id: 4269, value: 'lesignano de bagni', cap: '43037', provinceId: 39},
{id: 1755, value: 'lentate sul seveso', cap: '20823', provinceId: 108},
{id: 7569, value: 'lampedusa e linosa', cap: '92010', provinceId: 94},
{id: 3800, value: 'magnano in riviera', cap: '33010', provinceId: 30},
{id: 4515, value: 'rocca san casciano', cap: '47017', provinceId: 45},
{id: 5820, value: 'rocca san giovanni', cap: '66020', provinceId: 71},
{id: 679, value: 'roccaforte mondovi', cap: '12088', provinceId: 4},
{id: 4295, value: 'varano de melegari', cap: '43040', provinceId: 39},
{id: 751, value: 'buttigliera d asti', cap: '14021', provinceId: 5},
{id: 9403, value: 'kastelbell tschars', cap: '39020', provinceId: 21},
{id: 7515, value: 'roccella valdemone', cap: '98030', provinceId: 93},
{id: 946, value: 'isola sant antonio', cap: '15050', provinceId: 6},
{id: 7338, value: 'calatafimi segesta', cap: '91013', provinceId: 91},
{id: 6489, value: 'romagnano al monte', cap: '84020', provinceId: 78},
{id: 6987, value: 'guardia piemontese', cap: '87020', provinceId: 86},
{id: 7476, value: 'gualtieri sicamino', cap: '98040', provinceId: 93},
{id: 1970, value: 'grumello del monte', cap: '24064', provinceId: 14},
{id: 829, value: 'revigliasco d asti', cap: '14010', provinceId: 5},
{id: 2724, value: 'mandello del lario', cap: '23826', provinceId: 19},
{id: 1397, value: 'porto valtravaglia', cap: '21010', provinceId: 10},
{id: 959, value: 'mongiardino ligure', cap: '15060', provinceId: 6},
{id: 2570, value: 'pozzaglio ed uniti', cap: '26010', provinceId: 17},
{id: 3278, value: 'bassano del grappa', cap: '36061', provinceId: 24},
{id: 1798, value: 'pozzuolo martesana', cap: '20060', provinceId: 13},
{id: 1038, value: 'vignale monferrato', cap: '15049', provinceId: 6},
{id: 2808, value: 'montanaso lombardo', cap: '26836', provinceId: 20},
{id: 5203, value: 'montalto di castro', cap: '01014', provinceId: 63},
{id: 1641, value: 'prata camportaccio', cap: '23020', provinceId: 12},
{id: 3949, value: 'prata di pordenone', cap: '33080', provinceId: 33},
{id: 2082, value: 'vigano san martino', cap: '24060', provinceId: 14},
{id: 2896, value: 'prato allo stelvio', cap: '39026', provinceId: 21},
{id: 7498, value: 'montalbano elicona', cap: '98065', provinceId: 93},
{id: 5233, value: 'belmonte in sabina', cap: '02020', provinceId: 64},
{id: 4994, value: 'belvedere ostrense', cap: '60030', provinceId: 60},
{id: 2614, value: 'borgofranco sul po', cap: '46020', provinceId: 18},
{id: 255, value: 'monastero di vasco', cap: '12080', provinceId: 4},
{id: 875, value: 'berzano di tortona', cap: '15050', provinceId: 6},
{id: 457, value: 'monastero di lanzo', cap: '10070', provinceId: 1},
{id: 455, value: 'mombello di torino', cap: '10020', provinceId: 1},
{id: 2255, value: 'puegnago sul garda', cap: '25080', provinceId: 15},
{id: 6745, value: 'minervino di lecce', cap: '73027', provinceId: 83},
{id: 154, value: 'borgo san dalmazzo', cap: '12011', provinceId: 4},
{id: 3496, value: 'maserada sul piave', cap: '31052', provinceId: 26},
{id: 2773, value: 'borgo san giovanni', cap: '26851', provinceId: 20},
{id: 7122, value: 'martirano lombardo', cap: '88040', provinceId: 87},
{id: 3895, value: 'mariano del friuli', cap: '34070', provinceId: 31},
{id: 2766, value: 'verderio superiore', cap: '23878', provinceId: 19},
{id: 2765, value: 'verderio inferiore', cap: '23879', provinceId: 19},
{id: 908, value: 'castelletto d orba', cap: '15060', provinceId: 6},
{id: 394, value: 'colleretto giacosa', cap: '10010', provinceId: 1},
{id: 918, value: 'cerrina monferrato', cap: '15020', provinceId: 6},
{id: 791, value: 'dusino san michele', cap: '14010', provinceId: 5},
{id: 3768, value: 'castions di strada', cap: '33050', provinceId: 30},
{id: 1602, value: 'castione andevenno', cap: '23012', provinceId: 12},
{id: 767, value: 'castello di annone', cap: '14034', provinceId: 5},
{id: 1326, value: 'castello cabiaglio', cap: '21030', provinceId: 10},
{id: 6978, value: 'falconara albanese', cap: '87030', provinceId: 86},
{id: 2782, value: 'castiglione d adda', cap: '26823', provinceId: 20},
{id: 3889, value: 'dolegna del collio', cap: '34070', provinceId: 31},
{id: 3469, value: 'castello di godego', cap: '31030', provinceId: 26},
{id: 3474, value: 'cison di valmarino', cap: '31030', provinceId: 26},
{id: 2162, value: 'darfo boario terme', cap: '25047', provinceId: 15},
{id: 5426, value: 'cisterna di latina', cap: '04012', provinceId: 66},
{id: 769, value: 'castelnuovo calcea', cap: '14040', provinceId: 5},
{id: 1015, value: 'sant agata fossili', cap: '15050', provinceId: 6},
{id: 923, value: 'cuccaro monferrato', cap: '15040', provinceId: 6},
{id: 4977, value: 'sant agata feltria', cap: '47866', provinceId: 46},
{id: 9466, value: 'st martin in thurn', cap: '39030', provinceId: 21},
{id: 6642, value: 'santeramo in colle', cap: '70029', provinceId: 80},
{id: 5834, value: 'schiavi di abruzzo', cap: '66045', provinceId: 71},
{id: 4840, value: 'cerreto di spoleto', cap: '06041', provinceId: 57},
{id: 5216, value: 'soriano nel cimino', cap: '01038', provinceId: 63},
{id: 6417, value: 'celle di bulgheria', cap: '84040', provinceId: 78},
{id: 2040, value: 'sant omobono terme', cap: '24038', provinceId: 14},
{id: 6555, value: 'celenza valfortore', cap: '71035', provinceId: 79},
{id: 5767, value: 'celenza sul trigno', cap: '66050', provinceId: 71},
{id: 4336, value: 'sant ilario d enza', cap: '42049', provinceId: 40},
{id: 6068, value: 'santa maria a vico', cap: '81028', provinceId: 74},
{id: 3438, value: 'san vito di cadore', cap: '32046', provinceId: 25},
{id: 8105, value: 'cavallino treporti', cap: '30013', provinceId: 27},
{id: 5981, value: 'sant elena sannita', cap: '86095', provinceId: 73},
{id: 6979, value: 'figline vegliaturo', cap: '87050', provinceId: 86},
{id: 6242, value: 'sant antonio abate', cap: '80057', provinceId: 76},
{id: 5830, value: 'santa maria imbaro', cap: '66030', provinceId: 71},
{id: 5399, value: 'sant angelo romano', cap: '00010', provinceId: 65},
{id: 7588, value: 'sant angelo muxaro', cap: '92020', provinceId: 94},
{id: 1340, value: 'crosio della valle', cap: '21020', provinceId: 10},
{id: 4735, value: 'chiusi della verna', cap: '52010', provinceId: 53},
{id: 3711, value: 'castelnovo bariano', cap: '45030', provinceId: 29},
{id: 3298, value: 'cogollo del cengio', cap: '36010', provinceId: 24},
{id: 1936, value: 'costa valle imagna', cap: '24030', provinceId: 14},
{id: 5983, value: 'sessano del molise', cap: '86097', provinceId: 73},
{id: 7392, value: 'contessa entellina', cap: '90030', provinceId: 92},
{id: 5475, value: 'castelnuovo parano', cap: '03040', provinceId: 67},
{id: 2852, value: 'cornedo all isarco', cap: '39053', provinceId: 21},
{id: 789, value: 'costigliole d asti', cap: '14055', provinceId: 5},
{id: 6981, value: 'fiumefreddo bruzio', cap: '87030', provinceId: 86},
{id: 3402, value: 'comelico superiore', cap: '32040', provinceId: 25},
{id: 6970, value: 'corigliano calabro', cap: '87064', provinceId: 86},
{id: 7990, value: 'settimo san pietro', cap: '09040', provinceId: 102},
{id: 5641, value: 'scurcola marsicana', cap: '67068', provinceId: 68},
{id: 3196, value: 'colognola ai colli', cap: '37030', provinceId: 23},
{id: 5714, value: 'civitella casanova', cap: '65010', provinceId: 70},
{id: 1821, value: 'sesto san giovanni', cap: '20099', provinceId: 13},
{id: 6160, value: 'sant agata de goti', cap: '82019', provinceId: 75},
{id: 5580, value: 'civitella alfedena', cap: '67030', provinceId: 68},
{id: 179, value: 'castelletto uzzone', cap: '12070', provinceId: 4},
{id: 4803, value: 'civitella paganico', cap: '58045', provinceId: 55},
{id: 1019, value: 'serravalle scrivia', cap: '15069', provinceId: 6},
{id: 4223, value: 'fiorenzuola d arda', cap: '29017', provinceId: 38},
{id: 4979, value: 'sant angelo in vado', cap: '61048', provinceId: 59},
{id: 4305, value: 'cadelbosco di sopra', cap: '42023', provinceId: 40},
{id: 1146, value: 'calasca castiglione', cap: '28873', provinceId: 8},
{id: 1310, value: 'cadegliano viconago', cap: '21031', provinceId: 10},
{id: 4349, value: 'castelnuovo rangone', cap: '41051', provinceId: 41},
{id: 7402, value: 'isola delle femmine', cap: '90040', provinceId: 92},
{id: 2975, value: 'calceranica al lago', cap: '38050', provinceId: 22},
{id: 3246, value: 'sant anna d alfaedo', cap: '37020', provinceId: 23},
{id: 6072, value: 'sant angelo d alife', cap: '81017', provinceId: 74},
{id: 5924, value: 'sant elia a pianisi', cap: '86048', provinceId: 72},
{id: 5390, value: 'rocca santo stefano', cap: '00030', provinceId: 65},
{id: 3192, value: 'cazzano di tramigna', cap: '37030', provinceId: 23},
{id: 4808, value: 'magliano in toscana', cap: '58051', provinceId: 55},
{id: 6498, value: 'san giovanni a piro', cap: '84070', provinceId: 78},
{id: 7232, value: 'san giorgio morgeto', cap: '89017', provinceId: 88},
{id: 7453, value: 'casalvecchio siculo', cap: '98032', provinceId: 93},
{id: 1918, value: 'cazzano sant andrea', cap: '24026', provinceId: 14},
{id: 4905, value: 'lugnano in teverina', cap: '05020', provinceId: 58},
{id: 2143, value: 'cazzago san martino', cap: '25046', provinceId: 15},
{id: 7069, value: 'spezzano della sila', cap: '87058', provinceId: 86},
{id: 3419, value: 'lorenzago di cadore', cap: '32040', provinceId: 25},
{id: 1716, value: 'cavenago di brianza', cap: '20873', provinceId: 108},
{id: 5163, value: 'sant elpidio a mare', cap: '63811', provinceId: 109},
{id: 2776, value: 'casaletto lodigiano', cap: '26852', provinceId: 20},
{id: 2031, value: 'romano di lombardia', cap: '24058', provinceId: 14},
{id: 1145, value: 'brovello carpugnino', cap: '28833', provinceId: 8},
{id: 6533, value: 'vallo della lucania', cap: '84078', provinceId: 78},
{id: 7057, value: 'sant agata di esaro', cap: '87010', provinceId: 86},
{id: 6127, value: 'guardia sanframondi', cap: '82034', provinceId: 75},
{id: 6641, value: 'sannicandro di bari', cap: '70028', provinceId: 80},
{id: 4731, value: 'castiglion fibocchi', cap: '52029', provinceId: 53},
{id: 2907, value: 'san genesio atesino', cap: '39050', provinceId: 21},
{id: 7078, value: 'vaccarizzo albanese', cap: '87060', provinceId: 86},
{id: 6686, value: 'francavilla fontana', cap: '72021', provinceId: 82},
{id: 695, value: 'san benedetto belbo', cap: '12050', provinceId: 4},
{id: 5238, value: 'cantalupo in sabina', cap: '02040', provinceId: 64},
{id: 5781, value: 'francavilla al mare', cap: '66023', provinceId: 71},
{id: 7970, value: 'san gavino monreale', cap: '09037', provinceId: 106},
{id: 912, value: 'castelnuovo scrivia', cap: '15053', provinceId: 6},
{id: 7526, value: 'sant alessio siculo', cap: '98030', provinceId: 93},
{id: 4885, value: 'tuoro sul trasimeno', cap: '06069', provinceId: 57},
{id: 6000, value: 'capriati a volturno', cap: '81014', provinceId: 74},
{id: 1811, value: 'san donato milanese', cap: '20097', provinceId: 13},
{id: 35, value: 'tronzano vercellese', cap: '13049', provinceId: 2},
{id: 7041, value: 'san donato di ninea', cap: '87010', provinceId: 86},
{id: 2333, value: 'carbonara al ticino', cap: '27020', provinceId: 16},
{id: 7040, value: 'san demetrio corone', cap: '87069', provinceId: 86},
{id: 3554, value: 'campolongo maggiore', cap: '30010', provinceId: 27},
{id: 6770, value: 'santa cesarea terme', cap: '73020', provinceId: 83},
{id: 6351, value: 'sant angelo a scala', cap: '83010', provinceId: 77},
{id: 2504, value: 'campagnola cremasca', cap: '26010', provinceId: 17},
{id: 7033, value: 'roseto capo spulico', cap: '87070', provinceId: 86},
{id: 1645, value: 'san giacomo filippo', cap: '23020', provinceId: 12},
{id: 4400, value: 'casalecchio di reno', cap: '40033', provinceId: 42},
{id: 6767, value: 'san donato di lecce', cap: '73010', provinceId: 83},
{id: 3312, value: 'grisignano di zocco', cap: '36040', provinceId: 24},
{id: 185, value: 'castiglione tinella', cap: '12053', provinceId: 4},
{id: 4226, value: 'gragnano trebbiense', cap: '29010', provinceId: 38},
{id: 4663, value: 'campiglia marittima', cap: '57021', provinceId: 51},
{id: 5341, value: 'gallicano nel lazio', cap: '00010', provinceId: 65},
{id: 6435, value: 'giffoni valle piana', cap: '84095', provinceId: 78},
{id: 3257, value: 'valeggio sul mincio', cap: '37067', provinceId: 23},
{id: 544, value: 'san germano chisone', cap: '10065', provinceId: 1},
{id: 2979, value: 'campitello di fassa', cap: '38031', provinceId: 22},
{id: 2172, value: 'gardone val trompia', cap: '25063', provinceId: 15},
{id: 1743, value: 'garbagnate milanese', cap: '20024', provinceId: 13},
{id: 4766, value: 'castiglione d orcia', cap: '53023', provinceId: 54},
{id: 2814, value: 'salerano sul lambro', cap: '26857', provinceId: 20},
{id: 4678, value: 'rosignano marittimo', cap: '57016', provinceId: 51},
{id: 4282, value: 'salsomaggiore terme', cap: '43039', provinceId: 39},
{id: 3397, value: 'cencenighe agordino', cap: '32020', provinceId: 25},
{id: 6878, value: 'satriano di lucania', cap: '85050', provinceId: 84},
{id: 5508, value: 'pignataro interamna', cap: '03040', provinceId: 67},
{id: 8075, value: 'scano di montiferro', cap: '09078', provinceId: 103},
{id: 3774, value: 'cividale del friuli', cap: '33043', provinceId: 30},
{id: 2089, value: 'vilminore di scalve', cap: '24020', provinceId: 14},
{id: 5272, value: 'montopoli di sabina', cap: '02034', provinceId: 64},
{id: 4229, value: 'monticelli d ongina', cap: '29010', provinceId: 38},
{id: 2164, value: 'desenzano del garda', cap: '25015', provinceId: 15},
{id: 6747, value: 'montesano salentino', cap: '73030', provinceId: 83},
{id: 2449, value: 'sartirana lomellina', cap: '27020', provinceId: 16},
{id: 3700, value: 'ariano nel polesine', cap: '45012', provinceId: 29},
{id: 736, value: 'villar san costanzo', cap: '12020', provinceId: 4},
{id: 5026, value: 'poggio san marcello', cap: '60030', provinceId: 60},
{id: 3023, value: 'fai della paganella', cap: '38010', provinceId: 22},
{id: 8095, value: 'villanova truschedu', cap: '09084', provinceId: 103},
{id: 4248, value: 'villanova sull arda', cap: '29010', provinceId: 38},
{id: 4102, value: 'villanova d albenga', cap: '17038', provinceId: 35},
{id: 5007, value: 'falconara marittima', cap: '60015', provinceId: 60},
{id: 5776, value: 'fara filiorum petri', cap: '66010', provinceId: 71},
{id: 6706, value: 'bagnolo del salento', cap: '73020', provinceId: 83},
{id: 4750, value: 'pieve santo stefano', cap: '52036', provinceId: 53},
{id: 4534, value: 'morciano di romagna', cap: '47833', provinceId: 46},
{id: 703, value: 'santo stefano roero', cap: '12040', provinceId: 4},
{id: 1793, value: 'pessano con bornago', cap: '20060', provinceId: 13},
{id: 821, value: 'passerano marmorito', cap: '14020', provinceId: 5},
{id: 4983, value: 'serra sant abbondio', cap: '61040', provinceId: 59},
{id: 5935, value: 'acquaviva d isernia', cap: '86080', provinceId: 73},
{id: 3651, value: 'ospedaletto euganeo', cap: '35045', provinceId: 28},
{id: 4278, value: 'pellegrino parmense', cap: '43047', provinceId: 39},
{id: 4771, value: 'colle di val d elsa', cap: '53034', provinceId: 54},
{id: 1090, value: 'occhieppo superiore', cap: '13898', provinceId: 7},
{id: 1089, value: 'occhieppo inferiore', cap: '13897', provinceId: 7},
{id: 3227, value: 'peschiera del garda', cap: '37019', provinceId: 23},
{id: 2360, value: 'corvino san quirico', cap: '27050', provinceId: 16},
{id: 3480, value: 'crespano del grappa', cap: '31017', provinceId: 26},
{id: 5616, value: 'pettorano sul gizio', cap: '67034', provinceId: 68},
{id: 5334, value: 'civitella san paolo', cap: '00060', provinceId: 65},
{id: 4235, value: 'pianello val tidone', cap: '29010', provinceId: 38},
{id: 6384, value: 'altavilla silentina', cap: '84045', provinceId: 78},
{id: 3270, value: 'altavilla vicentina', cap: '36077', provinceId: 24},
{id: 5190, value: 'civitella d agliano', cap: '01020', provinceId: 63},
{id: 3655, value: 'piazzola sul brenta', cap: '35016', provinceId: 28},
{id: 204, value: 'costigliole saluzzo', cap: '12024', provinceId: 4},
{id: 2705, value: 'crandola valsassina', cap: '23832', provinceId: 19},
{id: 5137, value: 'monteleone di fermo', cap: '63841', provinceId: 109},
{id: 319, value: 'baldissero canavese', cap: '10080', provinceId: 1},
{id: 332, value: 'borgofranco d ivrea', cap: '10013', provinceId: 1},
{id: 256, value: 'monasterolo casotto', cap: '12080', provinceId: 4},
{id: 47, value: 'bellinzago novarese', cap: '28043', provinceId: 3},
{id: 263, value: 'montaldo di mondovi', cap: '12080', provinceId: 4},
{id: 3586, value: 'santa maria di sala', cap: '30036', provinceId: 27},
{id: 5938, value: 'belmonte del sannio', cap: '86080', provinceId: 73},
{id: 5125, value: 'monsampietro morico', cap: '63842', provinceId: 109},
{id: 4788, value: 'san giovanni d asso', cap: '53020', provinceId: 54},
{id: 6941, value: 'belvedere marittimo', cap: '87021', provinceId: 86},
{id: 4272, value: 'monchio delle corti', cap: '43010', provinceId: 39},
{id: 4716, value: 'santa maria a monte', cap: '56020', provinceId: 52},
{id: 873, value: 'belforte monferrato', cap: '15070', provinceId: 6},
{id: 956, value: 'mombello monferrato', cap: '15020', provinceId: 6},
{id: 5888, value: 'mirabello sannitico', cap: '86010', provinceId: 72},
{id: 7662, value: 'mirabella imbaccari', cap: '95040', provinceId: 97},
{id: 7491, value: 'militello rosmarino', cap: '98070', provinceId: 93},
{id: 6037, value: 'mignano monte lungo', cap: '81049', provinceId: 74},
{id: 2772, value: 'borghetto lodigiano', cap: '26812', provinceId: 20},
{id: 2698, value: 'cernusco lombardone', cap: '23870', provinceId: 19},
{id: 1627, value: 'mazzo di valtellina', cap: '23030', provinceId: 12},
{id: 1764, value: 'marcallo con casone', cap: '20010', provinceId: 13},
{id: 1680, value: 'bellinzago lombardo', cap: '20060', provinceId: 13},
{id: 4927, value: 'belforte all isauro', cap: '61026', provinceId: 59},
{id: 7546, value: 'villafranca tirrena', cap: '98049', provinceId: 93},
{id: 965, value: 'montechiaro d acqui', cap: '15010', provinceId: 6},
{id: 3218, value: 'monteforte d alpone', cap: '37032', provinceId: 23},
{id: 5131, value: 'montefiore dell aso', cap: '63062', provinceId: 62},
{id: 320, value: 'baldissero torinese', cap: '10020', provinceId: 1},
{id: 1639, value: 'ponte in valtellina', cap: '23026', provinceId: 12},
{id: 8035, value: 'baratili san pietro', cap: '09070', provinceId: 103},
{id: 3277, value: 'barbarano vicentino', cap: '36021', provinceId: 24},
{id: 5544, value: 'villa santo stefano', cap: '03020', provinceId: 67},
{id: 6478, value: 'pontecagnano faiano', cap: '84098', provinceId: 78},
{id: 3734, value: 'pontecchio polesine', cap: '45030', provinceId: 29},
{id: 3327, value: 'montecchio maggiore', cap: '36075', provinceId: 24},
{id: 711, value: 'sommariva del bosco', cap: '12048', provinceId: 4},
{id: 702, value: 'santo stefano belbo', cap: '12058', provinceId: 4},
{id: 5146, value: 'monte vidon corrado', cap: '63836', provinceId: 109},
{id: 5365, value: 'monte porzio catone', cap: '00040', provinceId: 65},
{id: 3228, value: 'povegliano veronese', cap: '37064', provinceId: 23},
{id: 4957, value: 'monte grimano terme', cap: '61010', provinceId: 59},
{id: 5174, value: 'bassano in teverina', cap: '01030', provinceId: 63},
{id: 3827, value: 'pozzuolo del friuli', cap: '33050', provinceId: 30},
{id: 7059, value: 'santa sofia d epiro', cap: '87048', provinceId: 86},
{id: 7528, value: 'santa marina salina', cap: '98050', provinceId: 93},
{id: 2036, value: 'san giovanni bianco', cap: '24015', provinceId: 14},
{id: 5348, value: 'guidonia montecelio', cap: '00012', provinceId: 65},
{id: 3854, value: 'san vito di fagagna', cap: '33030', provinceId: 30},
{id: 4348, value: 'castelfranco emilia', cap: '41013', provinceId: 41},
{id: 7678, value: 'san pietro clarenza', cap: '95030', provinceId: 97},
{id: 911, value: 'castelnuovo bormida', cap: '15017', provinceId: 6},
{id: 6062, value: 'san gregorio matese', cap: '81010', provinceId: 74},
{id: 1463, value: 'casnate con bernate', cap: '22070', provinceId: 11},
{id: 4243, value: 'san pietro in cerro', cap: '29010', provinceId: 38},
{id: 4738, value: 'foiano della chiana', cap: '52045', provinceId: 53},
{id: 3468, value: 'castelfranco veneto', cap: '31033', provinceId: 26},
{id: 5977, value: 'san pietro avellana', cap: '86088', provinceId: 73},
{id: 7072, value: 'terranova da sibari', cap: '87010', provinceId: 86},
{id: 6585, value: 'san marco la catola', cap: '71030', provinceId: 79},
{id: 7425, value: 'santa cristina gela', cap: '90030', provinceId: 92},
{id: 1028, value: 'tagliolo monferrato', cap: '15070', provinceId: 6},
{id: 4789, value: 'san quirico d orcia', cap: '53027', provinceId: 54},
{id: 7520, value: 'san marco d alunzio', cap: '98070', provinceId: 93},
{id: 7049, value: 'san marco argentano', cap: '87018', provinceId: 86},
{id: 4547, value: 'casola in lunigiana', cap: '54014', provinceId: 47},
{id: 7138, value: 'san pietro apostolo', cap: '88040', provinceId: 87},
{id: 4312, value: 'castelnovo di sotto', cap: '42024', provinceId: 40},
{id: 4215, value: 'castel san giovanni', cap: '29015', provinceId: 38},
{id: 6617, value: 'cassano delle murge', cap: '70020', provinceId: 80},
{id: 699, value: 'san michele mondovi', cap: '12080', provinceId: 4},
{id: 548, value: 'san giusto canavese', cap: '10090', provinceId: 1},
{id: 6157, value: 'san nicola manfredi', cap: '82010', provinceId: 75},
{id: 3242, value: 'san mauro di saline', cap: '37030', provinceId: 23},
{id: 3885, value: 'forgaria nel friuli', cap: '33030', provinceId: 30},
{id: 5946, value: 'castel san vincenzo', cap: '86071', provinceId: 73},
{id: 6155, value: 'san martino sannita', cap: '82010', provinceId: 75},
{id: 2697, value: 'castello di brianza', cap: '23884', provinceId: 19},
{id: 5645, value: 'tione degli abruzzi', cap: '67020', provinceId: 68},
{id: 6411, value: 'castelnuovo cilento', cap: '84040', provinceId: 78},
{id: 1601, value: 'castello dell acqua', cap: '23030', provinceId: 12},
{id: 6011, value: 'castello del matese', cap: '81016', provinceId: 74},
{id: 839, value: 'san marzano oliveto', cap: '14050', provinceId: 5},
{id: 2847, value: 'castelbello ciardes', cap: '39020', provinceId: 21},
{id: 554, value: 'san raffaele cimena', cap: '10090', provinceId: 1},
{id: 838, value: 'san martino alfieri', cap: '14010', provinceId: 5},
{id: 3891, value: 'fogliano redipuglia', cap: '34070', provinceId: 31},
{id: 7585, value: 'san giovanni gemini', cap: '92020', provinceId: 94},
{id: 3374, value: 'torri di quartesolo', cap: '36040', provinceId: 24},
{id: 6697, value: 'torre santa susanna', cap: '72028', provinceId: 82},
{id: 4021, value: 'san lorenzo al mare', cap: '18017', provinceId: 34},
{id: 367, value: 'castagnole piemonte', cap: '10060', provinceId: 1},
{id: 906, value: 'castellazzo bormida', cap: '15073', provinceId: 6},
{id: 7464, value: 'fondachelli fantina', cap: '98050', provinceId: 93},
{id: 5085, value: 'san severino marche', cap: '62027', provinceId: 61},
{id: 6873, value: 'san severino lucano', cap: '85030', provinceId: 84},
{id: 905, value: 'castellar guidobono', cap: '15050', provinceId: 6},
{id: 183, value: 'castelnuovo di ceva', cap: '12070', provinceId: 4},
{id: 3436, value: 'san tomaso agordino', cap: '32020', provinceId: 25},
{id: 4667, value: 'castagneto carducci', cap: '57022', provinceId: 51},
{id: 6511, value: 'san valentino torio', cap: '84010', provinceId: 78},
{id: 4313, value: 'castelnovo ne monti', cap: '42035', provinceId: 40},
{id: 2280, value: 'tavernole sul mella', cap: '25060', provinceId: 15},
{id: 6446, value: 'mercato san severino', cap: '84085', provinceId: 78},
{id: 3976, value: 'borghetto d arroscia', cap: '18020', provinceId: 34},
{id: 6029, value: 'gricignano di aversa', cap: '81030', provinceId: 74},
{id: 1082, value: 'mezzana mortigliengo', cap: '13831', provinceId: 7},
{id: 877, value: 'borghetto di borbera', cap: '15060', provinceId: 6},
{id: 7613, value: 'vallelunga pratameno', cap: '93010', provinceId: 95},
{id: 3531, value: 'santa lucia di piave', cap: '31025', provinceId: 26},
{id: 5690, value: 'roseto degli abruzzi', cap: '64026', provinceId: 69},
{id: 953, value: 'mirabello monferrato', cap: '15040', provinceId: 6},
{id: 1008, value: 'rosignano monferrato', cap: '15030', provinceId: 6},
{id: 5976, value: 'rocchetta a volturno', cap: '86070', provinceId: 73},
{id: 2254, value: 'provaglio val sabbia', cap: '25070', provinceId: 15},
{id: 1189, value: 'premosello chiovenda', cap: '28803', provinceId: 8},
{id: 2378, value: 'gravellona lomellina', cap: '27020', provinceId: 16},
{id: 6070, value: 'santa maria la fossa', cap: '81050', provinceId: 74},
{id: 5042, value: 'belforte del chienti', cap: '62020', provinceId: 61},
{id: 4524, value: 'bellaria igea marina', cap: '47814', provinceId: 46},
{id: 1241, value: 'gressoney la trinite', cap: '11020', provinceId: 9},
{id: 7495, value: 'monforte san giorgio', cap: '98041', provinceId: 93},
{id: 7812, value: 'santa maria coghinas', cap: '07030', provinceId: 100},
{id: 6448, value: 'moio della civitella', cap: '84060', provinceId: 78},
{id: 7278, value: 'san mauro marchesato', cap: '88831', provinceId: 89},
{id: 3500, value: 'monastier di treviso', cap: '31050', provinceId: 26},
{id: 4340, value: 'vezzano sul crostolo', cap: '42030', provinceId: 40},
{id: 6161, value: 'sant angelo a cupolo', cap: '82010', provinceId: 75},
{id: 1242, value: 'gressoney saint jean', cap: '11025', provinceId: 9},
{id: 7142, value: 'san vito sullo ionio', cap: '88067', provinceId: 87},
{id: 6368, value: 'torella dei lombardi', cap: '83057', provinceId: 77},
{id: 7487, value: 'mazzarra sant andrea', cap: '98056', provinceId: 93},
{id: 4208, value: 'borgonovo val tidone', cap: '29011', provinceId: 38},
{id: 7527, value: 'santa lucia del mela', cap: '98046', provinceId: 93},
{id: 1804, value: 'robecco sul naviglio', cap: '20087', provinceId: 13},
{id: 3905, value: 'san lorenzo isontino', cap: '34070', provinceId: 31},
{id: 1893, value: 'brignano gera d adda', cap: '24053', provinceId: 14},
{id: 4422, value: 'lizzano in belvedere', cap: '40042', provinceId: 42},
{id: 1331, value: 'cavaria con premezzo', cap: '21044', provinceId: 10},
{id: 1816, value: 'san zenone al lambro', cap: '20070', provinceId: 13},
{id: 7203, value: 'laureana di borrello', cap: '89023', provinceId: 88},
{id: 6154, value: 'san marco dei cavoti', cap: '82029', provinceId: 75},
{id: 1840, value: 'veduggio con colzano', cap: '20837', provinceId: 108},
{id: 6152, value: 'san lorenzo maggiore', cap: '82034', provinceId: 75},
{id: 7227, value: 'roccaforte del greco', cap: '89060', provinceId: 88},
{id: 7529, value: 'sant angelo di brolo', cap: '98060', provinceId: 93},
{id: 1654, value: 'torre di santa maria', cap: '23020', provinceId: 12},
{id: 5923, value: 'sant angelo limosano', cap: '86020', provinceId: 72},
{id: 6342, value: 'san mango sul calore', cap: '83050', provinceId: 77},
{id: 3852, value: 'santa maria la longa', cap: '33050', provinceId: 30},
{id: 2380, value: 'inverno e monteleone', cap: '27010', provinceId: 16},
{id: 7056, value: 'santa domenica talao', cap: '87020', provinceId: 86},
{id: 2910, value: 'san martino in badia', cap: '39030', provinceId: 21},
{id: 6567, value: 'margherita di savoia', cap: '76016', provinceId: 110},
{id: 7702, value: 'santa croce camerina', cap: '97017', provinceId: 98},
{id: 3901, value: 'ronchi dei legionari', cap: '34077', provinceId: 31},
{id: 3462, value: 'caerano di san marco', cap: '31031', provinceId: 26},
{id: 6716, value: 'castrignano de greci', cap: '73020', provinceId: 83},
{id: 2320, value: 'borgoratto mormorolo', cap: '27040', provinceId: 16},
{id: 1265, value: 'rhemes saint georges', cap: '11010', provinceId: 9},
{id: 549, value: 'san martino canavese', cap: '10010', provinceId: 1},
{id: 4976, value: 'san lorenzo in campo', cap: '61047', provinceId: 59},
{id: 2584, value: 'san martino del lago', cap: '26040', provinceId: 17},
{id: 6717, value: 'castrignano del capo', cap: '73040', provinceId: 83},
{id: 4638, value: 'incisa in val d arno', cap: '50064', provinceId: 50},
{id: 441, value: 'luserna san giovanni', cap: '10062', provinceId: 1},
{id: 4228, value: 'lugagnano val d arda', cap: '29018', provinceId: 38},
{id: 6350, value: 'sant angelo all esca', cap: '83050', provinceId: 77},
{id: 6412, value: 'castelnuovo di conza', cap: '84020', provinceId: 78},
{id: 4619, value: 'barberino di mugello', cap: '50031', provinceId: 50},
{id: 1194, value: 'santa maria maggiore', cap: '28857', provinceId: 8},
{id: 866, value: 'altavilla monferrato', cap: '15041', provinceId: 6},
{id: 4283, value: 'san secondo parmense', cap: '43017', provinceId: 39},
{id: 2789, value: 'cornegliano laudense', cap: '26854', provinceId: 20},
{id: 555, value: 'san sebastiano da po', cap: '10020', provinceId: 1},
{id: 3549, value: 'volpago del montello', cap: '31040', provinceId: 26},
{id: 293, value: 'pezzolo valle uzzone', cap: '12070', provinceId: 4},
{id: 7416, value: 'piana degli albanesi', cap: '90037', provinceId: 92},
{id: 6042, value: 'piana di monte verna', cap: '81013', provinceId: 74},
{id: 3812, value: 'muzzana del turgnano', cap: '33055', provinceId: 30},
{id: 64, value: 'castellazzo novarese', cap: '28060', provinceId: 3},
{id: 4500, value: 'civitella di romagna', cap: '47012', provinceId: 45},
{id: 7664, value: 'motta sant anastasia', cap: '95040', provinceId: 97},
{id: 5670, value: 'civitella del tronto', cap: '64010', provinceId: 69},
{id: 6066, value: 'san potito sannitico', cap: '81016', provinceId: 74},
{id: 4691, value: 'castellina marittima', cap: '56040', provinceId: 52},
{id: 5633, value: 'san pio delle camere', cap: '67020', provinceId: 68},
{id: 5683, value: 'mosciano sant angelo', cap: '64023', provinceId: 69},
{id: 3671, value: 'san pietro viminario', cap: '35020', provinceId: 28},
{id: 6721, value: 'corigliano d otranto', cap: '73022', provinceId: 83},
{id: 2407, value: 'olevano di lomellina', cap: '27020', provinceId: 16},
{id: 5100, value: 'appignano del tronto', cap: '63083', provinceId: 62},
{id: 2227, value: 'paderno franciacorta', cap: '25050', provinceId: 15},
{id: 3944, value: 'pasiano di pordenone', cap: '33087', provinceId: 33},
{id: 7576, value: 'palma di montechiaro', cap: '92020', provinceId: 94},
{id: 4645, value: 'palazzuolo sul senio', cap: '50035', provinceId: 50},
{id: 4, value: 'palazzolo vercellese', cap: '13040', provinceId: 2},
{id: 2230, value: 'palazzolo sull oglio', cap: '25036', provinceId: 15},
{id: 6852, value: 'palazzo san gervasio', cap: '85026', provinceId: 84},
{id: 2494, value: 'acquanegra cremonese', cap: '26020', provinceId: 17},
{id: 4372, value: 'pavullo nel frignano', cap: '41026', provinceId: 41},
{id: 5851, value: 'acquaviva collecroce', cap: '86030', provinceId: 72},
{id: 6461, value: 'olevano sul tusciano', cap: '84062', provinceId: 78},
{id: 4615, value: 'serravalle pistoiese', cap: '51030', provinceId: 49},
{id: 6017, value: 'conca della campania', cap: '81044', provinceId: 74},
{id: 3562, value: 'concordia sagittaria', cap: '30023', provinceId: 27},
{id: 5000, value: 'castelleone di suasa', cap: '60010', provinceId: 60},
{id: 6290, value: 'conza della campania', cap: '83040', provinceId: 77},
{id: 2059, value: 'tavernola bergamasca', cap: '24060', provinceId: 14},
{id: 2917, value: 'selva di val gardena', cap: '39048', provinceId: 21},
{id: 4005, value: 'olivetta san michele', cap: '18030', provinceId: 34},
{id: 6694, value: 'san pietro vernotico', cap: '72027', provinceId: 82},
{id: 4876, value: 'scheggia e pascelupo', cap: '06027', provinceId: 57},
{id: 9452, value: 'prad am stilfserjoch', cap: '39026', provinceId: 21},
{id: 2399, value: 'montecalvo versiggia', cap: '27047', provinceId: 16},
{id: 602, value: 'villafranca piemonte', cap: '10068', provinceId: 1},
{id: 3695, value: 'villafranca padovana', cap: '35010', provinceId: 28},
{id: 1606, value: 'chiesa in valmalenco', cap: '23023', provinceId: 12},
{id: 6193, value: 'castello di cisterna', cap: '80030', provinceId: 76},
{id: 4521, value: 'sogliano al rubicone', cap: '47030', provinceId: 45},
{id: 6452, value: 'montecorvino rovella', cap: '84096', provinceId: 78},
{id: 7098, value: 'chiaravalle centrale', cap: '88064', provinceId: 87},
{id: 6349, value: 'sant andrea di conza', cap: '83053', provinceId: 77},
{id: 4952, value: 'montecalvo in foglia', cap: '61020', provinceId: 59},
{id: 2242, value: 'polpenazze del garda', cap: '25080', provinceId: 15},
{id: 3326, value: 'montebello vicentino', cap: '36054', provinceId: 24},
{id: 5145, value: 'monte vidon combatte', cap: '63847', provinceId: 109},
{id: 4620, value: 'barberino val d elsa', cap: '50021', provinceId: 50},
{id: 6064, value: 'san nicola la strada', cap: '81020', provinceId: 74},
{id: 7788, value: 'santa teresa gallura', cap: '07028', provinceId: 104},
{id: 7279, value: 'san nicola dell alto', cap: '88817', provinceId: 89},
{id: 7320, value: 'san nicola da crissa', cap: '89821', provinceId: 90},
{id: 7530, value: 'santa teresa di riva', cap: '98028', provinceId: 93},
{id: 1814, value: 'santo stefano ticino', cap: '20010', provinceId: 13},
{id: 4910, value: 'monteleone d orvieto', cap: '05017', provinceId: 58},
{id: 6883, value: 'terranova di pollino', cap: '85030', provinceId: 84},
{id: 3434, value: 'san pietro di cadore', cap: '32040', provinceId: 25},
{id: 3360, value: 'san pietro mussolino', cap: '36070', provinceId: 24},
{id: 622, value: 'asigliano vercellese', cap: '13032', provinceId: 2},
{id: 8103, value: 'montiglio monferrato', cap: '14026', provinceId: 5},
{id: 4702, value: 'monteverdi marittimo', cap: '56040', provinceId: 52},
{id: 4383, value: 'savignano sul panaro', cap: '41056', provinceId: 41},
{id: 4444, value: 'san pietro in casale', cap: '40018', provinceId: 42},
{id: 7236, value: 'san pietro di carida', cap: '89020', provinceId: 88},
{id: 4572, value: 'fabbriche di vallico', cap: '55020', provinceId: 48},
{id: 6087, value: 'falciano del massico', cap: '81030', provinceId: 74},
{id: 2038, value: 'san pellegrino terme', cap: '24016', provinceId: 14},
{id: 2298, value: 'villanuova sul clisi', cap: '25089', provinceId: 15},
{id: 4643, value: 'montelupo fiorentino', cap: '50056', provinceId: 50},
{id: 7803, value: 'villanova monteleone', cap: '07019', provinceId: 100},
{id: 1044, value: 'villanova monferrato', cap: '15030', provinceId: 6},
{id: 3747, value: 'villanova marchesana', cap: '45030', provinceId: 29},
{id: 3746, value: 'villanova del ghebbo', cap: '45020', provinceId: 29},
{id: 2482, value: 'villanova d ardenghi', cap: '27030', provinceId: 16},
{id: 6569, value: 'monteleone di puglia', cap: '71020', provinceId: 79},
{id: 370, value: 'castiglione torinese', cap: '10090', provinceId: 1},
{id: 3247, value: 'san zeno di montagna', cap: '37010', provinceId: 23},
{id: 7633, value: 'valguarnera caropepe', cap: '94019', provinceId: 96},
{id: 2601, value: 'torricella del pizzo', cap: '26040', provinceId: 17},
{id: 4839, value: 'castiglione del lago', cap: '06061', provinceId: 57},
{id: 3668, value: 'san giorgio in bosco', cap: '35010', provinceId: 28},
{id: 4441, value: 'san giorgio di piano', cap: '40016', provinceId: 42},
{id: 7091, value: 'caraffa di catanzaro', cap: '88050', provinceId: 87},
{id: 7559, value: 'campobello di licata', cap: '92023', provinceId: 94},
{id: 7339, value: 'campobello di mazara', cap: '91021', provinceId: 91},
{id: 5939, value: 'cantalupo nel sannio', cap: '86092', provinceId: 73},
{id: 2437, value: 'san damiano al colle', cap: '27040', provinceId: 16},
{id: 1955, value: 'fornovo san giovanni', cap: '24040', provinceId: 14},
{id: 546, value: 'san giorgio canavese', cap: '10090', provinceId: 1},
{id: 2715, value: 'garbagnate monastero', cap: '23846', provinceId: 19},
{id: 7044, value: 'san giorgio albanese', cap: '87060', provinceId: 86},
{id: 6589, value: 'sant agata di puglia', cap: '71028', provinceId: 79},
{id: 538, value: 'san benigno canavese', cap: '10080', provinceId: 1},
{id: 3617, value: 'carmignano di brenta', cap: '35010', provinceId: 28},
{id: 5325, value: 'castelnuovo di porto', cap: '00060', provinceId: 65},
{id: 5297, value: 'torricella in sabina', cap: '02030', provinceId: 64},
{id: 7038, value: 'san benedetto ullano', cap: '87040', provinceId: 86},
{id: 4213, value: 'carpaneto piacentino', cap: '29013', provinceId: 38},
{id: 5827, value: 'san giovanni teatino', cap: '66020', provinceId: 71},
{id: 6713, value: 'carpignano salentino', cap: '73020', provinceId: 83},
{id: 5708, value: 'carpineto della nora', cap: '65010', provinceId: 70},
{id: 7971, value: 'san giovanni suergiu', cap: '09010', provinceId: 107},
{id: 2538, value: 'gabbioneta binanuova', cap: '26030', provinceId: 17},
{id: 6829, value: 'francavilla in sinni', cap: '85034', provinceId: 84},
{id: 5826, value: 'san giovanni lipioni', cap: '66050', provinceId: 71},
{id: 2438, value: 'san genesio ed uniti', cap: '27010', provinceId: 16},
{id: 4445, value: 'sant agata bolognese', cap: '40019', provinceId: 42},
{id: 6583, value: 'san giovanni rotondo', cap: '71013', provinceId: 79},
{id: 3719, value: 'frassinelle polesine', cap: '45030', provinceId: 29},
{id: 6185, value: 'casalnuovo di napoli', cap: '80013', provinceId: 76},
{id: 7518, value: 'san filippo del mela', cap: '98044', provinceId: 93},
{id: 3488, value: 'giavera del montello', cap: '31040', provinceId: 26},
{id: 3288, value: 'campiglia dei berici', cap: '36020', provinceId: 24},
{id: 837, value: 'san giorgio scarampi', cap: '14059', provinceId: 5},
{id: 6766, value: 'san cesario di lecce', cap: '73016', provinceId: 83},
{id: 7299, value: 'francavilla angitola', cap: '89815', provinceId: 90},
{id: 4727, value: 'caprese michelangelo', cap: '52033', provinceId: 53},
{id: 5242, value: 'castelnuovo di farfa', cap: '02031', provinceId: 64},
{id: 5320, value: 'capranica prenestina', cap: '00030', provinceId: 65},
{id: 1466, value: 'castelnuovo bozzente', cap: '22070', provinceId: 11},
{id: 5840, value: 'torrevecchia teatina', cap: '66010', provinceId: 71},
{id: 3903, value: 'san canzian d isonzo', cap: '34075', provinceId: 31},
{id: 184, value: 'castiglione falletto', cap: '12060', provinceId: 4},
{id: 7046, value: 'san lorenzo bellizzi', cap: '87070', provinceId: 86},
{id: 7240, value: 'sant agata del bianco', cap: '89030', provinceId: 88},
{id: 3942, value: 'montereale valcellina', cap: '33086', provinceId: 33},
{id: 5896, value: 'montenero di bisaccia', cap: '86036', provinceId: 72},
{id: 6962, value: 'cerchiara di calabria', cap: '87070', provinceId: 86},
{id: 3529, value: 'san pietro di feletto', cap: '31020', provinceId: 26},
{id: 8070, value: 'san nicolo d arcidano', cap: '09097', provinceId: 103},
{id: 7410, value: 'montemaggiore belsito', cap: '90020', provinceId: 92},
{id: 7211, value: 'melito di porto salvo', cap: '89063', provinceId: 88},
{id: 6695, value: 'san vito dei normanni', cap: '72019', provinceId: 82},
{id: 8107, value: 'poggio di chiesanuova', cap: '47894', provinceId: 111},
{id: 3644, value: 'megliadino san vitale', cap: '35040', provinceId: 28},
{id: 701, value: 'santa vittoria d alba', cap: '12069', provinceId: 4},
{id: 6348, value: 'santa lucia di serino', cap: '83020', provinceId: 77},
{id: 6060, value: 'san cipriano d aversa', cap: '81036', provinceId: 74},
{id: 3243, value: 'san pietro di morubio', cap: '37050', provinceId: 23},
{id: 6982, value: 'francavilla marittima', cap: '87072', provinceId: 86},
{id: 4379, value: 'san felice sul panaro', cap: '41038', provinceId: 41},
{id: 2816, value: 'san martino in strada', cap: '26817', provinceId: 20},
{id: 3802, value: 'malborghetto valbruna', cap: '33010', provinceId: 30},
{id: 4690, value: 'castelfranco di sotto', cap: '56022', provinceId: 52},
{id: 1223, value: 'challand saint victor', cap: '11020', provinceId: 9},
{id: 3333, value: 'monticello conte otto', cap: '36010', provinceId: 24},
{id: 4729, value: 'castelfranco di sopra', cap: '52020', provinceId: 53},
{id: 2272, value: 'saviore dell adamello', cap: '25040', provinceId: 15},
{id: 7053, value: 'san pietro in guarano', cap: '87047', provinceId: 86},
{id: 4753, value: 'san giovanni valdarno', cap: '52027', provinceId: 53},
{id: 5914, value: 'san felice del molise', cap: '86030', provinceId: 72},
{id: 2268, value: 'san felice del benaco', cap: '25010', provinceId: 15},
{id: 4714, value: 'santa croce sull arno', cap: '56029', provinceId: 52},
{id: 6061, value: 'san felice a cancello', cap: '81027', provinceId: 74},
{id: 4742, value: 'marciano della chiana', cap: '52047', provinceId: 53},
{id: 7193, value: 'feroleto della chiesa', cap: '89050', provinceId: 88},
{id: 3244, value: 'san pietro in cariano', cap: '37029', provinceId: 23},
{id: 7052, value: 'san pietro in amantea', cap: '87030', provinceId: 86},
{id: 6504, value: 'san pietro al tanagro', cap: '84030', provinceId: 78},
{id: 2828, value: 'villanova del sillaro', cap: '26818', provinceId: 20},
{id: 1906, value: 'carobbio degli angeli', cap: '24060', provinceId: 14},
{id: 1813, value: 'san giuliano milanese', cap: '20098', provinceId: 13},
{id: 5127, value: 'montalto delle marche', cap: '63068', provinceId: 62},
{id: 7912, value: 'villagrande strisaili', cap: '08049', provinceId: 105},
{id: 6587, value: 'san paolo di civitate', cap: '71010', provinceId: 79},
{id: 7058, value: 'santa maria del cedro', cap: '87020', provinceId: 86},
{id: 4159, value: 'santo stefano d aveto', cap: '16049', provinceId: 36},
{id: 7259, value: 'belvedere di spinello', cap: '88824', provinceId: 89},
{id: 3264, value: 'villafranca di verona', cap: '37069', provinceId: 23},
{id: 3217, value: 'montecchia di crosara', cap: '37030', provinceId: 23},
{id: 3622, value: 'cervarese santa croce', cap: '35030', provinceId: 28},
{id: 6258, value: 'santa maria la carita', cap: '80050', provinceId: 76},
{id: 5126, value: 'monsampolo del tronto', cap: '63077', provinceId: 62},
{id: 3328, value: 'montecchio precalcino', cap: '36030', provinceId: 24},
{id: 5576, value: 'castelvecchio subequo', cap: '67024', provinceId: 68},
{id: 6692, value: 'san michele salentino', cap: '72018', provinceId: 82},
{id: 3109, value: 'san michele all adige', cap: '38010', provinceId: 22},
{id: 6344, value: 'san michele di serino', cap: '83020', provinceId: 77},
{id: 6451, value: 'montecorvino pugliano', cap: '84090', provinceId: 78},
{id: 748, value: 'berzano di san pietro', cap: '14020', provinceId: 5},
{id: 5755, value: 'montebello sul sangro', cap: '66040', provinceId: 71},
{id: 1718, value: 'cernusco sul naviglio', cap: '20063', provinceId: 13},
{id: 5524, value: 'san vittore del lazio', cap: '03040', provinceId: 67},
{id: 5447, value: 'santi cosma e damiano', cap: '04020', provinceId: 66},
{id: 1903, value: 'capriate san gervasio', cap: '24042', provinceId: 14},
{id: 4023, value: 'santo stefano al mare', cap: '18010', provinceId: 34},
{id: 6501, value: 'san marzano sul sarno', cap: '84010', provinceId: 78},
{id: 2507, value: 'cappella de picenardi', cap: '26030', provinceId: 17},
{id: 3771, value: 'cervignano del friuli', cap: '33052', provinceId: 30},
{id: 550, value: 'san maurizio canavese', cap: '10077', provinceId: 1},
{id: 4861, value: 'monteleone di spoleto', cap: '06045', provinceId: 57},
{id: 3190, value: 'castelnuovo del garda', cap: '37014', provinceId: 23},
{id: 5723, value: 'montebello di bertona', cap: '65010', provinceId: 70},
{id: 1985, value: 'misano di gera d adda', cap: '24040', provinceId: 14},
{id: 4350, value: 'castelvetro di modena', cap: '41014', provinceId: 41},
{id: 383, value: 'chiusa di san michele', cap: '10050', provinceId: 1},
{id: 7424, value: 'san mauro castelverde', cap: '90010', provinceId: 92},
{id: 4020, value: 'san biagio della cima', cap: '18036', provinceId: 34},
{id: 7319, value: 'san gregorio d ippona', cap: '89853', provinceId: 90},
{id: 5897, value: 'montorio nei frentani', cap: '86040', provinceId: 72},
{id: 3362, value: 'san vito di leguzzano', cap: '36030', provinceId: 24},
{id: 1947, value: 'fara olivana con sola', cap: '24058', provinceId: 14},
{id: 4419, value: 'granarolo dell emilia', cap: '40057', provinceId: 42},
{id: 1202, value: 'vanzone con san carlo', cap: '28879', provinceId: 8},
{id: 2609, value: 'acquanegra sul chiese', cap: '46011', provinceId: 18},
{id: 3238, value: 'san giovanni ilarione', cap: '37035', provinceId: 23},
{id: 2818, value: 'sant angelo lodigiano', cap: '26866', provinceId: 20},
{id: 5090, value: 'serravalle di chienti', cap: '62038', provinceId: 61},
{id: 2447, value: 'sant angelo lomellina', cap: '27030', provinceId: 16},
{id: 133, value: 'albaretto della torre', cap: '12050', provinceId: 4},
{id: 6121, value: 'foiano di val fortore', cap: '82020', provinceId: 75},
{id: 4443, value: 'san lazzaro di savena', cap: '40068', provinceId: 42},
{id: 7045, value: 'san giovanni in fiore', cap: '87055', provinceId: 86},
{id: 1467, value: 'castiglione d intelvi', cap: '22023', provinceId: 11},
{id: 558, value: 'sant antonino di susa', cap: '10050', provinceId: 1},
{id: 1014, value: 'san sebastiano curone', cap: '15056', provinceId: 6},
{id: 9467, value: 'st martin in passeier', cap: '39010', provinceId: 21},
{id: 6235, value: 'san giorgio a cremano', cap: '80046', provinceId: 76},
{id: 2909, value: 'san lorenzo di sebato', cap: '39030', provinceId: 21},
{id: 4274, value: 'neviano degli arduini', cap: '43024', provinceId: 39},
{id: 5916, value: 'san giovanni in galdo', cap: '86010', provinceId: 72},
{id: 5143, value: 'monte san pietrangeli', cap: '63815', provinceId: 109},
{id: 6202, value: 'giugliano in campania', cap: '80014', provinceId: 76},
{id: 6149, value: 'san giorgio la molara', cap: '82020', provinceId: 75},
{id: 4178, value: 'calice al cornoviglio', cap: '19020', provinceId: 37},
{id: 2811, value: 'ospedaletto lodigiano', cap: '26864', provinceId: 20},
{id: 4974, value: 'san giorgio di pesaro', cap: '61030', provinceId: 59},
{id: 1273, value: 'saint rhemy en bosses', cap: '11010', provinceId: 9},
{id: 7271, value: 'isola di capo rizzuto', cap: '88841', provinceId: 89},
{id: 5980, value: 'sant angelo del pesco', cap: '86080', provinceId: 73},
{id: 4571, value: 'coreglia antelminelli', cap: '55025', provinceId: 48},
{id: 7384, value: 'castronovo di sicilia', cap: '90030', provinceId: 92},
{id: 5444, value: 'roccasecca dei volsci', cap: '04010', provinceId: 66},
{id: 3848, value: 'san giorgio di nogaro', cap: '33058', provinceId: 30},
{id: 3108, value: 'san lorenzo in banale', cap: '38078', provinceId: 22},
{id: 6602, value: 'acquaviva delle fonti', cap: '70021', provinceId: 80},
{id: 6150, value: 'san leucio del sannio', cap: '82010', provinceId: 75},
{id: 770, value: 'castelnuovo don bosco', cap: '14022', provinceId: 5},
{id: 2583, value: 'san giovanni in croce', cap: '26037', provinceId: 17},
{id: 6874, value: 'sant angelo le fratte', cap: '85050', provinceId: 84},
{id: 6956, value: 'castiglione cosentino', cap: '87040', provinceId: 86},
{id: 762, value: 'castagnole monferrato', cap: '14030', provinceId: 5},
{id: 1860, value: 'almenno san salvatore', cap: '24031', provinceId: 14},
{id: 1829, value: 'trezzano sul naviglio', cap: '20090', provinceId: 13},
{id: 5518, value: 'san giovanni incarico', cap: '03028', provinceId: 67},
{id: 3289, value: 'campolongo sul brenta', cap: '36020', provinceId: 24},
{id: 3239, value: 'san giovanni lupatoto', cap: '37057', provinceId: 23},
{id: 5549, value: 'anversa degli abruzzi', cap: '67030', provinceId: 68},
{id: 9697, value: 'campolongo tapogliano', cap: '33040', provinceId: 30},
{id: 3926, value: 'casarsa della delizia', cap: '33072', provinceId: 33},
{id: 7050, value: 'san martino di finita', cap: '87010', provinceId: 86},
{id: 7809, value: 'loiri porto san paolo', cap: '07020', provinceId: 104},
{id: 5950, value: 'civitanova del sannio', cap: '86094', provinceId: 73},
{id: 3669, value: 'san martino di lupari', cap: '35018', provinceId: 28},
{id: 552, value: 'san pietro val lemina', cap: '10060', provinceId: 1},
{id: 7061, value: 'san vincenzo la costa', cap: '87030', provinceId: 86},
{id: 4760, value: 'abbadia san salvatore', cap: '53021', provinceId: 54},
{id: 3481, value: 'crocetta del montello', cap: '31035', provinceId: 26},
{id: 2509, value: 'casalbuttano ed uniti', cap: '26011', provinceId: 17},
{id: 4835, value: 'campello sul clitunno', cap: '06042', provinceId: 57},
{id: 3927, value: 'castelnovo del friuli', cap: '33090', provinceId: 33},
{id: 7047, value: 'san lorenzo del vallo', cap: '87040', provinceId: 86},
{id: 1305, value: 'brissago valtravaglia', cap: '21030', provinceId: 10},
{id: 1956, value: 'fuipiano valle imagna', cap: '24030', provinceId: 14},
{id: 5522, value: 'sant elia fiumerapido', cap: '03049', provinceId: 67},
{id: 2539, value: 'gadesco pieve delmona', cap: '26030', provinceId: 17},
{id: 3489, value: 'godega di sant urbano', cap: '31010', provinceId: 26},
{id: 3763, value: 'camino al tagliamento', cap: '33030', provinceId: 30},
{id: 6325, value: 'mugnano del cardinale', cap: '83027', provinceId: 77},
{id: 4764, value: 'castellina in chianti', cap: '53011', provinceId: 54},
{id: 4732, value: 'castiglion fiorentino', cap: '52043', provinceId: 53},
{id: 6090, value: 'san marco evangelista', cap: '81020', provinceId: 74},
{id: 7675, value: 'san giovanni la punta', cap: '95037', provinceId: 97},
{id: 6234, value: 'san gennaro vesuviano', cap: '80040', provinceId: 76},
{id: 7233, value: 'san giovanni di gerace', cap: '89040', provinceId: 88},
{id: 6236, value: 'san giuseppe vesuviano', cap: '80047', provinceId: 76},
{id: 6550, value: 'casalnuovo monterotaro', cap: '71033', provinceId: 79},
{id: 4407, value: 'castello di serravalle', cap: '40050', provinceId: 42},
{id: 6354, value: 'santo stefano del sole', cap: '83050', provinceId: 77},
{id: 1012, value: 'san giorgio monferrato', cap: '15020', provinceId: 6},
{id: 1812, value: 'san giorgio su legnano', cap: '20010', provinceId: 13},
{id: 6551, value: 'casalvecchio di puglia', cap: '71030', provinceId: 79},
{id: 4242, value: 'san giorgio piacentino', cap: '29019', provinceId: 38},
{id: 4196, value: 'santo stefano di magra', cap: '19037', provinceId: 37},
{id: 3817, value: 'palazzolo dello stella', cap: '33056', provinceId: 30},
{id: 540, value: 'san colombano belmonte', cap: '10080', provinceId: 1},
{id: 3433, value: 'san nicolo di comelico', cap: '32040', provinceId: 25},
{id: 393, value: 'colleretto castelnuovo', cap: '10080', provinceId: 1},
{id: 6327, value: 'ospedaletto d alpinolo', cap: '83014', provinceId: 77},
{id: 2532, value: 'cumignano sul naviglio', cap: '26020', provinceId: 17},
{id: 5130, value: 'montefalcone appennino', cap: '63855', provinceId: 109},
{id: 5397, value: 'san polo dei cavalieri', cap: '00010', provinceId: 65},
{id: 7765, value: 'monteleone rocca doria', cap: '07010', provinceId: 100},
{id: 638, value: 'san giacomo vercellese', cap: '13030', provinceId: 2},
{id: 1859, value: 'almenno san bartolomeo', cap: '24030', provinceId: 14},
{id: 2269, value: 'san gervasio bresciano', cap: '25020', provinceId: 15},
{id: 27, value: 'san germano vercellese', cap: '13047', provinceId: 2},
{id: 3358, value: 'san germano dei berici', cap: '36040', provinceId: 24},
{id: 2441, value: 'sannazzaro de burgondi', cap: '27039', provinceId: 16},
{id: 6377, value: 'villanova del battista', cap: '83030', provinceId: 77},
{id: 5506, value: 'piedimonte san germano', cap: '03030', provinceId: 67},
{id: 3943, value: 'morsano al tagliamento', cap: '33075', provinceId: 33},
{id: 4822, value: 'monterotondo marittimo', cap: '58025', provinceId: 55},
{id: 4520, value: 'savignano sul rubicone', cap: '47039', provinceId: 45},
{id: 542, value: 'san francesco al campo', cap: '10070', provinceId: 1},
{id: 3945, value: 'pinzano al tagliamento', cap: '33094', provinceId: 33},
{id: 4759, value: 'terranuova bracciolini', cap: '52028', provinceId: 53},
{id: 6158, value: 'san salvatore telesino', cap: '82030', provinceId: 75},
{id: 8058, value: 'nughedu santa vittoria', cap: '09080', provinceId: 103},
{id: 5575, value: 'castelvecchio calvisio', cap: '67020', provinceId: 68},
{id: 2665, value: 'san giorgio di mantova', cap: '46030', provinceId: 18},
{id: 7679, value: 'sant agata li battiati', cap: '95030', provinceId: 97},
{id: 7318, value: 'san costantino calabro', cap: '89851', provinceId: 90},
{id: 5918, value: 'san giuliano di puglia', cap: '86040', provinceId: 72},
{id: 1538, value: 'oltrona di san mamette', cap: '22070', provinceId: 11},
{id: 1412, value: 'tronzano lago maggiore', cap: '21010', provinceId: 10},
{id: 9486, value: 'taufers im muenstertal', cap: '39020', provinceId: 21},
{id: 3587, value: 'santo stino di livenza', cap: '30029', provinceId: 27},
{id: 3851, value: 'san pietro al natisone', cap: '33049', provinceId: 30},
{id: 6148, value: 'san giorgio del sannio', cap: '82018', provinceId: 75},
{id: 1856, value: 'albano sant alessandro', cap: '24061', provinceId: 14},
{id: 4004, value: 'montegrosso pian latte', cap: '18025', provinceId: 34},
{id: 3847, value: 'san daniele del friuli', cap: '33038', provinceId: 30},
{id: 9473, value: 'wolkenstein in groeden', cap: '39048', provinceId: 21},
{id: 1588, value: 'albaredo per san marco', cap: '23010', provinceId: 12},
{id: 5864, value: 'castellino del biferno', cap: '86020', provinceId: 72},
{id: 4658, value: 'tavarnelle val di pesa', cap: '50028', provinceId: 50},
{id: 1222, value: 'challand saint anselme', cap: '11020', provinceId: 9},
{id: 2666, value: 'san giovanni del dosso', cap: '46020', provinceId: 18},
{id: 6579, value: 'rocchetta sant antonio', cap: '71020', provinceId: 79},
{id: 1803, value: 'robecchetto con induno', cap: '20020', provinceId: 13},
{id: 4614, value: 'san marcello pistoiese', cap: '51028', provinceId: 49},
{id: 2440, value: 'san martino siccomario', cap: '27028', provinceId: 16},
{id: 910, value: 'castelletto monferrato', cap: '15040', provinceId: 6},
{id: 6159, value: 'santa croce del sannio', cap: '82020', provinceId: 75},
{id: 7681, value: 'santa maria di licodia', cap: '95038', provinceId: 97},
{id: 5737, value: 'sant eufemia a maiella', cap: '65020', provinceId: 70},
{id: 5709, value: 'castiglione a casauria', cap: '65020', provinceId: 70},
{id: 1688, value: 'boffalora sopra ticino', cap: '20010', provinceId: 13},
{id: 7624, value: 'gagliano castelferrato', cap: '94010', provinceId: 96},
{id: 1438, value: 'beregazzo con figliaro', cap: '22070', provinceId: 11},
{id: 5515, value: 'san biagio saracinisco', cap: '03040', provinceId: 67},
{id: 2632, value: 'gazoldo degli ippoliti', cap: '46040', provinceId: 18},
{id: 1594, value: 'berbenno di valtellina', cap: '23010', provinceId: 12},
{id: 1631, value: 'montagna in valtellina', cap: '23020', provinceId: 12},
{id: 7375, value: 'campofelice di fitalia', cap: '90030', provinceId: 92},
{id: 6817, value: 'castelluccio inferiore', cap: '85040', provinceId: 84},
{id: 6818, value: 'castelluccio superiore', cap: '85040', provinceId: 84},
{id: 4978, value: 'sant angelo in lizzola', cap: '61020', provinceId: 59},
{id: 4116, value: 'castiglione chiavarese', cap: '16030', provinceId: 36},
{id: 5979, value: 'santa maria del molise', cap: '86096', provinceId: 73},
{id: 7466, value: 'francavilla di sicilia', cap: '98034', provinceId: 93},
{id: 5086, value: 'sant angelo in pontano', cap: '62020', provinceId: 61},
{id: 4875, value: 'sant anatolia di narco', cap: '06040', provinceId: 57},
{id: 6552, value: 'castelluccio dei sauri', cap: '71025', provinceId: 79},
{id: 3214, value: 'marano di valpolicella', cap: '37020', provinceId: 23},
{id: 7436, value: 'ventimiglia di sicilia', cap: '90020', provinceId: 92},
{id: 7650, value: 'fiumefreddo di sicilia', cap: '95013', provinceId: 97},
{id: 113, value: 'san maurizio d opaglio', cap: '28017', provinceId: 3},
{id: 1585, value: 'vertemate con minoprio', cap: '22070', provinceId: 11},
{id: 3202, value: 'ferrara di monte baldo', cap: '37020', provinceId: 23},
{id: 4947, value: 'mercatello sul metauro', cap: '61040', provinceId: 59},
{id: 6497, value: 'san cipriano picentino', cap: '84099', provinceId: 78},
{id: 4375, value: 'prignano sulla secchia', cap: '41048', provinceId: 41},
{id: 3740, value: 'san martino di venezze', cap: '45030', provinceId: 29},
{id: 4378, value: 'san cesario sul panaro', cap: '41018', provinceId: 41},
{id: 5635, value: 'sant eusanio forconese', cap: '67020', provinceId: 68},
{id: 4216, value: 'castelvetro piacentino', cap: '29010', provinceId: 38},
{id: 931, value: 'frassinello monferrato', cap: '15035', provinceId: 6},
{id: 1193, value: 'san bernardino verbano', cap: '28804', provinceId: 8},
{id: 761, value: 'castagnole delle lanze', cap: '14054', provinceId: 5},
{id: 4786, value: 'san casciano dei bagni', cap: '53040', provinceId: 54},
{id: 4019, value: 'san bartolomeo al mare', cap: '18016', provinceId: 34},
{id: 4765, value: 'castelnuovo berardenga', cap: '53019', provinceId: 54},
{id: 7648, value: 'castiglione di sicilia', cap: '95012', provinceId: 97},
{id: 3527, value: 'san biagio di callalta', cap: '31048', provinceId: 26},
{id: 6586, value: 'san nicandro garganico', cap: '71015', provinceId: 79},
{id: 4411, value: 'castiglione dei pepoli', cap: '40035', provinceId: 42},
{id: 5630, value: 'san benedetto dei marsi', cap: '67058', provinceId: 68},
{id: 3643, value: 'megliadino san fidenzio', cap: '35040', provinceId: 28},
{id: 3432, value: 'san gregorio nelle alpi', cap: '32030', provinceId: 25},
{id: 4046, value: 'borghetto santo spirito', cap: '17052', provinceId: 35},
{id: 7551, value: 'alessandria della rocca', cap: '92010', provinceId: 94},
{id: 7340, value: 'castellammare del golfo', cap: '91014', provinceId: 91},
{id: 6106, value: 'castelfranco in miscano', cap: '82022', provinceId: 75},
{id: 5396, value: 'san gregorio da sassola', cap: '00010', provinceId: 65},
{id: 7243, value: 'sant ilario dello ionio', cap: '89040', provinceId: 88},
{id: 7255, value: 'terranova sappo minulio', cap: '89010', provinceId: 88},
{id: 7055, value: 'santa caterina albanese', cap: '87010', provinceId: 86},
{id: 2586, value: 'scandolara ripa d oglio', cap: '26047', provinceId: 17},
{id: 2825, value: 'terranova dei passerini', cap: '26827', provinceId: 20},
{id: 3904, value: 'san floriano del collio', cap: '34070', provinceId: 31},
{id: 2911, value: 'san martino in passiria', cap: '39010', provinceId: 21},
{id: 4703, value: 'montopoli in val d arno', cap: '56020', provinceId: 52},
{id: 3504, value: 'moriago della battaglia', cap: '31010', provinceId: 26},
{id: 6522, value: 'sicignano degli alburni', cap: '84029', provinceId: 78},
{id: 3506, value: 'nervesa della battaglia', cap: '31040', provinceId: 26},
{id: 2667, value: 'san martino dall argine', cap: '46010', provinceId: 18},
{id: 878, value: 'borgoratto alessandrino', cap: '15013', provinceId: 6},
{id: 6147, value: 'san bartolomeo in galdo', cap: '82028', provinceId: 75},
{id: 5831, value: 'sant eusanio del sangro', cap: '66037', provinceId: 71},
{id: 5922, value: 'santa croce di magliano', cap: '86047', provinceId: 72},
{id: 5963, value: 'montenero val cocchiara', cap: '86080', provinceId: 73},
{id: 7524, value: 'santa domenica vittoria', cap: '98030', provinceId: 93},
{id: 3672, value: 'santa giustina in colle', cap: '35010', provinceId: 28},
{id: 7676, value: 'san gregorio di catania', cap: '95027', provinceId: 97},
{id: 5968, value: 'pettoranello del molise', cap: '86090', provinceId: 73},
{id: 3567, value: 'fossalta di portogruaro', cap: '30025', provinceId: 27},
{id: 6192, value: 'castellammare di stabia', cap: '80053', provinceId: 76},
{id: 3437, value: 'santo stefano di cadore', cap: '32045', provinceId: 25},
{id: 6168, value: 'sant arcangelo trimonte', cap: '82021', provinceId: 75},
{id: 7677, value: 'san michele di ganzaria', cap: '95040', provinceId: 97},
{id: 6284, value: 'castelvetere sul calore', cap: '83040', provinceId: 77},
{id: 9469, value: 'st christina in groeden', cap: '39047', provinceId: 21},
{id: 4156, value: 'san colombano certenoli', cap: '16040', provinceId: 36},
{id: 9464, value: 'st leonhard in passeier', cap: '39015', provinceId: 21},
{id: 2130, value: 'calvagese della riviera', cap: '25080', provinceId: 15},
{id: 3313, value: 'grumolo delle abbadesse', cap: '36040', provinceId: 24},
{id: 6328, value: 'pago del vallo di lauro', cap: '83020', provinceId: 77},
{id: 4409, value: 'castel san pietro terme', cap: '40024', provinceId: 42},
{id: 1810, value: 'san colombano al lambro', cap: '20078', provinceId: 13},
{id: 6507, value: 'sant angelo a fasanella', cap: '84027', provinceId: 78},
{id: 6693, value: 'san pancrazio salentino', cap: '72026', provinceId: 82},
{id: 4859, value: 'monte castello di vibio', cap: '06057', provinceId: 57},
{id: 5186, value: 'castiglione in teverina', cap: '01024', provinceId: 63},
{id: 557, value: 'sant ambrogio di torino', cap: '10057', provinceId: 1},
{id: 4512, value: 'portico e san benedetto', cap: '47010', provinceId: 45},
{id: 1714, value: 'cassinetta di lugagnano', cap: '20081', provinceId: 13},
{id: 84, value: 'granozzo con monticello', cap: '28060', provinceId: 3},
{id: 2446, value: 'santa maria della versa', cap: '27047', provinceId: 16},
{id: 4352, value: 'concordia sulla secchia', cap: '41033', provinceId: 41},
{id: 5893, value: 'montefalcone nel sannio', cap: '86033', provinceId: 72},
{id: 2819, value: 'santo stefano lodigiano', cap: '26849', provinceId: 20},
{id: 5632, value: 'san demetrio ne vestini', cap: '67028', provinceId: 68},
{id: 7810, value: 'sant antonio di gallura', cap: '07030', provinceId: 104},
{id: 3956, value: 'san vito al tagliamento', cap: '33078', provinceId: 33},
{id: 556, value: 'san secondo di pinerolo', cap: '10060', provinceId: 1},
{id: 7525, value: 'sant agata di militello', cap: '98076', provinceId: 93},
{id: 4157, value: 'santa margherita ligure', cap: '16038', provinceId: 36},
{id: 5919, value: 'san martino in pensilis', cap: '86046', provinceId: 72},
{id: 5917, value: 'san giuliano del sannio', cap: '86010', provinceId: 72},
{id: 1386, value: 'montegrino valtravaglia', cap: '21010', provinceId: 10},
{id: 4492, value: 'sant agata sul santerno', cap: '48020', provinceId: 44},
{id: 7589, value: 'santo stefano quisquina', cap: '92020', provinceId: 94},
{id: 7376, value: 'campofelice di roccella', cap: '90010', provinceId: 92},
{id: 6870, value: 'san costantino albanese', cap: '85030', provinceId: 84},
{id: 3241, value: 'san martino buon albergo', cap: '37036', provinceId: 23},
{id: 2439, value: 'san giorgio di lomellina', cap: '27020', provinceId: 16},
{id: 4868, value: 'passignano sul trasimeno', cap: '06065', provinceId: 57},
{id: 6933, value: 'alessandria del carretto', cap: '87070', provinceId: 86},
{id: 3849, value: 'san giovanni al natisone', cap: '33048', provinceId: 30},
{id: 1915, value: 'castione della presolana', cap: '24020', provinceId: 14},
{id: 6352, value: 'sant angelo dei lombardi', cap: '83054', provinceId: 77},
{id: 2576, value: 'rivarolo del re ed uniti', cap: '26036', provinceId: 17},
{id: 1013, value: 'san salvatore monferrato', cap: '15046', provinceId: 6},
{id: 2781, value: 'castelnuovo bocca d adda', cap: '26843', provinceId: 20},
{id: 3723, value: 'giacciano con baruchella', cap: '45020', provinceId: 29},
{id: 4587, value: 'san romano in garfagnana', cap: '55038', provinceId: 48},
{id: 7523, value: 'san salvatore di fitalia', cap: '98070', provinceId: 93},
{id: 6126, value: 'ginestra degli schiavoni', cap: '82020', provinceId: 75},
{id: 2908, value: 'san leonardo in passiria', cap: '39015', provinceId: 21},
{id: 5691, value: 'sant egidio alla vibrata', cap: '64016', provinceId: 69},
{id: 2341, value: 'castelletto di branduzzo', cap: '27040', provinceId: 16},
{id: 6415, value: 'castiglione del genovesi', cap: '84090', provinceId: 78},
{id: 6554, value: 'castelnuovo della daunia', cap: '71034', provinceId: 79},
{id: 65, value: 'castelletto sopra ticino', cap: '28053', provinceId: 3},
{id: 2824, value: 'tavazzano con villavesco', cap: '26838', provinceId: 20},
{id: 7206, value: 'marina di gioiosa ionica', cap: '89046', provinceId: 88},
{id: 4541, value: 'santarcangelo di romagna', cap: '47822', provinceId: 46},
{id: 4405, value: 'castel guelfo di bologna', cap: '40023', provinceId: 42},
{id: 1563, value: 'san nazzaro val cavargna', cap: '22010', provinceId: 11},
{id: 2444, value: 'sant alessio con vialone', cap: '27016', provinceId: 16},
{id: 5326, value: 'castel san pietro romano', cap: '00030', provinceId: 65},
{id: 4559, value: 'villafranca in lunigiana', cap: '54028', provinceId: 47},
{id: 6553, value: 'castelluccio valmaggiore', cap: '71020', provinceId: 79},
{id: 1987, value: 'monasterolo del castello', cap: '24060', provinceId: 14},
{id: 3673, value: 'santa margherita d adige', cap: '35040', provinceId: 28},
{id: 5161, value: 'san benedetto del tronto', cap: '63074', provinceId: 62},
{id: 6288, value: 'chiusano di san domenico', cap: '83040', provinceId: 77},
{id: 4959, value: 'montemaggiore al metauro', cap: '61030', provinceId: 59},
{id: 5516, value: 'san donato val di comino', cap: '03046', provinceId: 67},
{id: 9389, value: 'eppan an der weinstrasse', cap: '39057', provinceId: 21},
{id: 6069, value: 'santa maria capua vetere', cap: '81055', provinceId: 74},
{id: 2442, value: 'santa cristina e bissone', cap: '27010', provinceId: 16},
{id: 6582, value: 'san ferdinando di puglia', cap: '76017', provinceId: 110},
{id: 1344, value: 'curiglia con monteviasco', cap: '21010', provinceId: 10},
{id: 4801, value: 'castiglione della pescaia', cap: '58043', provinceId: 55},
{id: 6238, value: 'san sebastiano al vesuvio', cap: '80040', provinceId: 76},
{id: 4540, value: 'san giovanni in marignano', cap: '47842', provinceId: 46},
{id: 1562, value: 'san fermo della battaglia', cap: '22020', provinceId: 11},
{id: 257, value: 'monasterolo di savigliano', cap: '12030', provinceId: 4},
{id: 3533, value: 'san zenone degli ezzelini', cap: '31020', provinceId: 26},
{id: 1390, value: 'oggiona con santo stefano', cap: '21040', provinceId: 10},
{id: 5636, value: 'santo stefano di sessanio', cap: '67020', provinceId: 68},
{id: 5637, value: 'san vincenzo valle roveto', cap: '67050', provinceId: 68},
{id: 7060, value: 'santo stefano di rogliano', cap: '87056', provinceId: 86},
{id: 5631, value: 'san benedetto in perillis', cap: '67020', provinceId: 68},
{id: 4570, value: 'castiglione di garfagnana', cap: '55033', provinceId: 48},
{id: 3776, value: 'colloredo di monte albano', cap: '33010', provinceId: 30},
{id: 3536, value: 'sernaglia della battaglia', cap: '31020', provinceId: 26},
{id: 2989, value: 'castello molina di fiemme', cap: '38030', provinceId: 22},
{id: 9481, value: 'tramin an der weinstrasse', cap: '39040', provinceId: 21},
{id: 4700, value: 'montecatini val di cecina', cap: '56040', provinceId: 52},
{id: 7532, value: 'santo stefano di camastra', cap: '98077', provinceId: 93},
{id: 6334, value: 'prata di principato ultra', cap: '83030', provinceId: 77},
{id: 5766, value: 'castiglione messer marino', cap: '66033', provinceId: 71},
{id: 4569, value: 'castelnuovo di garfagnana', cap: '55032', provinceId: 48},
{id: 7609, value: 'santa caterina villarmosa', cap: '93018', provinceId: 95},
{id: 5048, value: 'castelsantangelo sul nera', cap: '62039', provinceId: 61},
{id: 7446, value: 'barcellona pozzo di gotto', cap: '98051', provinceId: 93},
{id: 7724, value: 'portopalo di capo passero', cap: '96010', provinceId: 99},
{id: 4193, value: 'ricco del golfo di spezia', cap: '19020', provinceId: 37},
{id: 2664, value: 'san giacomo delle segnate', cap: '46020', provinceId: 18},
{id: 5046, value: 'camporotondo di fiastrone', cap: '62020', provinceId: 61},
{id: 6343, value: 'san martino valle caudina', cap: '83018', provinceId: 77},
{id: 2510, value: 'casale cremasco vidolasco', cap: '26010', provinceId: 17},
{id: 7242, value: 'sant eufemia d aspromonte', cap: '89027', provinceId: 88},
{id: 4442, value: 'san giovanni in persiceto', cap: '40017', provinceId: 42},
{id: 5770, value: 'civitella messer raimondo', cap: '66010', provinceId: 71},
{id: 6104, value: 'campoli del monte taburno', cap: '82030', provinceId: 75},
{id: 5520, value: 'sant andrea del garigliano', cap: '03040', provinceId: 67},
{id: 6821, value: 'castronuovo di sant andrea', cap: '85030', provinceId: 84},
{id: 2459, value: 'torre beretti e castellaro', cap: '27030', provinceId: 16},
{id: 2625, value: 'castiglione delle stiviere', cap: '46043', provinceId: 18},
{id: 2398, value: 'montebello della battaglia', cap: '27054', provinceId: 16},
{id: 3954, value: 'san martino al tagliamento', cap: '33098', provinceId: 33},
{id: 4736, value: 'civitella in val di chiana', cap: '52041', provinceId: 53},
{id: 7799, value: 'trinita d agultu e vignola', cap: '07038', provinceId: 104},
{id: 5162, value: 'santa vittoria in matenano', cap: '63854', provinceId: 109},
{id: 5498, value: 'monte san giovanni campano', cap: '03025', provinceId: 67},
{id: 4862, value: 'monte santa maria tiberina', cap: '06010', provinceId: 57},
{id: 7241, value: 'sant alessio in aspromonte', cap: '89050', provinceId: 88},
{id: 6455, value: 'montesano sulla marcellana', cap: '84033', provinceId: 78},
{id: 3667, value: 'san giorgio delle pertiche', cap: '35010', provinceId: 28},
{id: 3585, value: 'san michele al tagliamento', cap: '30028', provinceId: 27},
{id: 7140, value: 'santa caterina dello ionio', cap: '88060', provinceId: 87},
{id: 3696, value: 'villanova di camposampiero', cap: '35010', provinceId: 28},
{id: 2913, value: 'santa cristina val gardena', cap: '39047', provinceId: 21},
{id: 9400, value: 'kaltern an der weinstrasse', cap: '39052', provinceId: 21},
{id: 7587, value: 'santa margherita di belice', cap: '92018', provinceId: 94},
{id: 6110, value: 'castelvetere in val fortore', cap: '82023', provinceId: 75},
{id: 9410, value: 'kurtinig an der weinstrasse', cap: '39040', provinceId: 21},
{id: 2874, value: 'magre sulla strada del vino', cap: '39040', provinceId: 21},
{id: 9430, value: 'margreid an der weinstrasse', cap: '39040', provinceId: 21},
{id: 5915, value: 'san giacomo degli schiavoni', cap: '86030', provinceId: 72},
{id: 1560, value: 'san bartolomeo val cavargna', cap: '22010', provinceId: 11},
{id: 5828, value: 'san martino sulla marrucina', cap: '66010', provinceId: 71},
{id: 2544, value: 'grumello cremonese ed uniti', cap: '26023', provinceId: 17},
{id: 7244, value: 'santo stefano in aspromonte', cap: '89057', provinceId: 88},
{id: 4651, value: 'san casciano in val di pesa', cap: '50026', provinceId: 50},
{id: 7239, value: 'santa cristina d aspromonte', cap: '89056', provinceId: 88},
{id: 4440, value: 'san benedetto val di sambro', cap: '40048', provinceId: 42},
{id: 6132, value: 'montefalcone di val fortore', cap: '82025', provinceId: 75},
{id: 7659, value: 'militello in val di catania', cap: '95043', provinceId: 97},
{id: 5666, value: 'castiglione messer raimondo', cap: '64034', provinceId: 69},
{id: 6674, value: 'san marzano di san giuseppe', cap: '74020', provinceId: 81},
{id: 2445, value: 'santa margherita di staffora', cap: '27050', provinceId: 16},
{id: 5271, value: 'monte san giovanni in sabina', cap: '02040', provinceId: 64},
{id: 3417, value: 'livinallongo del col di lana', cap: '32020', provinceId: 25},
{id: 2525, value: 'corte de cortesi con cignone', cap: '26020', provinceId: 17},
{id: 5214, value: 'villa san giovanni in tuscia', cap: '01010', provinceId: 63},
{id: 6509, value: 'sant egidio del monte albino', cap: '84010', provinceId: 78},
{id: 9409, value: 'kurtatsch an der weinstrasse', cap: '39040', provinceId: 21},
{id: 4692, value: 'castelnuovo di val di cecina', cap: '56041', provinceId: 52},
{id: 5519, value: 'sant ambrogio sul garigliano', cap: '03040', provinceId: 67},
{id: 3245, value: 'sant ambrogio di valpolicella', cap: '37015', provinceId: 23},
{id: 2844, value: 'caldaro sulla strada del vino', cap: '39052', provinceId: 21},
{id: 3953, value: 'san giorgio della richinvelda', cap: '33095', provinceId: 33},
{id: 2925, value: 'termeno sulla strada del vino', cap: '39040', provinceId: 21},
{id: 3674, value: 'sant angelo di piove di sacco', cap: '35020', provinceId: 28},
{id: 2051, value: 'sotto il monte giovanni xxiii', cap: '24039', provinceId: 14},
{id: 2833, value: 'appiano sulla strada del vino', cap: '39057', provinceId: 21},
{id: 5679, value: 'isola del gran sasso d italia', cap: '64045', provinceId: 69},
{id: 2854, value: 'cortina sulla strada del vino', cap: '39040', provinceId: 21},
{id: 4055, value: 'castelvecchio di rocca barbena', cap: '17034', provinceId: 35},
{id: 3914, value: 'san dorligo della valle dolina', cap: '34018', provinceId: 32},
{id: 5649, value: 'villa santa lucia degli abruzzi', cap: '67020', provinceId: 68},
{id: 2853, value: 'cortaccia sulla strada del vino', cap: '39040', provinceId: 21},
{id: 7141, value: 'sant andrea apostolo dello ionio', cap: '88060', provinceId: 87},
{id: 4497, value: 'castrocaro terme e terra del sole', cap: '47011', provinceId: 45},
{id: 5738, value: 'san valentino in abruzzo citeriore', cap: '65020', provinceId: 70},
{id: 9385, value: 'unsere liebe frau im walde st felix', cap: '39010', provinceId: 21},
{id: 1395, value: 'pino sulla sponda del lago maggiore', cap: '21010', provinceId: 10}
]