import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NewslettersComponent } from './components/newsletters.component';
import { NewslettersCreateComponent } from './components/newsletters-create.component';
import { NewslettersListComponent } from './components/newsletters-list.component';

import { NewslettersResolve } from './services/newsletters-resolve.service';
import { AuthGuardService } from '../../shared/services/auth-guard.service';

const newslettersRoutes: Routes = [
  {
    path: 'newsletters/create',
    component: NewslettersCreateComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'newsletters',
    redirectTo: '/newsletters/list',
    pathMatch: 'full'
  },
  {
    path: 'newsletters',
    component: NewslettersComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'list',
        component: NewslettersListComponent,
        canActivate: [AuthGuardService],
        resolve: {
              newsletters: NewslettersResolve
            }
      }
    ]
  }
];

export const newslettersRouting: ModuleWithProviders = RouterModule.forChild(newslettersRoutes);
