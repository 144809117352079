import { Component, Input } from '@angular/core';

@Component({
  selector: 'sidebar-links',
  templateUrl: './sidebar-links.component.html'
})

export class SidebarLinksComponent {
    @Input() classAttributes: string;
}
