import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Locations } from '../models/locations.model';
import { LocationsService } from '../services/locations.service';

@Component({
  selector: 'locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})

export class LocationsComponent implements OnInit {

  public locations: Locations[] = [];
  public lat = 43.2984268;
  public lng = 13.4534767;

  constructor(private route: ActivatedRoute,
              private locationsService: LocationsService) {}

  ngOnInit() {
    this.route.data.forEach((data: { locations: Locations[] }) => {
        this.locations = this.locationsService.castingStringToNumber(data.locations);
    });
  }

  refreshMap() {
    this.locationsService.locations().subscribe(
      (data: any) => {
        this.locations = this.locationsService.castingStringToNumber(data);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
