import { Component } from '@angular/core';

@Component({
  selector: 'not-found',
  template: `
    <div class="col-xs-4 col-xs-offset-4">
      <h2>Page you are searching for does not exist!</h2>
    </div>
  `
})

export class NotFoundComponent {}
