import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { NumberDisplayModule } from '../../shared/modules/number-display/number-display.module';
import { TabModule } from '../../shared/modules/tab/tab.module';

import { StatsComponent } from './components/stats.component';
import { StatsOverviewComponent } from './components/stats-overview.component';
import { StatsSessionComponent } from './components/stats-session.component';

import { StatsOverviewService } from './services/stats-overview.service';
import { StatsOverviewResolve } from './services/stats-overview-resolve.service';
import { StatsSessionService } from './services/stats-session.service';
import { StatsSessionResolve } from './services/stats-session-resolve.service';

import { statsRouting } from './stats.routing';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ChartsModule,
    statsRouting,
    NumberDisplayModule,
    TabModule
  ],
  declarations: [
    StatsComponent,
    StatsOverviewComponent,
    StatsSessionComponent,
  ],
  providers: [
      StatsOverviewService,
      StatsOverviewResolve,
      StatsSessionService,
      StatsSessionResolve
  ]
})
export class StatsModule {}
