import { Component, OnInit } from '@angular/core';
import { FaqCreate } from '../models/faq-create.model';
import { Faq } from '../models/faq.model';
import { FaqsService } from '../services/faqs.service';
import { NotificationsService } from 'angular2-notifications';
import { not_options } from '../../../shared/models/notification-options';
import { FaqProfileType } from '../models/faq-profile-type.model';

@Component({
  selector: 'create-faq',
  templateUrl: './faq-create.component.html',
  styleUrls: ['./faq-create.component.css']
})

export class FaqCreateComponent implements OnInit {

  faq: FaqCreate = new FaqCreate();
  faqToUpdate: Faq = new Faq();
  faqs: Faq[] = [];
  map: { [key: number]: boolean; } = {};
  id: number;
  isIdSet = false;
  error: any;
  isLoading = false;
  titlePop = 'Are you sure?';
  messagePop = 'This faq is going to be deleted';
  faqCategories: any[];
  faqProfileTypes: FaqProfileType[];

  constructor(
    private faqsService: FaqsService,
    private notificationsService: NotificationsService) {
    }

    ngOnInit() {
      this.faqCategories = this.faqsService.getFaqCategories();
      this.faqProfileTypes = this.faqsService.getFaqProfileTypes();
      this.faq.target = this.faqProfileTypes[0].value;
      this.faq.category = this.faqCategories[0].value;
    }

  createFaq() {
    this.isLoading = true;
    this.faqsService.createFaq(this.faq)
                    .subscribe(
                      (response: any) => {
                          this.id = response.id;
                          this.isIdSet = true;
                          this.notificationsService.success('Faq created',
                          'Check out the list', not_options);
                      },
                      (error) => {
                          this.error = error;
                          this.isLoading = false;
                          this.notificationsService.error('Faq has not been created',
                          'An error occured during interaction with the server', not_options);
                      },
                      () => {
                          this.isLoading = false;
                      }
                    );
  }

  public getFaq() {
    this.faqsService.getFaq(this.id).subscribe(
      (response) => {
        this.faqs.push(response);
        this.map[response.id] = true;
      },
      (error) => {
        this.notificationsService.error('Error in retrieving faq',
          'An error occured during interaction with the server', not_options);
      },
      () => {
        this.isIdSet = false;
      }
    );
  }

  public setId(id: number): void {
    this.map[id] = !this.map[id];
  }

  public getId(id: number): boolean {
    return this.map[id];
  }

  public onConfirm(faq: Faq) {
    this.faqsService.removeFaq(faq.id).subscribe(
      () => {
        const index: number = this.faqs.indexOf(faq);
        if (index > -1) {
          this.faqs.splice(index, 1);
          this.map[faq.id] = false;
        }
      },
      (error) => {
        this.notificationsService.error('Faq has not been created',
          'An error occured during interaction with the server', not_options);
      },
      () => {
        this.id = undefined;
      }
    );
  }

  public onCancel() {

  }

  public setFaqToUpdate(faq: Faq) {
    this.faqToUpdate = faq;
  }

  public onSaveChanges(faq: Faq) {
    if (faq !== undefined) {
      const faqToUpdate = this.faqs.find((currentFaq) => currentFaq.id === faq.id);
      const index: number = this.faqs.indexOf(faqToUpdate);
      if (index > -1) {
        this.faqs.splice(index, 1, faq);
      }
    } else {
      this.notificationsService.error('Faq has not been updated',
          'An error occured during the interaction with the server', not_options);
    }
  }
}
