import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FaqsService } from '../../faqs/services/faqs.service';
import { Faq } from '../../faqs/models/faq.model';

@Component({
    selector: 'modal-update',
    templateUrl: './modal-update.component.html',
    styleUrls: ['./modal-update.component.css']
})
export class ModalUpdateComponent {
    @Output() onSaveChanges = new EventEmitter<Faq>();

    public faqToUpdate: Faq = new Faq();

    constructor(private faqsService: FaqsService) {
    }

    @Input()
    set faq(faq: Faq) {
        if (faq !== undefined) {
            // clone the object
            this.faqToUpdate = JSON.parse(JSON.stringify(faq));
        }
    }

    get faq() { return this.faqToUpdate; }

    public update() {
        this.faqsService.updateFaq(this.faqToUpdate)
                        .subscribe(
                                (success) => {
                                    this.faqToUpdate = success;
                                },
                                (error) => {
                                    this.faqToUpdate = undefined;
                                },
                                () => {
                                    this.onSaveChanges.emit(this.faqToUpdate);
                                }
                        );
    }
}
