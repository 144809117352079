import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CandidaciesService } from '../services/candidacies.service';
import { Announcement } from '../../announcements-approval/models/announcement.model';

import { PROFILE_TYPES } from '../../../shared/mocks/profile-types.mock';
import { CATEGORIES } from '../../../shared/mocks/categories.mock';
import { ROLES } from '../../../shared/mocks/roles.mock';
import { Observable } from 'rxjs';
import { MunicipalityService } from 'app/shared/services/municipality.service';
import { FirestoreAnnouncement } from 'app/shared/models/firestore/announcement/announcement.model';

@Component({
    selector: 'modal-announcement',
    templateUrl: './modal-announcement.component.html'
})
export class ModalAnnouncementComponent implements OnInit {

    constructor(private candidaciesService: CandidaciesService,
        private municipalityService: MunicipalityService) {}

    @Output() onClose = new EventEmitter<boolean>();
    public appendedTargets = '';
    public appendedRoles = '';
    public appendedCategories = '';
    public announcement: FirestoreAnnouncement;
    public municipalityObservable: Observable<any>;

    ngOnInit() {
        this.candidaciesService.getAnnouncementId().subscribe(
            (announcementId: number) => {
                this.candidaciesService.getAnnouncementReferenceCandidacy(announcementId).subscribe(
                    (data: FirestoreAnnouncement) => {
                        this.announcement = data;
                        this.setCommaSeparatedValues();
                    },
                    (error: any) => {
                        console.error('Cannot find announcement id: ' + announcementId + ' candidaciesService. ' + error);
                    }
                );
            },
            (error: any) => {
                console.error('Cannot get announcementId modal announcement in candidacy module');
            }
        );
    }

    onHide() {
        this.onClose.emit(false);
    }

    onHidden() {
        this.onClose.emit(false);
    }

    capitalizeFirst(value: string): string {
        const lowerCase: string = value.toString().toLowerCase();
        return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
    }

    setCommaSeparatedValues() {
        for (let i = 0; i < this.announcement.targets.length; i++) {
            if (i === 0) {
                this.appendedTargets = this.capitalizeFirst(this.announcement.targets[i]);
            } else {
                this.appendedTargets = this.appendedTargets + this.capitalizeFirst(this.announcement.targets[i]);
            }
            if (i !== this.announcement.targets.length - 1) {
                this.appendedTargets = this.appendedTargets + ', ';
            }
        }

        for (let i = 0; i < this.announcement.categories.length; i++) {
            if (i === 0) {
                this.appendedCategories = this.capitalizeFirst(this.announcement.categories[i]);
            } else {
                this.appendedCategories = this.appendedCategories + this.capitalizeFirst(this.announcement.categories[i]);
            }
            if (i !== this.announcement.categories.length - 1) {
                this.appendedCategories = this.appendedCategories + ', ';
            }
        }

        for (let i = 0; i < this.announcement.roles.length; i++) {
            if (i === 0) {
                this.appendedRoles = this.capitalizeFirst(this.announcement.roles[i]);
            } else {
                this.appendedRoles = this.appendedRoles + this.capitalizeFirst(this.announcement.roles[i]);
            }
            if (i !== this.announcement.roles.length - 1) {
                this.appendedRoles = this.appendedRoles + ', ';
            }
        }
    }
}
