export const environment = {
    production: false,
    environment: 'dev',
    algoliaAppId: '1Y7MHH90U2',
    algoliaApiKey: '2f3d2dad6208fdb3c948e7be6198867a',
    mapsApiKey: 'AIzaSyCQCqNbT6aCLlOH8GFYNxCMMqfHPE0iW1E',
    hmr: false,
    firebase: {
        apiKey: 'AIzaSyDgjFAqZ5u1s1Awut-h2fvBMtCaIk7alAA',
        authDomain: 'clubup-dev.firebaseapp.com',
        databaseURL: 'https://clubup-dev.firebaseio.com',
        projectId: 'clubup-dev',
        storageBucket: 'clubup-dev.appspot.com',
        messagingSenderId: '87904803018'
    },
    sentry: null, // https://7b6c7d2358dd4adfbf8a7e02f59e7904@sentry.io/1538998
    logRocket: null, // backoffice-dev
};
