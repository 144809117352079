import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Candidacy } from '../models/candidacy.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CandidaciesService } from '../services/candidacies.service';

@Component({
  selector: 'modal-confirmation',
  templateUrl: './modal-confirmation.component.html'
})

export class ModalConfirmationComponent implements OnInit {

    @ViewChild('modalConfirmation') modalConfirmation: ModalDirective;
    @Output() onConfirmCandidacyOperation = new EventEmitter<boolean>();
    @Output() onClose = new EventEmitter<boolean>();

    public message = 'Default message';
    public modalType = 'approve';
    public candidacy: Candidacy;

    constructor(private candidaciesService: CandidaciesService) {
    }

    ngOnInit() {
        this.candidaciesService.getCandidacyToModal().subscribe(
            (candidacy: any) => {
                this.candidacy = candidacy;
            },
            (error: any) => {
                // console.log("Error in getting candidacy: " + error);
            }
        );
        this.candidaciesService.getModalType().subscribe(
            (modalType: any) => {
                this.modalType = modalType;
                if (this.modalType == 'approve') {
                    this.message = 'Are you sure you want to approve this candidacy?';
                } else if (this.modalType == 'deny') {
                    this.message = 'Are you sure you want to deny this candidacy?';
                }
            },
            (error: any) => {
                // console.log("Error in getting modal type: " + error);
            }
        );
    }

    onConfirm() {
        this.onConfirmCandidacyOperation.emit(true);
        this.onClose.emit(false);
    }

    onHide() {
        this.onClose.emit(false);
    }

    onHidden() {
        this.onClose.emit(false);
    }
}
