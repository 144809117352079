import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../modules/users/services/users.service';
import { UsersData } from '../../modules/users/models/users-data.model';

@Component({
    selector: 'modal-table',
    templateUrl: './modal-table.component.html'
})
export class ModalTableComponent implements OnInit {

    public usersData: UsersData = new UsersData();

    constructor(private usersService: UsersService) {}

    ngOnInit() {
        this.usersService.pushedData.subscribe(
            (data: any) => this.usersData = data
        );
    }

}
