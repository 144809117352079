import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TabModule } from '../../shared/modules/tab/tab.module';
import { Ng2PaginationModule } from 'ng2-pagination';

import { UsersComponent } from './components/users.component';
import { SearchComponent } from './components/search.component';
import { SearchboxComponent } from './components/searchbox.component';
import { SubscriptionsComponent } from './components/subscriptions.component';
import { ModalTableComponent } from '../../shared/components/modal-table.component';
import { InstantSearchComponent } from './components/instantsearch.component';

import { NgAisModule } from 'angular-instantsearch';

import { usersRouting } from './users.routing';

import { AlgoliaService } from './services/algolia.service';
import { UsersService } from './services/users.service';
import { SubscriptionsResolve } from './services/subscriptions-resolve.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TabModule,
    Ng2PaginationModule,
    usersRouting,
    NgAisModule
  ],
  declarations: [
    UsersComponent,
    SearchboxComponent,
    SearchComponent,
    SubscriptionsComponent,
    ModalTableComponent,
    InstantSearchComponent
  ],
  providers: [
    SubscriptionsResolve,
    UsersService,
    AlgoliaService
  ]
})
export class UsersModule {}
