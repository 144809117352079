import { Province } from './../models/province.model';

export const PROVINCES: Province[] = [
    {id: 1, value: 'torino', abbreviation: 'to', regionId: 1},
    {id: 2, value: 'vercelli', abbreviation: 'vc', regionId: 1},
    {id: 3, value: 'novara', abbreviation: 'no', regionId: 1},
    {id: 4, value: 'cuneo', abbreviation: 'cn', regionId: 1},
    {id: 5, value: 'asti', abbreviation: 'at', regionId: 1},
    {id: 6, value: 'alessandria', abbreviation: 'al', regionId: 1},
    {id: 7, value: 'biella', abbreviation: 'bi', regionId: 1},
    {id: 8, value: 'verbano cusio ossola', abbreviation: 'vb', regionId: 1},
    {id: 9, value: 'aosta', abbreviation: 'ao', regionId: 2},
    {id: 10, value: 'varese', abbreviation: 'va', regionId: 3},
    {id: 11, value: 'como', abbreviation: 'co', regionId: 3},
    {id: 12, value: 'sondrio', abbreviation: 'so', regionId: 3},
    {id: 13, value: 'milano', abbreviation: 'mi', regionId: 3},
    {id: 14, value: 'bergamo', abbreviation: 'bg', regionId: 3},
    {id: 15, value: 'brescia', abbreviation: 'bs', regionId: 3},
    {id: 16, value: 'pavia', abbreviation: 'pv', regionId: 3},
    {id: 17, value: 'cremona', abbreviation: 'cr', regionId: 3},
    {id: 18, value: 'mantova', abbreviation: 'mn', regionId: 3},
    {id: 19, value: 'lecco', abbreviation: 'lc', regionId: 3},
    {id: 20, value: 'lodi', abbreviation: 'lo', regionId: 3},
    {id: 21, value: 'bolzano', abbreviation: 'bz', regionId: 4},
    {id: 22, value: 'trento', abbreviation: 'tn', regionId: 4},
    {id: 23, value: 'verona', abbreviation: 'vr', regionId: 5},
    {id: 24, value: 'vicenza', abbreviation: 'vi', regionId: 5},
    {id: 25, value: 'belluno', abbreviation: 'bl', regionId: 5},
    {id: 26, value: 'treviso', abbreviation: 'tv', regionId: 5},
    {id: 27, value: 'venezia', abbreviation: 've', regionId: 5},
    {id: 28, value: 'padova', abbreviation: 'pd', regionId: 5},
    {id: 29, value: 'rovigo', abbreviation: 'ro', regionId: 5},
    {id: 30, value: 'udine', abbreviation: 'ud', regionId: 6},
    {id: 31, value: 'gorizia', abbreviation: 'go', regionId: 6},
    {id: 32, value: 'trieste', abbreviation: 'ts', regionId: 6},
    {id: 33, value: 'pordenone', abbreviation: 'pn', regionId: 6},
    {id: 34, value: 'imperia', abbreviation: 'im', regionId: 7},
    {id: 35, value: 'savona', abbreviation: 'sv', regionId: 7},
    {id: 36, value: 'genova', abbreviation: 'ge', regionId: 7},
    {id: 37, value: 'la spezia', abbreviation: 'sp', regionId: 7},
    {id: 38, value: 'piacenza', abbreviation: 'pc', regionId: 8},
    {id: 39, value: 'parma', abbreviation: 'pr', regionId: 8},
    {id: 40, value: 'reggio emilia', abbreviation: 're', regionId: 8},
    {id: 41, value: 'modena', abbreviation: 'mo', regionId: 8},
    {id: 42, value: 'bologna', abbreviation: 'bo', regionId: 8},
    {id: 43, value: 'ferrara', abbreviation: 'fe', regionId: 8},
    {id: 44, value: 'ravenna', abbreviation: 'ra', regionId: 8},
    {id: 45, value: 'forli cesena', abbreviation: 'fc', regionId: 8},
    {id: 46, value: 'rimini', abbreviation: 'rn', regionId: 8},
    {id: 47, value: 'massa', abbreviation: 'ms', regionId: 9},
    {id: 48, value: 'lucca', abbreviation: 'lu', regionId: 9},
    {id: 49, value: 'pistoia', abbreviation: 'pt', regionId: 9},
    {id: 50, value: 'firenze', abbreviation: 'fi', regionId: 9},
    {id: 51, value: 'livorno', abbreviation: 'li', regionId: 9},
    {id: 52, value: 'pisa', abbreviation: 'pi', regionId: 9},
    {id: 53, value: 'arezzo', abbreviation: 'ar', regionId: 9},
    {id: 54, value: 'siena', abbreviation: 'si', regionId: 9},
    {id: 55, value: 'grosseto', abbreviation: 'gr', regionId: 9},
    {id: 56, value: 'prato', abbreviation: 'po', regionId: 9},
    {id: 57, value: 'perugia', abbreviation: 'pg', regionId: 10},
    {id: 58, value: 'terni', abbreviation: 'tr', regionId: 10},
    {id: 59, value: 'pesaro e urbino', abbreviation: 'pu', regionId: 11},
    {id: 60, value: 'ancona', abbreviation: 'an', regionId: 11},
    {id: 61, value: 'macerata', abbreviation: 'mc', regionId: 11},
    {id: 62, value: 'ascoli piceno', abbreviation: 'ap', regionId: 11},
    {id: 63, value: 'viterbo', abbreviation: 'vt', regionId: 12},
    {id: 64, value: 'rieti', abbreviation: 'ri', regionId: 12},
    {id: 65, value: 'roma', abbreviation: 'rm', regionId: 12},
    {id: 66, value: 'latina', abbreviation: 'lt', regionId: 12},
    {id: 67, value: 'frosinone', abbreviation: 'fr', regionId: 12},
    {id: 68, value: 'l \`aquila', abbreviation: 'aq', regionId: 13},
    {id: 69, value: 'teramo', abbreviation: 'te', regionId: 13},
    {id: 70, value: 'pescara', abbreviation: 'pe', regionId: 13},
    {id: 71, value: 'chieti', abbreviation: 'ch', regionId: 13},
    {id: 72, value: 'campobasso', abbreviation: 'cb', regionId: 14},
    {id: 73, value: 'isernia', abbreviation: 'is', regionId: 14},
    {id: 74, value: 'caserta', abbreviation: 'ce', regionId: 15},
    {id: 75, value: 'benevento', abbreviation: 'bn', regionId: 15},
    {id: 76, value: 'napoli', abbreviation: 'na', regionId: 15},
    {id: 77, value: 'avellino', abbreviation: 'av', regionId: 15},
    {id: 78, value: 'salerno', abbreviation: 'sa', regionId: 15},
    {id: 79, value: 'foggia', abbreviation: 'fg', regionId: 16},
    {id: 80, value: 'bari', abbreviation: 'ba', regionId: 16},
    {id: 81, value: 'taranto', abbreviation: 'ta', regionId: 16},
    {id: 82, value: 'brindisi', abbreviation: 'br', regionId: 16},
    {id: 83, value: 'lecce', abbreviation: 'le', regionId: 16},
    {id: 84, value: 'potenza', abbreviation: 'pz', regionId: 17},
    {id: 85, value: 'matera', abbreviation: 'mt', regionId: 17},
    {id: 86, value: 'cosenza', abbreviation: 'cs', regionId: 18},
    {id: 87, value: 'catanzaro', abbreviation: 'cz', regionId: 18},
    {id: 88, value: 'reggio calabria', abbreviation: 'rc', regionId: 18},
    {id: 89, value: 'crotone', abbreviation: 'kr', regionId: 18},
    {id: 90, value: 'vibo valentia', abbreviation: 'vv', regionId: 18},
    {id: 91, value: 'trapani', abbreviation: 'tp', regionId: 19},
    {id: 92, value: 'palermo', abbreviation: 'pa', regionId: 19},
    {id: 93, value: 'messina', abbreviation: 'me', regionId: 19},
    {id: 94, value: 'agrigento', abbreviation: 'ag', regionId: 19},
    {id: 95, value: 'caltanissetta', abbreviation: 'cl', regionId: 19},
    {id: 96, value: 'enna', abbreviation: 'en', regionId: 19},
    {id: 97, value: 'catania', abbreviation: 'ct', regionId: 19},
    {id: 98, value: 'ragusa', abbreviation: 'rg', regionId: 19},
    {id: 99, value: 'siracusa', abbreviation: 'sr', regionId: 19},
    {id: 100, value: 'sassari', abbreviation: 'ss', regionId: 20},
    {id: 101, value: 'nuoro', abbreviation: 'nu', regionId: 20},
    {id: 102, value: 'cagliari', abbreviation: 'ca', regionId: 20},
    {id: 103, value: 'oristano', abbreviation: 'or', regionId: 20},
    {id: 104, value: 'olbia tempio', abbreviation: 'ot', regionId: 20},
    {id: 105, value: 'ogliastra', abbreviation: 'og', regionId: 20},
    {id: 106, value: 'medio campidano', abbreviation: 'vs', regionId: 20},
    {id: 107, value: 'carbonia iglesias', abbreviation: 'ci', regionId: 20},
    {id: 108, value: 'monza e della brianza', abbreviation: 'mb', regionId: 3},
    {id: 109, value: 'fermo', abbreviation: 'fm', regionId: 11},
    {id: 110, value: 'barletta andria trani', abbreviation: 'bt', regionId: 16},
    {id: 111, value: 'san marino', abbreviation: 'sm', regionId: 21},
];
