import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap';
import { TabModule } from '../../shared/modules/tab/tab.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AnnouncementsListComponent } from './components/announcements-list.component';
import { AnnouncementItemComponent } from './components/announcement-item.component';
import { ModalConfirmationComponent } from './components/modal-confirmation.component';
import { NoAnnouncementComponent } from './components/no-announcement.component';
import { AnnouncementsApprovalComponent } from './components/announcements-approval.component';

import { AnnouncementsService } from './services/announcements.service';

import { announcementsApprovalRouting } from './announcements-approval.routing';

import { ModalCandidaciesComponent } from './components/modal-candidacies.component';
import { CandidaciesService } from '../candidacies-approval/services/candidacies.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    announcementsApprovalRouting,
    ModalModule.forRoot(),
    TabModule,
    NgxPaginationModule,
    FontAwesomeModule
  ],
  declarations: [
    AnnouncementsApprovalComponent,
    AnnouncementItemComponent,
    AnnouncementsListComponent,
    ModalConfirmationComponent,
    NoAnnouncementComponent,
    ModalCandidaciesComponent
  ],
  providers: [
    AnnouncementsService,
    CandidaciesService,
  ]
})
export class AnnouncementsApprovalModule {}
