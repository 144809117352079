import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { StatsOverviewService } from './stats-overview.service';

@Injectable()
export class StatsOverviewResolve implements Resolve<any> {

  public error: any;

  constructor(private statsOverviewService: StatsOverviewService) {}
  resolve(): Observable<any> | Promise<any> | any {
    return this.statsOverviewService.overview();
  }
}
