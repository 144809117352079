import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AnnouncementsApprovalComponent } from './components/announcements-approval.component';
import { AnnouncementsListComponent } from './components/announcements-list.component';

import { AuthGuardService } from '../../shared/services/auth-guard.service';

const announcementsApprovalRoutes: Routes = [
  {
    path: 'announcementsapproval',
    redirectTo: '/announcementsapproval/all',
    pathMatch: 'full',
  },
  {
    path: 'announcementsapproval',
    component: AnnouncementsApprovalComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: ':filter',
        component: AnnouncementsListComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
];

export const announcementsApprovalRouting: ModuleWithProviders = RouterModule.forChild(announcementsApprovalRoutes);
