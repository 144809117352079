import { Component, OnInit } from '@angular/core';
import { Newsletters } from '../models/newsletters.model';
import { ActivatedRoute } from '@angular/router';
import { NewslettersService } from '../services/newsletters.service';
import { not_options } from '../../../shared/models/notification-options';

@Component({
  selector: 'newsletters-list',
  templateUrl: './newsletters-list.component.html'
})

export class NewslettersListComponent implements OnInit {

  public newsletters: Newsletters[] = [];
  public map: { [key: number]: boolean; } = {};

  constructor(private route: ActivatedRoute,
              private newslettersService: NewslettersService) {
  }

  ngOnInit() {
    this.route.data.forEach((data: { newsletters: Newsletters[] }) => {
      this.newsletters = data.newsletters;
      for (let i = 0; i < this.newsletters.length; i++) {
        this.map[this.newsletters[i].id] = true;
      }
    });
  }

  public setId(id: number): void {
    this.map[id] = !this.map[id];
  }

  public getId(id: number): boolean {
    return this.map[id];
  }

  public anyNewsletter(): boolean {
    return this.newsletters.length > 0;
  }
}
