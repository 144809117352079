import { Filter } from '../../../shared/enums/filters.enum';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Candidacy } from '../models/candidacy.model';
import { ActivatedRoute } from '@angular/router';
import { CandidaciesService } from '../services/candidacies.service';
import { NotificationsService } from 'angular2-notifications';
import { not_options } from '../../../shared/models/notification-options';
import { ApprovalPost, ApprovalPostAction } from 'app/shared/models/approval-post.model';
import { Observable, Subject } from 'rxjs';
import { tap, map, switchMap, takeUntil } from 'rxjs/operators';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { DocumentSnapshot, QueryDocumentSnapshot } from '@angular/fire/firestore';

declare let $: any;

@Component({
  selector: 'candidacies-list',
  templateUrl: './candidacies-list.component.html',
  styleUrls: ['./candidacies-list.component.css'],
})
export class CandidaciesListComponent implements OnInit, OnDestroy {
  public candidacies: Candidacy[] = [];
  public modalType = '';
  public candidacyToApproveDeny: Candidacy;
  public showModal = false;
  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  pageNum = 0;

  private filter: Filter;
  destroy$: Subject<boolean> = new Subject<boolean>();
  readonly paginationLenght = this.candidaciesService.paginationLenght;
  obsCandidacies$: Observable<QueryDocumentSnapshot<Candidacy>[]>;

  constructor(
    private route: ActivatedRoute,
    private candidaciesService: CandidaciesService,
    private notificationsService: NotificationsService,
  ) {
    this.obsCandidacies$ = this.route.params.pipe(
      map(params => params.filter),
      tap(filter => (this.filter = filter)),
      switchMap(filter => this.candidaciesService.getAllFirestoreCandidacies(filter)),
    );
  }

  ngOnInit() {
    this.candidaciesService
      .getAnnouncementId()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (announcementId: number) => {
          const modalAnnouncementCandidacyRef = '#modal-announcement';
          $(modalAnnouncementCandidacyRef).modal();
        },
        (error: any) => {
          console.error('candidaciesListComponent - cannot get announcementId modal announcement in candidacy module');
        },
      );
  }

  fetch(lastCandidacy: DocumentSnapshot<Candidacy>, nextPage = true) {
    if (nextPage) {
      this.pageNum++;
    } else {
      this.pageNum--;
    }
    this.obsCandidacies$ = this.candidaciesService
      .getAllFirestoreCandidacies(this.filter, lastCandidacy, nextPage)
      .pipe(tap(candidacies => console.log('candidacies-list.component.ts - new candidacies: ', candidacies)));
  }

  onCloseAnnouncementModal(event: boolean) {
    if (!event) {
      const modalAnnouncementCandidacyRef = '#modal-announcement';
      $(modalAnnouncementCandidacyRef).modal('hide');
    }
  }

  // I think I have to call different APIs for approving and declining an announcement
  onApproveCandidacy(candidacyToApprove: Candidacy) {
    this.modalType = 'approve';
    this.candidaciesService.setCandidacyToModal(candidacyToApprove);
    this.candidaciesService.setModalType(this.modalType);
    this.candidacyToApproveDeny = candidacyToApprove;
    this.openModal();
  }

  // I think I have to call different APIs for approving and declining an announcement
  onDenyCandidacy(candidacyToDeny: Candidacy) {
    this.modalType = 'deny';
    this.candidaciesService.setCandidacyToModal(candidacyToDeny);
    this.candidaciesService.setModalType(this.modalType);
    this.candidacyToApproveDeny = candidacyToDeny;
    this.openModal();
  }

  openModal() {
    this.showModal = true;
    const modalConfirmationCandidacyRef = '#modal';
    $(modalConfirmationCandidacyRef).modal();
  }

  onConfirmCandidacyOperation(operationConfirmed: boolean) {
    let candidacyApprovalPost: ApprovalPost;
    if (this.modalType === 'approve') {
      candidacyApprovalPost = {
        action: ApprovalPostAction.approve,
      };
      this.candidaciesService
        .postCandidacy(this.candidacyToApproveDeny, candidacyApprovalPost)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: any) => {
            this.notificationsService.success(
              'Candidacy approved',
              'Check the public site out to seeing it',
              not_options,
            );
          },
          (error: any) => {
            this.notificationsService.error(
              'Ooops',
              'An error occured during interaction with the server; candidacy not approved',
              not_options,
            );
          },
        );
    } else if (this.modalType === 'deny') {
      candidacyApprovalPost = {
        action: ApprovalPostAction.reject,
      };
      this.candidaciesService
        .postCandidacy(this.candidacyToApproveDeny, candidacyApprovalPost)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: any) => {
            this.notificationsService.success(
              'Candidacy rejected',
              'It should be on Declined tab in this page',
              not_options,
            );
          },
          (error: any) => {
            console.log('Error: ' + error);
            this.notificationsService.error(
              'Ooops',
              'An error occured during interaction with the server; candidacy not rejected',
              not_options,
            );
          },
        );
    }
  }

  onCloseModal(event: boolean) {
    if (!event) {
      const modalConfirmationCandidacyRef = '#modal';
      $(modalConfirmationCandidacyRef).modal('hide');
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
