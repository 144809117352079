import { Component, Input } from '@angular/core';
import { Tab } from '../models/tab.model';

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html'
})

export class TabsComponent {

  @Input() tabs: Tab[];

  constructor() {}

}
