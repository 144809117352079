import { Component, OnInit } from '@angular/core';
import { Faq } from '../models/faq.model';
import { ActivatedRoute } from '@angular/router';
import { FaqsService } from '../services/faqs.service';
import { NotificationsService } from 'angular2-notifications';
import { not_options } from '../../../shared/models/notification-options';

@Component({
  selector: 'faqs-target',
  templateUrl: './faqs-target.component.html'
})

export class FaqsTargetComponent implements OnInit {

  public faqs: Faq[];
  public faqToUpdate: Faq = new Faq();
  public map: { [key: number]: boolean; } = {};
  public titlePop = 'Are you sure?';
  public messagePop = 'This faq is going to be deleted';
  private isThereAnyFaq = false;
  private target: string;

  constructor(private route: ActivatedRoute,
              private faqsService: FaqsService,
              private notificationsService: NotificationsService) {
  }

  ngOnInit() {
    this.route.data.forEach((data: { faqs: Faq[] }) => {
      this.faqs = data.faqs;

      // value of target is updated each time a new set of faqs is retrieved from the backend (see FaqsService)
      this.target = this.faqsService.getTarget();

      if (this.faqs.length > 0) { this.isThereAnyFaq = true; } else { this.isThereAnyFaq = false; }

      for (let i = 0; i < this.faqs.length; i++) {
        this.map[this.faqs[i].id] = true;
      }
    });
  }

  public setId(id: number): void {
    this.map[id] = !this.map[id];
  }

  public getId(id: number): boolean {
    return this.map[id];
  }

  public onConfirm(faq: Faq) {
    this.faqsService.removeFaq(faq.id).subscribe(
      () => {
        const index: number = this.faqs.indexOf(faq);
        if (index > -1) {
          this.faqs.splice(index, 1);
          this.map[faq.id] = false;
        }
      },
      (error) => {
        this.notificationsService.error('Faq has not been created',
          'An error occured during interaction with the server', not_options);
      }
    );

  }

  public onCancel() {

  }

  public setFaqToUpdate(faq: Faq) {
    this.faqToUpdate = faq;
  }

  public onSaveChanges(faq: Faq) {
    if (faq !== undefined) {
      const faqToUpdate = this.faqs.find((currentFaq) => currentFaq.id === faq.id);
      const index: number = this.faqs.indexOf(faqToUpdate);
      if (index > -1) {
        this.faqs.splice(index, 1, faq);
      }
    } else {
      this.notificationsService.error('Faq has not been updated',
          'An error occured during the interaction with the server', not_options);
    }
  }

  public anyFaq(): boolean {
    return this.isThereAnyFaq;
  }

  /*public collapsed(event:any):void {
    console.log("collapsed: " + event.value);
  }

  public expanded(event:any):void {
    console.log("expanded: " + event.value);
  }*/
}
