import { Component } from '@angular/core';
import { Tab } from '../../../shared/modules/tab/models/tab.model';
import { Filter } from '../../../shared/enums/filters.enum';

@Component({
  selector: 'candidaciesapproval',
  templateUrl: './candidacies-approval.component.html'
})
export class CandidaciesApprovalComponent {

  public tabs: Tab[];
  public tabAll: Tab;
  public tabPendingApproval: Tab;
  public tabDeclined: Tab;
  public tabApproved: Tab;

  constructor() {
    this.tabs = [];

    this.tabAll = new Tab();
    this.tabAll.path = '/candidaciesapproval/' + Filter.ALL;
    this.tabAll.name = 'All';

    this.tabPendingApproval = new Tab();
    this.tabPendingApproval.path = '/candidaciesapproval/' + Filter.PENDING_APPROVAL;
    this.tabPendingApproval.name = 'Pending Approval';

    this.tabDeclined = new Tab();
    this.tabDeclined.path = '/candidaciesapproval/' + Filter.DECLINED;
    this.tabDeclined.name = 'Declined';

    this.tabApproved = new Tab();
    this.tabApproved.path = '/candidaciesapproval/' + Filter.APPROVED;
    this.tabApproved.name = 'Approved';

    this.tabs.push(this.tabAll);
    this.tabs.push(this.tabPendingApproval);
    this.tabs.push(this.tabDeclined);
    this.tabs.push(this.tabApproved);
  }
}
