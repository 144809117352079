export class Feature {

    public id: number;
    public key: string;
    public value: number;
    public note: string;
    public created_at: string;
    public updated_at: string;
    public update?: boolean;
}
