import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from './../../modules/main/services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(take(1));
  }

  canActivateChild() {
    return this.canActivate();
  }
}
