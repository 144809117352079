import {Categories} from '../models/categories.model';

export const CATEGORIES: Categories[] = [
    {'id': 2, 'value': 'a1'},
    {'id': 3, 'value': 'a2'},
    {'id': 4, 'value': 'b unica'},
    {'id': 5, 'value': 'b1'},
    {'id': 6, 'value': 'b2'},
    {'id': 7, 'value': 'c'},
    {'id': 8, 'value': 'd'},
    {'id': 10, 'value': 'prima divisione'},
    {'id': 11, 'value': 'seconda divisione'},
    {'id': 13, 'value': 'terza divisione'},
    {'id': 12, 'value': 'giovanili'},
    {'id': 14, 'value': 'csi'},
    {'id': 15, 'value': 'a3'},
];
