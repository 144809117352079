import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { FaqsService } from './faqs.service';

@Injectable()
export class FaqsResolve implements Resolve<any> {

  constructor(private faqsService: FaqsService) {}
  
  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    return this.faqsService.getAllFaqs(route.params['target']);
  }
}
