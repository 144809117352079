import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '../services/users.service';
import { UsersSubscribed } from '../models/users-subscribed.model';
import { UsersData } from '../models/users-data.model';

@Component({
  selector: 'subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.css']
})
export class SubscriptionsComponent implements OnInit {

  public subscriptions: UsersData[];
  public total: number;
  public per_page: number;
  public current_page: number;
  public last_page: number;
  public next_page_url: string;
  public prev_page_url: string;
  public from: number;
  public to: number;

  constructor(private route: ActivatedRoute,
              private usersService: UsersService) {

  }

  ngOnInit() {
    this.route.data.forEach((data: { subscriptions: UsersSubscribed }) => {
      this.total = data.subscriptions.total;
      this.per_page = data.subscriptions.per_page;
      this.current_page = data.subscriptions.current_page;
      this.last_page = data.subscriptions.last_page;
      this.next_page_url = data.subscriptions.next_page_url;
      this.prev_page_url = data.subscriptions.prev_page_url;
      this.from = data.subscriptions.from;
      this.to = data.subscriptions.to;
      this.subscriptions = data.subscriptions.data;
    });
  }

  pageChanged(event: string) {
    this.usersService.getUsers(event).subscribe(
      (subscriptions: any) => {
        this.subscriptions = [];
        this.total = subscriptions.total;
        this.per_page = subscriptions.per_page;
        this.current_page = subscriptions.current_page;
        this.last_page = subscriptions.last_page;
        this.next_page_url = subscriptions.next_page_url;
        this.prev_page_url = subscriptions.prev_page_url;
        this.from = subscriptions.from;
        this.to = subscriptions.to;
        this.subscriptions = subscriptions.data;
      },
      (error: any) => {
        // (error);
      }
    );
  }

  openModal(subscription: UsersData) {
    this.usersService.pushData(subscription);
  }
}
