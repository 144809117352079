import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NewslettersCreate } from '../models/newsletters-create.model';
import { options } from '../../../shared/models/content-headers';
import { Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';

@Injectable()
export class NewslettersService {
  constructor (private httpClient: HttpClient) {}

  public newslettersUrl = '/api/newsletters';  // URL to web API

  public sendEmail(newslettersCreate: NewslettersCreate): Observable<any> {
    const subject: string =  newslettersCreate.subject;
    const body: string = newslettersCreate.body;
    const test: boolean = newslettersCreate.test;
    const target: string = newslettersCreate.target;
    const bodyRequest = JSON.stringify({subject, body, test, target});
    return this.httpClient.post(this.newslettersUrl, bodyRequest, options)
            .pipe(
              catchError(this.handleError)
            );
  }

  public getNewsletters(): Observable<any> {
    return this.httpClient.get(this.newslettersUrl, options)
            .pipe(
              catchError(this.handleError)
            );
  }

  public getNewsletter(id: number): Observable<any> {
    return this.httpClient.get(this.newslettersUrl + '/' + id, options)
              .pipe(
                catchError(this.handleError)
              );
  }

  private handleError (error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }

}
