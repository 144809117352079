import { Pipe, PipeTransform} from '@angular/core';

/*
 * Replace all of occurrences in a string
 * Takes a search argumenti and a replace argument
 * Usage:
 *   value | replace:search:replace
 * Example:
 *   {{ hello-world | replace:"-":" " }}
 *   formats to: hello world
*/
@Pipe({
    name: 'replace'
})

export class ReplacePipe implements PipeTransform {

    transform(string: string, search: string, replace: string): string {
        if (string === null || search === null || replace === null) {
            return string;
        }

        const regExp = new RegExp(search, 'g');
        return string.replace(regExp, replace);

    }
}
