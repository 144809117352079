import { Region } from './../models/region.model';
import { Injectable } from '@angular/core';
import { REGIONS } from '../mocks/regions.mock';

@Injectable({
    providedIn: 'root'
  })
export class RegionService {
    private regions: Region[] = [];

    constructor() {
        this.regions = REGIONS;
    }

    get(id: number): Region | null {
        return this.regions.find(region => region.id === id);
    }
}
