import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap';
import { TabModule } from '../../shared/modules/tab/tab.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { CandidaciesListComponent } from './components/candidacies-list.component';
import { CandidacyItemComponent } from './components/candidacy-item.component';
import { ModalConfirmationComponent } from './components/modal-confirmation.component';
import { ModalAnnouncementComponent } from './components/modal-announcement.component';
import { NoCandidacyComponent } from './components/no-candidacy.component';
import { CandidaciesApprovalComponent } from './components/candidacies-approval.component';

import { CandidaciesService } from './services/candidacies.service';

import { candidaciesApprovalRouting } from './candidacies-approval.routing';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    candidaciesApprovalRouting,
    ModalModule.forRoot(),
    TabModule,
    NgxPaginationModule,
    FontAwesomeModule
  ],
  declarations: [
    CandidacyItemComponent,
    CandidaciesListComponent,
    ModalConfirmationComponent,
    NoCandidacyComponent,
    CandidaciesApprovalComponent,
    ModalAnnouncementComponent
  ],
  providers: [
    CandidaciesService
  ]
})
export class CandidaciesApprovalModule {}
