import { Component } from '@angular/core';
import { NewslettersCreate } from '../models/newsletters-create.model';
import { Newsletters } from '../models/newsletters.model';
import { NewslettersService } from '../services/newsletters.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { not_options } from '../../../shared/models/notification-options';

@Component({
  selector: 'newsletters-create',
  templateUrl: './newsletters-create.component.html'
})

export class NewslettersCreateComponent {

  public newslettersCreate: NewslettersCreate = new NewslettersCreate();
  public error: any;
  public isLoading = false;
  public newsletters: Newsletters[] = [];
  public map: { [key: number]: boolean; } = {};
  public id: number;
  public isIdSet = false;

  constructor(private newslettersService: NewslettersService
                , private notificationsService: NotificationsService) {
                    this.newslettersCreate.target = 'all';
                }

  submitTest() {
    this.newslettersCreate.test = true;
    this.isLoading = true;
    this.newslettersService.sendEmail(this.newslettersCreate)
            .subscribe(
                () => {
                    this.notificationsService.success('Test email sent',
                    'Check your email to see it', not_options);
                },
                error => {
                    this.error = error;
                    this.isLoading = false;
                    this.notificationsService.error('Test email was not sent',
                    'An error occured during interaction with the server', not_options);
                },
                () => {
                    this.isLoading = false;
                }
            );
  }

  submit() {
      this.newslettersCreate.test = false;
      this.isLoading = true;
      this.newslettersService.sendEmail(this.newslettersCreate)
            .subscribe(
                (data: any) => {
                    const html = '<div>Email sent to ' + this.newslettersCreate.target + '<div><hr> '
                                + '<div>Created-at: ' + data.created_at + '</div>'
                                + '<div>Updated-at: ' + data.updated_at + '</div>'
                                + '<div>Id: ' + data.id
                                + '<div>Delivered: ' + data.delivered + '</div>';
                    this.isIdSet = true;
                    this.id = data.id;
                    /*let content:string = 'Created-at: ' + data.created_at
                    + ' *** Updated-at: ' + data.updated_at
                    + ' *** Id: ' + data.id
                    + ' *** Delivered: ' + data.delivered;
                    this.notificationsService.success('Email sent to ' + this.target + ' users',
                    content,not_options);*/
                    this.notificationsService.html(html, NotificationType.Success, not_options);

                },
                error => {
                    this.error = error;
                    this.isLoading = false;
                    // console.log(error.json());
                    this.notificationsService.error('Test email not sent',
                    'An error occured during interaction with the server', not_options);
                },
                () => {
                    this.isLoading = false;
                }
            );
  }

  public getNewsletter() {
    this.newslettersService.getNewsletter(this.id).subscribe(
      (response) => {
        this.newsletters.push(response);
        this.map[response.id] = true;
      },
      (error) => {
        this.notificationsService.error('Error in retrieving faq',
          'An error occured during interaction with the server', not_options);
      },
      () => {
        this.isIdSet = false;
      }
    );
  }

  public setId(id: number): void {
    this.map[id] = !this.map[id];
  }

  public getId(id: number): boolean {
    return this.map[id];
  }
}
