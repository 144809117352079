import { QueryFn, DocumentSnapshot } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FirestoreQueryHelperService {

  constructor() { }

  addLimitClause(query: QueryFn, limit: number): QueryFn {
    return ref => query(ref).limit(limit);
  }

  addStartAfterClause(query: QueryFn, startAfter: any): QueryFn {
    return ref => query(ref).startAfter(startAfter);
  }

  addEndBeforeClause(query: QueryFn, endBefore: any): QueryFn {
    return ref => query(ref).endBefore(endBefore);
  }
}
