import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FaqsComponent } from './components/faqs.component';
import { FaqCreateComponent } from './components/faq-create.component';
import { FaqsTargetComponent } from './components/faqs-target.component';

import { FaqsResolve } from './services/faqs-resolve.service';
import { AuthGuardService } from '../../shared/services/auth-guard.service';

const faqsRoutes: Routes = [
  {
    path: 'faq/create',
    component: FaqCreateComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'faqs',
    redirectTo: '/faqs/all',
    pathMatch: 'full'
  },
  {
    path: 'faqs',
    component: FaqsComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: ':target',
        component: FaqsTargetComponent,
        canActivate: [AuthGuardService],
        resolve: {
              faqs: FaqsResolve
            }
      }
    ]
  }
];

export const faqsRouting: ModuleWithProviders = RouterModule.forChild(faqsRoutes);
