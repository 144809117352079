import { Component, Input } from '@angular/core';
import { Tab } from '../../../shared/modules/tab/models/tab.model';

@Component({
  selector: 'faqs',
  templateUrl: './faqs.component.html'
})
export class FaqsComponent {

  public tabs: Tab[];
  public tabAll: Tab;
  public tabTeams: Tab;
  public tabPlayers: Tab;
  public tabCoaches: Tab;

  constructor() {
    this.tabs = [];
    this.tabAll = new Tab();
    this.tabTeams = new Tab();
    this.tabPlayers = new Tab();
    this.tabCoaches = new Tab();
    this.tabAll.path = '/faqs/all';
    this.tabTeams.path = '/faqs/teams';
    this.tabPlayers.path = '/faqs/players';
    this.tabCoaches.path = '/faqs/coaches';
    this.tabAll.name = 'All';
    this.tabTeams.name = 'Teams';
    this.tabPlayers.name = 'Players';
    this.tabCoaches.name = 'Coaches';

    this.tabs.push(this.tabAll);
    this.tabs.push(this.tabTeams);
    this.tabs.push(this.tabPlayers);
    this.tabs.push(this.tabCoaches);

  }
}
