import { Component, Input } from '@angular/core';

@Component({
  selector: 'no-faq',
  templateUrl: './no-faq.component.html'
})

export class NoFaqComponent {

  @Input('target') target: string;

  constructor() {
  }
}
