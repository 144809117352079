import { Component, OnInit } from '@angular/core';
import { AlgoliaService } from '../services/algolia.service';

@Component({
  selector: 'instant-search',
  template: `
    <ais-instantsearch [config]="config">
        <header>
        <ais-search-box></ais-search-box>
    </header>
    <main>
        <ais-hits>
            <ng-template let-hits="hits">
                <div *ngFor="let hit of hits">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                        <h3 class="panel-title">{{hit.nome}} {{hit.cognome}}</h3>
                        </div>
                        <div class="panel-body">
                        <p>Data nascita: {{hit.daata_nascita}}</p>
                        <p>Ruolo: {{hit.ruolo}}</p>
                        <p>Citta': {{hit.citta}}</p>
                        <p>Email: {{hit.email}}</p>
                        </div>
                    </div>
                </div>
                </ng-template>
        </ais-hits>
    </main>
    </ais-instantsearch>
  `
})

export class InstantSearchComponent {

 readonly config = {
     apiKey: this.algoliaService.algoliaApiKey,
     appId: this.algoliaService.algoliaAppId,
     indexName: 'users_index',
     routing: true
 };
  constructor(private algoliaService: AlgoliaService) {}
}
