import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AnnouncementsService } from '../services/announcements.service';
import { CandidaciesService } from '../../candidacies-approval/services/candidacies.service';
import { Observable, of } from 'rxjs';
import { QueryDocumentSnapshot } from '@angular/fire/firestore';
import { take, switchMap } from 'rxjs/operators';
import { FirestoreAnnouncementCandidacy } from '../../../shared/models/firestore/announcement/candidacy.model';

@Component({
    selector: 'modal-candidacies',
    templateUrl: './modal-candidacies.component.html',
    styleUrls: ['./modal-candidacies.component.css']
})

export class ModalCandidaciesComponent implements OnInit {

    constructor(private announcementsService: AnnouncementsService,
                private candidaciesService: CandidaciesService) {}

    @Output() onClose = new EventEmitter<boolean>();

    obsCandidacies$: Observable<QueryDocumentSnapshot<FirestoreAnnouncementCandidacy>[]>;
    messageNoCandidacies = 'No candidacies found';

    ngOnInit() {

        this.obsCandidacies$ = this.announcementsService.getAnnouncementId().pipe(
            take(1),
            switchMap((announcementId: string) => this.candidaciesService.getCandidaciesById(announcementId))
        );
        
    }

    onHide() {
        this.messageNoCandidacies = '';
        this.obsCandidacies$ = of([]);
        this.onClose.emit(false);
    }

    onHidden() {
        this.messageNoCandidacies = '';
        this.obsCandidacies$ = of([]);
        this.onClose.emit(false);
    }

    showPhone(phone: string): boolean {
        if (phone == null || phone === undefined || phone === '') {
            return false;
        }
        return true;
    }

}
