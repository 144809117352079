import { FirestoreUserProfile } from './../models/firestore/user/profile.model';
import { PartialUserData } from './../models/firestore/user/partial-user-data.model';
import { COLLECTIONS } from './../../../../../../collections';
import { FirestoreAnnouncementCandidacy } from './../models/firestore/announcement/candidacy.model';
import { ProfileTypeService } from './profile-type.service';
import { FirestoreCandidacyAnnouncement } from './../models/firestore/candidacy/announcement.model';
import { FirestoreCandidacy } from './../models/firestore/candidacy/candidacy.model';
import { Candidacy } from './../../modules/candidacies-approval/models/candidacy.model';
import { FirestoreMunicipality } from '../models/firestore/municipality.model';
import { Announcement } from './../../modules/announcements-approval/models/announcement.model';
import { FirestoreAnnouncement } from './../models/firestore/announcement/announcement.model';
import { PROFILE_TYPES } from './../mocks/profile-types.mock';
import { FirestoreCreator } from 'app/shared/models/firestore/creator.model';
import {
  AngularFirestore,
  DocumentReference,
  DocumentChangeAction,
  DocumentSnapshot,
  QueryFn,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@angular/fire/firestore';
import { generateCurrentFirestoreTimestamp } from 'app/shared/models/firestore/timestamp.model';
import { FirestoreUser } from './../models/firestore/user/user.model';
import { Injectable } from '@angular/core';
import { Sex } from '../enums/sex.enum';
import { Observable, from, of } from 'rxjs';
import { tap, map, switchMap, take, mapTo } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { MunicipalityService } from './municipality.service';
import { ProfileType } from '../enums/profile-type.enum';
import { FavouriteContact } from '../enums/favourite-contact.enum';
import { FirestoreQueryHelperService } from './firestore-query-helper.service';
import { Filter } from '../enums/filters.enum';
import { ApprovalPost, ApprovalPostAction } from '../models/approval-post.model';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  constructor(
    private afs: AngularFirestore,
    private municipalityService: MunicipalityService,
    private profileTypeService: ProfileTypeService,
    private queryHelperService: FirestoreQueryHelperService,
  ) {}

  getCandidaciesOfApprovedAnnouncement(
    announcementId: string,
  ): Observable<QueryDocumentSnapshot<FirestoreAnnouncementCandidacy>[]> {
    return this.getApprovedAnnouncementByPublicAnnouncemntId(announcementId).pipe(
      switchMap((snapshot: QueryDocumentSnapshot<FirestoreAnnouncement>) =>
        this.afs
          .collection<FirestoreAnnouncementCandidacy>(`${COLLECTIONS.announcements}/${snapshot.id}/candidacies`)
          .snapshotChanges(),
      ),
      map((actions: DocumentChangeAction<FirestoreAnnouncementCandidacy>[]) =>
        actions.map(action => action.payload.doc),
      ),
    );
  }

  getApprovedAnnouncementByPublicAnnouncemntId(
    announcementId: string,
  ): Observable<QueryDocumentSnapshot<FirestoreAnnouncement>> {
    return this.afs
      .collection<FirestoreAnnouncement>(COLLECTIONS.announcements, ref =>
        ref.where('publicAnnouncementId', '==', announcementId),
      )
      .snapshotChanges()
      .pipe(
        take(1),
        map((actions: DocumentChangeAction<FirestoreAnnouncement>[]) => actions[0].payload.doc),
      );
  }

  getPaginatedCandidacies(
    filter: Filter,
    startEndDocument: DocumentSnapshot<Candidacy> = null,
    nextPage = true,
    limit: number = null,
  ): Observable<QueryDocumentSnapshot<Candidacy>[]> {
    let query: QueryFn;
    if (filter === Filter.ALL) {
      query = ref => ref.orderBy('created_at', 'desc');
    } else if (filter === Filter.PENDING_APPROVAL) {
      query = ref => ref.where('handled', '==', 0).orderBy('created_at', 'desc');
    } else if (filter === Filter.DECLINED) {
      query = ref =>
        ref
          .where('handled', '==', 1)
          .where('approved', '==', 0)
          .orderBy('created_at', 'desc');
    } else if (filter === Filter.APPROVED) {
      query = ref =>
        ref
          .where('handled', '==', 1)
          .where('approved', '==', 1)
          .orderBy('created_at', 'desc');
    } else {
      throw new Error(`Unknown filter value ${filter}`);
    }
    query = limit ? this.queryHelperService.addLimitClause(query, limit) : query;
    if (nextPage) {
      query = startEndDocument ? this.queryHelperService.addStartAfterClause(query, startEndDocument) : query;
    } else {
      query = startEndDocument ? this.queryHelperService.addEndBeforeClause(query, startEndDocument) : query;
    }
    return this.afs
      .collection<Candidacy>(COLLECTIONS.publicCandidacies, query)
      .snapshotChanges()
      .pipe(map((actions: DocumentChangeAction<Candidacy>[]) => actions.map(action => action.payload.doc)));
  }

  getPaginatedAnnouncements(
    filter: Filter,
    startEndDocument: DocumentSnapshot<Announcement> = null,
    nextPage = true,
    limit: number = null,
  ): Observable<QueryDocumentSnapshot<Announcement>[]> {
    let query: QueryFn;
    if (filter === Filter.ALL) {
      query = ref => ref.orderBy('created_at', 'desc');
    } else if (filter === Filter.PENDING_APPROVAL) {
      query = ref =>
        ref
          .where('handled', '==', 0)
          .where('confirmed', '==', 1)
          .orderBy('created_at', 'desc');
    } else if (filter === Filter.PENDING_CONFIRMATION) {
      query = ref =>
        ref
          .where('confirmed', '==', 0)
          .where('handled', '==', 0)
          .orderBy('created_at', 'desc');
    } else if (filter === Filter.DECLINED) {
      query = ref =>
        ref
          .where('handled', '==', 1)
          .where('approved', '==', 0)
          .orderBy('created_at', 'desc');
    } else if (filter === Filter.APPROVED) {
      query = ref =>
        ref
          .where('handled', '==', 1)
          .where('approved', '==', 1)
          .orderBy('created_at', 'desc');
    } else {
      throw new Error(`Unknown filter value ${filter}`);
    }
    query = limit ? this.queryHelperService.addLimitClause(query, limit) : query;
    if (nextPage) {
      query = startEndDocument ? this.queryHelperService.addStartAfterClause(query, startEndDocument) : query;
    } else {
      query = startEndDocument ? this.queryHelperService.addEndBeforeClause(query, startEndDocument) : query;
    }
    return this.afs
      .collection<Announcement>(COLLECTIONS.publicAnnouncements, query)
      .snapshotChanges()
      .pipe(map((actions: DocumentChangeAction<Announcement>[]) => actions.map(action => action.payload.doc)));
  }

  getDuplicatedCandidacy(candidacy: Candidacy): Observable<FirestoreCandidacy | null> {
    return this.getUserByEmail(candidacy.email).pipe(
      switchMap((user: FirestoreUser) => {
        if (!user) {
          return of(null);
        }

        const candidacyId = `${candidacy.announcement_id}_${user.id}`;
        return this.afs
          .doc(`${COLLECTIONS.candidacies}/${candidacyId}`)
          .get()
          .pipe(
            map((documentSnapshot: firebase.firestore.DocumentSnapshot) => {
              if (documentSnapshot.exists) {
                return {
                  ...(documentSnapshot.data() as FirestoreCandidacy),
                };
              }

              return null;
            }),
          );
      }),
    );
  }

  postCandidacyInFirestore(candidacy: Candidacy): Observable<any> {
    const userData: PartialUserData = {
      ...(candidacy.phone && { phoneNumber: candidacy.phone }),
      email: candidacy.email,
      favouriteContact: candidacy.favourite_contact as FavouriteContact,
    };

    return this.getOrCreateFirestoreCreator(userData, {
      profileType: this.profileTypeService.mapProfileTypeIdOrStringToProfileType(candidacy.profile_type_id),
    }).pipe(
      switchMap((creator: FirestoreCreator) => this.mapCandidacyFirestore(candidacy, creator)),
      switchMap((firestoreCandidacy: FirestoreCandidacy) => {
        return this.createCandidacyFirestore(firestoreCandidacy).pipe(
          tap((persistedFirestoreCandidacy: FirestoreCandidacy) => {
            console.log(
              `[FirestoreService - createCandidacyFirestore] Doc added with key ${persistedFirestoreCandidacy.id}`,
            );

            const expectedKey = `${candidacy.announcement_id}_${firestoreCandidacy.creator.id}`;
            if (persistedFirestoreCandidacy.id !== expectedKey) {
              throw new Error(
                `Firestore candidacy key ${persistedFirestoreCandidacy.id} should be equal to ${expectedKey} (annId_userId)`,
              );
            }
          }),
          switchMap((persistedFirestoreCandidacy: FirestoreCandidacy) => {
            return this.addCandidacyToAnnouncement(persistedFirestoreCandidacy).pipe(
              switchMap(() => {
                return this.getUserByEmail(persistedFirestoreCandidacy.creator.email).pipe(
                  tap((user: FirestoreUser) => {
                    if (!user) {
                      throw new Error(`User with ${persistedFirestoreCandidacy.creator.email} should exists!`);
                    }
                  }),
                  switchMap(user => this.addCandidacyKeyToUser(persistedFirestoreCandidacy.id, user)),
                );
              }),
              switchMap(() => {
                return this.updateCandidacyCountInAnnouncement(candidacy.announcement_id);
              }),
            );
          }),
        );
      }),
    );
  }

  private addCandidacyToAnnouncement(firestoreCandidacy: FirestoreCandidacy): Observable<DocumentReference> {
    console.log(`[FirestoreService - addCandidacyToAnnouncement] firestoreCandidacy`, firestoreCandidacy);
    let firestoreAnnouncementCandidacy: FirestoreAnnouncementCandidacy = {
      creator: firestoreCandidacy.creator,
      createdAt: firestoreCandidacy.createdAt,
    };

    if (firestoreCandidacy.message) {
      firestoreAnnouncementCandidacy = {
        ...firestoreAnnouncementCandidacy,
        message: firestoreCandidacy.message,
      };
    }

    return from(
      this.afs
        .collection(`${COLLECTIONS.announcements}/${firestoreCandidacy.announcement.id}/${COLLECTIONS.candidacies}`)
        .add(firestoreAnnouncementCandidacy),
    );
  }

  private mapCandidacyFirestore(candidacy: Candidacy, creator: FirestoreCreator): Observable<FirestoreCandidacy> {
    return this.getAnnouncement(`${candidacy.announcement_id}`).pipe(
      map((firestoreAnnouncement: FirestoreAnnouncement) => {
        const candidacyAnnouncement: FirestoreCandidacyAnnouncement = {
          id: firestoreAnnouncement.id,
          title: firestoreAnnouncement.title,
          description: firestoreAnnouncement.description,
          creator: {
            ...firestoreAnnouncement.creator,
          },
        };

        let firestoreCandidacy: FirestoreCandidacy = {
          creator,
          favouriteContact: candidacy.favourite_contact as FavouriteContact,
          announcement: candidacyAnnouncement,
          createdAt: generateCurrentFirestoreTimestamp(),
          updatedAt: generateCurrentFirestoreTimestamp(),
          publicCandidacyId: candidacy.firestoreId,
        };

        if (candidacy.message) {
          firestoreCandidacy = { ...firestoreCandidacy, message: candidacy.message };
        }

        return firestoreCandidacy;
      }),
    );
  }

  postAnnouncementInFirestore(announcement: Announcement, deletionToken: string): Observable<null> {
    if (!deletionToken) {
      throw new Error('Deletion token needs to be set');
    }

    const userData: PartialUserData = {
      email: announcement.creator_email,
      name: announcement.creator_name,
      surname: announcement.creator_surname,
      sex: announcement.creator_sex as Sex,
      phoneNumber: announcement.creator_phone,
    };

    return this.getOrCreateFirestoreCreator(userData, {
      profileType: this.profileTypeService.mapProfileTypeIdOrStringToProfileType(announcement.creator_profile_type_id),
    }).pipe(
      map((creator: FirestoreCreator) => this.mapAnnouncementFirestore(announcement, creator, deletionToken)),
      switchMap((firestoreAnnouncement: FirestoreAnnouncement) => {
        return this.createAnnouncementFirestore(firestoreAnnouncement).pipe(
          tap(resp => console.log(`[FirestoreService - postAnnouncementInFirestore] Doc added with key ${resp.id}`)),
        );
      }),
      switchMap((firestoreAnnouncement: FirestoreAnnouncement) => {
        return this.getUserByEmail(firestoreAnnouncement.creator.email).pipe(
          switchMap(user => {
            if (!user) {
              throw new Error(`User with ${firestoreAnnouncement.creator.email} should exists!`);
            }

            if (!firestoreAnnouncement.id) {
              throw new Error(`firestoreAnnouncement.id needs to be set to add it to the user`);
            }

            return this.addAnnouncementKeyToUser(firestoreAnnouncement.id, user);
          }),
        );
      }),
    );
  }

  private mapAnnouncementFirestore(
    announcement: Announcement,
    creator: FirestoreCreator,
    deletionToken: string,
  ): FirestoreAnnouncement {
    const firestoreMunicipality: FirestoreMunicipality = this.municipalityService.getFirestoreMunicipality(
      Number(announcement.municipality_id),
    );
    const announcementTargets: string[] = [];
    for (let i = 0; i < announcement.targets.length; i++) {
      for (const profileType of PROFILE_TYPES) {
        if (profileType.viewValue === announcement.targets[i]) {
          announcementTargets[i] = profileType.value;
        }
      }
    }
    const firestoreAnnouncement: FirestoreAnnouncement = {
      candidacyCount: 0,
      categories: announcement.categories,
      createdAt: generateCurrentFirestoreTimestamp(),
      creator,
      deletedAt: null,
      description: announcement.description,
      isRemunered: announcement.remuneration === 1 ? true : false,
      maxAge: announcement.max_age,
      minAge: announcement.min_age,
      municipality: firestoreMunicipality,
      publishedAt: generateCurrentFirestoreTimestamp(),
      roles: announcement.roles,
      sexes: announcement.sexes,
      targets: announcementTargets,
      title: announcement.title,
      updatedAt: generateCurrentFirestoreTimestamp(),
      deletionToken: deletionToken,
      publicAnnouncementId: announcement.firestoreId,
    };
    return firestoreAnnouncement;
  }

  private getOrCreateFirestoreCreator(
    userData: PartialUserData,
    options: { profileType: ProfileType },
  ): Observable<FirestoreCreator> {
    console.log(`[FirestoreService - getOrCreateFirestoreCreator]`);
    return this.getUserByEmail(userData.email).pipe(
      switchMap((user: FirestoreUser) => {
        if (user) {
          if (!user.id) {
            throw new Error(`User found but no id is set for user with email ${userData.email}!`);
          }

          console.log(`[FirestoreService - getOrCreateFirestoreCreator] user found!`);
          return of(user.id);
        }

        console.log(
          `[FirestoreService - getOrCreateFirestoreCreator] user not found! Going to create it from scratch...`,
        );
        return this.createUserAndProfileFromData(userData, options.profileType).pipe(
          map((freshUser: DocumentReference) => freshUser.id),
          tap(id => console.log(`[FirestoreService - getOrCreateFirestoreCreator] user created! Key ${id}`)),
        );
      }),
      map(userId => {
        return {
          ...(userData.name && { name: userData.name }),
          ...(userData.phoneNumber && { phoneNumber: userData.phoneNumber }),
          ...(userData.favouriteContact && { favouriteContact: userData.favouriteContact }),
          id: userId,
          profileType: options.profileType,
          email: userData.email,
          createdAt: generateCurrentFirestoreTimestamp(),
          updatedAt: generateCurrentFirestoreTimestamp(),
        };
      }),
    );
  }

  private getUserByEmail(email: string): Observable<FirestoreUser | null> {
    console.log(`[FirestoreService - getUserByEmail] email`, email);
    return this.afs
      .collection(COLLECTIONS.users, ref => ref.where('email', '==', email))
      .snapshotChanges()
      .pipe(
        take(1),
        map((actions: DocumentChangeAction<FirestoreUser>[]) =>
          actions.map(action => {
            return {
              id: action.payload.doc.id,
              ...(action.payload.doc.data() as FirestoreUser),
            };
          }),
        ),
        map(users => {
          if (users.length > 0) {
            return users[0];
          }

          return null;
        }),
      );
  }

  getAnnouncement(id: string): Observable<FirestoreAnnouncement> {
    console.log(`[FirestoreService - getAnnouncement] id`, id);

    return this.afs
      .doc(`${COLLECTIONS.announcements}/${id}`)
      .get()
      .pipe(
        take(1),
        map((documentSnapshot: DocumentSnapshot<FirestoreAnnouncement>) => {
          if (!documentSnapshot.exists) {
            throw new Error(`Cannot find announcement with id ${id} in firestore!`);
          }
          return {
            ...(documentSnapshot.data() as FirestoreAnnouncement),
            id: documentSnapshot.id,
          };
        }),
      );
  }

  createUserAndProfileFromData(userData: PartialUserData, profileType: ProfileType): Observable<DocumentReference> {
    const user: Partial<FirestoreUser> = {
      ...(userData.name && { name: userData.name }),
      ...(userData.surname && { surname: userData.surname }),
      ...(userData.sex && { sex: userData.sex }),
      ...(userData.isFake && { isFake: userData.isFake }),
      ...(userData.municipality && { municipality: userData.municipality }),
      email: userData.email,
      createdAt: generateCurrentFirestoreTimestamp(),
      updatedAt: generateCurrentFirestoreTimestamp(),
      announcements: [],
      candidacies: [],
    };
    return from(this.afs.collection<Partial<FirestoreUser>>(COLLECTIONS.users).add(user)).pipe(
      switchMap(userDocRef => {
        const profile: FirestoreUserProfile = {
          type: profileType,
          ...(userData.phoneNumber && { phone: userData.phoneNumber }),
          ...(userData.favouriteContact && { favouriteContact: userData.favouriteContact }),
          createdAt: generateCurrentFirestoreTimestamp(),
          updatedAt: generateCurrentFirestoreTimestamp(),
        };

        console.log(
          `[FirestoreService - createUserAndProfileFromData] creating profile ${userDocRef.id} user`,
          profile,
        );
        return from(
          this.afs
            .collection<FirestoreUserProfile>(`${COLLECTIONS.users}/${userDocRef.id}/${COLLECTIONS.usersProfiles}`)
            .add(profile),
        ).pipe(map(() => userDocRef));
      }),
    );
  }

  private addAnnouncementKeyToUser(key: string, user: FirestoreUser): Observable<null> {
    console.log(`[FirestoreService - addAnnouncementKeyToUser] announcementKey ${key} user`, user);
    return from(
      this.afs
        .collection<FirestoreUser>(COLLECTIONS.users)
        .doc(user.id)
        .update({ announcements: firebase.firestore.FieldValue.arrayUnion(key) }),
    ).pipe(map(() => null));
  }

  private addCandidacyKeyToUser(key: string, user: FirestoreUser): Observable<null> {
    console.log(`[FirestoreService - addCandidacyKeyToUser] candidacyKey ${key} user`, user);
    return from(
      this.afs
        .collection<FirestoreUser>(COLLECTIONS.users)
        .doc(user.id)
        .update({ candidacies: firebase.firestore.FieldValue.arrayUnion(key) }),
    ).pipe(map(() => null));
  }

  private updateCandidacyCountInAnnouncement(announcementId: number): Observable<null> {
    console.log(`[FirestoreService - updateCandidacyCountInAnnouncement] announcementId ${announcementId}`);
    const castedAnnouncementId = String(announcementId);
    return this.afs
      .collection<FirestoreAnnouncement>(COLLECTIONS.announcements)
      .doc(castedAnnouncementId)
      .get()
      .pipe(
        take(1),
        map((documentSnapshot: DocumentSnapshot<FirestoreAnnouncement>) => {
          if (!documentSnapshot.exists) {
            throw new Error(`Cannot find announcement with id ${announcementId} in firestore!`);
          }
          return {
            ...(documentSnapshot.data() as FirestoreAnnouncement),
          };
        }),
        switchMap((announcement: FirestoreAnnouncement) => {
          return from(
            this.afs
              .collection<FirestoreAnnouncement>(COLLECTIONS.announcements)
              .doc(castedAnnouncementId)
              .update({ candidacyCount: announcement.candidacyCount + 1 }),
          ).pipe(map(() => null));
        }),
      );
  }

  private createAnnouncementFirestore(announcement: FirestoreAnnouncement): Observable<FirestoreAnnouncement> {
    console.log(`[FirestoreService - createAnnouncementFirestore] Creating announcement with body:`, announcement);
    return from(this.afs.collection<FirestoreAnnouncement>(COLLECTIONS.announcements).add(announcement)).pipe(
      map(docRef => ({
        ...announcement,
        id: docRef.id,
      })),
    );
  }

  updatePublicCandidacyFirestore(candidacy: Candidacy, candidacyApprovalPost: ApprovalPost): Observable<Candidacy> {
    const timestamp = generateCurrentFirestoreTimestamp();
    const updatedFields: Partial<Candidacy> = {
      deleted_at: timestamp,
      handled: 1,
      updated_at: timestamp,
    };

    if (candidacyApprovalPost.action === ApprovalPostAction.approve) {
      console.log(
        `[FirestoreService - updatePublicCandidacyFirestore] Updating public` +
          `candidacy with key ${candidacy.firestoreId} and body`,
        candidacy,
      );
      return from(
        this.afs
          .collection<Candidacy>(COLLECTIONS.publicCandidacies)
          .doc(candidacy.firestoreId)
          .update({
            ...updatedFields,
            approved: 1,
          }),
      ).pipe(mapTo(candidacy));
    } else if (candidacyApprovalPost.action === ApprovalPostAction.reject) {
      console.log(
        `[FirestoreService - updatePublicCandidacyFirestore] Updating public` +
          `candidacy with key ${candidacy.firestoreId} and body`,
        candidacy,
      );
      return from(
        this.afs
          .collection<Candidacy>(COLLECTIONS.publicCandidacies)
          .doc(candidacy.firestoreId)
          .update({
            ...updatedFields,
            approved: 0,
          }),
      ).pipe(mapTo(candidacy));
    }

    throw new Error(`Unknown candidacy approval post action value: ${candidacyApprovalPost.action}`);
  }

  updatePublicAnnouncementFirestore(
    announcement: Announcement,
    announcementApprovalPost: ApprovalPost,
  ): Observable<Announcement> {
    const timestamp = generateCurrentFirestoreTimestamp();
    const updatedFields: Partial<Announcement> = {
      deleted_at: timestamp,
      handled: 1,
      updated_at: timestamp,
    };

    if (announcementApprovalPost.action === ApprovalPostAction.approve) {
      if (!announcement.deletion_token) {
        throw new Error('Deletion token needs to be set');
      }

      console.log(
        `[FirestoreService - updatePublicAnnouncementFirestore] Updating public` +
          `announcement with key ${announcement.firestoreId} and body`,
        announcement,
      );
      return from(
        this.afs
          .collection(COLLECTIONS.publicAnnouncements)
          .doc(announcement.firestoreId)
          .update({
            ...updatedFields,
            approved: 1,
            deletion_token: announcement.deletion_token,
          }),
      ).pipe(mapTo(announcement));
    } else if (announcementApprovalPost.action === ApprovalPostAction.reject) {
      console.log(
        `[FirestoreService - updatePublicCandidacyFirestore] Updating public` +
          `candidacy with key ${announcement.firestoreId} and body`,
        announcement,
      );
      return from(
        this.afs
          .collection(COLLECTIONS.publicAnnouncements)
          .doc(announcement.firestoreId)
          .update({
            ...updatedFields,
            approved: 0,
          }),
      ).pipe(mapTo(announcement));
    }

    throw new Error(`Unknown announcement approval post action value: ${announcementApprovalPost.action}`);
  }

  private createCandidacyFirestore(candidacy: FirestoreCandidacy): Observable<FirestoreCandidacy> {
    const key = `${candidacy.announcement.id}_${candidacy.creator.id}`;
    console.log(`[FirestoreService - createCandidacyFirestore] Creating candidacy with key ${key} and body`, candidacy);
    return from(
      this.afs
        .collection<FirestoreCandidacy>(COLLECTIONS.candidacies)
        .doc(key)
        .set(candidacy),
    ).pipe(
      map(() => ({
        id: key,
        ...candidacy,
      })),
    );
  }
}
