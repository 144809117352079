import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';

import { FeaturesComponent } from './components/features.component';
import { CheckboxDirective } from '../../shared/directives/checkbox.directive';
import { ButtonDirective } from '../../shared/directives/button.directive';

import { FeaturesService } from './services/features.service';
import { FeaturesResolve } from './services/features-resolve.service';

import { featuresRouting } from './features.routing';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' // set defaults here
    }),
    featuresRouting,
  ],
  declarations: [
    FeaturesComponent,
    CheckboxDirective,
    ButtonDirective
  ],
  providers: [
    FeaturesService,
    FeaturesResolve
  ]
})
export class FeaturesModule {}
