import { ApprovalPost } from './../../../shared/models/approval-post.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Announcement } from '../models/announcement.model';
import { ActivatedRoute } from '@angular/router';
import { AnnouncementsService } from '../services/announcements.service';
import { NotificationsService } from 'angular2-notifications';
import { not_options } from '../../../shared/models/notification-options';
import { ApprovalPostAction } from 'app/shared/models/approval-post.model';
import { Filter } from '../../../shared/enums/filters.enum';
import { Observable, Subject } from 'rxjs';
import { QueryDocumentSnapshot, DocumentSnapshot } from '@angular/fire/firestore';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { map, tap, switchMap, takeUntil } from 'rxjs/operators';

declare let $: any;

@Component({
  selector: 'announcements-list',
  templateUrl: './announcements-list.component.html',
  styleUrls: ['./announcements-list.component.css'],
})
export class AnnouncementsListComponent implements OnInit, OnDestroy {
  public announcements: Announcement[] = [];
  public modalType = '';
  public announcementToApproveDeny: Announcement;
  public showModal = false;

  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  pageNum = 0;

  filter: Filter;
  readonly paginationLenght = this.announcementsService.paginationLenght;
  obsAnnouncements$: Observable<QueryDocumentSnapshot<Announcement>[]>;
  // https://alligator.io/angular/takeuntil-rxjs-unsubscribe/
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private announcementsService: AnnouncementsService,
    private notificationsService: NotificationsService,
  ) {
    this.obsAnnouncements$ = this.route.params.pipe(
      map(params => params.filter),
      tap(filter => (this.filter = filter)),
      switchMap(filter => this.announcementsService.getAllFirestoreAnnouncements(filter)),
    );
  }

  ngOnInit() {
    this.announcementsService
      .getAnnouncementId()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        console.log('Open candidacies modal');
        const modalAnnouncementCandidaciesRef = '#modal-candidacies';
        $(modalAnnouncementCandidaciesRef).modal();
      });
  }

  fetch(lastAnnouncement: DocumentSnapshot<Announcement>, nextPage = true) {
    if (nextPage) {
      this.pageNum++;
    } else {
      this.pageNum--;
    }
    this.obsAnnouncements$ = this.announcementsService
      .getAllFirestoreAnnouncements(this.filter, lastAnnouncement, nextPage)
      .pipe(tap(candidacies => console.log('announcements-list.component.ts - new candidacies: ', candidacies)));
  }

  onApproveAnnouncement(announcementToApprove: Announcement) {
    this.modalType = 'approve';
    this.announcementsService.setAnnouncementToModal(announcementToApprove);
    this.announcementsService.setModalType(this.modalType);
    this.announcementToApproveDeny = announcementToApprove;
    this.openModal();
  }

  onDenyAnnouncement(announcementToDeny: Announcement) {
    this.modalType = 'deny';
    this.announcementsService.setAnnouncementToModal(announcementToDeny);
    this.announcementsService.setModalType(this.modalType);
    this.announcementToApproveDeny = announcementToDeny;
    this.openModal();
  }

  openModal() {
    this.showModal = true;
    const modalConfirmationAnnouncementRef = '#modal';
    $(modalConfirmationAnnouncementRef).modal();
  }

  onConfirmAnnouncementOperation(operationConfirmed: boolean) {
    let announcementApprovalPost: ApprovalPost;
    if (this.modalType === 'approve') {
      announcementApprovalPost = {
        action: ApprovalPostAction.approve,
      };
      this.announcementsService
        .postAnnouncement(this.announcementToApproveDeny, announcementApprovalPost)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: any) => {
            this.notificationsService.success(
              'Announcement approved',
              'Check the public site out to see it',
              not_options,
            );
          },
          (error: any) => {
            this.notificationsService.error(
              'Ooops',
              'An error occured during interaction with the server; announcement not approved',
              not_options,
            );
          },
        );
    } else if (this.modalType === 'deny') {
      announcementApprovalPost = {
        action: ApprovalPostAction.reject,
      };
      this.announcementsService
        .postAnnouncement(this.announcementToApproveDeny, announcementApprovalPost)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: any) => {
            this.notificationsService.success(
              'Announcement rejected',
              'It should be on Declined tab in this page',
              not_options,
            );
          },
          (error: any) => {
            this.notificationsService.error(
              'Ooops',
              'An error occured during interaction with the server; announcement not rejected',
              not_options,
            );
          },
        );
    }
  }

  onCloseModal(event: boolean) {
    if (!event) {
      const modalConfirmationAnnouncementRef = '#modal';
      $(modalConfirmationAnnouncementRef).modal('hide');
    }
  }

  onCloseCandidaciesModal(event: boolean) {
    if (!event) {
      const modalAnnouncementCandidaciesRef = '#modal-candidacies';
      $(modalAnnouncementCandidaciesRef).modal('hide');
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }
}
