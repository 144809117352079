import { environment } from './../../../environments/environment';
import { Component } from '@angular/core';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'my-app',
  template: `
    <div class="container">
      <navbar></navbar>
      <div class="row">
        <div class="col-sm-2">
          <sidebar></sidebar>
        </div>
        <div class="col-sm-10">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <simple-notifications></simple-notifications>
  `,
})
export class AppComponent {
  constructor() {
    Sentry.init({
      dsn: environment.sentry,
    });

    if (environment.logRocket) {
      LogRocket.init(`rtosmq/${environment.logRocket}`);

      LogRocket.getSessionURL(sessionURL => {
        Sentry.configureScope(scope => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
    }
  }
}
