import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { StatsSessionService } from './stats-session.service';

@Injectable()
export class StatsSessionResolve implements Resolve<any> {

  public error: any;

  constructor(private statsSessionService: StatsSessionService) {}
  resolve(): Observable<any> | Promise<any> | any {
    return this.statsSessionService.session();
  }
}
