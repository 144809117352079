import { CapitalizeFirstPipe } from './pipes/capitalize-first.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation';
import { CommonModule } from '@angular/common';
import { SpinnerModule } from '../../shared/modules/spinner/spinner.module';
import { TabModule } from '../../shared/modules/tab/tab.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';

import { FaqsComponent } from './components/faqs.component';
import { FaqCreateComponent } from './components/faq-create.component';
import { FaqsTargetComponent } from './components/faqs-target.component';
import { NoFaqComponent } from './components/no-faq.component';
import { ModalUpdateComponent } from './components/modal-update.component';

import { FaqsResolve } from './services/faqs-resolve.service';
import { FaqsService } from './services/faqs.service';

import { faqsRouting } from './faqs.routing';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CustomFormsModule,
    SpinnerModule,
    CollapseModule.forRoot(),
    SpinnerModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' // set defaults here
    }),
    TabModule,
    faqsRouting,
  ],
  declarations: [
    FaqsComponent,
    FaqCreateComponent,
    FaqsTargetComponent,
    ModalUpdateComponent,
    NoFaqComponent,
    ReplacePipe,
    CapitalizeFirstPipe
  ],
  providers: [
      FaqsResolve,
      FaqsService
  ]
})
export class FaqsModule {}
