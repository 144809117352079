import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { options } from '../../../shared/models/content-headers';
import { Observable } from 'rxjs';
import { Locations } from '../models/locations.model';

import { catchError } from 'rxjs/operators';

@Injectable()
export class LocationsService {
  constructor (private httpClient: HttpClient) {}

  public locationsUrl = '/api/locations';  // URL to web API
  public locationsArray: Locations[];

  locations(): Observable<any> {
      return this.httpClient.get(this.locationsUrl, options)
                      .pipe(
                        catchError(this.handleError)
                      );
  }

  private handleError (error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }

  castingStringToNumber(data: Locations[]): Locations[] {
    this.locationsArray = [];
    for (let i = 0; i < data.length; i++) {
      const locations: Locations = new Locations();
      locations.lat = +data[i].lat;
      locations.lng = +data[i].lng;
      locations.formatted_address = data[i].formatted_address;
      locations.id = data[i].id;
      locations.quantity = data[i].quantity;
      this.locationsArray.push(locations);
    }
    return this.locationsArray;
  }
}
