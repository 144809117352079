import { FAQ_CATEGORIES } from './../mocks/faq-categories.mock';
import { FAQ_PROFILE_TYPES } from './../mocks/faq-profile-types.mock';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { options } from '../../../shared/models/content-headers';
import { Observable } from 'rxjs';
import { FaqCreate } from '../models/faq-create.model';
import { Faq } from '../models/faq.model';
import { FaqProfileType } from '../models/faq-profile-type.model';

import { catchError } from 'rxjs/operators';

@Injectable()
export class FaqsService {
  constructor (private httpClient: HttpClient) {}

  public faqsUrl = '/api/faqs';  // URL to web API
  private target: string;

  public getAllFaqs(target: any): Observable<any> {
      this.target = target;
      return this.httpClient.get(this.faqsUrl + '?target=' + target, options)
              .pipe(
                catchError(this.handleError)
              );
  }

  public getTarget(): string {
    return this.target;
  }

  private handleError (error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }

  public createFaq(body: FaqCreate): Observable<any> {
    return this.httpClient.post(this.faqsUrl, body, options)
            .pipe(
              catchError(this.handleError)
            );
  }

  public getFaq(id: number): Observable<any> {
    return this.httpClient.get(this.faqsUrl + '/' + id, options)
            .pipe(
              catchError(this.handleError)
            );
  }

  public removeFaq(id: number): Observable<any> {
    return this.httpClient.delete(this.faqsUrl + '/' + id, options)
            .pipe(
              catchError(this.handleError)
            );
  }

  public updateFaq(body: Faq): Observable<any> {
    const category: string = body.category;
    const target: string = body.target;
    const question: string = body.question;
    const answer: string = body.answer;
    const priority: number = body.priority;
    const bodyRequest = JSON.stringify({question, answer, target, category, priority});
    return this.httpClient.put(this.faqsUrl + '/' + body.id, bodyRequest, options)
            .pipe(
              catchError(this.handleError)
            );
  }

  public getFaqCategories(): any[] {
    return FAQ_CATEGORIES;
  }

  public getFaqProfileTypes(): FaqProfileType[] {
    return FAQ_PROFILE_TYPES;
  }
}
