import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { UsersService } from './users.service';

@Injectable()
export class SubscriptionsResolve implements Resolve<any> {

  public error: any;

  constructor(private usersService: UsersService) {}
  resolve(): Observable<any> | Promise<any> | any {
    return this.usersService.getUsers();
  }
}
