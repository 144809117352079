import { Component, Input } from '@angular/core';

@Component({
  selector: 'number-display',
  templateUrl: './number-display.component.html'
})

export class NumberDisplayComponent {
    @Input() number: number;
    @Input() title: string;
    @Input() description: string;
}
