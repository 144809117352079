import { CandidaciesService } from '../services/candidacies.service';
import { Candidacy } from '../models/candidacy.model';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreService } from 'app/shared/services/firestore.service';
import { FirestoreCandidacy } from 'app/shared/models/firestore/candidacy/candidacy.model';
import { DocumentSnapshot } from '@angular/fire/firestore';

@Component({
  selector: 'app-candidacy-item',
  templateUrl: './candidacy-item.component.html',
  styleUrls: ['./candidacy-item.component.css', './candidacy-item-shared.component.css'],
})
export class CandidacyItemComponent implements OnInit {
  constructor(private candidaciesService: CandidaciesService, private firestoreService: FirestoreService) {}
  _candidacy: Candidacy;
  @Output() onDenyCandidacy = new EventEmitter<Candidacy>();
  @Output() onApproveCandidacy = new EventEmitter<Candidacy>();
  duplicatedCandidacy$: Observable<FirestoreCandidacy>;

  @Input() set candidacy(doc: DocumentSnapshot<Candidacy>) {
    this._candidacy = { ...doc.data(), firestoreId: doc.id };
  }

  ngOnInit() {
    this.duplicatedCandidacy$ = this.firestoreService.getDuplicatedCandidacy(this._candidacy);
  }

  clickAnnouncementReference() {
    this.candidaciesService.setAnnouncementId(this._candidacy.announcement_id);
  }

  approveCandidacy(candidacy: Candidacy) {
    this.onApproveCandidacy.emit(candidacy);
  }

  denyCandidacy(candidacy: Candidacy) {
    this.onDenyCandidacy.emit(candidacy);
  }

  // is true only if the announcement is in the pending_approval state (deleted_at == null)
  canCandidacyBeApprovedOrDeclined(): boolean {
    if (this._candidacy.deleted_at == null) {
      return true;
    }
    return false;
  }

  showEmail() {
    if (this._candidacy.email !== '' && this._candidacy.email !== undefined && this._candidacy.email != null) {
      return true;
    }
    return false;
  }

  showPhone() {
    if (this._candidacy.phone !== '' && this._candidacy.phone !== undefined && this._candidacy.phone != null) {
      return true;
    }
    return false;
  }

  showFavouriteContact() {
    if (
      this._candidacy.favourite_contact !== '' &&
      this._candidacy.favourite_contact !== undefined &&
      this._candidacy.favourite_contact != null
    ) {
      return true;
    }
    return false;
  }

  showMessage() {
    if (this._candidacy.message !== '' && this._candidacy.message !== undefined && this._candidacy.message != null) {
      return true;
    }
    return false;
  }
}
