import { FirestoreService } from './../../../shared/services/firestore.service';
import { ApprovalPostAction } from './../../..//shared/models/approval-post.model';
import { ApprovalPost } from './../../../shared/models/approval-post.model';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { Candidacy } from '../models/candidacy.model';

import { catchError, switchMap } from 'rxjs/operators';
import { DocumentSnapshot, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { FirestoreAnnouncement } from '../../../shared/models/firestore/announcement/announcement.model';
import { Filter } from '../../../shared/enums/filters.enum';
import { FirestoreAnnouncementCandidacy } from '../../../shared/models/firestore/announcement/candidacy.model';

@Injectable()
export class CandidaciesService {
  subjectCandidacyToModal: Subject<Candidacy> = new Subject<Candidacy>();
  subjectModalType: Subject<string> = new Subject<string>();
  subjectAnnouncementId: Subject<number> = new Subject<number>();

  readonly paginationLenght = 5;

  constructor(private firestoreService: FirestoreService) {}

  getCandidaciesById(announcementId: string): Observable<QueryDocumentSnapshot<FirestoreAnnouncementCandidacy>[]> {
    return this.firestoreService.getCandidaciesOfApprovedAnnouncement(announcementId);
  }

  getAllFirestoreCandidacies(
    filter: Filter,
    startAt: DocumentSnapshot<Candidacy> = null,
    nextPage = true,
    limit = this.paginationLenght,
  ): Observable<QueryDocumentSnapshot<Candidacy>[]> {
    return this.firestoreService.getPaginatedCandidacies(filter, startAt, nextPage, limit);
  }

  postCandidacy(candidacy: Candidacy, candidacyApprovalPost: ApprovalPost): Observable<any> {
    console.log(`candidacies.service.ts - postCandidacy: candidacy`, candidacy);
    if (!candidacy.firestoreId) {
      throw new Error('firestoreId should be set!');
    }

    return this.firestoreService.updatePublicCandidacyFirestore(candidacy, candidacyApprovalPost).pipe(
      switchMap(() => {
        if (candidacyApprovalPost.action !== ApprovalPostAction.approve) {
          return of(null);
        }
        return this.firestoreService.postCandidacyInFirestore(candidacy);
      }),
      catchError(this.handleError),
    );
  }

  getAnnouncementReferenceCandidacy(announcement_id: number): Observable<FirestoreAnnouncement> {
    return this.firestoreService.getAnnouncement(String(announcement_id));
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }

  setAnnouncementId(announcementId: number): void {
    this.subjectAnnouncementId.next(announcementId);
  }

  getAnnouncementId(): Observable<number> {
    return this.subjectAnnouncementId.asObservable();
  }

  setCandidacyToModal(candidacy: Candidacy): void {
    this.subjectCandidacyToModal.next(candidacy);
  }

  getCandidacyToModal(): Observable<Candidacy> {
    return this.subjectCandidacyToModal.asObservable();
  }

  setModalType(modalType: string): void {
    this.subjectModalType.next(modalType);
  }

  getModalType(): Observable<string> {
    return this.subjectModalType.asObservable();
  }
}
