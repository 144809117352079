import { Component, OnInit } from '@angular/core';
import { StatsSession } from '../models/stats-session.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'stats-session',
  templateUrl: './stats-session.component.html'
})

export class StatsSessionComponent implements OnInit {

  barChartLabelsTen: string[];
  barChartLabelsThirty: string[];
  barChartLabelsTwelveMonths: string[];

  barChartType = 'bar';
  barChartLegend = true;

  barChartDataTen: any[];
  barChartDataThirty: any[];
  barChartDataTwelveMonths: any[];

  barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  updated_at: string;
  isSessionDataAvailable = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data.forEach((data: { statsSession: StatsSession }) => {

      if ('session_past_ten_days' in data.statsSession) {
        this.isSessionDataAvailable = true;

        this.barChartLabelsTen = data.statsSession.session_past_ten_days.labels;
        this.barChartLabelsThirty = data.statsSession.session_past_thirty_days.labels;
        this.barChartLabelsTwelveMonths = data.statsSession.session_past_twelve_months.labels;

        this.barChartDataTen = [
          {data: data.statsSession.session_past_ten_days.login.data, label: 'Login'},
          {data: data.statsSession.session_past_ten_days.logout.data, label: 'Logout'},
          {data: data.statsSession.session_past_ten_days.signup.data, label: 'Signup'}
        ];
        this.barChartDataThirty = [
          {data: data.statsSession.session_past_thirty_days.login.data, label: 'Login'},
          {data: data.statsSession.session_past_thirty_days.logout.data, label: 'Logout'},
          {data: data.statsSession.session_past_thirty_days.signup.data, label: 'Signup'}
        ];
        this.barChartDataTwelveMonths = [
          {data: data.statsSession.session_past_twelve_months.login.data, label: 'Login'},
          {data: data.statsSession.session_past_twelve_months.logout.data, label: 'Logout'},
          {data: data.statsSession.session_past_twelve_months.signup.data, label: 'Signup'}
        ];

        this.updated_at = data.statsSession.updated_at;
      }
    });
  }
}
