import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

const algoliasearch = require('algoliasearch');

@Injectable()
export class AlgoliaService {

    public client: any;
    public index: any;
    public params: any;
    public algoliaAppId: any;
    public algoliaApiKey: any;

    constructor () {
        this.algoliaAppId = environment.algoliaAppId;
        this.algoliaApiKey = environment.algoliaApiKey;

        this.client = algoliasearch(this.algoliaAppId, this.algoliaApiKey, {protocol: 'https:'});
        this.index = this.client.initIndex('users_index');
    }
}
