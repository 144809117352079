import { Component } from '@angular/core';

@Component({
  selector: 'newsletters',
  templateUrl: './newsletters.component.html'
})
export class NewslettersComponent {

  constructor() {}
}
