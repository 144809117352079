import { NgModule } from '@angular/core';

import { NumberDisplayComponent } from './components/number-display.component';

@NgModule({
  declarations: [
    NumberDisplayComponent,
  ],
  exports: [
    NumberDisplayComponent,
  ]
})
export class NumberDisplayModule {}
