import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Credentials } from '../models/credentials.model';
import { AuthService } from '../services/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { not_options } from '../../../shared/models/notification-options';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnDestroy {
  credentials: Credentials = new Credentials();
  error: any;
  private subscription: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private notificationsService: NotificationsService,
  ) {
    this.subscription = this.authService
      .isAuthenticated()
      .pipe(filter(v => v))
      .subscribe(() => {
        this.router.navigate(['/announcementsapproval']);
      });
  }

  onSubmit() {
    this.authService.login(this.credentials.email, this.credentials.password).subscribe(
      () => console.log('logged in!'),
      error => {
        this.error = error;
        this.notificationsService.error('Login failed', 'Please check your email/password', not_options);
      },
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
