import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { LocationsService } from './locations.service';

@Injectable()
export class LocationsResolve implements Resolve<any> {

  private error: any;

  constructor(private locationsService: LocationsService) {}
  resolve(): Observable<any> | Promise<any> | any {
    return this.locationsService.locations();
  }
}
