import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { Announcement } from '../models/announcement.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AnnouncementsService } from '../services/announcements.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'modal-confirmation',
  templateUrl: './modal-confirmation.component.html'
})

export class ModalConfirmationComponent implements OnInit, OnDestroy {

    @ViewChild('modalConfirmation') modalConfirmation: ModalDirective;
    @Output() onConfirmAnnouncementOperation = new EventEmitter<boolean>();
    @Output() onClose = new EventEmitter<boolean>();

    public message = 'Default message';
    public modalType = 'approve';
    public announcement: Announcement;

    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private announcementsService: AnnouncementsService) {
    }

    ngOnInit() {
        // console.log("OnInit modalConfirmation");
        this.announcementsService.getAnnouncementToModal().pipe(takeUntil(this.destroy$)).subscribe(
            (announcement: any) => {
                // console.log("Subject announcement: " + JSON.stringify(announcement));
                this.announcement = announcement;
            },
            (error: any) => {
                // console.log("Error in getting announcement: " + error);
            }
        );
        this.announcementsService.getModalType().pipe(takeUntil(this.destroy$)).subscribe(
            (modalType: any) => {
                // console.log("Subject modalType: " + modalType);
                this.modalType = modalType;
                if (this.modalType == 'approve') {
                    this.message = 'Are you sure you want to approve this announcement?';
                } else if (this.modalType == 'deny') {
                    this.message = 'Are you sure you want to deny this announcement?';
                }
            },
            (error: any) => {
                // console.log("Error in getting modal type: " + error);
            }
        );
    }

    onConfirm() {
        this.onConfirmAnnouncementOperation.emit(true);
        // console.log("Announcement: " + JSON.stringify(this.announcement));
        // console.log("modalType: " + this.modalType);
        this.onClose.emit(false);
    }

    onHide() {
        this.onClose.emit(false);
    }

    onHidden() {
        this.onClose.emit(false);
    }

    ngOnDestroy() {
        // console.log("OnDestroy modalConfirmation");
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
