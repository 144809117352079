import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'logout',
  template: `
    <div class="col-xs-4 col-xs-offset-4">
      <h2>You are now logged out. Bye Bye.</h2>
    </div>
  `
})

export class LogoutComponent {
  constructor(private authService: AuthService, private router: Router) {
      this.authService.logout();
      this.router.navigate(['/login']);
  }
}
