import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/modules/main/app.module';
import { enableProdMode } from '@angular/core';
import { environment } from './app/environments/environment';
import { hmrBootstrap } from './hmr';
import { bootloader } from '@angularclass/hmr';

const main = () => {
  if (environment.production) {
    enableProdMode();
  }

  const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

  if (environment.hmr) {
    if (module[ 'hot' ]) {
      hmrBootstrap(module, bootstrap);
    } else {
      console.error('HMR is not enabled for webpack-dev-server!');
      console.log('Are you using the --hmr flag for ng serve?');
    }
  } else {
    bootstrap().catch(err => console.log(err));
  }
};

bootloader(main); // boot on document ready




