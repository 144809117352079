import { FeaturesService } from './../../modules/features/services/features.service';
import { Feature } from './../../modules/features/models/feature.model';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({ selector: '[myCheckbox]' })

export class CheckboxDirective {

    public _defaultValue: boolean;
    public _defaultFeature: Feature;

    @Input('myCheckbox') value: boolean;

    @Input() set defaultValue(feature: Feature) {
        this._defaultFeature = feature;
        if (feature.value === 0) {
            this.el.nativeElement.checked = false;
            this._defaultValue = false;
        } else {
            this.el.nativeElement.checked = true;
            this._defaultValue = true;
        }
    }

    @HostListener('click') onClick() {
        if (this.value === false) { this.el.nativeElement.checked = true; } else { this.el.nativeElement.checked = false; }


        if (this._defaultValue === this.value) {
            this._defaultFeature.update = false;
            this.featuresService.pushUpdateButton(this._defaultFeature);
        } else {
            this._defaultFeature.update = true;
            this.featuresService.pushUpdateButton(this._defaultFeature);
        }

        /*if(this._defaultValue === this.value) document.getElementById('button-to-enable-disable').setAttribute('disabled','true');
        else document.getElementById('button-to-enable-disable').setAttribute('disabled','false');*/
    }

    constructor(private el: ElementRef, private featuresService: FeaturesService) {
    }
}
