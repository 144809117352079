export class UsersData {
    public id: number;
    public id_categoria: number;
    public ruolo: string;
    public sport: string;
    public email: string;
    public enabled: number;
    public nome: string;
    public data_nascita: string;
    public data_iscrizione: string;
    public img_principale: string;
    public colore_1: string;
    public colore_2: string;
    public colore_3: string;
    public sito: string;
    public id_struttura: number;
    public id_regione: number;
    public id_provincia: number;
    public citta: string;
    public via: string;
    public numero: number;
    public cap: number;
    public telefono: number;
    public facebook: string;
    public googleplus: string;
    public twitter: string;
    public id_ruolo: number;
    public id_squadra_attuale: number;
    public cognome: string;
    public sesso: string;
    public id_nazionalita: number;
    public ingaggio: number;
    public disponibilita: number;
    public spostamento: number;
    public id_societa: number;
    public patentino: string;
    public signup_source_id: number;
}
