import { Injectable, EventEmitter } from '@angular/core';
import { Feature } from '../models/feature.model';
import { HttpClient } from '@angular/common/http';
import { options } from '../../../shared/models/content-headers';
import { Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';

@Injectable()
export class FeaturesService {

    constructor(private httpClient: HttpClient) {}

    public pushedData = new EventEmitter<Feature>();
    public featuresUrl = '/api/features';

    public getAllFeatures(): Observable<any> {
      return this.httpClient.get(this.featuresUrl, options)
                .pipe(
                    catchError(this.handleError)
                );
    }

    public getFeatureById(id: number): Observable<any> {
      return this.httpClient.get(this.featuresUrl + '/' + id, options)
                .pipe(
                    catchError(this.handleError)
                );
    }

    public updateFeature(body: Feature, new_value: boolean): Observable<any> {
        const key: string = body.key;
        let value: number;
        if (new_value) { value = 1; } else { value = 0; }
        const note: string = body.note;
        const bodyRequest = JSON.stringify({key, value, note});
        return this.httpClient.put(this.featuresUrl + '/' + body.id, bodyRequest, options)
                    .pipe(
                        catchError(this.handleError)
                    );
    }

    public pushUpdateButton(feature: Feature) {
        this.pushedData.emit(feature);
    }

    private handleError (error: any) {
        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        const errMsg = (error.message) ? error.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error(errMsg); // log to console instead
        return Observable.throw(errMsg);
    }
}
