import { FirestoreService } from './../../../shared/services/firestore.service';
import { ApprovalPostAction } from 'app/shared/models/approval-post.model';
import { ApprovalPost } from './../../../shared/models/approval-post.model';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { Announcement } from '../models/announcement.model';
import { catchError, switchMap } from 'rxjs/operators';
import { Filter } from '../../../shared/enums/filters.enum';
import * as faker from 'faker';
import { DocumentSnapshot, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { FirestoreAnnouncement } from 'app/shared/models/firestore/announcement/announcement.model';

@Injectable()
export class AnnouncementsService {
  subjectAnnouncementToModal: Subject<Announcement> = new Subject<Announcement>();
  subjectModalType: Subject<string> = new Subject<string>();
  subjectAnnouncementId: Subject<string> = new Subject<string>();

  readonly paginationLenght = 5;

  constructor(private firestoreService: FirestoreService) {}

  getAllFirestoreAnnouncements(
    filter: Filter,
    startAt: DocumentSnapshot<Announcement> = null,
    nextPage = true,
    limit = this.paginationLenght,
  ): Observable<QueryDocumentSnapshot<Announcement>[]> {
    return this.firestoreService.getPaginatedAnnouncements(filter, startAt, nextPage, limit);
  }

  getApprovedAnnouncementByPublicAnnouncementId(
    announcementId: string,
  ): Observable<QueryDocumentSnapshot<FirestoreAnnouncement>> {
    return this.firestoreService.getApprovedAnnouncementByPublicAnnouncemntId(announcementId);
  }

  postAnnouncement(announcement: Announcement, announcementApprovalPost: ApprovalPost): Observable<Announcement> {
    console.log('[AnnouncementsService - postAnnouncement] announcement is going to be created!', announcement);
    if (!announcement.firestoreId) {
      throw new Error('firestoreId should be set!');
    }

    if (announcementApprovalPost.action === ApprovalPostAction.approve) {
      // create deletion token to be shared between public and private announcement
      announcement.deletion_token = faker.random.alphaNumeric(200);
    }

    return this.firestoreService.updatePublicAnnouncementFirestore(announcement, announcementApprovalPost).pipe(
      switchMap((announcement: Announcement) => {
        if (announcementApprovalPost.action !== ApprovalPostAction.approve) {
          return of(null);
        }

        return this.firestoreService.postAnnouncementInFirestore(announcement, announcement.deletion_token);
      }),
      catchError(this.handleError),
    );
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }

  setAnnouncementToModal(announcement: Announcement): void {
    this.subjectAnnouncementToModal.next(announcement);
  }

  getAnnouncementToModal(): Observable<Announcement> {
    return this.subjectAnnouncementToModal.asObservable();
  }

  setModalType(modalType: string): void {
    this.subjectModalType.next(modalType);
  }

  getModalType(): Observable<string> {
    return this.subjectModalType.asObservable();
  }

  setAnnouncementId(id: string): void {
    this.subjectAnnouncementId.next(id);
  }

  getAnnouncementId(): Observable<string> {
    return this.subjectAnnouncementId.asObservable();
  }
}
