import { PROVINCES } from './../mocks/provinces.mock';
import { Province } from './../models/province.model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class ProvinceService {

    private provinces: Province[] = [];

    constructor() {
        this.provinces = PROVINCES;
    }

    get(id: number): Province | null {
        return this.provinces.find(province => province.id === id);
    }
}
