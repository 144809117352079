import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CandidaciesListComponent } from './components/candidacies-list.component';
import { CandidaciesApprovalComponent } from './components/candidacies-approval.component';

import { AuthGuardService } from '../../shared/services/auth-guard.service';

const candidaciesApprovalRoutes: Routes = [
  {
    path: 'candidaciesapproval',
    redirectTo: '/candidaciesapproval/all',
    pathMatch: 'full'
  },
  {
    path: 'candidaciesapproval',
    component: CandidaciesApprovalComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: ':filter',
        component: CandidaciesListComponent,
        canActivate: [AuthGuardService]
      }
    ]
  }
];

export const candidaciesApprovalRouting: ModuleWithProviders = RouterModule.forChild(candidaciesApprovalRoutes);
