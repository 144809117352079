import { Region } from './../models/region.model';

export const REGIONS: Region[] = [
    {id: 1, value: 'piemonte'},
    {id: 2, value: 'valle d\'aosta'},
    {id: 3, value: 'lombardia'},
    {id: 4, value: 'trentino alto adige'},
    {id: 5, value: 'veneto'},
    {id: 6, value: 'friuli venezia giulia'},
    {id: 7, value: 'liguria'},
    {id: 8, value: 'emilia romagna'},
    {id: 9, value: 'toscana'},
    {id: 10, value: 'umbria'},
    {id: 11, value: 'marche'},
    {id: 12, value: 'lazio'},
    {id: 13, value: 'abruzzo'},
    {id: 14, value: 'molise'},
    {id: 15, value: 'campania'},
    {id: 16, value: 'puglia'},
    {id: 17, value: 'basilicata'},
    {id: 18, value: 'calabria'},
    {id: 19, value: 'sicilia'},
    {id: 20, value: 'sardegna'},
    {id: 21, value: 'san marino'},
];
