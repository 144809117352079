import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { FeaturesService } from './features.service';

@Injectable()
export class FeaturesResolve implements Resolve<any> {

  public error: any;

  constructor(private featuresService: FeaturesService) {}
  resolve(): Observable<any> | Promise<any> | any {
    return this.featuresService.getAllFeatures();
  }
}
