import { Component } from '@angular/core';

@Component({
  selector: 'no-announcement',
  template: `
    <div>
      <h3 class="text-center">Currently there are no announcements here</h3>
    </div>
  `
})
export class NoAnnouncementComponent {}
