import { Announcement } from '../models/announcement.model';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { AnnouncementsService } from '../services/announcements.service';
import { Observable, of } from 'rxjs';
import { MunicipalityService } from 'app/shared/services/municipality.service';
import { PROFILE_TYPES } from 'app/shared/mocks/profile-types.mock';
import { CATEGORIES } from 'app/shared/mocks/categories.mock';
import { ROLES } from 'app/shared/mocks/roles.mock';
import { QueryDocumentSnapshot } from '@angular/fire/firestore';
import { Filter } from 'app/shared/enums/filters.enum';
import { FirestoreAnnouncementCandidacy } from 'app/shared/models/firestore/announcement/candidacy.model';
import { Municipality } from 'app/shared/models/municipality';
import { FirestoreAnnouncement } from 'app/shared/models/firestore/announcement/announcement.model';

@Component({
  selector: 'app-announcement-item',
  templateUrl: './announcement-item.component.html',
  styleUrls: ['./announcement-item.component.css', './announcement-item-shared.component.css'],
})
export class AnnouncementItemComponent implements OnInit {
  _announcement: Announcement;
  @Input() set announcement(doc: QueryDocumentSnapshot<Announcement>) {
    this._announcement = { ...doc.data(), firestoreId: doc.id };
    this.setAnnouncementFields(this._announcement);
    this.setAnnouncementProperties();
  }
  @Input() set filter(filter: Filter) {
    this._filter = filter;
  }

  @Output() onDenyAnnouncement = new EventEmitter<Announcement>();
  @Output() onApproveAnnouncement = new EventEmitter<Announcement>();

  municipalityObservable$: Observable<Municipality>;
  candidaciesObservable$: Observable<QueryDocumentSnapshot<FirestoreAnnouncementCandidacy>[]>;
  firestoreAnnouncementObservable$: Observable<QueryDocumentSnapshot<FirestoreAnnouncement>>;
  appendedTargets = '';
  appendedRoles = '';
  appendedCategories = '';
  timer: number;

  private _filter: Filter;

  constructor(private announcementsService: AnnouncementsService, private municipalityService: MunicipalityService) {}

  ngOnInit(): void {
    this.municipalityObservable$ = this.municipalityService.getMunicipality(this._announcement.municipality_id);

    if (this._filter === Filter.APPROVED) {
      this.firestoreAnnouncementObservable$ = this.announcementsService.getApprovedAnnouncementByPublicAnnouncementId(
        this._announcement.firestoreId,
      );
    } else {
      this.firestoreAnnouncementObservable$ = of(undefined);
    }
  }

  setAnnouncementFields(announcement: Announcement) {
    // targets: any -> == not ===
    for (let i = 0; i < announcement.targets.length; i++) {
      for (const profileType of PROFILE_TYPES) {
        if (profileType.id == announcement.targets[i]) {
          announcement.targets[i] = profileType.viewValue;
        }
      }
    }
    // profile type: any -> == not ===
    for (const profileType of PROFILE_TYPES) {
      if (profileType.id == announcement.creator_profile_type_id) {
        announcement.creator_profile_type_id = profileType.viewValue;
      }
    }
    // categories: any -> == not ===
    for (let i = 0; i < announcement.categories.length; i++) {
      for (const genericCategory of CATEGORIES) {
        if (genericCategory.id === announcement.categories[i]) {
          announcement.categories[i] = genericCategory.value;
        }
      }
    }
    // roles: any -> == not ===
    for (let i = 0; i < announcement.roles.length; i++) {
      for (const genericRole of ROLES) {
        if (genericRole.id == announcement.roles[i]) {
          announcement.roles[i] = genericRole.value;
        }
      }
    }
  }

  setAnnouncementProperties(): void {
    // modify categories attribute in order that we have comma separated values
    // modify roles attribute in order that we have comma separated values
    for (let i = 0; i < this._announcement.targets.length; i++) {
      if (i === 0) {
        this.appendedTargets = this.capitalizeFirst(this._announcement.targets[i]);
      } else {
        this.appendedTargets = this.appendedTargets + this.capitalizeFirst(this._announcement.targets[i]);
      }
      if (i !== this._announcement.targets.length - 1) {
        this.appendedTargets = this.appendedTargets + ', ';
      }
    }

    for (let i = 0; i < this._announcement.categories.length; i++) {
      if (i === 0) {
        this.appendedCategories = this.capitalizeFirst(this._announcement.categories[i]);
      } else {
        this.appendedCategories = this.appendedCategories + this.capitalizeFirst(this._announcement.categories[i]);
      }
      if (i !== this._announcement.categories.length - 1) {
        this.appendedCategories = this.appendedCategories + ', ';
      }
    }

    for (let i = 0; i < this._announcement.roles.length; i++) {
      if (i === 0) {
        this.appendedRoles = this.capitalizeFirst(this._announcement.roles[i]);
      } else {
        this.appendedRoles = this.appendedRoles + this.capitalizeFirst(this._announcement.roles[i]);
      }
      if (i !== this._announcement.roles.length - 1) {
        this.appendedRoles = this.appendedRoles + ', ';
      }
    }
  }

  capitalizeFirst(value: string): string {
    const lowerCase: string = value.toString().toLowerCase();
    return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
  }

  approveAnnouncement(announcement: Announcement) {
    this.onApproveAnnouncement.emit(announcement);
  }

  denyAnnouncement(announcement: Announcement) {
    this.onDenyAnnouncement.emit(announcement);
  }

  // is true only if the announcement is in the pending_approval state (confirmed = 1 && deleted_at == null)
  canAnnouncementBeApprovedOrDeclined(): boolean {
    if (this._filter === Filter.APPROVED) {
      // announcement from private site db is already approved
      return false;
    }
    if (
      this._announcement.confirmed === 1 && // confirmed &&
      this._announcement.deleted_at == null
    ) {
      // not yet approved/declined
      return true;
    }
    return false;
  }

  showCreatorProfileType(): boolean {
    if (
      this._announcement.creator_profile_type_id !== '' &&
      this._announcement.creator_profile_type_id !== undefined &&
      this._announcement.creator_profile_type_id != null
    ) {
      return true;
    }
    return false;
  }

  showTitle(): boolean {
    if (this._announcement.title !== '' && this._announcement.title !== undefined && this._announcement.title != null) {
      return true;
    }
    return false;
  }

  showDescription(): boolean {
    if (
      this._announcement.description !== '' &&
      this._announcement.description !== undefined &&
      this._announcement.description != null
    ) {
      return true;
    }
    return false;
  }

  showName() {
    if (
      this._announcement.creator_name !== '' &&
      this._announcement.creator_name !== undefined &&
      this._announcement.creator_name != null
    ) {
      return true;
    }
    return false;
  }

  showSurname() {
    if (
      this._announcement.creator_surname !== '' &&
      this._announcement.creator_surname !== undefined &&
      this._announcement.creator_surname != null
    ) {
      return true;
    }
    return false;
  }

  showEmail() {
    if (
      this._announcement.creator_email !== '' &&
      this._announcement.creator_email !== undefined &&
      this._announcement.creator_email != null
    ) {
      return true;
    }
    return false;
  }

  showPhone() {
    if (
      this._announcement.creator_phone !== '' &&
      this._announcement.creator_phone !== undefined &&
      this._announcement.creator_phone != null
    ) {
      return true;
    }
    return false;
  }

  showMunicipality(): boolean {
    if (this._announcement.municipality_id !== '' && this._announcement.municipality_id !== undefined) {
      return true;
    }
    return false;
  }

  showTargets(): boolean {
    if (this._announcement.targets == null || this._announcement.targets === undefined) {
      this._announcement.targets = [];
    }
    if (this._announcement.targets.length !== 0) {
      return true;
    }
    return false;
  }

  showRoles(): boolean {
    if (this._announcement.roles == null || this._announcement.roles === undefined) {
      this._announcement.roles = [];
    }
    if (this._announcement.roles.length !== 0) {
      return true;
    }
    return false;
  }

  showCategories(): boolean {
    if (this._announcement.categories == null || this._announcement.roles === undefined) {
      this._announcement.categories = [];
    }
    if (this._announcement.categories.length !== 0) {
      return true;
    }
    return false;
  }

  showMan(sexes: string[]): boolean {
    // not === because, === is the equality included the type of the two properties in comparison
    // what is the type of null?
    if (sexes == null || sexes === undefined) {
      sexes = [];
    }
    let lowerCase: string;
    for (let i = 0; i < sexes.length; i++) {
      lowerCase = sexes[i].toString().toLowerCase();
      if (lowerCase === 'm' || lowerCase === 'uomini') {
        return true;
      }
    }
    return false;
  }

  showWoman(sexes: string[]): boolean {
    // not === because, === is the equality included the type of the two properties in comparison
    // what is the type of null?
    if (sexes == null || sexes === undefined) {
      sexes = [];
    }
    let lowerCase: string;
    for (let i = 0; i < sexes.length; i++) {
      lowerCase = sexes[i].toString().toLowerCase();
      if (lowerCase === 'f' || lowerCase === 'donne') {
        return true;
      }
    }
    return false;
  }

  showMinAge(): boolean {
    if (this._announcement.min_age == null || this._announcement.min_age === undefined) {
      return false;
    }
    return true;
  }

  showMaxAge(): boolean {
    if (this._announcement.max_age == null || this._announcement.max_age === undefined) {
      return false;
    }
    return true;
  }

  showCandidacies(): boolean {
    if (this._filter === Filter.APPROVED) {
      // announcement from private site db may have associated candidacies
      return true;
    }
    return false;
  }

  isRemunerated(): boolean {
    return this._announcement.remuneration === 1;
  }

  clickCandidaciesReference() {
    this.announcementsService.setAnnouncementId(this._announcement.firestoreId);
  }
}
