import { AuthService } from './../services/auth.service';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'sidebar',
  template: `
    <div class="sidebar" *ngIf="isAuthenticated$ | async" id="sidenav">
      <sidebar-links classAttributes="nav nav-sidebar"></sidebar-links>
    </div>
  `,
})
export class SidebarComponent {
  isAuthenticated$: Observable<boolean>;

  constructor(private authService: AuthService) {
    this.isAuthenticated$ = this.authService.isAuthenticated();
  }
}
