import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SpinnerModule } from '../../shared/modules/spinner/spinner.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { NewslettersComponent } from './components/newsletters.component';
import { NewslettersCreateComponent } from './components/newsletters-create.component';
import { NewslettersListComponent } from './components/newsletters-list.component';

import { NewslettersResolve } from './services/newsletters-resolve.service';
import { NewslettersService } from './services/newsletters.service';

import { newslettersRouting } from './newsletters.routing';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SpinnerModule,
    CollapseModule.forRoot(),
    newslettersRouting
  ],
  declarations: [
    NewslettersComponent,
    NewslettersCreateComponent,
    NewslettersListComponent,
  ],
  providers: [
      NewslettersResolve,
      NewslettersService
  ],
})
export class NewslettersModule {}
