import { FirestoreMunicipality } from './../models/firestore/municipality.model';
import { RegionService } from './region.service';
import { ProvinceService } from './province.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Municipality } from '../models/municipality';
import { MUNICIPALITIES } from '../mocks/municipalities.mock';

@Injectable({
    providedIn: 'root'
  })
export class MunicipalityService {

    private municipalities: Municipality[] = [];

    constructor(
        private provinceService: ProvinceService,
        private regionService: RegionService,
    ) {
        this.municipalities = MUNICIPALITIES;
    }

    getMunicipality(id: string): Observable<Municipality> {
        return Observable.create(observer => {
            const desiredMunicipality = this.municipalities.find(municipality => +municipality.id === +id);
            if (desiredMunicipality !== undefined) {
                observer.next(desiredMunicipality);
                observer.complete();
            } else {
                observer.error('Municipality with id ' + id + ' is not in the list of municipalities!');
            }
        });
    }

    getMunicipalitySync(id: string | number): Municipality {
        id = Number(id);
        return this.municipalities.find(municipality => municipality.id === id);
    }

    getFirestoreMunicipality(id: number): FirestoreMunicipality {
        const municipality = this.getMunicipalitySync(id);
        const province = this.provinceService.get(municipality.provinceId);
        const region = this.regionService.get(province.regionId);

        return {
            value: municipality.value,
            province: province.value,
            region: region.value,
        };
    }
}
