import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StatsComponent } from './components/stats.component';
import { StatsOverviewComponent } from './components/stats-overview.component';
import { StatsSessionComponent } from './components/stats-session.component';

import { StatsOverviewResolve } from './services/stats-overview-resolve.service';
import { StatsSessionResolve } from './services/stats-session-resolve.service';
import { AuthGuardService } from '../../shared/services/auth-guard.service';

const statsRoutes: Routes = [
  {
    path: 'stats',
    redirectTo: '/stats/overview',
    pathMatch: 'full'
  },
  {
    path: 'stats',
    component: StatsComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'overview',
        component: StatsOverviewComponent,
        canActivate: [AuthGuardService],
        resolve: {
              statsOverview: StatsOverviewResolve
            }
      },
      {
        path: 'session',
        component: StatsSessionComponent,
        canActivate: [AuthGuardService],
        resolve: {
              statsSession: StatsSessionResolve
            }
      }
    ]
  }
];

export const statsRouting: ModuleWithProviders = RouterModule.forChild(statsRoutes);
