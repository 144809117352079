import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { NewslettersService } from './newsletters.service';

@Injectable()
export class NewslettersResolve implements Resolve<any> {

  public error: any;

  constructor(private newslettersService: NewslettersService) {}
  resolve(): Observable<any> | Promise<any> | any {
    return this.newslettersService.getNewsletters();
  }
}
