import { ProfileType } from 'app/shared/enums/profile-type.enum';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileTypeService {

  constructor() { }

  /**
   * Sometimes the profile type from the backend can assume different values (1,2,3,'player', 'coach', 'team', etc)
   * This method makes sure that those values are correctly casted to the corresponding enum
   */
  mapProfileTypeIdOrStringToProfileType(profileType: string | number): ProfileType {
        if (!profileType) {
            throw new Error(`Given profileType is not valid! Given value is ${profileType}`);
        }
        const castedProfileType = parseInt(<string>profileType);
        if (isNaN(castedProfileType)) {
            switch (profileType) {
                case 'player': return ProfileType.player;
                case 'giocatore': return ProfileType.player;
                case 'coach': return ProfileType.coach;
                case 'allenatore': return ProfileType.coach;
                case 'team': return ProfileType.team;
                case 'squadra': return ProfileType.team;
                default: throw new Error(`Unsupported profile type ${profileType}`);
            }
        } else {
            switch (castedProfileType) {
                case 1: return ProfileType.player;
                case 2: return ProfileType.coach;
                case 3: return ProfileType.team;
                default: throw new Error(`Unsupported profile type ${profileType}`);
            }
        }
    }
}
