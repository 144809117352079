import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import { UsersData } from '../models/users-data.model';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit {

  public subscription: UsersData;
  public isSubscription = false;

  constructor(private usersService: UsersService) {}

  ngOnInit() {
    this.usersService.userSelected.subscribe(
            (userId: string) => {
              this.usersService.getUser(userId).subscribe(
                (subscription: any) => {
                  this.subscription = subscription;
                  this.isSubscription = true;
                },
                (error: any) => {
                  // console.log(error);
                }
              );
            },
            (error: any) => {
              // console.log(error);
            }
        );
  }

  openModal(subscription: UsersData) {
    this.usersService.pushData(subscription);
  }



}
