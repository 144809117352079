import { Component } from '@angular/core';
import { Tab } from '../../../shared/modules/tab/models/tab.model';

@Component({
  selector: 'stats',
  templateUrl: './stats.component.html'
})
export class StatsComponent {

  public tabs: Tab[];
  public tabOverview: Tab;
  public tabSession: Tab;

  constructor() {
    this.tabs = [];
    this.tabOverview = new Tab();
    this.tabSession = new Tab();
    this.tabOverview.path = '/stats/overview';
    this.tabOverview.name = 'Overview';
    this.tabSession.path = '/stats/session';
    this.tabSession.name = 'Session';
    this.tabs.push(this.tabOverview);
    this.tabs.push(this.tabSession);
  }
}
