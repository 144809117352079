import { Component, OnInit } from '@angular/core';
import { Users } from '../models/users.model';
import { StatsOverview } from '../models/stats-overview.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'stats-overview',
  templateUrl: './stats-overview.component.html'
})

export class StatsOverviewComponent implements OnInit {

  updated_at: string;
  isUserDataAvailable = false;

  // Doughnut users
  doughnutChartUsersLabels: string[];
  doughnutChartUsersData: number[];
  doughnutChartType = 'doughnut';

  // Doughnut users gender
  doughnutChartGenderLabels: string[];
  doughnutChartGenderData: number[];

  // Pie users origin sources
  pieChartSourcesLabels: string[];
  pieChartSourcesData: number[];
  pieChartType = 'pie';

  // Number users enabled
  numberEnabledUsers: number;
  titleEnabledUsers = 'Enabled users';
  descriptionEnabledUsers = 'How many users have an enabled account';

  // Number users disabled
  numberDisabledUsers: number;
  titleDisabledUsers = 'Disabled users';
  descriptionDisabledUsers = 'How many users have a disabled account';

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data.forEach((data: { statsOverview: StatsOverview }) => {
      if ('users' in data.statsOverview) {
        this.isUserDataAvailable = true;

        let players: number = data.statsOverview.users.players;
        let teams: number = data.statsOverview.users.teams;
        let coaches: number = data.statsOverview.users.coaches;
        const male: number = data.statsOverview.users.male;
        const female: number = data.statsOverview.users.female;
        if (isNaN(players)) { players = 0; }
        if (isNaN(teams)) { teams = 0; }
        if (isNaN(coaches)) { coaches = 0; }

        this.doughnutChartUsersLabels = ['players', 'teams', 'coaches'];
        this.doughnutChartUsersData = [players, teams, coaches];

        this.doughnutChartGenderLabels = ['female', 'male'];
        this.doughnutChartGenderData = [female, male];

        this.pieChartSourcesLabels = data.statsOverview.origin_sources.labels;
        this.pieChartSourcesData = data.statsOverview.origin_sources.data;

        this.numberEnabledUsers = data.statsOverview.total_enabled_users;
        this.numberDisabledUsers = data.statsOverview.total_disabled_users;

        this.updated_at = data.statsOverview.updated_at;
      }
    });
  }
}
