import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { options } from '../../../shared/models/content-headers';
import { Observable } from 'rxjs';
import { StatsSession } from '../models/stats-session.model';

import { catchError } from 'rxjs/operators';

@Injectable()
export class StatsSessionService {
  constructor (private httpClient: HttpClient) {}

  public statsSessionUrl = '/api/stats?scope=session';  // URL to web API
  public statsSession: StatsSession;

  session(): Observable<any> {
      return this.httpClient.get(this.statsSessionUrl, options)
              .pipe(
                catchError(this.handleError)
              );
  }

  private handleError (error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
