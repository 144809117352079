import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { options } from '../../../shared/models/content-headers';
import { Observable } from 'rxjs';
import { UsersSubscribed } from '../models/users-subscribed.model';
import { UsersData } from '../models/users-data.model';

import { catchError } from 'rxjs/operators';

@Injectable()
export class UsersService {
  constructor (private httpClient: HttpClient) {}

  public pushedData = new EventEmitter<UsersData>();
  public userSelected = new EventEmitter<string>();
  public usersUrl = '/api/users';  // URL to web API
  public usersSubscribed: UsersSubscribed;

  getUsers(urlParam?: string): Observable<any> {
      if (urlParam === undefined) {
        return this.httpClient.get(this.usersUrl, options)
                .pipe(
                  catchError(this.handleError)
                );
      }
      return this.httpClient.get(this.usersUrl + '?page=' + urlParam, options)
              .pipe(
                catchError(this.handleError)
              );

  }

  getUser(userId?: string): Observable<any> {
      return this.httpClient.get(this.usersUrl + '/' + userId, options)
              .pipe(
                catchError(this.handleError)
              );
  }

  private handleError (error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }

  pushData(usersData: UsersData) {
    this.pushedData.emit(usersData);
  }

  pushUserSelected(userId: string) {
    this.userSelected.emit(userId);
  }
}
