import { Component } from '@angular/core';
import { Tab } from '../../../shared/modules/tab/models/tab.model';

@Component({
  selector: 'announcementsapproval',
  templateUrl: './announcements-approval.component.html'
})
export class AnnouncementsApprovalComponent {

  public tabs: Tab[];
  public tabAll: Tab;
  public tabPendingApproval: Tab;
  public tabPendingConfirmation: Tab;
  public tabDeclined: Tab;
  public tabApproved: Tab;

  constructor() {
    this.tabs = [];

    this.tabAll = new Tab();
    this.tabAll.path = '/announcementsapproval/all';
    this.tabAll.name = 'All';

    this.tabPendingApproval = new Tab();
    this.tabPendingApproval.path = '/announcementsapproval/pending_approval';
    this.tabPendingApproval.name = 'Pending Approval';

    this.tabPendingConfirmation = new Tab();
    this.tabPendingConfirmation.path = '/announcementsapproval/pending_confirmation';
    this.tabPendingConfirmation.name = 'Pending Confirmation';

    this.tabDeclined = new Tab();
    this.tabDeclined.path = '/announcementsapproval/declined';
    this.tabDeclined.name = 'Declined';

    this.tabApproved = new Tab();
    this.tabApproved.path = '/announcementsapproval/approved';
    this.tabApproved.name = 'Approved';

    this.tabs.push(this.tabAll);
    this.tabs.push(this.tabPendingApproval);
    this.tabs.push(this.tabPendingConfirmation);
    this.tabs.push(this.tabDeclined);
    this.tabs.push(this.tabApproved);

  }
}
