import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UsersComponent } from './components/users.component';
import { SearchComponent } from './components/search.component';
import { SubscriptionsComponent } from './components/subscriptions.component';
import { InstantSearchComponent } from './components/instantsearch.component';

import { SubscriptionsResolve } from './services/subscriptions-resolve.service';
import { AuthGuardService } from '../../shared/services/auth-guard.service';

const usersRoutes: Routes = [
  {
    path: 'users',
    redirectTo: '/users/search',
    pathMatch: 'full',
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'search',
        component: SearchComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'subscriptions',
        component: SubscriptionsComponent,
        canActivate: [AuthGuardService],
        resolve: {
              subscriptions: SubscriptionsResolve
            }
      },
      {
        path: 'instantsearch',
        component: InstantSearchComponent,
        canActivate: [AuthGuardService],
      }
    ]
  }
];

export const usersRouting: ModuleWithProviders = RouterModule.forChild(usersRoutes);
