export const COLLECTIONS = {
  users: 'users',
  usersProfiles: 'profiles',
  announcements: 'announcements',
  candidacies: 'candidacies',
  publicCandidacies: 'publicCandidacies',
  publicAnnouncements: 'publicAnnouncements',
  pagination: 'pagination',
  paginationSponsorships: 'sponsorships',
  paginationAnnouncements: 'announcements',
  paginationCandidacies: 'candidacies',
  paymentTransactions: 'paymentTransactions',
  tasks: 'tasks',
  emailSubscriptionTokens: 'emailSubscriptionTokens',
  contactUs: 'contactUs',
};
