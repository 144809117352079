import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FeaturesComponent } from './components/features.component';

import { FeaturesResolve } from './services/features-resolve.service';
import { AuthGuardService } from '../../shared/services/auth-guard.service';

const featuresRoutes: Routes = [
  {
    path: 'features',
    component: FeaturesComponent,
    canActivate: [AuthGuardService],
    resolve: {
              features: FeaturesResolve
            }
  }
];

export const featuresRouting: ModuleWithProviders = RouterModule.forChild(featuresRoutes);
