import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { FeaturesService } from '../../modules/features/services/features.service';
import { Feature } from '../../modules/features/models/feature.model';

@Directive({ selector: '[myButton]' })

export class ButtonDirective implements OnInit {

    @Input('myButton') key: string;

    constructor(private el: ElementRef, private featuresService: FeaturesService) {
    }

    ngOnInit() {
        this.featuresService.pushedData.subscribe(
            (feature: Feature) => {
                if (this.key === feature.key) {
                    if (feature.update) { this.el.nativeElement.disabled = true; } else { this.el.nativeElement.disabled = false; }
                 }
            }
        );
    }
}
